.signature-modal {
  .modal-dialog {
    z-index: 100;
  }

  .modal-header {
    .close-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
  }
}
