.user-settings-my-signatures-side-bar-container {
  min-width: 300px;
  width: 300px;
  height: 100vh;
  @include themed() {
    background: t("bgRHS");
    border: 1px solid t("lightToneContrast");
  }
  box-shadow: 0px 3px 6px #00000029;

  z-index: 1;
  @media (max-width: $screen-lg) and (min-width: $screen-md) {
    width: 250px;
    min-width: 250px;
  }
  @media (max-width: $screen-md) {
    width: 100vw;
    overflow: hidden;
  }
  .my-sginature-side-bar-back-btn {
    @media (max-width: $screen-md) {
      position: absolute;
      top: 15px;
      left: 10px;
    }
  }
  .my-signatures-side-bar-avatar-container {
    margin-top: 46px;
    display: flex;
    justify-content: center;
  }
  h1 {
    margin-top: 13px;
    margin-bottom: 50px;
    font-size: 20px;
    @include themed() {
      color: t("fontColor");
    }
    text-align: center;
  }

  .my-signature-side-bar-search-container {
    margin-left: 20px;
    margin-right: 30px;
    margin-bottom: 15px;
    input {
      @include themed() {
        color: t("text");
      }
    }
    .MuiInput-underline::after {
      @include themed() {
        border-bottom-color: t("primary");
      }
    }
  }

  .my-signature-side-bar-number-container {
    padding: 20px 17px;
    display: flex;
    @include themed() {
      color: t("fontDarkGray");
    }
    cursor: pointer;
    &:hover {
      @include themed() {
        background: t("cardBorder");
      }
    }

    &.active {
      @include themed() {
        background: t("cardBorder");
      }
    }

    .number-title {
      font-size: 14px;
    }
    .number-assigned {
      font-weight: 500;
      @include themed() {
        color: t("primary");
      }
    }
    .number-number-text {
      font-size: 16px;
    }
  }
}

.user-settings-my-signatures-container {
  padding: 47px 68px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: $screen-md) {
    padding: 0px;
    justify-content: center;
  }
  @media (max-width: $screen-lg) and (min-width: $screen-md) {
    padding: 30px 20px;
  }
}

.user-settings-my-signatures-card-info {
  min-width: 400px;
  max-width: 400px;
  height: 100%;
  flex-basis: calc(50% - 68px - 68px);
  @media (max-width: $screen-md) {
    min-width: 350px;
    margin-right: 0px !important;
    width: 90%;
    margin-top: 15px;
  }
  @media (max-width: $screen-xs) {
    min-width: 300px;
  }
}

.user-settings-my-signatures-card-info-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .card-info-title-subtitle {
    font-size: 14px;
    font-weight: 500;
  }

  .globe-icon {
    margin-top: 3px;
  }

  .switch-with-label {
    span[label] {
      font-weight: 400;
    }
  }
}

.user-settings-my-signatures-card-info-container {
  min-height: 85px;
  @include themed() {
    color: t("fontDarkGray");
  }
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .themed-button {
    @include themed() {
      color: t("primary");
    }
  }
}
