@import "./base/variables";
@import "./base/global-theme";

/*------------------------------------------------------------------
[ Filter View]
*/
.filter-sidebar {
  visibility: hidden;
  width: 0%;
  transition: none;

  @include themed() {
    background: t("leftSideMenu") !important;
    border-right: 1px solid t("sideBarDividerColor");
  }
  z-index: 999;
  position: relative;

  .filter-sidebar-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10100;
    background: rgba(0, 0, 0, 0.75);
  }

  .filter-sidebar-header {
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-right: 11px;

    .show-info {
      cursor: pointer;
      fill: grey;
    }

    .filter-sidebar-title {
      @include themed() {
        color: t("leftMenuText");
      }

      font-size: 16px;
      flex: 1;
    }

    a.filter-icon {
      svg path {
        fill: #808080;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .sidebar-menus {
    height: 100%;
    padding-left: 15px;
    padding-right: 10px;

    ul.filters-list {
      list-style: none;
      padding-left: 0px;
      padding-top: 4px;

      li a,
      div.collapsible-header {
        display: flex;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 11px;
        font-size: 14px;
        color: #664f8e;

        .label {
          flex: 1;
          padding-top: 2px;
          margin-left: 1px;

          i {
            font-size: 12px;
          }

          span.text {
            width: auto;
            padding-right: 10px;
            display: inline-block;
          }

          @media (max-width: $screen-md) {
            font-size: 18px;
          }
        }

        &.active {
          border-radius: 50px;
          color: #ffffff;

          @include themed() {
            background: t("filterBackgroundHover");
            border: 2px solid t("filterBackgroundHover");
          }

          div.icon {
            svg path:not([fill="none"]) {
              fill: white !important;
            }

            svg#web-widgets-purple {
              g {
                stroke: white;
              }
            }
          }

          .number.default-hidden {
            visibility: visible;
          }
        }

        &:hover {
          border-radius: 50px;

          @include themed() {
            background: t("filterBackgroundHover");
            border: 2px solid t("filterBackgroundHover");
          }

          .label {
            @include themed() {
              color: t("settingsMenuText");
            }
          }

          div.icon {
            svg path:not([fill="none"]) {
              fill: white !important;
            }

            svg#web-widgets-purple {
              g {
                stroke: white;
              }
            }
          }
        }

        &.level2-collapse {
          padding-left: 10px;
        }

        .back-icon {
          @media (max-width: $screen-md) {
            margin-top: 5px;
          }
        }
      }

      .collapsible-header[aria-expanded="true"] .im-angle-down .fa-angle-down {
        transform: rotate(180deg);
        transition: transform 150ms ease;
      }

      .collapsible-header[aria-expanded="false"] .im-angle-down .fa-angle-down {
        transition: transform 150ms ease;
      }

      div.collapsible-body {
        padding-left: 33px;

        a {
          padding-left: 10px;
        }

        .title-with-integration-badge {
          margin-right: 5px;
        }

        .one-way-integration-badge {
          background-color: #a6a6a6;
          padding: 4px;
          margin-left: 4px;
          width: 70px;
          font-size: 11px;
          height: fit-content;

          svg {
            padding-bottom: 1px;
          }
        }
      }

      .menu-filter-list {
        .label {
          padding-left: 20px;
        }

        @include themed() {
          color: t("menuFontColor");

          svg path:not([fill="none"]) {
            fill: t("menuFontColor");
          }

          svg#web-widgets-purple {
            g {
              stroke: t("menuFontColor");
            }
          }
        }

        &.active {
          background: #6c37c9 !important;

          @include themed() {
            color: white;
          }
        }

        &:hover {
          background: #6c37c9 !important;

          .label {
            color: white !important;
          }
        }

        @media (max-width: $screen-md) {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-top: 12px;
          padding-left: 20px;
        }
      }
    }

    @media (max-width: $screen-md) {
      padding: 0px;
    }
  }

  .filter-sidebar-inner {
    padding-left: 5px;
    display: flex;
    flex-direction: column;
    height: 100%;

    ul.filters-list {
      list-style: none;
      padding-left: 0px;
      padding-top: 10px;

      li {
        margin-right: 14px;
        margin-left: 8px;
      }

      li[id="allPeople"] {
        position: relative;

        .export-all-people {
          position: absolute;
          right: 0px;
          top: 0px;
          cursor: pointer;

          &.export-all-people-loading {
            top: 0px;
            right: 0px;
          }

          .spinner div {
            width: 1rem;
            height: 1rem;
            border: 0.25em solid #7155c9;
            border-right-color: transparent;
          }
        }
      }

      li a,
      div.collapsible-header {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        font-size: 14px;
        color: #808080;
        transition: 0s;
        padding-left: 10px;
        padding-right: 10px;

        .label {
          display: flex;
          width: 100%;
          padding: 5px 5px 5px 10px;
          margin-right: 10px;

          i {
            font-size: 12px;
          }

          span.text {
            width: auto;
            display: inline-block;

            &.highlighted {
              font-weight: 500;

              @include themed() {
                color: t("sideBarTextHighlight");
              }
            }
          }
        }

        .number {
          font-size: 14px;
          margin-left: auto;
          max-width: 32px;
          min-width: 32px;
          width: 32px;
          text-align: end;

          &.red {
            color: #ff5800;
          }

          &.default-hidden {
            visibility: hidden;
          }
        }

        div.icon {
          color: #d9d9d9;

          svg path:not([fill="none"]) {
            fill: #808080;
          }

          &.highlighted {
            svg path:not([fill="none"]) {
              @include themed() {
                fill: t("sideBarTextHighlight");
              }
            }
          }

          &.icon-with-status {
            position: relative;

            span.notification-badge-undelivered {
              background-color: #ff5800;
              position: absolute;
              top: 2px;
              right: 10px;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              display: block;
            }

            span.notification-badge-unread {
              background-color: #ffffff;
              position: absolute;
              top: 2px;
              right: 10px;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              display: block;
            }

            span.notification-badge-blue {
              background-color: #64c3e0;
              position: absolute;
              top: 4px;
              right: 4px;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              display: block;
            }
          }
        }

        &:hover {
          border-radius: 50px;

          @include themed() {
            background: t("sideBarBackgroundHover");
          }

          @include themed() {
            color: t("sideBarColorHover");
          }

          svg path:not([fill="none"]) {
            @include themed() {
              fill: t("sideBarColorHover");
            }
          }

          svg#pin-yellow {
            path {
              fill: #f5b400 !important;
            }
          }

          svg#pin-gray {
            path {
              fill: #a9a9a9 !important;
            }
          }

          div.icon {
            color: #fff;

            @include themed() {
              path:not([fill="none"]) {
                fill: t("leftMenuText") !important;
              }
            }
          }

          .number.default-hidden {
            visibility: visible;
          }
        }

        &.active {
          border-radius: 50px;

          @include themed() {
            background: t("filterBackgroundHover");
          }

          @include themed() {
            color: t("leftMenuText");
          }

          div.icon {
            color: #fff;

            @include themed() {
              path:not([fill="none"]) {
                fill: t("leftMenuText") !important;
              }
            }
          }

          .number.default-hidden {
            visibility: visible;
          }
        }

        &.level2-collapse {
          padding-left: 10px;
        }
      }

      .collapsible-header[aria-expanded="true"] .im-angle-down .fa-angle-down {
        transform: rotate(180deg);
        transition: transform 150ms ease;
      }

      .collapsible-header[aria-expanded="false"] .im-angle-down .fa-angle-down {
        transition: transform 150ms ease;
      }

      div.collapsible-body {
        padding-left: 22px;

        a {
          padding-left: 10px;
        }

        .title-with-integration-badge {
          margin-right: 5px;
        }

        .one-way-integration-badge {
          background-color: #a6a6a6;
          padding: 4px;
          margin-left: 4px;
          width: 70px;
          font-size: 11px;
          height: fit-content;

          svg {
            padding-bottom: 1px;
          }
        }
      }
    }

    @media (max-width: $screen-lg) {
      padding-left: 0px;
      padding-right: 5px;
    }
  }

  .more-menu-back-btn {
    border-bottom: 1px solid #707070;
    padding: 10px 10px 30px 10px;

    .label {
      @include themed() {
        color: t("leftMenuText");
      }

      font-size: 18px;
    }
  }

  .mobile-menus-filter-item {
    border-bottom: 1px solid #707070;
    padding: 25px 0px 20px 25px;
    text-transform: capitalize;
    font-weight: 500;

    &.text-inherit {
      text-transform: inherit;
    }

    .user-avatar-name-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .user-name {
        @include themed() {
          font-size: 16px;
          font-weight: 500;
          color: t("inverseFontColor");
        }
      }
    }

    .label {
      @include themed() {
        color: t("leftMenuText");
      }

      font-size: 18px;
    }

    .post {
      @include themed() {
        color: t("leftMenuText");
      }

      font-size: 14px;
    }

    @media (max-width: $screen-md) {
      .credit-balance-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .billing-analytics-container {
          margin-left: 10px;

          .name {
            @include themed() {
              color: t("inverseFontColor");
            }
          }
        }
      }
    }

    @media (max-width: $screen-md) {
      .number-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;

        .phone-number-wrapper {
          border-radius: 5px;
          padding: 2px 5px;
          border: 1px solid #707070;
        }

        .number {
          @include themed() {
            color: t("inverseFontColor") !important;
          }

          margin-left: 10px;
        }
      }
    }

    .medium-label {
      @include themed() {
        color: t("leftMenuText");
      }

      font-size: 16px;

      svg {
        @include themed() {
          path:not([fill="none"]),
          circle {
            fill: t("leftMenuText") !important;
          }
        }
      }
    }

    .more-menu {
      @include themed() {
        color: t("inverseFontColor");
      }

      font-size: 16px;

      svg {
        @include themed() {
          path:not([fill="none"]),
          circle {
            fill: t("inverseFontColor") !important;
          }
        }
      }
    }

    .number-row {
      @media (max-width: $screen-md) {
        font-size: 16px;

        @include themed() {
          color: t("leftMenuText");
        }
      }

      .filter-number {
        @media (max-width: $screen-md) {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          @include themed() {
            color: t("leftMenuText");
          }

          svg {
            @include themed() {
              path:not([fill="none"]),
              circle {
                fill: t("leftMenuText") !important;
              }
            }
          }
        }

        .number {
          @media (max-width: $screen-md) {
            color: $sidebar-icon-default;
            margin-left: 10px;
          }
        }
      }
    }

    .menuitem {
      @media (max-width: $screen-md) {
        font-size: 16px;

        @include themed() {
          color: t("leftMenuText");
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;

        svg {
          @include themed() {
            path:not([fill="none"]),
            circle {
              fill: t("leftMenuText") !important;
            }
          }
        }
      }
    }

    .company-details {
      @media (max-width: $screen-md) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: capitalize;
        font-weight: 500;
        font-size: 16px;
        width: 90%;

        @include themed() {
          color: t("leftMenuText");

          path:not([fill="none"]) {
            fill: t("leftMenuText");
          }
        }
      }
    }
  }
}

.filter-sidebar-active {
  width: 270px;
  visibility: visible;
}

.filter-sidebar-menus-active {
  width: 230px;
  visibility: visible;
}

.filter-sidebar-menus-keywords-active {
  width: 68px;
  visibility: visible;
}

.newContact-btn {
  color: #000000;

  &:hover {
    color: #000000;
  }
}

.add-people-popover {
  display: flex;
  flex-direction: column;
}

.submenu-sidebar-inner {
  padding: 0px 9px 0px 12px;
  height: 100%;

  .sub-menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .toggle-filter-btn {
      cursor: pointer;
      background: transparent;
      outline: none;
      border: none;
    }

    .search-icon {
      cursor: pointer;
    }
  }

  .tab-label {
    padding-left: 4px;

    @include themed() {
      color: t("leftMenuText");
    }

    // margin-top: 25px;
    text-transform: capitalize;
    font-size: 1rem;
  }

  ul.search-panel-container {
    border-bottom: 1px solid #707070;
    padding-bottom: 15px;
  }

  ul.search-panel-container:last-child {
    border-bottom: none;
  }

  ul.sub-menu-filters-list {
    list-style: none;
    padding-left: 0px;
    padding-top: 4px;

    div.search-panel-header {
      &:hover {
        border: none !important;
      }
    }

    li a,
    div.collapsible-header {
      display: flex;
      padding: 5px 11px 5px 10px;
      font-size: 14px;
      margin-bottom: 8px;
      color: #808080;
      width: 100%;
      transition: none;
      border-radius: 50px;

      .label {
        flex: 1;
        text-wrap: nowrap;

        i {
          font-size: 12px;
        }

        span.text {
          width: auto;
          padding-right: 10px;
          display: inline-block;
        }
      }

      div.icon {
        color: #d9d9d9;

        svg path:not([fill="none"]) {
          fill: #808080;
        }
      }

      &:hover {
        border-radius: 50px;

        @include themed() {
          background: t("sideBarBackgroundHover");
          color: t("sideBarColorHover");
        }

        svg path:not([fill="none"]) {
          @include themed() {
            fill: t("sideBarColorHover");
          }
        }

        .number.default-hidden {
          visibility: visible;
        }
      }

      &.active {
        @include themed() {
          background: t("filterBackgroundHover");
          color: t("leftMenuText");
        }

        div.icon {
          color: #fff;

          svg path:not([fill="none"]) {
            fill: #fff;
          }
        }

        .number.default-hidden {
          visibility: visible;
        }
      }
    }

    div.collapsible-body {
      padding-left: 26px;

      .title-with-integration-badge {
        margin-right: 5px;
      }

      .one-way-integration-badge {
        background-color: #a6a6a6;
        padding: 4px;
        margin-left: 4px;
        width: 70px;
        font-size: 11px;
        height: fit-content;

        svg {
          padding-bottom: 1px;
        }
      }
    }
  }
}

.notification-dot-unresolved-conflicts {
  top: 1px !important;
  right: 2px !important;
}

.dot-icon {
  display: inline-block;
  background: #64c3e0;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  cursor: pointer;
}

.submenu-new-chip {
  color: white;
  background-color: #4caf50;
  font-size: 0.6rem;
  height: 15px;
  font-weight: 400;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  .submenu-new-chip-label {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 1px;
  }
}

.filter-notfication {
  color: white;
  background-color: #4caf50;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-left: 5px;
  font-size: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    padding-top: 1px;
  }
}

.filter-item-expand-icon-wrapper {
  display: flex;
  align-items: center;
  width: 25px;
  min-width: 25px;
  max-width: 25px;
  height: 100%;
  margin-right: -10px;
}

.filter-item-expand-icon {
  margin-right: 10px;
  height: auto;

  &.highlighted {
    path:not([fill="none"]) {
      @include themed() {
        fill: t("sideBarTextHighlight");
      }
    }
  }
}

.collapsible-header[aria-expanded="true"] .filter-item-expand-icon {
  transform: rotate(90deg);
  transition: transform 150ms ease;
}

.collapsible-header[aria-expanded="false"] .filter-item-expand-icon {
  transition: transform 150ms ease;
}

.filter-item-icon-wrapper {
  display: inline-flex;
  margin-right: 6px;
  position: relative;
  top: 2px;
}

.filter-item-badge {
  margin-left: 3px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: block;
  align-self: center;

  span.notification-badge-danger {
    background-color: #ff5800;
    margin-left: 0px;
    @extend .filter-item-badge;
  }

  span.notification-badge-white {
    background-color: #ffffff;
    margin-left: 0px;
    @extend .filter-item-badge;
  }

  span.notification-badge-info {
    background-color: #64c3e0;
    margin-left: 0px;
    @extend .filter-item-badge;
  }
}

.filter-item-integration-icon {
  display: flex;
  align-items: center;
}

.filter-pin-unpin-button-container {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden-filters-collapse-menu {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #808080;
  transition: 0s;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;

  .filters-list {
    padding-top: 8px;
  }

  .collapsable-menu-wrapper {
    justify-content: flex-start !important;
    align-items: center !important;

    .angle-down-up-icon {
      margin-left: 20px !important;
    }
  }
}

.hidden-filter-divider {
  background: #808080;
  margin-top: 0px !important;
}
