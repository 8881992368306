.new-contact-modal {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 380px;
    }
  }

  .modal-content {
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    min-height: 450px;
  }

  .modal-header {
    border-bottom: 0;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }
  .modal-body {
    label {
      @include themed() {
        color: t("label");
      }
    }
    input {
      @include themed() {
        color: t("text");
      }
    }
    .MuiInput-underline::after {
      @include themed() {
        border-bottom-color: t("primary") !important;
      }
    }
  }
  .input-field {
    // margin-bottom: 28px;
    margin-top: 40px;
    ::placeholder {
      color: #8f8f8f;
      font-size: 0.94rem;
    }
    &.warning {
      .form-control {
        border-color: $secondary;
      }
      .form-text {
        color: $secondary;
        display: block;
        text-align: right;
      }
    }
    .form-text {
      display: none;
    }
    .label {
      color: #707070;
      font-size: 0.75rem;
      margin-bottom: 0;
    }
  }

  .form-control {
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    border-top: 0;
    color: #122b28;
    padding: 0;
  }

  .old-number-selector {
    display: none;
    margin-bottom: 30px;
    text-align: center;
    &.active {
      display: block;
    }
    &.selected {
      .btn:not(.active) {
        border-color: #dbdbdb;
        path {
          fill: #dbdbdb;
        }
      }
    }
    .btn {
      align-items: center;
      border: 1px solid #707070;
      border-radius: 10px !important;
      display: inline-flex;
      height: 50px;
      justify-content: center;
      margin: 0 5px;
      padding: 0;
      transition: border-width 0.3s ease-in-out;
      width: 80px;
      &:hover {
        border: 4px solid #b69be4;
      }
      &.active {
        border: 4px solid $primary;
        box-shadow: none;
      }
    }
  }

  .modal-footer {
    border-top: 0;
    .btn {
      min-width: 90px;
    }
  }
}

.modal-content {
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);

  .group-type {
    border-bottom: 0;
    justify-content: center;
    margin-bottom: 10px;
    text-align: center;
    .MuiInput-formControl {
      input {
        @include themed() {
          color: t("text");
        }
      }
    }
    .MuiInput-underline::after {
      @include themed() {
        border-bottom-color: t("primary");
      }
    }

    &.inner {
      justify-content: center;
      margin-bottom: 30px;

      .nav-item {
        flex-grow: unset;
      }

      a {
        height: 50px;
        width: 65px;
      }
    }

    a {
      align-items: center;
      border: 1px solid #dbdbdb;
      border-radius: 10px !important;
      display: inline-flex;
      flex-direction: column;
      height: 100px;
      justify-content: center;
      margin: 0 5px;
      padding: 0;
      transition: border-width 0.3s ease-in-out;
      width: 140px;
      @include themed() {
        background: t("bgRHS");
      }

      &:focus {
        background: none;
      }

      svg {
        @include themed() {
          path:not([fill="none"]) {
            fill: t("text") !important;
          }
        }
      }

      &:hover {
        border: 4px solid #b69be4;
      }

      &.active {
        @include themed() {
          border-color: t("primary");
        }

        border: 4px solid;
        box-shadow: none;

        svg {
          @include themed() {
            path:not([fill="none"]) {
              fill: t("primary") !important;
            }
          }
        }

        span {
          @include themed() {
            color: t("primary");
          }
        }
      }

      span {
        @include themed() {
          color: t("text");
        }
        display: inline-block;
        font-weight: bold;
        margin-top: 10px;
      }
    }
  }

  .tag-input-wrapper {
    &.show-keywords {
      .input-field {
        display: block;
      }
    }

    .input-field {
      display: none;
    }
  }

  .tag-input {
    position: relative;

    &.has-value {
      .add-btn {
        opacity: 1;
      }
    }

    input {
      padding-right: 30px;
    }

    .add-btn {
      color: $primary;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .input-field {
    .label {
      @include themed() {
        color: t("label");
      }
      font-size: 12px;
      margin-left: 5px;
    }

    .custom-select {
      color: #122b28;
      font-size: 1rem;
    }

    .form-control {
      @include themed() {
        color: t("iconColor");
        background: t("bgRHS");
      }

      font-size: 1rem;
    }

    .filled-mode {
      align-items: center;
      border-bottom: 1px solid #dbdbdb;
      display: flex;
      height: 38px;

      .value-wrapper {
        color: #122b28;
        overflow: hidden;
        padding-left: 7px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .edit-icon {
        color: $primary;
        display: inline-block;
        padding: 7px;
      }

      .drop-arrow {
        font-size: 7px;
        margin-left: auto;
        margin-right: 7px;
      }
    }
  }
  .form-control {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    padding-left: 5px;
    padding-right: 0;
    @include themed() {
      background: t("bgRHS");
    }
  }

  .custom-switch {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding-left: 3rem;

    .info-icon {
      color: #122b28;
      font-size: 16px;
      margin-top: 3px;
    }
  }

  .custom-switch .custom-control-label::before {
    background: rgba(180, 180, 180, 0.4);
    border: 0;
    height: 16px;
    left: -3rem;
    width: 30px;
  }

  .custom-switch .custom-control-label::after {
    background: #fff;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.16));
    left: calc(-3rem + 2px);
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background: rgba($primary, 0.4);
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background: $primary;
  }
}
