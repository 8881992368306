.user-settings-my-organizations-container {
  @extend .container-flex-column-100;
  .user-settings-my-organizations-header-container {
    padding: 25px 32px 25px 43px;
    @media (max-width: $screen-md) {
      padding: 10px;
    }

    .user-settings-my-organizations-tabs-header {
      padding: 0;
      border: none;
    }
  }

  .user-settings-my-organizations-table-header {
    tr th {
      padding-bottom: 5px;
      @include themed() {
        border-color: t("tableDivider") !important;
      }
      border-top: 1px solid;
      border-bottom: 1px solid;
    }
  }
  .user-settings-my-organizations-table-body {
    tr {
      &.active {
        @include themed() {
          background: t("bgRHS");
        }
      }
    }
    tr td:first-child {
      border-bottom: none;
    }
    td {
      @include themed() {
        border-color: t("tableDivider") !important;
      }
    }
  }
  .user-settings-my-organizations-scroll-table {
    @media (max-width: $screen-md) {
      height: 80vh;
    }
  }
}

.user-settings-my-organizations-rhs {
  width: 320px;
  .user-settings-my-organizations-rhs-avatar-container {
    margin: 64px 114px 15px 114px;
  }
  .user-settings-my-organizations-rhs-title {
    font-size: 20px;
    @include themed() {
      color: t("iconColor");
    }

    font-weight: 500;
    margin-bottom: 28px;
    text-align: center;
    padding: 0 10px;
  }

  .user-settings-my-organizations-rhs-text-container {
    @extend .text-container-rhs;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 82px;
    font-size: 16px;

    .login-text {
      font-size: 16px;
      @include themed() {
        color: t("fontDarkGray");
      }
    }
    .themed-primary-color {
      @include themed() {
        color: t("primary");
      }
    }
    .label {
      font-size: 14px;
      @include themed() {
        color: t("label");
      }
    }

    .text {
      font-size: 16px;
      @include themed() {
        color: t("iconColor");
      }
    }
    .transfer-text {
      font-size: 16px;
      @include themed() {
        color: t("primary");
      }
      cursor: pointer;
    }
    .switch-to-transfer-text {
      font-size: 14px;
      @include themed() {
        color: t("label");
      }
    }
  }

  .anchor-disabled {
    opacity: 0.5;
  }
}

.user-settings-my-organizations-header-title {
  flex: auto;
  display: flex;
  @include themed() {
    color: t("primary");
  }

  @media (max-width: $screen-lg) {
    font-size: 12px;
  }
  .preview-icon {
    cursor: pointer;
    align-self: center;
    display: none;
  }
  &:hover {
    .preview-icon {
      display: block;
    }
  }
}
