.delete-greeting-container-modal {
  .modal-dialog {
    width: 690px;
    max-width: 690px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }
  }
}

.delete-greeting-container {
  padding: 18px 29px 36px 26px;
  .delete-greeting-title {
    @extend .custom-modal-title;
    margin-bottom: 19px;
  }
  .delete-greeting-text {
    font-size: 16px;
    margin-bottom: 0;
  }
  .delete-greeting-footer {
    margin-top: 35px;
    display: flex;
    justify-content: flex-end;

    .delete-button {
      width: 135px;
    }
  }
}
