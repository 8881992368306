.remove-widget-modal {
  .modal-dialog {
    width: 490px;
    max-width: 490px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
}

.remove-widget-container {
  padding: 19px 25px 35px 25px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 28px;
  }
  p {
    font-size: 16px;
    margin-bottom: 28px;
  }
}
