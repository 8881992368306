.upgrade-plan-wrapper {
  .modal-content {
    width: 520px;
    height: 339px;
  }
  .upgrade-plan-image {
    width: 50%;
  }
  .upgrade-plan-container {
    display: flex;
    .upgrade-plan-image {
      width: 50%;
    }
    .upgrade-plan-text {
      width: 60%;
      padding: 50px 20px 0px 20px;
      .title {
        font-size: 36px;
        color: $primary;
        font-weight: bold;
      }
      .countdown-timer {
        display: block;
        color: #808080;
        font-size: 24px;
        font-weight: bold;
      }
      td {
        text-align: center;
      }
      .time-elements,
      .countdown-promo {
        font-size: 12px;
        font-weight: normal;
      }
      .upgrade-plan-button {
        float: right;
      }
    }
  }
}
