@import "../base/global-theme";

.chat-main-wrapper.active {
  display: table;
}

.mobile-user-menu {
  .header-action-modal {
    .modal-dialog {
      width: 100%;
      margin: 0;
      align-items: flex-start;

      .modal-content {
        border: none;
        background: $primary;

        .close {
          text-align: center;
          color: #fff;
          opacity: 1;

          i {
            font-size: 45px;
          }
        }
      }

      .modal-body {
        .user-menu {
          display: flex;
          list-style: none;
          padding-left: 0;

          li {
            margin-right: 31px;

            .nav-link {
              color: #fff;
              padding: 0;
              position: relative;

              .badge {
                position: absolute;
                color: #fff;
                top: 4px;
                right: -10px;
                background: #122b28;
                width: 17px;
                height: 17px;
                line-height: 17px;
                font-size: 11px;
                font-weight: 500;
                border-radius: 50%;
                text-align: center;
                padding: 0;
              }
            }
          }
        }

        .left-menu {
          padding: 0;
          margin: 0;
          list-style-type: none;

          li {
            line-height: 1.5;
            padding: 10px 0;
          }

          .dropdown-toggle {
            padding: 0;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
          }

          .dropdown-menu {
            min-width: 136px;

            .dropdown-item {
              height: 30px;
              line-height: 30px;
            }

            .dropdown-divider {
              border: none;
            }
          }

          .free-trial {
            color: #fff;
            font-size: 16px;
            font-weight: 500;
          }

          .credits {
            color: #fff;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.overlay-non-desktop {
  position: absolute;
  width: calc(100% + 60px);
  top: 0;
  left: -60px;
  height: 100%;
  z-index: 1000000;

  @include themed() {
    background: t("bg");
  }

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    left: 0;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .resolution-back-btn-container {
    position: fixed;
    top: 20px;
    left: 20px;
  }
}

.page-wrapper.inbox {
  .chat-wrap-inner {
    .no-message-info {
      color: #707070;
      font-size: 2.375rem;
      font-weight: 300;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      @media (max-width: 900px) {
        font-size: 1.5rem;
      }

      @media (max-width: 600px) {
        font-size: 1rem;
      }

      .no-message-icon {
        color: #dbdbdb;
        font-size: 5.94rem;

        @media (max-width: 900px) {
          font-size: 3.13rem;
        }

        @media (max-width: 600px) {
          font-size: 1.88rem;
        }
      }

      .call-broadcast-button {
        min-width: 285px;

        .button-small {
          padding: 5px 7px;
          min-width: 120px;

          &:hover {
            color: #fff;
          }

          &:first-child {
            margin-right: 10px;
          }

          &:last-child {
            margin-left: 10px;
          }

          img {
            margin-right: 7px;
          }
        }

        @media (max-width: 900px) {
          margin-top: 20px;
        }
      }
    }
  }

  .task-view {
    .fixed-header {
      padding: 16px 15px;

      .navbar {
        display: flex;
        justify-content: space-between;

        .search-wrapper {
          @include themed() {
            background: t("textArea");
            border: 1px solid t("fontDarkGray");
          }

          display: flex;
          align-items: center;
          height: 34px;
          padding: 0 10px;
          border-radius: 17px;

          &.focused {
            border: 1px solid $primary;

            .search-icon {
              display: none;
            }

            .dropdown {
              display: block;
            }
          }

          &.has-value {
            .clear-icon {
              display: block;
            }
          }

          .selected-value {
            color: $primary;
          }

          input {
            border: none;
            background: none;
            padding-left: 5px;
            width: auto;
            min-width: 1px;
            flex-grow: 1;
          }

          .search-action {
            display: flex;
            align-items: center;
          }

          .dropdown {
            display: none;

            .dropdown-toggle {
              border: none;
              background: none;
              margin-left: 0;

              &:focus {
                outline: 0;
              }
            }

            .dropdown-menu {
              right: 0;
              left: auto;
            }
          }

          .clear-icon {
            display: none;
            font-size: 20px;
            color: $primary;
            margin-left: 5px;
          }

          .search-icon {
            font-size: 0.8125rem;
          }
        }

        .cancel-btn {
          font-size: 2rem;
        }

        .action {
          display: flex;
          align-items: center;

          .call {
            color: $font-color-dark;
            display: flex;
          }

          .call-people {
            display: flex;
            margin-right: 30px;

            @media (max-width: $screen-md) {
              margin-right: 5px;
            }
          }

          .call-not {
            display: none !important;
          }

          &.active {
            .call {
              display: none;
            }

            .call-not {
              display: flex !important;

              svg {
                height: 20px;
                width: 20px;

                path {
                  fill: $font-color-dark;
                }
              }
            }
          }

          .thread-header-icon {
            @include themed() {
              path:not([fill="none"]) {
                fill: t("iconColor") !important;
              }
            }
          }
        }
      }

      .nav-tabs {
        min-height: 56px;

        .nav-item {
          .nav-link {
            padding: 15px;
          }
        }
      }

      .new {
        .btn-secondary {
          color: #fff;
          background: #c0392b;
          font-size: 1rem;
          font-weight: 500;
          padding: 9px 29px;
          border-radius: 5px;

          &:after {
            display: none;
          }
        }

        .dropdown-menu {
          border: 1px solid #707070;
          border-radius: 3px;

          .dropdown-item {
            display: flex;
            align-items: center;
            color: #122b28;
            font-size: 0.875rem;
            font-weight: 500;

            &:focus {
              background-color: none;
            }

            &:hover {
              background-color: #fff;
            }

            .icon {
              display: inline-block;
              width: 40px;
              text-align: left;
            }
          }
        }
      }

      &.header-tabs-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
        padding-bottom: 10px !important;
      }

      &.light-tone {
        min-height: 60px;
        padding: 0 10px;
        display: flex;
        align-items: center;

        @include themed() {
          background: t("bgRHS");
        }

        border-bottom: 1px soild $light-tone-contrast;

        .avatar-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: calc(100% - 48px);
          max-width: calc(100% - 48px);

          &.adjust-btn {
            flex: 1;
            width: 100%;
            max-width: 100%;
          }

          .avatar {
            width: 30px;
            min-width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: 0.88rem;
            font-weight: 400;
            color: $avatar-text-purple;

            &.group-avatar {
              padding: 1px;
            }
          }

          .thread-header-icon {
            @include themed() {
              path:not([fill="none"]) {
                fill: t("iconColor") !important;
              }
            }
          }

          .top-panel-toggle-btn {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border: none;
            outline: none;
            background: none;
          }

          .chat-info {
            display: flex;
            align-items: center;
            margin-left: 10px;

            @include themed() {
              color: t("fontColor");
            }

            .multi-contacts {
              font-size: 1rem;

              @include themed() {
                color: t("fontColor");
              }

              font-weight: 400;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: flex;
              align-items: center;
            }

            strong {
              display: inline-block;
              max-width: 100%;
              margin-right: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 1rem;

              @include themed() {
                color: t("fontColor");
              }

              font-weight: 500;
            }

            .icon {
              svg path {
                @include themed() {
                  color: t("fontColor");
                }
              }
            }
          }
        }

        .avatar-wrapper-people {
          display: block;
          align-items: center;
          justify-content: space-between;
          max-width: calc(100% - 48px);
          margin-top: 10px;
          margin-left: 20px;

          &.adjust-btn {
            width: calc(100% - 69px);
            max-width: calc(100% - 69px);
          }

          @media (max-width: $screen-md) {
            margin-left: 0px;
          }

          .avatar {
            line-height: 30px;
            font-size: 0.88rem;
            font-weight: 400;
            color: $avatar-text-purple;
            margin-right: 10px;

            &.group-avatar {
              padding: 1px;
            }
          }

          .top-panel-toggle-btn {
            display: flex;
            align-items: center;
            border: none;
            outline: none;
            background: none;
          }

          .chat-info-group-icons {
            margin-top: -3px;

            .applycommitte {
              display: flex;
              align-items: center;
              margin-left: 2px;

              .applycommitte-subtitle {
                font-size: 12px;
                color: #808080;
              }

              .icon {
                margin-top: 0;
                margin-right: 3px;
              }
            }

            .chat-info-group {
              span {
                font-weight: 400;
                font-size: 18px;
              }
            }
          }

          .chat-info {
            align-items: center;
            width: inherit;
            height: 50px;
            margin-right: 10px;

            .multi-contacts {
              font-size: 1rem;
              color: $font-color-dark;
              font-weight: 400;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: flex;
              align-items: center;
            }

            .multi-contacts-email {
              position: absolute;
              bottom: 0;
            }

            .applycommitte {
              display: flex;

              .icon {
                margin-top: 5px;
                margin-right: 3px;
              }
            }

            strong {
              display: inline-block;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 1rem;

              @include themed() {
                color: t("fontColor");
              }

              font-weight: 500;
              margin-bottom: 2px;
              max-width: calc((100vw - 900px) / 2);
              overflow: hidden;
            }

            span.phone-number {
              display: inline-block;
              max-width: calc((100vw - 900px) / 2);
              margin-right: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 14px;
              color: gray;
            }

            svg {
              @include themed() {
                path:not([fill="none"]) {
                  fill: t("fontMidGray") !important;
                }
              }
            }

            .icon {
              svg path {
                fill: $font-color-dark;
              }
            }
          }

          .chat-info-group {
            .themed-color {
              @include themed() {
                color: t("text");
              }
            }
          }

          .one-way-integration-badge {
            background-color: #a6a6a6;
            padding: 4px;
            margin-left: 4px;
            width: 70px;
            font-size: 11px;

            svg {
              padding-bottom: 1px;
            }
          }
        }

        .nav-people {
          margin-left: 18px;
          margin-bottom: -10px;

          .nav-item {
            @include themed() {
              background: t("bgRHS") !important;
            }
          }

          .navbar-light {
            .navbar-nav {
              .nav-link {
                font-size: 0.875rem;
                font-weight: 400;

                @include themed() {
                  color: t("iconColor") !important;
                }

                &:hover {
                  @include themed() {
                    color: t("primary") !important;
                  }
                }

                &:focus {
                  @include themed() {
                    color: t("primary") !important;
                  }

                  font-weight: 500;
                }
              }

              .nav-link.active {
                @include themed() {
                  color: t("primary") !important;
                }

                font-weight: 500 !important;
              }
            }
          }
        }

        .navbar {
          display: flex;
          justify-content: space-between;
          width: 100%;

          &.group {
            strong {
              margin-right: 11px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              max-width: 225px;
            }
          }

          .close {
            background: $primary;
            width: 34px;
            height: 34px;
            line-height: 34px;
            min-width: 34px;
            text-align: center;
            border-radius: 50%;
            color: #fff;
            opacity: 1;
            margin-right: 20px;
            margin-left: 5px;

            i {
              font-size: 1rem;
            }
          }
        }

        .dropdown {
          .filter-number {
            color: #707070;
            font-size: 0.8125rem;
            font-weight: 400;

            &:after {
              vertical-align: -1px;
              border-top: 0.4rem solid;
              border-bottom: 0;
              border-right: 0.4rem solid transparent;
              border-left: 0.4rem solid transparent;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}

.nav-tabs {
  .nav-item {
    flex-grow: 1;
    text-align: center;

    .nav-link {
      color: #122b28;
      font-size: 0.875rem;
      font-weight: 400;

      &:hover {
        background: none;
      }
    }

    @media (max-width: $screen-md) {
      flex-grow: 0;
      margin-top: 0px !important;
    }
  }
}

.chat-sidebar .chat-contents {
  position: relative;

  &.message {
    padding: 0;
  }

  .form-group {
    margin-bottom: 0;
  }

  .date-picker-wrapper .input-group-addon {
    width: 25px;
    height: 25px;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .date-picker-wrapper input.form-control {
    height: 0 !important;
    width: 0;
    padding: 0;
    border: none;
    opacity: 0;
  }

  .date-picker-wrapper .input-group-addon img {
    width: 18px;
  }

  .table-content {
    .button-flat {
      background: $primary;
      width: 100%;
      display: block;
      text-align: center;
      height: 60px;

      i {
        color: #fff;
        font-size: 24px;
        line-height: 60px;
      }
    }

    .message-saved.modal {
      &.show {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }

      .modal-body {
        background: $primary;
        padding: 35px;

        p {
          margin: 0;
          font-size: 20px;
          font-weight: bold;
          color: #fff;
          text-align: center;
        }
      }
    }

    .defult-message {
      color: #707070;
      font-size: 24px;
      font-weight: 300;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .contact-detail-slide {
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 0;

      &.show {
        .modal-dialog {
          -webkit-transform: translate(0, 0) !important;
          transform: translate(0, 0) !important;
        }
      }

      .modal-dialog {
        height: 100%;
        min-height: 100%;
        margin: 0;
        -webkit-transform: translate(100%, 0) !important;
        transform: translate(100%, 0) !important;
      }

      .modal-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: none;
        background: #f8f8f8;
        overflow-y: auto;

        .modal-body {
          padding: 0;

          .top-action {
            top: 80px;
          }
        }
      }
    }
  }

  .search {
    position: relative;

    input {
      background: #f5f5f5;
      border: 1px solid #dbdbdb;
      border-radius: 28px;
      font-size: 1rem;
      font-weight: 400;
      height: 33px;
      margin-bottom: 12px;
      padding: 8px;
      width: 100%;
    }

    ::placeholder {
      color: #707070;
    }

    i {
      font-size: 0.8125rem;
      position: absolute;
      right: 31px;
      top: 29px;
    }
  }

  .group-name {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 9px;
    padding: 20px 20px 0;

    h3 {
      align-items: center;
      color: #122b28;
      font-size: 1.25rem;
      font-weight: 500;
      margin: 0 0 0 15px;
    }

    .edit {
      border-radius: 5px;
      color: $primary;
      font-size: 0.75rem;
      font-weight: 400;
      margin-left: 13px;
      padding: 1px 7px;

      &:hover {
        border: 1px solid $primary;
      }
    }
  }

  .contact-single {
    display: flex;
    align-items: center;
    padding: 10px 20px;

    &:hover {
      .group-action {
        display: none !important;
      }

      .action-hover {
        display: flex;
      }
    }

    .action-hover {
      display: none;
      width: 50px;
      justify-content: space-between;

      .close-button,
      .delete-icon {
        position: relative;
        right: auto;
      }

      .custom-checkbox {
        margin-right: 20px;
      }
    }

    &.has-collapse {
      flex-direction: row-reverse;
      justify-content: flex-end;

      .arrow-d,
      .arrow-r {
        margin-left: 10px;
        color: #707070;
      }
    }

    .number-type {
      margin-right: 13px;
    }

    &.disabled {
      &:hover {
        .name strong {
          color: #122b28;
        }
      }

      .action-hover {
        width: 50px;
      }

      .avatar {
        background: #dbdbdb !important;

        &.img {
          background: #fff !important;
        }
      }

      .name strong {
        color: #c4c4c4;
      }
    }

    .custom-control {
      margin-left: auto;
      margin-right: 20px;
      margin-top: -10px;
    }

    .avatar {
      background: #fff;
      width: 42px;
      height: 42px;
      line-height: 42px;
      color: #fff;
      font-size: 1.25rem;
      font-weight: 400;

      &.purple {
        background: #db83e6;
      }

      &.teal {
        background: #20cb96;
      }

      &.blue {
        background: #73baef;
      }

      &.orange {
        background: #fa8d0e;
      }

      &.green {
        background: #abce68;
      }
    }

    .name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 2px;
      color: #122b28 !important;

      a {
        color: #122b28;
      }

      strong {
        color: #122b28;
        font-size: 1rem;
        font-weight: 500;
      }
    }

    .close-button {
      border: 1px solid $primary !important;
      border-radius: 5px !important;
      color: $primary;
      width: 18px;
      height: 18px;
      transition: all 0.2s ease-in-out;
      position: absolute;
      right: 48px;
      text-align: center;

      i {
        font-size: 16px;
        line-height: 18px;
      }

      &:hover {
        color: #c0392b;
        border: 1px solid #c0392b !important;
      }
    }

    .delete-icon {
      color: $primary;
      position: absolute;
      right: 20px;
      transition: all 0.2s ease-in-out;

      i {
        font-size: 22px;
      }

      &:hover {
        color: #c0392b;
      }
    }
  }

  // collaspe start//
  .custom-collapse {
    .contact-single {
      &:hover {
        .close-button {
          visibility: visible;
        }

        .delete-icon {
          visibility: visible;
        }
      }

      .close-button {
        border: 1px solid $primary !important;
        border-radius: 5px !important;
        color: $primary;
        width: 22px;
        height: 22px;
        transition: all 0.2s ease-in-out;
        position: absolute;
        right: 48px;
        text-align: center;
        visibility: hidden;

        i {
          font-size: 18px;
          line-height: 22px;
        }
      }

      .delete-icon {
        color: $primary;
        position: absolute;
        right: 20px;
        visibility: hidden;
        transition: all 0.2s ease-in-out;

        i {
          font-size: 22px;
        }
      }
    }
  }
}

.info-container {
  padding: 25px 20px;
  display: flex;
  position: relative;
  height: 100%;

  @include themed() {
    background: t("bg");
  }

  .close {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 20px;
  }

  @media (max-width: $screen-lg) {
    flex-wrap: wrap;
    padding: 0px;
    justify-content: center;
  }
}

.list-group {
  border: none;

  .list-group-item {
    padding: 0px;
    border: none;
    font-size: 15px;
    font-weight: 400;
    color: #6c757d;
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    p {
      margin: 0;
      min-height: 27px;
    }

    .list-group-item-text {
      color: black;
      font-size: 16px;
      display: flex;
      align-items: flex-start;

      span {
        display: inline-block;
      }
    }

    .list-group-item-text.unknown {
      color: #6c757d70;
      font-size: 15px;
    }

    .check {
      font-weight: 500;
      font-size: 14px;
      margin-top: 2px;
      margin-left: 4px;
    }

    .save {
      font-weight: 500;
      font-size: 13px;
      margin-left: 5px;
    }

    label#standard-password-input-label {
      font-size: 20px;
    }
  }
}

//Detail
.table th,
.table td {
  padding: 1rem;
  vertical-align: top;
  border-bottom: 1px solid;
  font-weight: 400;
  font-size: 16px;

  @include themed() {
    border-color: t("tableDivider") !important;
  }
}

.tags-table-container {
  @media (max-width: $screen-md) {
    margin-left: 0px;
    height: 85vh;
  }

  td,
  th {
    @media (max-width: $screen-md) {
      padding: 10px;
    }
  }
}

.detail-header {
  @include themed() {
    color: t("tableHeader");
    background: t("bg");
    border-color: t("tableDivider");
  }

  border-bottom: 2px solid;
  height: 60px;
  display: flex;
  align-items: center;
  vertical-align: bottom;
  font-size: 18px;
  font-weight: 500;
  padding-left: 36px;
  padding-right: 40px;
  justify-content: space-between;

  > div {
    &:first-child {
      width: 50%;
    }
  }

  .integrated-group-filters {
    font-size: 14px;
    font-weight: normal;
    color: $primary;

    div {
      display: inline-block;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &.header-with-stats {
    height: auto;
  }

  @media (max-width: $screen-md) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.table {
  @include themed() {
    color: t("tableHeader");
    background: t("bg");
  }

  position: relative;
  z-index: 0;
}

.table thead th {
  vertical-align: bottom;
  font-size: 13px;
  font-weight: 400;
  border: none;
  position: sticky;
  top: 0;

  @include themed() {
    color: t("tableHeader");
    background: t("bg");
  }

  overflow: hidden;
  z-index: 2;
}

.search {
  font-size: 15px;
  font-weight: 500;
  margin-top: 5px;
  margin-right: 20px;

  @include themed() {
    color: t("primary");
  }

  cursor: pointer;
}

.search-input {
  border: none !important;
  background: none;
  padding-left: 5px;
  min-width: 1px;
  flex-grow: 1;
  margin-bottom: 0 !important;
  height: 32px;
  padding-right: 20px;
  font-size: 15px;

  @include themed() {
    color: t("fontColor");
  }
}

.table > tbody > tr > td {
  vertical-align: middle;
  font-weight: 400;
  font-size: 16px;
}

.tr {
  background: white;
}

.scroll-table {
  min-height: 100px;

  @include themed() {
    background: t("bg");
  }

  .custom-scrollbars > div:last-child,
  .custom-scrollbars > div:nth-child(2) {
    opacity: 0;
    transition: opacity 200ms ease;
  }

  .custom-scrollbars:hover > div:last-child,
  .custom-scrollbars:hover > div:nth-child(2) {
    opacity: 1;
  }
}

path#search-2 {
  fill: #6c757d;
}

th.col-md-2 {
  width: 200px;
  margin-left: 2px;
  min-width: 200px;
}

.more {
  max-width: 56px !important;
}

// MODAL

.inbox-modal.confirm-group-message {
  .modal-dialog {
    max-width: 762px;

    .modal-content {
      padding: 14px 25px 14px 50px;
      border: none;
      box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
      border-radius: 5px;

      @media (max-width: $screen-md) {
        padding: 10px;
        height: auto !important;
        max-height: auto !important;
      }

      .modal-header {
        border-bottom: none;
        padding: 0;

        .close {
          padding: 0;
          color: #c0392b;
          opacity: 1;
          font-size: 2.1875rem;
          font-weight: bold;

          &:focus {
            outline: 0;
          }
        }
      }

      .modal-body {
        padding: 0;

        .caller-info {
          display: flex;

          .custom-checkbox
            .custom-control-input:checked
            ~ .custom-control-label::after {
            // new icons
            background-image: url("../img/icons-new/general/check/check-white.svg");
          }
        }
      }

      .text-container {
        margin-left: 30px;
      }

      .checkbox-container {
        position: relative;

        .checkmark {
          left: -2px;
          top: -1px;
          border: 1px solid #666666;
          width: 18px;
          height: 18px;
          margin-left: 0;

          &.checkmark-checked {
            border: none;
            background: $sidebar-icon-default;
          }

          svg {
            position: absolute;
            left: -2px;
            top: -2px;
          }
        }

        input {
          opacity: 0;
          position: absolute;
          width: 18px;
          height: 18px;
          z-index: 1;
        }
      }

      .custom-control-label::before {
        border: 1px solid #5517c1 !important;
      }

      .number-status,
      .phone-type,
      .integrations,
      .opt-in {
        min-width: 180px;
        color: #6c757d;
        margin-right: 30px;
        font-size: 12px;

        > div:first-child {
          margin-top: 20px;
        }
      }

      .number-status {
        min-width: 180px;
      }

      .phone-type {
        min-width: 150px;
      }

      .integrations {
        min-width: 140px;
      }

      .opt-in {
        min-width: 130px;
      }

      .custom-control {
        display: block;
      }

      .modal-purpose-description {
        color: #808080;
        font-size: 12px;
        padding-bottom: 44px;
      }

      .custom-control-label {
        font-weight: 400;
      }

      .multiple-contacts-actions-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .skip-sending-multiple-content {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        // color: #202020;
        margin-bottom: 11.45px;
      }

      .skip-sending-multilple-sub-content {
        // color: #808080;
        font-size: 12px;
        border-bottom: 1px solid #d3d3d3;
        padding-bottom: 44px;
      }

      .table-row-multi-select {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #808080;
        border-top: 1px solid black;

        &:not(:last-child) {
          padding-right: 75px;
        }
      }

      .multiple-contacts-actions-sub-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 37%;
        margin-left: 37%;
      }

      .multiple-contacts-action-btn {
        text-align: center;
        border-top: 1px solid #d3d3d3;
        margin-left: -50px;
        margin-right: -25px;
        padding: 14px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #5517c1;

        &:hover {
          cursor: pointer;
        }
      }

      .multiple-contacts-action-btn-bottom {
        text-align: center;
        border-top: 1px solid #d3d3d3;
        margin-left: -50px;
        margin-right: -25px;
        padding: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #8f8f8f;

        &:hover {
          cursor: pointer;
        }
      }

      .remove-person-container-items {
        font-size: 24px;
      }

      .remove-person-confirmation {
        font-size: 16px;
        margin-top: 24px;
      }

      .skip-sending-content {
        font-size: 24px;
      }

      .skip-sending-confirmation {
        margin-top: 10px;
        font-size: 16px;
      }

      .skip-sending-confirmation-multiple {
        margin-top: 10px;
        font-size: 16px;
        margin-bottom: 30px;
      }

      .switch-modal-bottom {
        position: absolute;
        left: 0px;
      }

      .modal-content-stats {
        div {
          cursor: pointer;
          color: #6c37c9;
          font-size: 16px;
          font-weight: bold;

          &.additional-text {
            color: #202020;
            font-weight: 400;
          }
        }
      }

      .toggle-page-btn:disabled {
        color: #808080;
        background-color: #808080;
      }

      .title {
        display: flex;
        align-items: center;

        h4 {
          // color: #122b28;
          font-size: 1.25rem;
          font-weight: bold;
          margin: 30px 0 25px;
        }

        a {
          font-size: 1.25rem;
          font-weight: 400;
          color: #c0392b;
          margin-left: 30px;

          strong {
            color: #122b28;
            font-weight: bold;
          }
        }

        &.sleek {
          font-size: 24px;
          margin-bottom: 25px;
        }
      }

      .content {
        &.caller-info {
          p {
            margin: 0 0 30px;

            @include themed() {
              color: t("fontDarkGray");
            }

            font-size: 16px;
            font-weight: 400;
          }

          hr {
            @include themed() {
              border-top-color: t("fontColor");
            }
          }
        }

        &.sleek {
          p {
            margin: 0 0 30px;
            font-size: 16px;
            font-weight: 400;
          }
        }

        &.warning-content {
          p {
            color: #ff5800;
          }
        }

        .wrapper {
          margin-top: 20px;

          strong {
            color: #122b28;
            font-size: 1rem;
            font-weight: 500;
          }

          i {
            color: #122b28;
            font-size: 0.8125rem;
          }
        }

        span {
          @include themed() {
            color: t("fontDarkGray");
          }

          font-size: 1rem;
          font-weight: 400;
          margin-right: 7px;
        }

        .info-single {
          &.message-report {
            margin-top: 15px;
            margin-right: 0;
          }

          margin-right: 25px;
          margin-top: 27px;
        }

        .custom-control-label::before {
          border: 1px solid #6c757d;
        }

        .custom-checkbox
          .custom-control-input:checked
          ~ .custom-control-label::before {
          border: 1px solid #6c757d;
        }

        .custom-checkbox
          .custom-control-input:checked
          ~ .custom-control-label::after {
          //new icons
          background-image: url("../img/icons-new/general/check/check-black.svg");
        }

        .label {
          color: #707070;
          font-size: 1rem;
          font-weight: 400;

          &:after {
            left: 0;
          }

          &:before {
            left: 0;
          }

          strong {
            color: #122b28;
            font-weight: bold;
          }
        }

        .value {
          color: #122b28;
          font-size: 1rem;
          font-weight: 400;
        }
      }

      .modal-footer {
        padding: 0;
        border-top: none;
      }
    }

    .current-time {
      color: #122b28;
      font-size: 0.75rem;
      font-weight: 400;
      margin: 0;
    }

    .check-number {
      .check-number-label {
        color: #707070;
        font-size: 16px;
        padding-left: 27px;
      }

      .check-number-label::before {
        border-color: #122b28;
        border-radius: 0;
        left: 0;
        position: absolute;
      }

      .check-number-label::after {
        left: 0;
        position: absolute;
      }
    }

    .insert-btn {
      height: 38px;
    }
  }
}

.modal-video-spinner {
  .modal-dialog {
    .modal-content {
      padding: 0;
      background: transparent;
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// MODAL ENDED//
// CHAT VOICE MESSAGE

.chats {
  .voice-message-tabs {
    border-bottom: none;
    background: none;

    .nav-item {
      flex-grow: 0;
      margin-left: 25px;
    }

    .icon-image {
      margin-right: 5px;
    }

    .nav-link {
      padding: 10px;
      font-size: 0.88rem;
      font-weight: 400;
      color: #122b28;
      border-left: none;
    }
  }

  .voice-message-tab-content {
    .web-record-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 30px;

      .time {
        font-size: 38px;
        font-weight: 300;
        color: #122b28;
        border-bottom: 1px solid #707070;
        max-width: 200px;
        width: 100%;
        text-align: center;
        padding-bottom: 20px;
      }

      .title {
        font-size: 38px;
        font-weight: 300;
        color: #122b28;
      }

      .audioplayer {
        width: 100%;
        max-width: 350px;
      }

      .action-buttons {
        margin-top: 30px;

        .btn {
          background: none;
          border: none;
          border: 1px solid #122b28;
          width: 70px;
          height: 70px;
          line-height: 70px;
          border-radius: 50%;
          text-align: center;
          padding: 0;
          margin-left: 10px;
          margin-right: 10px;

          &.active {
            box-shadow: none;

            i {
              color: #c0392b;
            }
          }

          i {
            font-size: 50px;
            line-height: 70px;
          }
        }

        p {
          margin: 0;
          font-size: 12px;
          font-weight: 500;
          color: #122b28;
          transform: translate(0, -35px);
        }
      }

      .voice-recording-form {
        width: 100%;
        max-width: 350px;

        .form-control {
          border: 1px solid #e4e4e4;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 11px;

          ::placeholder {
            color: #707070;
          }

          &:focus {
            border: 1px solid #e4e4e4;
          }
        }

        .label {
          margin: 65px 0 10px;
          font-size: 1rem;
          font-weight: 300;
          color: #122b28;
        }
      }

      .send-broadcast {
        margin-top: 70px;
        display: flex;
        align-items: center;

        h4 {
          margin: 0;
          color: #122b28;
          font-size: 1.25rem;
          font-weight: bold;
          margin-right: 30px;
        }

        a {
          color: #c0392b;
          font-size: 20px;
          font-weight: 400;

          strong {
            color: #122b28;
          }

          span {
            color: #122b28;
          }
        }
      }

      .content {
        width: 100%;
        max-width: 350px;

        .subtitle {
          font-size: 1.13rem;
          margin-top: 40px;
        }

        p {
          font-size: 1rem;
          color: #707070;
          margin: 0;
        }

        .dropdown-toggle.arrow-left {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          background: transparent;

          &:focus {
            outline: 0;
            box-shadow: none;
          }
        }

        .dropdown-menu {
          min-width: 20px;
        }
      }
    }

    .date-picker-wrapper {
      margin-bottom: 0;
      margin-right: 10px;
      margin-left: auto;
    }

    .date-picker-wrapper .input-group-addon {
      width: 50px;
      height: 50px;
      background: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }

    .date-picker-wrapper input.form-control {
      height: 0 !important;
      width: 0;
      padding: 0;
      border: none;
      opacity: 0;
    }

    .date-picker-wrapper .input-group-addon img {
      width: 32px;
    }

    .voice-template-content {
      .table {
        color: #707070;

        thead {
          th {
            font-weight: 400;
            font-size: 0.88rem;
            border-bottom: 1px solid #d3d3d3;
            border-top: none;
          }
        }

        tbody {
          tr {
            &:hover {
              background: #fff;

              td {
                .action-button {
                  visibility: visible;
                }
              }
            }

            td {
              font-size: 0.88rem;
              font-weight: 400;
              border-bottom: 1px solid #d3d3d3;
              vertical-align: middle;

              .audioplayer {
                transform: translate(0, 3px);
              }

              .action-button {
                padding: 4px 14px;
                visibility: hidden;
                margin-left: 2px;
                transition: all 0.02s ease-in-out;
              }
            }
          }
        }
      }
    }
  }

  .groups-message {
    max-width: 100%;
  }

  .voice-call {
    width: 100%;
    margin-top: 36px;

    .dailed-number {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 7px;
      border-bottom: 1px solid #707070;

      input {
        border: none;
        background: none;
        flex-grow: 1;
        width: 100%;
        height: 40px;
        font-size: 1.75rem;
        font-weight: 300;
        padding-right: 20px;
        color: #122b28;
      }

      button {
        padding: 0;
        border: 1px solid $primary;
        background: none;
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        color: $primary;

        .material-icons {
          line-height: 30px;
        }

        &:focus {
          outline: 0;
        }
      }
    }

    .dail-pad {
      width: 100%;
      max-width: 290px;
      margin: 15px auto 0;

      .dial-pad-btn span {
        font-size: 0.56rem;
        font-weight: 500;
      }

      .dial-pad-btn:focus {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .dail-pad .dial-pad-btn {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      outline: 0;
      border: 1px solid #122b28;
      background: transparent;
      margin-top: 5px;
      font-size: 1.94rem;
      color: #122b28;
      font-weight: 300;
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 1;
      margin-left: auto;
      margin-right: auto;

      &.dial-action-button {
        background: #c0392b;
        color: #fff;
        border: 1px solid #c0392b;
        margin-top: 30px;
      }
    }
  }
}

.chat-action-popover {
  border: none;
  margin-left: -35px;

  .wrapper {
    display: flex;
    align-items: center;
  }

  a {
    display: inline-block;
    margin-right: 12px;
  }

  label {
    white-space: nowrap;

    &:before {
      left: 0 !important;
    }
  }
}

// RIGHT INFO MODAL

.right-info-trigger {
  color: $primary;
  display: flex;
  align-items: center;

  &:focus {
    color: $primary;
  }

  i {
    font-size: 32px;
    line-height: 1;
  }

  &:hover {
    color: $primary;
  }

  &:active {
    color: $primary;
  }
}

.right-info-modal {
  &.fade .modal-dialog {
    transform: translate(100%, 0);
  }

  &.show .modal-dialog {
    transform: translate(0, 0);
  }

  .modal-dialog {
    position: fixed;
    top: 0;
    right: 0;
    left: auto;
    width: 100%;
    max-width: 300px;
    bottom: 0;
    background: #fff;
    margin: 0;

    .modal-content {
      height: 100%;

      .nav-tabs .nav-item .nav-link {
        padding: 11px !important;
      }

      .tab-content {
        overflow-y: scroll !important;
      }

      .nav-tabs .nav-item.show .nav-link,
      .nav-tabs .nav-link.active {
        border-color: transparent;
        background: none;
      }

      &.chat-sidebar {
        width: 100% !important;

        @media (max-width: 1200px) {
          width: 100% !important;
        }

        .chat-contents {
          position: static;

          .table-content {
            position: relative;
          }
        }
      }

      .chat-sidebar {
        width: 100% !important;

        @media (max-width: 1200px) {
          width: 100% !important;
        }
      }
    }
  }
}

// LEFT INFO MODAL

.left-info-trigger {
  color: #fff;
  background: $primary;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  text-align: center;
  margin-right: 14px;

  &:focus {
    color: #fff;
  }

  &:hover {
    color: #fff !important;
  }

  &:active {
    color: #fff !important;
  }

  i {
    font-size: 26px;
    line-height: 31px;
  }
}

.left-info-modal {
  &.fade .modal-dialog {
    transform: translate(-100%, 0);
  }

  &.show .modal-dialog {
    transform: translate(0, 0);
  }

  .modal-dialog {
    position: fixed;
    top: 0;
    right: auto;
    left: 0;
    width: 100%;
    max-width: 300px;
    bottom: 0;
    background: #fff;
    margin: 0;

    .modal-content {
      height: 100%;

      .nav-tabs .nav-item .nav-link {
        padding: 11px !important;
      }

      .tab-content {
        overflow-y: scroll !important;
      }

      .nav-tabs .nav-item.show .nav-link,
      .nav-tabs .nav-link.active {
        border-color: transparent;
        background: none;
      }

      &.chat-sidebar {
        width: 100% !important;

        @media (max-width: 1200px) {
          width: 100% !important;
        }

        .chat-contents {
          position: static;

          .table-content {
            position: relative;
          }
        }
      }

      .chat-sidebar {
        width: 100% !important;

        @media (max-width: 1200px) {
          width: 100% !important;
        }
      }
    }
  }
}

.pagination-btns {
  border: none;
  background: none;
  color: black;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
}

.pagination-btns:disabled {
  svg path:not([fill="none"]) {
    fill: #808080;
  }
}

.pagination-bottom {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0px !important;
}

.pagination-bottom-count {
  display: flex;
  align-items: center;
  margin-top: 1px;
}

.add-btn-search {
  position: absolute;
  margin-left: 275px;
  background: none;
  border: none;
  color: #5517c1;
  outline: none;
}

.cross-btn-search {
  position: absolute;
  margin-left: 315px;
  border-radius: 50%;
  border: 1.5px solid black;
  background: transparent;
  margin-top: -1px;
  outline: none;
}

.no-tag-found {
  width: 100%;
  display: flex;
  min-height: 40vh;
  align-items: center;
  justify-content: center;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  height: 18px;
  width: 18px;
  border: 1px solid #5517c1;
}

.checkmarkin {
  position: absolute;
  height: 18px;
  width: 18px;
  border: 1px solid #96a1ab;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background: #5517c1;
}

.container:hover input ~ .checkmarkin {
  background-color: #ccc;
}

.container input:checked ~ .checkmarkin {
  background: #96a1ab;
}

.checkall:after {
  content: "+";
  left: 3px;
  top: -8px;
  width: 6px;
  height: 9px;
  font-weight: 600;
  position: absolute;
  display: none;
  color: #fff;
}

.checkindeterminate:after {
  content: "-";
  left: 4px;
  top: -8px;
  width: 8px;
  height: 9px;
  font-weight: 600;
  position: absolute;
  display: none;
  color: #fff;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.container input:checked ~ .checkmarkin:after {
  display: block;
}

.btn-delete-confirmation {
  color: #fff;
  background-color: #ff5800;
  border-color: #ff5800;
}

.btn-delete-confirmation:hover {
  background-color: #ff5900ea;
  color: white;
}

.group-modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0px;
  border-top: 1px solid #dee2e6;
}

.group-header-title {
  font-size: 1.3rem;
  font-weight: 500;
  color: black;
}

.thread-group-info {
  display: flex;

  .left {
    flex: 1;
    margin-right: 30px;

    & > div {
      margin-bottom: 15px;
    }
  }

  .right {
    flex: 2;
    margin-right: 25px;

    & > div {
      margin-bottom: 15px;
    }
  }
}

.list-item-select-container {
  margin-bottom: 20px;

  .in-card-sub-title {
    font-size: 14px;
    color: #a6a6a6;
    margin-bottom: 0;
  }

  .in-card-select {
    margin-top: -7px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      font-size: 12px;
    }
  }

  .in-card-text {
    font-size: 16px;
  }
}

.info-card-disabled {
  color: #c9cecd;
}

.simple-purple-button-text {
  @include themed() {
    color: t("primary");
  }
  cursor: pointer;
}

input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
}

.orange-color {
  color: #fc7e00;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.background-blue {
  background: #37c7f2;
}

.background-orange {
  background: #fc7e00;
}

.background-black {
  background: #272727;
}

.background-red {
  background: #c0392b;
}

.status-banner {
  width: 100%;
  color: white;
  height: 25px;
  text-align: center;
  padding-top: 2px;
}

.people-about-orange-warning-box {
  margin-top: 15px;
}

button.close-archived {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

.position-inherit {
  position: inherit;
}

.btn-with-tooltip {
  &.disabled {
    cursor: default;
    color: #fff;
    background-color: #5517c1;
    border-color: #5517c1;
    pointer-events: auto;
  }
}

.expand-btn {
  width: 100%;
  cursor: pointer;
  position: absolute;
  bottom: 0px;

  @include themed() {
    background: t("bgRHS");
  }

  padding-bottom: 5px;
  padding-top: 8px;

  .expansion-label {
    height: 1px;
    background: #c9cecd;

    span {
      position: absolute;
      font-size: 10px;
      top: 0px;
      left: calc(50% - 34px);

      @include themed() {
        background: t("bgRHS");
        color: t("fontDarkGray");
      }

      padding: 0px 10px;
    }
  }
}

.btn-orange {
  background: #fc7e00;
  color: white;
}

.btn-orange:hover {
  background: #ef7800;
  color: white;
}

.opacity-50 {
  opacity: 0.5;
}

.warning-color {
  color: #ff5800 !important;
}

.custom-modal-title {
  font-size: 24px;

  @include themed() {
    color: t("text");
  }

  font-weight: 400;
}

.custom-modal-steps-section-title {
  font-size: 18px;
  text-align: center;
  // @include themed() {
  //   color: t("primary");
  // }
}

.label-fields {
  font-size: 12px;

  @include themed() {
    color: t("fontDarkGray");
  }
}

input[type="file"],
/* FF, IE7+, chrome (except button) */
input[type="file"]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.clear-btn-background,
.clear-btn-background:hover,
.clear-btn-background:disabled,
.clear-btn-background:active {
  background-color: transparent !important;
  border: none !important;
}

.menus-tags-list {
  margin-right: -12px;
}

.font-size-16px {
  font-size: 16px;
}

.fw-500 {
  font-weight: 500;
}

.btn-outline-danger {
  border-color: #ff5800;
  color: #ff5800;
}

.btn-outline-danger:hover {
  border-color: #ff5800;
  background-color: #ff5800;
}

.sk-fading-circle {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #6c37c9;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

.btn-super-sm {
  font-size: 13px;
  padding: 2px 3px;
}

.font-line-through {
  text-decoration: line-through;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;

  border-top: 6px solid #000;
}

.badge {
  border-radius: 50px;
  padding-left: 8px;
  padding-right: 8px;
}

.badge-light {
  background: #b4b4b4;
  color: #efefef;
}

.badge-blue {
  background: #64c3e0;
  color: #f9fdfe;
}

.btn-success {
  background-color: #04c086;
  border-color: #04c086;
}

.btn-success:hover {
  background-color: #04ad79;
  border-color: #04ad79;
}

.btn-danger,
.btn-danger:disabled {
  background-color: #ff5800;
  border-color: #ff5800;
}

.btn-danger:hover {
  background-color: #e64f00;
  border-color: #e64f00;
}

.container-flex-column-100 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title-gray-text {
  font-size: 16px;
  color: #777;
}

.main-menu-header {
  @media (max-width: $screen-md) {
    width: 100vw;
  }

  .notfication-menu-header-extras {
    @media (max-width: $screen-md) {
      display: flex;
      flex-direction: column-reverse;
    }

    .user-settings-notifications-contact {
      @media (max-width: $screen-md) {
        padding: 20px 0px 20px 30px;
        border-bottom: 1px solid $light-tone-contrast;
        margin: 0px;
      }
    }

    .label {
      @media (max-width: $screen-md) {
        @include themed() {
          color: t("fontDarkGray");
        }

        font-weight: bold;
      }
    }

    .navbar-nav {
      @media (max-width: $screen-md) {
        flex-direction: column;
        align-items: flex-start;
      }

      .notfication-nav-link {
        @media (max-width: $screen-md) {
          padding: 20px 30px 20px 30px;
          border-bottom: 1px solid $light-tone-contrast;
          width: 100%;

          @include themed() {
            color: t("fontDarkGray") !important;
          }

          font-size: 16px !important;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

  @include themed() {
    color: t("fontColor");
  }

  .btn-primary {
    @include themed() {
      border-color: t("primary");
      background: t("primary");
      color: t("bg");
    }
  }

  .btn-outline-primary {
    @include themed() {
      border-color: t("primary");
      color: t("primary");
    }
  }

  .main-menu-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    text-transform: capitalize;
  }
}

iframe {
  border: none;
}

.white-space-normal {
  white-space: normal;
}

.selected-thread-filter-header {
  height: 50px;
  padding: 7px 32px 7px 32px;
  border-bottom: 1px solid #e1e1e1;
  border-radius: 0px 0px 5px 5px;
  display: flex;

  .selected-thread-filter-header-chip {
    box-shadow: 0px 0px 6px #d796ff;
    border: 1px solid #6c37c9;
    border-radius: 5px;
    padding: 8px 12px 10px 12px;
    font-size: 12px;
    color: #323335 !important;
    cursor: default;

    @include themed() {
      color: t("fontColor") !important;
    }

    .selected-thread-filter-header-chip-icon {
      margin-left: 5px;
      vertical-align: baseline !important;
      cursor: pointer;
    }
  }
}

.helper-text {
  font-size: 12px;
}

.orange-text {
  color: #ff5800 !important;
  svg path:not([fill="none"]) {
    fill: #ff5800 !important;
  }
}
