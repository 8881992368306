.view-all-conflicts-profiles-modal {
  .modal-dialog {
    width: 600px;
    max-width: 600px;
  }
}

.view-all-conflicts-profiles-container {
  padding: 20px 30px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 27px;
  }
  .description-text {
    font-size: 14px;
    color: #808080;
    margin-bottom: 36px;
  }

  .integration {
    margin: 10px;
  }
}
