.shortcode-warning-modal {
  .modal-dialog {
    width: 750px;
    max-width: 750px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
}

.shortcode-warning-container {
  padding: 25px;

  h1 {
    @extend .custom-modal-title;
    margin-bottom: 17px;
    @include themed() {
      color: t("fontColor");
    }
  }
  .shortcode-warning-description {
    font-size: 16px;
    margin-bottom: 30px;
    @include themed() {
      color: t("fontDarkGray") !important;
    }
  }
}
