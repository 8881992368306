@import "../base/global-theme";

// Panel tabs
.right-panel {
  .fixed-header {
    padding: 0;

    .nav-tabs {
      // min-height: 47px;
      background: $light-tone;

      .back-icon {
        align-items: center;
        display: flex;
        height: 48px;
        padding: 0 15px;
        position: absolute;
      }

      .nav-link {
        height: 46px;
        padding: 0 15px;
        color: #fff;
        display: inline-flex;
        align-items: center;
        background: none;
        border: none;
        opacity: 0.5;

        &.active {
          background: none;
          border: none;
          opacity: 1;

          svg path {
            fill: rgba(255, 255, 255, 1);
          }
        }

        svg path {
          fill: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  .tabs-navigation {
    .nav-tabs {
      border: none;
    }
    .nav-tabs .nav-item a {
      background-color: #d9d9d9;
      border: none;
      border-top: 1px solid #e1e1e1;
      border-bottom: 1px solid #f8f8f8;
      &.active {
        background-color: transparent;
      }
    }
  }

  .members-container {
    @include themed() {
      background: t("bgRHS");
    }
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    display: flex;
    height: 62px;
    align-items: flex-start;
    width: 100%;

    .menus-group-actions {
      margin-right: -10px;
      margin-bottom: -5px;
    }

    .message-text {
      display: block;
      font-size: 13px;
      color: #707070;
    }

    .info {
      display: flex;
    }

    .detail {
      flex-direction: column;
      margin-left: 10px;
    }

    .detail-single {
      display: flex;
      align-items: center;
      white-space: nowrap;
      max-width: 210px;

      .message-text {
        margin-top: 0;

        &.number {
          color: #122b28;
          font-size: 13px !important;
        }
      }

      .info .phone-icon,
      .optedout-icon svg {
        max-height: 12px;
      }
    }

    .name {
      margin-bottom: 0;
      @include themed() {
        color: t("fontDarkGray");
      }
      font-size: 1rem;
      font-weight: 400;
      line-height: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      i {
        color: #707070;
        font-size: 1rem;
        margin-left: 5px;
        margin-top: -2px;
        vertical-align: middle;
      }

      .icon {
        margin-left: 5px;
      }

      .duplicate {
        opacity: 0.5;
      }
    }

    .avatar {
      width: 36px;
      min-width: 36px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      overflow: hidden;
      border-radius: 50%;
      font-size: 1.06rem;
      color: $avatar-text-purple;
      font-weight: 400;
    }

    .no-margin {
      margin-top: 20px;
    }
  }

  .right-panel-group {
    .info-single.with-collapse {
      position: relative;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }

    .no-margin {
      margin-top: 0px !important;

      &.group-rhs-toggler {
        &[aria-expanded="true"] {
          .arrow-icon {
            transform: rotate(180deg);
          }
        }
      }
    }

    .archived-info-view {
      opacity: 0.6;
    }
  }

  .minimized-display {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-left: 1px solid #e1e1e1;

    .minimized-item {
      width: 100%;
      text-align: center;
      margin-top: 20px;

      a.user-action-single {
        color: #00c186;
      }
      &.themed-icon {
        @include themed() {
          path:not([fill="none"]) {
            fill: t("iconColor") !important;
          }
        }
      }
    }
  }
}

.subview-right-panel {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 350px;
  right: -350px;
  transition: right 0.4s ease;
  z-index: 10000;

  &.subview-active {
    right: 0px;
    @include themed() {
      box-shadow: -5px 0px 10px 0.25px t("bgRHS");
    }
  }
  @media (max-width: $screen-md) {
    width: 100%;
  }
}

.actions-btns {
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  padding: 0;
  margin: 0;

  &:focus {
    outline: none;
  }
  &.themed-color {
    svg {
      ellipse:not([fill="none"]) {
        @include themed() {
          fill: t("rhsIconColor");
        }
      }
    }
  }
}

.hover-fixed {
  position: absolute;
  left: calc(100vw - 9%);
  box-shadow: 0 0 20px 20px #ffffff;
  background-color: #ffffff;
}

.rhs-group-item-action {
  position: absolute;
  right: -10px;
  top: 15px;

  &.with-collapse-btn {
    right: 15px;
  }

  &.rhs-contact-item-action {
    position: relative;
    right: -6px;
    top: -12px;
  }

  &.rhs-contact-subitem-action {
    position: relative;
    right: -6px;
    top: -4px;
  }

  &.rhs-contact-subitem-action-hidden {
    position: relative;
    right: -6px;
    top: -4px;
    display: none;
  }
}

.archive-btn-show-hide {
  border: none;
  background: transparent;
  width: 100%;
  outline: none;
  position: relative;
  height: 20px;

  &:focus {
    border: none;
    outline: none;
  }

  .archive-label {
    color: #b4b4b4;
    font-size: 12px;
    background: #f8f8f8;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    top: 0;
    left: 39%;
  }
  .archive-border {
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;
    color: transparent;
    height: 1px;
    background: #dbdbdb;
  }
}

.unarchive-wrapper {
  width: 100%;
  height: 56px;
  position: absolute;
  left: 0;
}

.unarchive-btn-rhs {
  border: none;
  background: none;
  outline: none;
  color: #6c37c9;
  font-size: 12px;
  position: relative;
  top: -12px;
  right: 0;
  display: none;

  &.show-btn-unarchive {
    display: inline;
  }
  &:focus {
    outline: none;
  }

  &.unarchive-btn-rhs-group {
    top: 0;
  }

  &.unarchive-btn-rhs-subitem {
    top: -7px;
  }
}

.rhs-archive-toggle {
  position: fixed;
  bottom: 0;
  background: #f8f8f8;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  padding-left: 20px;
  z-index: 9;

  span {
    color: #666666;
    font-size: 14px;
  }
}
