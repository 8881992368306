.verify-your-number-modal {
  .modal-dialog {
    width: 360px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }
  }
}

.verify-your-number-container {
  padding: 21px 15px 33px 20px;
  .text {
    font-size: 14px;
    color: #808080;
    line-height: 1.4;
  }

  .text-step-two {
    font-size: 16px;
    @include themed() {
      color: t("fontDarkGray");
    }

    line-height: 1.4;
    text-align: center;
    span {
      @include themed() {
        color: t("primary");
      }
      cursor: pointer;
    }
  }

  .first-step-text {
    margin-bottom: 25px;
    @include themed() {
      color: t("fontDarkGray");
    }
  }

  h1 {
    @extend .custom-modal-title;
    margin-bottom: 15px;
  }

  .verify-your-number-section-title {
    @extend .custom-modal-steps-section-title;
    margin-bottom: 28px;
  }

  .phone-container {
    margin-bottom: 55px;
    option,
    input {
      @include themed() {
        color: t("text");
      }
    }
  }

  .btn-wrapper {
    width: 120px;
  }

  .code-confirmation-wrapper {
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .code-verified {
    text-align: center;
    font-size: 16px;
    color: #60c288;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .bottom-text {
    max-width: 230px;
    margin: 0 auto;
    margin-bottom: 27px;
  }
}

.number-code-element {
  width: auto !important;
  &.wrong-code {
    div {
      input {
        border-bottom: 2px solid #ff5800 !important;
      }
    }
  }
  div {
    display: flex;
    justify-content: center;
    input {
      font-size: 16px;
      font-weight: 600;
      margin: 0 9px;
      border: none;
      border-radius: 0;
      border-bottom: 2px solid #dbdbdb;
      &:focus {
        border: none;
        border-radius: 0;
        border-bottom: 2px solid $primary;
      }
      &:last-child,
      &:first-child {
        border-right: none;
        border-radius: 0;
      }
    }
  }
}
