.billing-info-modal {
  .modal-dialog {
    width: 520px;
    max-width: 520px;
  }
}

.billing-info-container {
  padding: 33px 32px;
  font-size: 14px;
  @include themed() {
    color: t("fontDarkGray");
  }

  h1 {
    @extend .custom-modal-title;
    margin-bottom: 34px;
    display: flex;
    align-items: center;
  }
  a {
    @include themed() {
      color: t("primary");
    }
  }
}
