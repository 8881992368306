.settings-users-assigned-modal {
  .modal-dialog {
    width: 420px;
    max-width: 420px;
  }
}

.settings-users-assigned-container {
  padding: 22px 26px 40px 26px;
  .settings-users-assigned-title {
    @extend .custom-modal-title;
    margin-bottom: 21px;
  }

  .settings-users-assigned-search {
    max-width: 230px;
    margin-bottom: 10px;
  }

  .settings-users-assigned-scroll-table {
    height: 320px;

    .settings-users-assigned-table-body {
      color: $font-color-dark;
    }
  }
}
