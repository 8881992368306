@import "../base/global-theme";

.multi-contacts-side-bar {
  width: 0px;
  overflow: hidden;
  transition: all 0.4s ease-in;
  padding: 0;
}

.multi-contacts-side-bar-active {
  width: 300px;
  @include themed() {
    background: t("bgRHS");
    color: t("fontColor");
    border-color: t("lightToneContrast");
  }

  padding: 0;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid;
}
