.simple-purple-calendar {
  width: 300px;
  border: none;
  .react-calendar__tile--active {
    background: #6c37c9;
    color: white !important;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #6c37c9;
  }

  .react-calendar__tile--hasActive {
    background: #9873d9;
  }

  .react-calendar__tile--hasActive:hover {
    background: #a787df;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #808080;
  }

  .react-calendar__month-view__days__day {
    color: #808080;
  }

  .react-calendar__navigation {
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #e3e3e3;
  }

  .react-calendar__viewContainer {
    padding-top: 0.5rem;
  }

  .react-calendar__month-view__weekdays__weekday {
    color: #565656;
    font-size: 0;
    margin-bottom: 0.5rem;
    &::first-letter {
      font-size: 16px;
    }
    & abbr {
      text-decoration: none;
      cursor: auto;
    }
  }

  .react-calendar__navigation__arrow {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-top: 44px;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 14px;
      height: 14px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &.react-calendar__navigation__prev2-button::after {
      background: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 17"><path d="m8.01017,8.471l7.146,7.147l-0.707,0.707l-7.853,-7.854l7.854,-7.853l0.707,0.707l-7.147,7.146z"/><path d="m2.70418,8.471l7.146,7.147l-0.707,0.707l-7.853,-7.854l7.854,-7.853l0.707,0.707l-7.147,7.146z"/></svg>');
    }

    &.react-calendar__navigation__prev-button::after {
      background: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 17"><g></g><path d="M5.207 8.471l7.146 7.147-0.707 0.707-7.853-7.854 7.854-7.853 0.707 0.707-7.147 7.146z"></path></svg>');
    }

    &.react-calendar__navigation__next-button::after {
      background: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 17"><g></g><path d="M13.207 8.472l-7.854 7.854-0.707-0.707 7.146-7.146-7.146-7.148 0.707-0.707 7.854 7.854z"></path></svg>');
    }

    &.react-calendar__navigation__next2-button::after {
      background: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 17"><path d="m15.70982,8.472l-7.854,7.854l-0.707,-0.707l7.146,-7.146l-7.146,-7.148l0.707,-0.707l7.854,7.854z"/><path d="m10.30373,8.472l-7.854,7.854l-0.707,-0.707l7.146,-7.146l-7.146,-7.148l0.707,-0.707l7.854,7.854z"/></svg>');
    }
  }
}
