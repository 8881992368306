.enable-auto-charge-modal {
  .modal-dialog {
    width: 360px;
    max-width: 360px;
  }
}

.enable-auto-charge-container {
  padding: 21px 26px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 53px;
  }
}
