.view-organization-profile-settings-modal {
  .modal-dialog {
    width: 680px;
    max-width: 680px;
  }
}

.view-organization-profile-settings-container {
  padding: 18px 34px 23px 26px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 17px;
  }
  .view-organization-profile-settings-content {
    font-size: 16px;
    @include themed() {
      color: t("fontDarkGray");
    }
  }

  .view-organization-profile-settings-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
  }
}
