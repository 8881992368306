.add-all-people-to-group {
  .modal-dialog {
    max-width: 745px;
    width: 100%;
    align-items: center;
    display: flex;
    height: 100%;
  }
  .modal-content {
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.26);
    padding: 30px;
    border: none;
    position: relative;
  }
  .modal-head {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    font-size: 20px;
    font-weight: 600;
    color: #122b28;
    .title {
      margin-right: 10px;
    }
    .group-name {
      display: flex;
      align-items: center;
    }
    .close-icon {
      position: absolute;
      top: 8px;
      right: 12px;
    }
  }
  .modal-body {
    .single {
      border-radius: 10px;
      background: #fff;
      box-shadow: 0 0 0 1px #707070;
      padding: 25px 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:hover {
        box-shadow: 0 0 0 4px #b69be4 !important;
      }

      &:active {
        box-shadow: 0 0 0 4px $primary;
      }
      .title {
        color: $primary;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 20px;
      }
      svg {
        height: 32px;
        width: 35px;
        margin-bottom: 5px;
        path {
          fill: $primary;
        }
      }
    }
  }
}
