.video-item-image {
  display: flex;
  height: 60px;
  width: 60px;
}

.video-item-thumbnail {
  object-fit: cover;
  width: 100%;
  height: 90%;
  border-radius: 8px;
}

.delete-video {
  position: absolute;
  right: 35px;
  display: flex;
  align-items: center;
  height: 65px;
}

.td-play {
  svg {
    @include themed() {
      path:not([fill="none"]) {
        fill: white;
      }
    }
  }
}

.video-template-delete {
  height: 20px;
  width: 20px;
}
