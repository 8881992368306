.new-tag-modal {
  .modal-dialog {
    width: 380px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }
  }
}

.new-tag-container {
  padding: 1rem 1rem;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 30px;
  }

  .tag-input-container {
    position: relative;
    .add-btn {
      @include themed() {
        color: t("primary");
      }
      opacity: 1;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
    }
  }
}
