.notification-banner-container {
  padding: 3px 25px 3px 10px;
  border-radius: 15px;
  font-size: 14px;
  position: relative;
  display: flex;

  &.with-margin {
    margin: 5px 10px 35px 10px;
  }

  &.blue {
    background-color: #37c7f2;
    color: #fff;
  }

  &.yellow {
    background-color: #ffff57;
    color: #565656;
  }

  &.red {
    background-color: #ff5800;
    color: #fff;
  }

  .close-notification-button {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 7px;
  }

  .action-button {
    cursor: pointer;
    font-weight: 500;
  }
}
