.admin-settings-integrations {
  padding-top: 18px;
  padding-bottom: 30px;
  .admin-settings-integrations-tabs-header {
    border-bottom: none;
  }
  .admin-settings-integrations-container {
    padding: 0 20px;
  }
}

.integration-card {
  padding: 20px 15px;
  border-radius: 10px;
  max-width: 270px;
  min-width: 250px;
  margin: 20px;
  box-shadow: 0px 2px 4px #00000015;
  border: 1px solid #cfcfcf;

  &.active-blue {
    border: 1px solid #3bc1b2;
    box-shadow: 1px 2px 5px #b0faf2;
  }

  &.active-purple {
    box-shadow: 1px 2px 5px #d2b9ff;
    border: 1px solid #6c37c9;
  }

  .integration-header {
    .image-placeholder {
      width: 48px;
      height: 48px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .integration-title {
      font-size: 16px;
      color: #2c2c2c;
      font-weight: 500;
      margin-top: 12px;
      letter-spacing: 0.48px;
    }
    .integration-subtitle {
      font-size: 10px;
      color: #808080;
    }
  }

  .integration-description {
    margin-top: 21px;
    padding-left: 8px;
    font-size: 11px;
    color: #808080;
  }

  .integration-syncing {
    margin-top: 21px;
    margin-left: 26px;
  }

  .integration-menu {
    position: absolute;
    right: 4px;
    top: 10px;
  }

  .integration-banner {
    position: absolute;
    top: -1px;
    right: 24px;
  }

  .integration-button {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.syncing-in-progress {
  font-size: 11px;
  color: #707070;
}
