.affiliate-main-container {
  display: flex;
  padding: 20px 0px;
}

.affiliate-opt-in-container {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;

  &.affiliate-opt-in-container-already-opt-in {
    margin-top: 30px;
  }

  @media (max-width: $screen-md) {
    margin-left: 0px;
    margin-top: 50px;
  }

  .affiliate-opt-in-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 50px;
    width: 40%;
    @media (max-width: $screen-md) {
      width: 100vw;
      margin-left: 0px;
      padding: 0px 30px;
    }
  }

  .affiliate-opt-in-illustration {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55%;
    @media (max-width: $screen-md) {
      display: none;
    }

    .affiliate-image {
      object-fit: fill;
    }
  }

  .affiliate-heading {
    font-size: 3rem;
    font-weight: 600;
    margin: 0px;
    @include themed() {
      color: t("text");
    }
  }

  .affiliate-sub-heading {
    font-size: 1.5rem;
    font-weight: 600;
    @include themed() {
      color: t("text");
    }
  }

  .affiliate-opt-in-desc {
    @include themed() {
      color: t("text");
    }
  }
}

.affiliate-dashboard-container {
  padding: 10px 30px;

  @media (max-width: $screen-md) {
    padding: 10px;
  }

  .affiliate-dashboard-heading-container {
    margin-right: 30px;
    @media (max-width: $screen-md) {
      padding-right: 30px;
    }
    @include themed() {
      border-bottom: 1px solid t("text");
    }

    .affiliate-manage-account-link {
      font-size: 1.1rem;

      @media (max-width: $screen-md) {
        font-size: 0.8rem;
      }
    }
  }

  .affiliate-dashboard-heading {
    margin: 0px;
    @include themed() {
      color: t("text");
    }
  }

  .affiliate-sub-heading {
    font-size: 1.2rem;
    @include themed() {
      color: t("text");
    }
  }

  .affiliate-body-container {
    width: 100%;
    height: 100%;
    margin-top: 15px;
  }

  .affiliate-table-container {
    margin-top: 10px;
    @include themed() {
      border: 1px solid t("lightToneContrast") !important;
    }

    th {
      @include themed() {
        background-color: t("bgRHS");
      }
    }

    &.w-95 {
      width: 95%;
    }
  }

  .affiliate-body-campaign-container {
    margin-top: 20px;
    margin-bottom: 20px;

    .affiliate-campaign-heading {
      font-weight: bold;
      @include themed() {
        color: t("primary");
      }
    }

    .affiliate-campaign-label {
      @include themed() {
        color: t("primary");
      }
    }

    .affiliate-simple-label {
      @include themed() {
        color: t("fontDarkGray");
      }
    }

    .affiliate-active-chip {
      color: white;
      background-color: #4caf50;
      font-size: 0.7rem;
      height: 20px;
      font-weight: 400;
      margin-left: 5px;
      margin-bottom: 10px;
    }
  }

  .user-affiliate-empty-commission {
    font-size: 1.5rem;
    font-weight: 400;
    @include themed() {
      color: t("fontMidGray");
    }
  }

  .user-affiliate-empty-commission-text {
    font-size: 1rem;
    font-weight: 400;
    @include themed() {
      color: t("fontMidGray");
    }
  }

  .affiliate-commission-card {
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px #d4dde7, 0 4px 12px 0px #d4dde7;
  }

  .affiliate-email {
    font-size: 0.8rem;
  }

  .affiliate-campaign {
    background-color: #e1e1e1;
    color: #666666;
    padding: 5px 10px;
    font-size: 0.8rem;
    border-radius: 4px;
    @media (max-width: $screen-md) {
      padding: 3px 5px;
      font-size: 0.7rem;
    }
  }
  .affiliate-breadcrump-label {
    @include themed() {
      color: t("fontDarkGray");
    }
  }
}

.affiliate-table-scroll {
  @media (max-width: $screen-md) {
    width: 100vw;
    overflow-x: scroll;
  }
}

.affiliate-referral-table-scroll {
  height: max-content;
  @media (max-width: $screen-md) {
    width: 100vw;
    overflow-x: scroll;
  }
}
