@import "../../../../base/global-theme";
.assigned-number-text {
  @include themed() {
    color: t("primary");
  }
  font-weight: bold;
}

.sending-as-text {
  @include themed() {
    color: t("fontDarkGray");
  }
}

.number-type-icon {
  width: 16px;
  height: 16px;
}
