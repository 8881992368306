.redirect-modals {
  bottom: 40px;
  left: 40px;
  position: fixed;
  z-index: 9999;
  display: flex;
  .toast {
    opacity: 1;
    margin-right: 65px;
    &:last-child {
      margin-right: 0;
    }
    &:not(:last-child) {
      margin-bottom: 0;
    }
    .toast-body {
      align-items: center;
      background: #401290;
      border-radius: 5px;
      box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
      color: #fff;
      display: inline-flex;
      font-size: 20px;
      font-weight: 600;
      height: 60px;
      min-width: 270px;
      padding: 0 20px;
      .avatar {
        margin-right: 20px;
      }
      img {
        max-width: 100%;
      }
    }
  }
}
