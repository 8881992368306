.number-filter-modal {
  z-index: 100100;
  @media (max-width: $screen-md) {
    z-index: 1200 !important;
  }

  .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 570px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }
  }

  .modal-content {
    border: 0;
    border-radius: 5px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    max-width: 550px;
    min-height: 420px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
    max-height: 90vh;

    @media (max-width: $screen-md) {
      width: 100vw;
      height: 100vh;
      max-width: 100vw;
      max-height: 100vh;
    }

    .modal-header {
      border-bottom: 0;
      padding: 22px 30px 0px 30px;
      @media (max-width: $screen-md) {
        padding: 20px 10px 10px 10px;
      }
    }

    .modal-body {
      padding: 15px 25px 25px 30px;
      .number-filter-search {
        max-width: 240px;
        margin-bottom: 10px;
        @media (max-width: $screen-md) {
          max-width: 80%;
          margin-bottom: 0px;
          margin-left: 20px;
        }
      }
      .number-filter-scroll-table {
        height: 360px;
        .number-filter-table-header {
          tr {
            th {
              padding-bottom: 8px;
            }
          }
        }
        .number-filter-table-body {
          tr {
            td {
              padding-top: 0.8rem;
              padding-bottom: 0.8rem;
              @media (max-width: $screen-md) {
                padding-left: 20px !important;
              }
              @media (max-width: $screen-sm) {
                font-size: 14px;
              }
            }
            td:first-child {
              padding-left: 0;
              padding-right: 0;
            }
            td:nth-child(2) {
              padding-left: 8px;
              min-width: 180px;
            }
          }
        }
        @media (max-width: $screen-md) {
          height: 100vh;
        }
      }
      @media (max-width: $screen-md) {
        padding: 20px 0px;
      }

      .modal-info {
        @include themed() {
          color: t("fontMidGray");
        }
        .my-number-text {
          @include themed() {
            color: t("primary");
          }
          font-weight: bold;
        }
        @media (max-width: $screen-md) {
          padding: 0px 10px 0px 20px;
          font-size: 14px;
        }
      }
    }

    .modal-title {
      color: #202020;
      font-size: 24px;
      font-weight: 400;
      margin: 0;
      text-align: center;
    }
  }

  .custom-control-label {
    width: 100%;

    .content > div:last-child {
      width: calc(100% - 24px);
    }
  }

  .custom-switch {
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 13px;
    padding-top: 13px;

    &:last-child {
      border-bottom: 0;
    }

    .content {
      padding: 0 !important;
    }

    .custom-control-label::before {
      background: rgba(180, 180, 180, 0.4);
      border: 0;
      height: 16px;
      top: 50%;
      transform: translate(0, -50%);
      width: 31px;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background: rgba(85, 23, 193, 0.4);
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      background: $primary;
      transform: translateX(0.85rem) translateY(-50%);
    }

    .custom-control-label::after {
      background: #fff;
      filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.16));
      height: 13px;
      // top: calc(0.25rem + 1.5px);
      width: 13px;
      top: 50%;
      transform: translate(0, -50%);
    }

    p {
      margin: 0;

      &.name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #707070;
      }

      &.number {
        color: #122b28;
        font-weight: 500;
      }
    }

    .user-icon {
      margin-top: 10px;
    }
  }

  .modal-footer {
    background: #fff;
    border-top: 0;
    padding: 15px 25px;
    text-align: center;
  }
}
