.video-range-slider {
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  .video-range-slider-thumb {
    width: 12px !important;
    height: 38px !important;
    border-radius: 4px !important;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='%23333' viewBox='0 0 24 24'%3E%3Cpath d='M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z' /%3E%3C/svg%3E")
      #fff !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    border: 1px solid black !important;
    cursor: e-resize;

    .video-range-slider-value-label {
      span {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        width: auto !important;
        border-radius: 20px !important;
        transform: none !important;
        height: 30px !important;
        background-color: black !important;
        padding: 10px !important;

        span {
          transform: none !important;
        }
      }
    }
  }

  .video-range-slider-track {
    height: 62px;
    @include themed() {
      background: t("primary") !important;
    }
    border: 3px solid #5517c1;
    opacity: 0.6;
    border-radius: 10px;
  }

  .video-range-slider-rail {
    height: 60px;
    border: 3px solid darkgray !important;
    background-color: lightgray !important;
    background-image: url("data:image/svg+xml,%3Csvg width='40px' height='40px' viewBox='0 0 24.00 24.00' fill='none' xmlns='http://www.w3.org/2000/svg' stroke='%239a9393'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0' transform='translate(12,12), scale(0)'%3E%3Crect x='0' y='0' width='24.00' height='24.00' rx='0' fill='%237ed0ec' strokewidth='0'%3E%3C/rect%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round' stroke='%23CCCCCC' stroke-width='4.8'%3E%3Cpath d='M3 11V13M6 11V13M9 11V13M12 10V14M15 11V13M18 11V13M21 11V13' stroke='%23a39f9f' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M3 11V13M6 11V13M9 11V13M12 10V14M15 11V13M18 11V13M21 11V13' stroke='%23a39f9f' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-position: center;
    background-repeat: repeat-x;
    border-radius: 10px;
  }
}

.range-slider-container {
  width: 100%;
  padding: 0px 20px;
}
