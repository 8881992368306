.pause-campaign-modal {
  .modal-dialog {
    width: 550px;
    max-width: 550px;
  }
}
.pause-campaign-container {
  padding: 25px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 22px;
  }
  p {
    font-size: 12px;
    color: #565656;
  }
}
