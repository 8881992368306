.set-up-caller-id-modal {
  .modal-dialog.modal-dialog-centered {
    width: 380px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }
  }
}

.set-up-caller-id-container {
  padding: 21px 14px 23px 28px;
  .set-up-caller-id-title {
    @extend .custom-modal-title;
    margin-bottom: 23px;
  }
  .set-up-caller-id-section-title {
    @extend .custom-modal-steps-section-title;
    margin-bottom: 28px;
  }

  .first-step-container {
    .first-step-info {
      font-size: 14px;
      @include themed() {
        color: t("fontDarkGray");
      }

      line-height: 1.4;
      margin-bottom: 35px;
    }

    .first-step-container-label {
      @extend .label-fields;
      margin-bottom: -5px;
      @include themed() {
        color: t("label");
      }
    }
    .MuiInput-underline {
      input {
        @include themed() {
          color: t("text");
        }
      }
      &:after {
        @include themed() {
          border-bottom-color: t("primary");
        }
      }
    }
    select.form-control,
    input.form-control {
      @include themed() {
        background: t("bgRHS");
        color: t("text");
      }
    }
    .MuiOutlinedInput-input {
      @include themed() {
        color: t("fontColor");
      }
    }
    .first-step-footer {
      margin-top: 52px;
      display: flex;
      justify-content: flex-end;
      .btn-primary {
        @include themed() {
          background: t("primary");
          color: t("bg");
          border-color: t("primary");
        }
      }
    }
  }

  .second-step-container {
    .main-info {
      font-size: 18px;
      margin-bottom: 18px;
      color: #808080;
      span {
        color: $font-color-dark;
      }
    }
    .number {
      font-size: 24px;
      color: $font-color-dark;
      margin-bottom: 18px;
      text-align: center;
    }

    .second-info {
      font-size: 18px;
      color: #808080;
      margin: 0 auto;
      text-align: center;
      max-width: 255px;
    }

    .second-step-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 69px;
    }
  }
}
