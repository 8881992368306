.replace-current-greeting-modal {
  .modal-dialog {
    width: 690px;
    max-width: 690px;
  }
}

.replace-current-greeting-container {
  padding: 18px 30px 36px 26px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 29px;
  }

  p {
    font-size: 16px;
    margin-bottom: 32px;
    @include themed() {
      color: t("iconMidGray");
    }
  }

  .replace-current-greeting-footer {
    display: flex;
    justify-content: flex-end;
    .cancel-btn {
      width: 105px;
    }
    .yes-btn {
      max-width: 135px;
    }
  }
}
