.container {
  z-index: 99009;
  padding: 11px 16px;
  font-size: 14px;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  color: #202020;
  position: absolute;
  left: 49px;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  display: none;

  &::before {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #ffffff;
    background-color: transparent;

    position: absolute;
    top: 16px;
    left: -5px;
    content: "";
    z-index: 1;
  }

  .border {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid #e2e2e2;
    background-color: transparent;

    position: absolute;
    top: 15px;
    left: -6px;
    z-index: 0;
  }

  &.active {
    left: 66px;
    display: block;
    transition: all 0.4s ease;
  }
}
