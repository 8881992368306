.new-cbb-group {
  .modal-content {
    .close {
      opacity: 1;
      outline: 0;
      position: absolute;
      right: 15px;
      top: 10px;
      cursor: pointer;
      z-index: 10;
    }
    .modal-body {
      padding: 20px 30px 28px 30px;
      .title {
        margin-bottom: 40px;
        h4 {
          margin-bottom: 0;
          font-size: 24px;
          font-weight: 400;
        }
      }
      .content {
        .label {
          font-size: 14px;
          color: #a6a6a6;
          margin-bottom: 0;
        }
        .item {
          margin-top: 35px;
        }

        .button-save {
          margin-top: 45px;
        }
      }
    }
  }

  .modal-dialog {
    width: 360px;
    margin-top: 0;
  }
}
