@media only screen and (min-width: 768px) {
  .modal-backdrop.custom-backdrop.in {
    background-color: #fff;
    left: 220px;
    opacity: 1;
  }

  .modal-open .modal.custom-modal {
    border-top: 1px solid #e7e7e7;
    left: 220px;
    top: 59px;
  }

  .custom-modal .modal-content {
    border: 0 none;
    box-shadow: inherit;
    margin: 0 auto;
    background-color: transparent;
    padding: 30px 0;
    width: 600px;
  }

  .custom-modal .modal-title {
    margin-bottom: 20px;
  }

  .custom-modal .modal-body.card-box {
    padding: 15px;
  }

  .custom-modal .modal-body {
    padding: 0;
  }

  .custom-modal .modal-dialog {
    height: 100%;
    margin: 0;
    width: 100%;
    max-width: 100%;
    pointer-events: inherit;
  }

  .modal.custom-modal {
    background-color: #f5f5f5;
  }

  .custom-modal .modal-header {
    border: 0;
    padding: 0;
  }

  .custom-modal .modal-footer {
    border: 0;
  }

  .custom-modal button.close {
    background: #00bf6f;
    border-radius: 50%;
    height: 50px;
    right: 15px;
    opacity: 1;
    position: absolute;
    top: 15px;
    width: 50px;
    text-shadow: inherit;
    color: #fff;
    z-index: 99;
  }

  .nav-tabs.nav-justified.nav-tabs-top {
    border-bottom: 1px solid #ddd;
  }

  .nav-tabs.nav-justified.nav-tabs-top > li > a,
  .nav-tabs.nav-justified.nav-tabs-top > li > a:focus,
  .nav-tabs.nav-justified.nav-tabs-top > li > a:hover {
    border-width: 2px 0 0 0;
  }

  .nav-tabs.nav-tabs-top > li {
    margin-bottom: 0;
  }

  .nav-tabs.nav-tabs-top > li > a,
  .nav-tabs.nav-tabs-top > li > a:focus,
  .nav-tabs.nav-tabs-top > li > a:hover {
    border-width: 2px 0 0 0;
  }

  .nav-tabs.nav-tabs-top > li + li > a {
    margin-left: 1px;
  }

  .nav-tabs.nav-tabs-top > li > a.active,
  .nav-tabs.nav-tabs-top > li > a.active:focus,
  .nav-tabs.nav-tabs-top > li > a.active:hover {
    border-top-color: #00bf6f;
  }

  .nav-tabs.nav-tabs-bottom > li {
    margin-bottom: -1px;
  }

  .nav-tabs.nav-tabs-solid {
    background-color: #fafafa;
    border: 0;
  }

  .nav-tabs.nav-tabs-solid > li {
    margin-bottom: 0;
  }

  .nav-tabs.nav-tabs-solid > li > a {
    border-color: transparent;
  }

  .nav-tabs.nav-tabs-solid > li > a:focus,
  .nav-tabs.nav-tabs-solid > li > a:hover {
    background-color: #f5f5f5;
  }

  .nav-tabs.nav-tabs-solid > .open:not(.active) > a {
    background-color: #f5f5f5;
    border-color: transparent;
  }

  .nav-tabs-justified.nav-tabs-top {
    border-bottom: 1px solid #ddd;
  }

  .nav-tabs-justified.nav-tabs-top > li > a,
  .nav-tabs-justified.nav-tabs-top > li > a:focus,
  .nav-tabs-justified.nav-tabs-top > li > a:hover {
    border-width: 2px 0 0 0;
  }

  .custom-modal .modal-content > button.close {
    display: none;
  }
}
@media only screen and (max-width: 1199.98px) {
  .custom-modal .modal-content.modal-lg {
    width: 90%;
  }
}
@media only screen and (max-width: 991.98px) {
  .alert.top-alert {
    min-height: 25px;
    height: auto;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 0px 0px 2px 15px;
  }

  .profile-rightbar {
    display: inline-block !important;
  }

  .task-right-sidebar .task-chat-view {
    display: block;
    position: fixed;
    right: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    margin-right: 0;
  }

  .task-chat-view {
    display: table-cell;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    right: 0;
    transform: translateX(0px);
    z-index: 1041;
    width: 300px;
    position: fixed;
    margin-right: -300px;
    top: 0;
    padding-bottom: 60px;
    margin-top: 60px;
  }

  .chat-profile-view {
    display: table-cell;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    right: 0;
    transform: translateX(0px);
    z-index: 1041;
    width: 300px;
    position: fixed;
    margin-right: -300px;
    top: 0;
    // padding-bottom: 47px;
    @media only screen and (max-width: 1400px) and (min-width: $screen-md) {
      width: 300px !important;
    }
  }

  .message-view.task-view {
    width: 100%;
  }

  .pull-left.ticket-view-details {
    width: 90%;
  }

  .slide-nav .page-wrapper {
    left: 225px;
    margin-left: 0;
  }

  .chat-main-row {
    left: 0;
  }

  .header .header-left {
    position: absolute;
    width: 100%;
  }

  .mobile_btn {
    display: block;
  }

  .search-filter,
  .search-filter.has-search {
    // background-color: $primary-light;

    .filter-icon,
    .right-action {
      color: white;
    }
  }

  .search-wrapper-outer .hide-search {
    color: white;
  }

  .search-filter.with-menu-btn {
    .mobile_btn {
      padding: 0 0 0 8px;
      height: 45px;
      color: white;
      text-align: center;
    }
  }

  .hubs-tab .nav-tabs {
    background-color: #e1e1e1;

    .nav-link {
      svg {
        path {
          fill: #707070;
        }
      }

      &.active {
        svg {
          path {
            fill: #122b28;
          }
        }
      }
    }
  }

  .page-title-box {
    display: none;
  }

  .sidebar {
    margin-left: -225px;
    width: 225px;
    &.opened {
      margin-left: 0;
    }
  }

  .page-wrapper {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .slide-nav .sidebar {
    margin-left: 0;
  }

  a.mobile_btn {
    color: #fff;
    font-size: 1.5rem;
    height: 45px;
    left: 0;
    line-height: 38px;
    padding: 0 20px;
    position: absolute;
    top: 0;
    width: 60px;
    z-index: 1000;
    background-color: $dark-top;

    i {
      position: relative;
      top: 5px;
    }
  }

  .modal-open .modal.custom-modal {
    left: 0;
    top: 0;
  }

  .modal-backdrop.custom-backdrop.in {
    left: 0;
  }

  body {
    font-size: 0.8125rem;
  }

  .sidebar-menu li a {
    font-size: 0.8125rem;
  }

  .chat-panel .custom-menu.navbar-nav > li > a {
    font-size: 1.125rem;
  }

  .user-summary {
    display: none;
  }

  .sidebar-navs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    li {
      width: auto !important;
      a.active,
      a.active:focus,
      a.active:hover,
      a:hover {
        background: transparent;
      }

      button.item-btn {
        width: 42px !important;
      }
    }
    @media (max-width: $screen-md) {
      flex-direction: row;
    }
  }

  .sidebar-menu .middle-container {
    .add-icon {
      margin-left: 15px;
    }
  }
}
@media only screen and (max-width: 767.98px) {
  .content-full {
    .sm-threads {
      background-color: #2a2a2a;
    }
  }
  .profile-info-left {
    border-right: none;
    border-bottom: 2px dashed #ccc;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .navbar-nav .open .dropdown-menu {
    float: left;
    position: absolute;
  }

  .notifications {
    right: -48px;
  }

  .notifications::after,
  .notifications::before {
    right: 60px;
  }

  .custom-modal button.close {
    background-color: #00bf6f;
    border-radius: 50%;
    height: 30px;
    right: 15px;
    opacity: 1;
    position: absolute;
    top: 11px;
    width: 30px;
    text-shadow: inherit;
    color: #fff;
    z-index: 99;
  }

  .modal-body.card-box {
    background-color: #fff;
    border: none;
    border-radius: inherit;
    box-shadow: unset;
    margin-bottom: 0;
    padding: 15px;
  }

  .header {
    z-index: 1040;
  }

  .task-wrapper #task-list li .task-container .task-label {
    padding-right: 50px;
    word-break: unset;
  }

  .task-btn-right {
    position: absolute;
    right: 8px;
  }

  .task-wrapper #task-list li .task-container {
    padding: 8px 10px;
  }

  .task-action-btn.task-check {
    width: 36px;
  }

  .roles-menu {
    margin-bottom: 20px;
  }

  .contacts-list {
    padding: 0 70px 0 15px;
  }

  .left-action {
    text-align: center;
    margin-bottom: 15px;
  }

  .right-action {
    text-align: center;
  }

  .top-action-left .pull-left {
    float: none !important;
  }

  .top-action-left .btn-group {
    margin-bottom: 15px;
  }

  .top-action-right {
    text-align: center;
  }

  .top-action-right a.btn.btn-white {
    margin-bottom: 15px;
  }

  .mail-sent-time {
    float: left;
    margin-top: 10px;
    width: 100%;
  }

  .custom-modal .modal-content.modal-lg {
    width: 100%;
  }

  .nav-tabs.nav-justified {
    border-bottom: 1px solid #ddd;
  }

  .nav-tabs.nav-justified > li > a.active,
  .nav-tabs.nav-justified > li > a.active:focus,
  .nav-tabs.nav-justified > li > a.active:hover {
    border-color: transparent transparent transparent #00bf6f;
    border-left-width: 2px;
  }

  .nav-tabs {
    border-bottom: 0;
    position: relative;
    padding: 0;
    border-radius: 3px;
  }

  .nav-tabs .nav-item {
    margin-bottom: 0;
  }

  .nav-tabs > li > a {
    border-width: 2px;
    border-left-color: transparent;
  }

  .nav-tabs .nav-link {
    border-width: 2px;
  }

  .nav-tabs > li > a:focus,
  .nav-tabs > li > a:hover {
    background-color: #40394c;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active,
  .nav-tabs > li > a.active,
  .nav-tabs > li > a.active:focus,
  .nav-tabs > li > a.active:hover {
    border-left-width: 2px;
  }

  .group-type > li > a.active,
  .group-type > li > a.active:focus,
  .group-type > li > a.active:hover {
    background-color: transparent !important;
  }

  .sidebar-navs {
    display: flex;
    li {
      width: auto !important;
      a.active,
      a.active:focus,
      a.active:hover,
      a:hover {
        background: transparent;
      }

      button.item-btn {
        width: 42px !important;
      }
    }
  }

  .nav-tabs > li.open:not(.active) > a,
  .nav-tabs > li.open:not(.active) > a:focus,
  .nav-tabs > li.open:not(.active) > a:hover {
    background-color: #40394c;
  }

  .nav-tabs.nav-tabs-solid {
    padding: 5px;
  }

  .nav-tabs.nav-tabs-solid.nav-tabs-rounded {
    border-radius: 5px;
  }

  .nav-tabs.nav-tabs-solid > li > a {
    border-left-width: 0 !important;
  }

  .nav-tabs-justified {
    border-bottom: 1px solid #ddd;
  }

  .nav-tabs-justified > li > a.active,
  .nav-tabs-justified > li > a.active:focus,
  .nav-tabs-justified > li > a.active:hover {
    border-width: 0 0 0 2px;
    border-left-color: #00bf6f;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.17em;
  }

  h4 {
    font-size: 1.12em;
  }

  h5 {
    font-size: 0.83em;
  }

  h6 {
    font-size: 0.75em;
  }

  .page-title {
    font-size: 1.2em;
  }

  .card-title {
    font-size: 1.12em;
  }

  .blog-view .blog-title {
    font-size: 1.5rem;
  }

  .widget h3 {
    font-size: 1.125rem;
  }

  .countdown ul {
    width: 100%;
  }

  .countdown ul li.timer {
    width: 19.75%;
    min-height: inherit;
  }

  .countdown ul li.seperator {
    font-size: 2.5rem;
    line-height: 97px;
    width: 7%;
  }

  .countdown ul li span {
    font-size: 2.5rem;
  }

  .countdown ul li p {
    font-size: 0.625rem;
  }

  .coming-title {
    font-size: 1.88rem;
  }

  .title-section {
    margin-bottom: 50px;
  }

  .countdown {
    margin-bottom: 50px;
  }

  .newsletter form {
    width: 90%;
  }

  .newsletter input[type="text"] {
    width: 70%;
    height: 40px;
  }

  .submit-btn {
    width: 30%;
    height: 40px;
  }

  .my-video ul li {
    width: 80px;
  }

  .voice-call-avatar .call-avatar {
    width: 120px;
    height: 120px;
  }

  .product-info {
    margin: 20px 0 30px;
  }

  .profile-bg {
    height: 280px;
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .header .has-arrow .dropdown-toggle > span:nth-child(2) {
    display: none;
  }

  .header .has-arrow .dropdown-toggle .user-name {
    display: inline-block !important;
  }

  .header .has-arrow .dropdown-toggle::after {
    display: none;
  }

  .modal-backdrop.custom-backdrop {
    z-index: 1041;
  }

  .custom-modal .modal-dialog-centered {
    min-height: inherit;
  }

  .custom-modal > button.close {
    display: none;
  }
}

.header .user-switch.inside-mobile-dropdown {
  display: flex;
  border-bottom: 1px solid #fff;
  margin-bottom: 15px;

  .dropdown-toggle:after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid #fff;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .user-name {
    color: #fff;
  }
}
@media only screen and (max-width: 575.98px) {
  .contact-cat {
    padding-right: 70px;
  }

  .user-menu {
    display: block;
  }

  .mobile-user-menu {
    display: block;
    margin-right: 50px;
  }

  .filter-row {
    margin-bottom: 15px;
  }

  .page-wrapper > .content {
    padding: 15px;
  }

  .profile-view .profile-basic {
    margin-left: 0;
  }

  .profile-img-wrap {
    position: relative;
    margin: 0 auto;
  }

  .profile-view .profile-img-wrap {
    margin: 0 auto 10px;
    height: 100px;
    width: 100px;
  }

  .profile-view .profile-img {
    height: 100px;
    width: 100px;
  }

  .profile-view .profile-img .avatar {
    font-size: 1.5rem;
    height: 100px;
    line-height: 100px;
    margin: 0;
    width: 100px;
  }

  .profile-info-left {
    text-align: center;
  }

  .profile-basic {
    margin-left: 0;
    margin-top: 15px;
  }

  .page-title {
    font-size: 1.125rem;
  }

  .fc-toolbar .fc-right {
    display: inline-block;
    float: none;
    margin: 10px auto 0;
    width: 200px;
    clear: both;
  }

  .fc-toolbar .fc-left {
    float: none;
    margin: 0 auto;
    width: 200px;
  }

  .upload-text {
    font-size: 1.125rem;
  }

  .call-duration {
    display: block;
    margin-top: 0;
    margin-bottom: 10px;
    position: inherit;
  }

  .end-call {
    margin-top: 10px;
    position: inherit;
  }

  .chat-panel .chats {
    padding: 15px 0;
  }

  .chat-right .chat-content {
    max-width: 90%;
  }

  .chat-right .chat-body {
    padding-left: 0;
    padding-right: 0;
  }

  .chat-left .chat-content {
    max-width: 90%;
  }

  .chat-left .chat-body {
    padding-left: 0;
    padding-right: 0;
  }

  .account-box {
    width: 100%;
    margin-bottom: 20px;
  }

  body {
    font-size: 0.75rem;
  }

  .sidebar-menu li a {
    font-size: 0.75rem;
  }

  .countdown ul {
    width: 100%;
  }

  .countdown ul li.timer {
    min-height: inherit;
    padding: 15px 5px;
    width: 19.75%;
  }

  .countdown ul li.seperator {
    width: 7%;
    font-size: 1.375rem;
    padding: 0 5px;
    line-height: 55px;
  }

  .countdown ul li span {
    font-size: 1.375rem;
  }

  .countdown ul li p {
    font-size: 0.44rem;
  }

  .coming-title {
    font-size: 1.25rem;
  }

  .title-section {
    margin-bottom: 20px;
  }

  .countdown {
    margin-bottom: 20px;
  }

  .newsletter form {
    width: 90%;
  }

  .newsletter input[type="text"] {
    width: 70%;
    height: 36px;
    padding: 0 10px;
  }

  .submit-btn {
    width: 30%;
    height: 36px;
    padding: 0 10px;
  }

  .sub-title {
    font-size: 0.6875rem;
  }

  .newsletter h3 {
    font-size: 1rem;
  }

  .pull-left.ticket-view-details {
    width: 80%;
  }

  .chat-panel .last-seen {
    font-size: 0.625rem;
  }

  .custom-menu.navbar-nav > li > a {
    margin-left: 10px;
  }

  .profile-bg {
    height: 180px;
  }

  .error-box h1 {
    font-size: 3.125rem;
  }
}

.long-name-modal {
  width: 320px;
}

@media only screen and (max-width: 991px) {
  .chat-sidebar .search-filter {
    position: fixed;
    transition: transform 0.4s ease-in-out;
    will-change: transform;
  }

  .popbox.chat-thread .thread-info-action {
    transition: transform 0.4s ease-in-out;
    will-change: transform;
  }

  body.main-panel-scrolling-up {
    .popbox.chat-thread .thread-info-action {
      transform: translate(0px, -45px);
    }
  }

  body.left-panel-scrolling-down {
    .chat-sidebar .search-filter {
      transform: translate(0px, -45px);
    }
  }
}

.mobile-avatar-wrapper {
  @media (max-width: $screen-md) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.desktop-mobile-height {
  height: 100%;
  @media (max-width: $screen-md) {
    height: 100vh;
  }
}
