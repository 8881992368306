.delete-user-modal {
  .modal-dialog {
    width: 680px;
    max-width: 680px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
}

.delete-user-container {
  padding: 25px;
  @include themed() {
    color: t("text");
  }

  h1 {
    @extend .custom-modal-title;
    margin-bottom: 17px;
  }
  .delete-user-description {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .checkboxes-container {
    margin-left: 15px;
  }

  .confirm-container {
    margin-left: 46px;
    margin-bottom: 51px;
    @media (max-width: $screen-md) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0px;
      margin-bottom: 20px;
    }
  }
}
