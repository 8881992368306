.resend-message-number-info-modal {
  .modal-dialog {
    width: 730px;
    max-width: 730px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
  @media (max-width: $screen-lg) {
    z-index: 140000;
  }
}

.resend-message-number-info-container {
  @include themed() {
    background: t("bg");
  }
  padding: 15px 26px 21px 26px;
  .resend-message-number-info-title {
    @extend .custom-modal-title;
    margin-bottom: 17px;
    margin-top: 0px;
    font-size: 1.8rem;
    font-weight: bold;
    @include themed() {
      color: t("fontDarkGray");
    }
  }
  .resend-message-number-info-sub-container {
    padding-right: 20px;

    .resend-message-number-info-description {
      font-size: 1rem;
      @include themed() {
        color: t("text");
      }
    }

    .resend-message-number-sub-heading {
      font-size: 1.1rem;
      font-weight: bold;
      @include themed() {
        color: t("text");
      }
    }

    .resend-message-number-info-details-link {
      font-size: 1.1rem;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
      @include themed() {
        color: t("primary");
      }
    }
  }
}
