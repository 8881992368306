@import "../../../../base/global-theme";

.user-settings-notifications-contact {
  padding: 2px;
  margin: 0px 20px 0px 0px;
  font-size: 14px;
  color: #808080;
  height: 19px;
  display: inline-table;
  margin-left: 5px;
  .light-icons {
    img {
      max-height: 15px;
      opacity: 0.25;
      width: 14px;
      margin-left: 2px;
    }
    .text {
      margin-left: 4px;
    }
    .extra-text {
      display: none;
      @include themed() {
        color: t("primary");
      }

      text-align: left;
      font-size: 12px;
      margin-left: 5px;
      font-weight: bold;
      &.show-default {
        display: inline !important;
      }
    }
  }
  &:hover {
    .extra-text {
      display: inline;
    }
  }
}
.user-settings-notifications-tooltip-overflow {
  display: table-cell;
}

.user-settings-notifications-card-info {
  margin-bottom: 36px;
  @media (max-width: $screen-md) {
    margin-bottom: 0px;
    margin-left: 0px;
    border: none !important;
  }
}
.user-settings-notifications-card-info-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .title-area {
    .switch {
      margin-left: 15px;
    }
  }
  .window-icon {
    cursor: pointer;
  }
}

.user-settings-notifications-body {
  padding: 30px 22px 30px 30px;
  @media (max-width: $screen-md) {
    padding: 0px;
  }
  .body-top {
    font-size: 16px;
    margin-bottom: 30px;
    @media (max-width: $screen-md) {
      margin-bottom: 0px;
    }
    .notification-header {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      color: #666666;
    }
    .smart-notification-extra {
      margin-top: 20px;
      @include themed() {
        color: t("fontDarkGray");
      }
      .numbers {
        @include themed() {
          color: t("primary");
        }
        cursor: pointer;
        margin-right: 5px;
      }
      @media (max-width: $screen-md) {
        padding: 12px 16px;
      }
    }
  }
  .smart-notifications {
    .container {
      min-height: 40px;
      position: relative;
      cursor: default;
      padding: 0px;
      margin-left: 0px;
      .info {
        font-size: 16px;
        color: #808080;
        position: absolute;
        top: 50%;
      }
    }
  }
  .focus-mode {
    .container {
      display: inline;
      font-size: 16px;
      cursor: default;
      color: #808080;
      .info {
        color: #808080;
      }
      .title {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        margin-top: 9px;
        margin-bottom: 9px;
        .title-cell {
          width: 80px;
          text-align: center;
        }
      }
      .summary-checkbox {
        display: flex;
        justify-content: space-between;
        margin-top: 9px;
        .checkbox-cell {
          width: 80px;
          text-align: center;
        }
      }
    }
  }
  .custom-notifications {
    .container {
      display: inline;
      cursor: default;
      font-size: 16px;
      color: #808080;
      .notfication-card-info {
        @media (max-width: $screen-md) {
          padding: 0px 0px 10px 20px;
          @include themed() {
            border-bottom: 1px solid t("cardBorder") !important;
          }
        }
      }
    }
    .custom-table {
      .category {
        width: 30%;
        .indent-level-1 {
          margin-left: 25px;
          @media (max-width: $screen-md) {
            margin-left: 20px;
          }
        }
        .indent-level-2 {
          margin-left: 50px;
        }
        .text {
          margin-left: 10px;
        }
        .rotating-arrow {
          font-size: 15px;
          opacity: 0.87;
          margin-left: 5px;
        }
        .view-more {
          margin-left: 5px;
          @include themed() {
            color: t("primary");
          }
        }
        .credits-input {
          height: 25px;
          width: 70px !important;
          div {
            height: 100%;
            input {
              position: relative;
              height: 100%;
              width: 100%;
              opacity: 1;
            }
          }
        }
        @media (max-width: $screen-md) {
          width: 100%;
        }

        .arrow-right {
          @media (max-width: $screen-md) {
            padding-right: 20px;
          }
        }
      }
      .rest-col {
        width: 5%;
        text-align: center;
      }
      tr {
        @include themed() {
          color: t("text");
          background-color: t("bg");
        }
      }
      td {
        @include themed() {
          border-color: t("tableDivider") !important;
        }
        @media (max-width: $screen-md) {
          padding: 20px 0px 20px 10px;
        }
      }
    }
  }
  .notfication-card-header {
    @media (max-width: $screen-md) {
      @include themed() {
        background: t("bg");
        border: none !important;
        border-bottom: 1px solid t("cardBorder") !important;
      }
    }
  }

  .notification-card-content {
    @media (max-width: $screen-md) {
      padding: 0px;
    }
  }
}
.notification-popover-body {
  display: inline-block;
}
.notification-popover-text {
  font-size: 14px;
  .notification-popover-link-text {
    color: #6c37c9;
    cursor: pointer;
  }
}

.notfication-columns-container {
  display: flex;
  flex-direction: column;
  .category {
    border-top: none;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .rest-col {
    border: none !important;
  }

  .notfication-columns-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px 0px 50px;
  }

  .notfication-column-label {
    text-align: left;
    font-weight: bold;
    margin-bottom: 15px;
    margin-left: 10px;
  }

  td {
    width: 100% !important;
    justify-content: space-between !important;
  }
}
