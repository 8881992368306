/*------------------------------------------------------------------
[6. Header]
*/

.header {
  background-color: $primary;
  height: 60px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.4s ease-in-out;
  z-index: 999999 !important;
}

.header .header-left {
  float: left;
  height: 60px;
  padding: 0 20px;
  position: relative;
  width: 90px;
  z-index: 1;
  display: flex;
  align-items: center;
}

.logo {
  display: inline-block;
  line-height: 60px;
}
@media (max-width: 992px) {
  .logo {
    margin-left: 40px;
  }
}

.ham-burger {
  margin-right: 30px;
  color: $primary;
  font-size: 1.875rem;
}
@media (max-width: 768px) {
  .ham-burger {
    display: none;
  }
}

.ham-burger:hover {
  color: $primary;
}

.header .navbar-nav .badge {
  position: absolute;
  right: 7px;
  top: 4px;
}

.header .dropdown-menu > li > a {
  position: relative;
}

.header .dropdown-toggle::after {
  display: none;
}

.header .has-arrow .dropdown-toggle::after {
  display: inline-block;
}

.page-title-box {
  border-radius: 0;
  height: 60px;
  margin-bottom: 0;
  padding: 17px 20px;
}

.page-title-box h3 {
  color: #333;
  font-size: 1.25rem;
  font-weight: normal;
  margin: 0;
}

.user-menu {
  margin: 0;
  position: relative;
  z-index: 9;
}

.user-menu.nav > li.user-switch {
  margin-left: 15px;
  margin-right: 15px;
}

.user-menu.nav > li > .nav-link {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  height: 60px;
  line-height: 60px;
  padding: 0 15px;

  &.gift-btn {
    font-size: 44px;

    .material-icons {
      font-size: 30px;
    }
  }

  .badge.badge-pill {
    height: 18px;
    line-height: 18px;
    margin-top: -15px;
    padding: 0;
    width: 18px;
  }
}
@media (max-width: 1024px) {
  .user-menu.nav > li > .nav-link {
    font-size: 0.8125rem;
    padding: 0 10px;
  }
}

.user-menu.nav > li > .free-trial strong {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  margin-right: 7px;
}

.user-menu.nav > li > .free-trial span {
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
}

.user-menu .double-credits img {
  margin-right: 10px;
  width: 35px;
}

.user-menu.pull-right.nav > li > a {
  align-items: center;
  display: inline-flex;
  padding: 0 4px;
  min-width: 48px;

  svg path {
    fill: #fff;
  }

  &.toggle-icon {
    &[aria-expanded="true"] {
      .filled {
        display: inline-block;
      }

      .outlined {
        display: none;
      }
    }

    .filled {
      display: none;
    }
  }
  @media (max-width: 991px) {
    min-width: 30px;
  }
}
@media (max-width: 992px) {
  .user-menu.left-menu {
    display: none;
  }
}

.user-menu.left-menu.nav > li > .nav-link {
  align-items: center;
  display: inline-flex;
  margin-right: 20px;
}

.user-menu.left-menu.nav > li.double-credits {
  svg {
    height: 29px;
    width: 29px;

    path {
      fill: #c8ff00;
    }
  }
}
@media (max-width: 1200px) {
  .user-menu.left-menu.nav > li > .nav-link {
    margin-right: 3px;
  }
}

.user-menu.left-menu.nav > li > .nav-link i {
  color: #fff !important;
  margin-left: 5px;
}
@media (max-width: 1024px) {
  .user-menu.left-menu.nav > li > .nav-link {
    margin-right: 0;
  }
}

.user-img {
  display: inline-block;
  position: relative;
}

.user-img .text-avatar {
  // background: #fff;
  @include themed() {
    background: t("fontDarkGray");
    color: #a5a5a5;
  }
  border-radius: 50%;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  height: 32px;
  line-height: 32px;
  margin-right: 10px;
  text-align: center;
  width: 32px;
  @media (max-width: 991px) {
    margin-right: 0;
  }
}

.text-avatar {
  &.light-avatar-text {
    @include themed() {
      color: t("rhsAvatarText") !important;
    }
  }
}

.user-img .rounded-circle {
  margin-right: 10px;
}

.user-img .status {
  border: 2px solid #fff;
  bottom: 0;
  height: 10px;
  position: absolute;
  right: 0;
  width: 10px;
}

.user-menu .user-img .status {
  bottom: 10px;
}

.user-menu .dropdown-menu {
  min-width: 136px;
  border: 1px solid #707070;
  border-radius: 3px;

  .dropdown-item {
    &:hover {
      .credit-full {
        display: inline;
      }

      .credit-short {
        display: none;
      }
    }

    .credit-full {
      display: none;
    }
  }
}

.user-menu .bg-primary {
  background-color: $primary !important;
}

.user-menu .bg-secondary {
  background-color: $secondary !important;
}

.user-menu .primary-badge {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #9253ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.status {
  background-color: #ccc;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
}

.status.online {
  background-color: #55ce63;
}

.status.offline {
  background-color: #f62d51;
}

.status.away {
  background-color: #faa937;
}

.nav-alphabet-header {
  padding: 10px 0px 10px 34px;
  font-size: 1rem;
  color: #808080;
  font-weight: 400;
  &.hide {
    display: none;
  }
}
