.user-pending-invitation-modal {
  .modal-dialog {
    width: 380px;
    max-width: 380px;
  }
}

.user-pending-invitation-container {
  padding: 25px 30px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 40px;
  }

  .description-text {
    font-size: 14px;
    color: #808080;
    margin-bottom: 35px;
  }
  .themed-color-primary {
    @include themed() {
      color: t("primary");
    }
  }
}
