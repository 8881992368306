.confirm-group-message {
  .modal-content {
    padding-top: 60px;
    .close,
    .close-archived {
      opacity: 1;
      outline: 0;
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 10;
      svg {
        @include themed() {
          rect:not([fill="none"]) {
            fill: t("iconColor") !important;
          }
        }
      }
    }
  }
  .modal-body {
    .title {
      line-height: 20px;
      margin-bottom: 60px;
      h4 {
        line-height: 20px;
        margin: 0 !important;
        @include themed() {
          color: t("text");
        }
      }
      a {
        line-height: 20px;
      }
    }

    .content {
      @include themed() {
        color: t("text");
      }
      font-size: 14px;
      text-align: left;
    }
  }
  .modal-footer {
    .btn {
      font-size: 1rem;
      align-items: center;
      display: inline-flex;
      height: 44px;
      justify-content: center;
      min-width: 90px;
      padding: 0 20px;
    }
    .btn-primary {
      @include themed() {
        color: t("bg");
        background-color: t("primary");
        border-color: t("primary");
      }
      &:hover {
        @include themed() {
          background: t("primaryActionButtonHoverBg");
          border-color: t("primaryActionButtonBg");
          color: t("primaryActionButtonHoverColor");
        }
      }
    }
    .btn-outline-primary {
      @include themed() {
        border: 1px solid t("primary");
      }
      &:hover {
        @include themed() {
          background: rgba(t("primary"), 0.1);
          color: t("primary");
        }
      }
    }
  }

  .similar-contact-modal-content {
    min-width: 740px;
    @media (max-width: $screen-md) {
      min-width: auto !important;
      padding: 10px !important;
    }
    th,
    td {
      @media (max-width: $screen-md) {
        padding: 0px;
        font-size: 12px;
      }
    }
    .modal-footer {
      margin-top: 5px;
    }
  }
}

.simple-confirm-modal {
  .modal-content {
    padding: 25px !important;
  }
}
