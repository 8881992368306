.thread-view-people-messages {
  display: block;
  height: 100%;
  table-layout: fixed;
  width: 100%;
  position: relative;
  .input-message-text-field {
    position: relative;
    .archived-icon {
      path:first-child {
        fill: white;
      }
    }
  }
}
