.create-new-greeting-modal {
  .modal-dialog {
    width: 380px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }
  }
}

.create-new-greeting-container {
  padding: 21px 28px 28px 30px;
  .create-new-greeting-title {
    @extend .custom-modal-title;
    margin-bottom: 41px;
  }
  .create-new-greeting-section-title {
    @extend .custom-modal-steps-section-title;
  }

  .step-one-container {
    margin-top: 60px;
    margin-bottom: 59px;
  }

  .step-two-container {
    .web-record {
      margin-top: 88px;
      margin-bottom: 145px;
    }
    .text-to-voice {
      margin-bottom: 50px;
    }
  }

  .step-three-container {
    margin-top: 49px;
    margin-bottom: 75px;
    .web-record-review {
      .selection-container {
        margin-top: 63px;
      }
    }

    .text-to-voice-review {
      .selection-container {
        margin-top: 45px;
      }
    }
  }
}
