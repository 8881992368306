.quick-merge-conflicts-modal {
  .modal-dialog {
    width: 600px;
    max-width: 600px;
  }
}

.quick-merge-conflicts-container {
  padding: 21px 26px;
  font-size: 16px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 10px;
  }
}
