@import "../base/variables";

.default-snack-container {
  background-color: #fff;
  z-index: 99009;
  border-radius: 4px;
  width: auto;
  max-width: 375px;
}

.default-snack-container-large {
  background-color: #fff;
  z-index: 99009;
  border-radius: 4px;
  width: auto;
  max-width: 375px;
}

.snackbar-container {
  display: flex;
  justify-content: space-between;
  .snack-text {
    padding-right: 8px;
    color: #202020;
    position: relative;
    top: 7px;
    padding-bottom: 7px;
  }
  .snack-text-error {
    padding-right: 26px;
    color: #fff;
    position: relative;
    top: 7px;
    padding-bottom: 7px;
  }
  .btn-outline-primary {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background: transparent !important;
    &:hover {
      color: $avatar-text-purple !important;
    }
  }
  &.snack-extra-text {
    display: flex;
    flex-direction: column;

    .snack-text {
      padding-top: 11px;
      top: 0px;
    }

    .btn-outline-primary {
      text-align: right;
    }
  }
}

.Toastify__toast-container--bottom-left {
  z-index: 99999;
  bottom: 1em;
  left: 5em;
}

.Toastify__toast--error {
  background: #ff5800;
}

.Toastify__close-button {
  color: #202020;
  outline: none !important;
}
