.user-settings-my-profile-container {
  display: flex;
  padding: 40px 69px;

  @media (max-width: $screen-lg) {
    padding: 5px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .in-card-text {
    font-size: 16px;
    margin-bottom: 18px;
  }
  .first-column {
    .avatar {
      height: 70px;
      width: 70px;
      font-size: 1.8rem;
    }
    .card-info {
      margin-bottom: 32px;
      .requested-email {
        font-size: 14px;
        color: #808080;
      }
      @media (max-width: $screen-xs) {
        max-width: 320px;
      }
    }

    .timezone-wrapper {
      width: 250px;
    }
  }

  .second-column {
    margin-left: 65px;
    .card-info {
      margin-bottom: 32px;
      @media (max-width: $screen-xs) {
        max-width: 350px;
      }
    }
    @media (max-width: $screen-lg) {
      margin-left: 0px;
    }
  }
  .profile-detail-card-container {
    min-width: 350px;
    @media (max-width: $screen-md) {
      min-width: auto;
    }
  }
}
.themed-primary-color {
  @include themed() {
    color: t("primary");
  }
}
.switch-label,
.MuiInputBase-input {
  @include themed() {
    color: t("text");
  }
}
.upload-avatar-image {
  position: relative;
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 155px;
    cursor: pointer;
  }

  .focus-mode-label {
    color: #6c757d;
  }
}
