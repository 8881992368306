.flex {
  display: flex;
}

.aligns-center {
  display: flex;
  align-items: center;
}

.justify-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.align-flex-start {
  display: flex;
  align-items: flex-start;
}

.overflow-visible {
  overflow: visible !important;
}

.ml-auto {
  margin-left: auto;
}

.ml-10px {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-20-md {
  margin-left: 20px;
  @media (max-width: $screen-md) {
    margin-left: 0px;
  }
}
.ml-40 {
  margin-left: 40px !important;
}

.no-padding {
  padding: 0;
}

.ml-5 {
  margin-left: 5px !important;
}

.no-margin {
  margin: 0;
}

.pl-20 {
  padding-left: 20px;
}

.justify-content {
  justify-content: center;
}

.flex-grow {
  flex-grow: 1;
}

.mt-40 {
  margin-top: 40px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-10 {
  margin-right: 10px;
}

.align-left {
  text-align: left;
}

.mt-10 {
  margin-top: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}

.w-10 {
  width: 10% !important;
}

.w-fit {
  width: fit-content !important;
}

.bg-none {
  background: none;
}
