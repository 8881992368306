.video-confirmation-modal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  .modal-dialog {
    margin: 15px;
    width: 75vw;
    max-width: 75vw;
    height: 80vh !important;
    max-height: 80vh !important;
    @media (max-width: $screen-md) {
      width: 100vw !important;
      max-width: 100vw !important;
      margin: 0px !important;
    }
  }

  .modal-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90%;
    width: 100%;
    @media (max-width: $screen-md) {
      height: 100%;
    }
  }

  .video-confirmation-modal-body {
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 40px;
    @media (max-width: $screen-md) {
      height: 75%;
    }
  }

  .video-confirmation-timer {
    color: gray;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 5px;
    margin-top: 5px;
    @media (max-width: $screen-md) {
      font-size: 0.8rem;
    }
  }

  .video-confirmation-footer {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    @media (max-width: $screen-md) {
      width: 100%;
      justify-content: center;
      flex-direction: column;
    }

    .btn {
      @media (max-width: $screen-md) {
        font-size: 0.9rem;
        width: 70%;
      }
    }

    .video-confirmation-border-buttons {
      @media (max-width: $screen-md) {
        margin-bottom: 15px;
      }
    }
  }

  .video-confirmation-border-buttons {
    &:hover {
      color: white !important;
    }
    @media (max-width: $screen-md) {
      font-size: 0.8rem;
    }
  }
}
