@import "../../base/global-theme";

.voice-broadcast-modals {
  z-index: 999999 !important;

  .voice-broadcast.wizard {
    padding-right: 14px;
  }

  .label-warning {
    font-size: 11px;
    background: #fb7e00;
    text-align: center;
    color: white;
    padding: 5px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .btn-delete {
    color: rgb(226, 78, 0);
    padding-left: 0;
  }

  .custom-input {
    opacity: 0;
    width: 1px;
  }

  a {
    text-decoration: none !important;
  }

  textarea {
    width: 100%;
    padding: 10px 15px;
    resize: none;
    font-size: 15px;
    color: #122b28;
    line-height: 1.35;

    &::placeholder {
      font-size: 15px;
      color: #8f8f8f;
    }
  }

  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 380px;
    }
  }

  .modal-content {
    min-height: 450px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    border-radius: 5px;
    border: none;
    @include themed() {
      background: t("bg");
    }

    @media (max-width: 576px) {
      height: calc(90% - 50px);

      .modal-body {
        overflow: auto;

        .scroll-area,
        .scroll-area > div,
        .broadcast-library .broadcast-list {
          height: 100% !important;
        }
      }
    }

    &.auto-height {
      min-height: auto;

      .scroll-area div:first-child {
        min-height: auto !important;
      }
    }

    .voice-broadcast-loader {
      height: 25vh;
    }

    .actions ul {
      display: flex;
      list-style: none;
      justify-content: space-between;
      padding: 0;

      li {
        .back-btn {
          background-color: #fff;
          color: $primary;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border: 1px solid rgba($primary, 0.5);
          height: 36px;
          min-width: 90px;
          border-radius: 5px;
        }
        // a[href$="#next"]{
        //   pointer-events: none;
        // }
        .next-btn {
          border: 1px solid rgba($primary, 0.5);
          height: 36px;
          min-width: 90px;
          border-radius: 5px;
          background-color: rgba($primary, 0.5);
          display: inline-flex;
          justify-content: center;
          align-items: center;
          pointer-events: none;
          cursor: default;
        }

        &.disabled {
          visibility: hidden;
        }
      }

      a {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .modal-header {
    padding: 22px;
    border-bottom: none;
    position: relative;
    height: 1px;

    .close-icon {
      position: absolute;
      top: 15px;
      right: 10px;
      cursor: pointer;
    }
  }

  .modal-body {
    margin: 20px 0;
    padding: 0 8px 0 22px;
  }

  .modal-footer {
    padding: 0 22px 22px;
    border-top: none;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }

  .section-title {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include themed() {
      color: t("primary");
    }

    i {
      font-size: 18px;
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .voice-broadcast-text {
    margin-bottom: 28px;
  }

  .broadcast-type {
    margin-top: 60px;
    @media (min-width: 576px) {
      margin-bottom: 60px;
    }
  }

  .voice-record {
    padding: 90px 0 140px;
  }

  .broadcast-setting {
    position: relative;
    padding-left: 6px;

    .audioplayer-bar {
      height: 4px;
    }

    .sending-as {
      margin-top: 30px;
      margin-bottom: 25px;

      .label {
        color: #707070;
        font-size: 0.75rem;
        margin-bottom: 0;
      }

      .input-group {
        display: flex;
        position: relative;
        width: 100%;
      }

      .form-control {
        border: none;
        width: 100%;
        padding-left: 0;
        border-bottom: 1px solid #dbdbdb;
        @include themed() {
          background: t("bg");
          color: t("fontColor");
        }
      }

      .sender-number-validation {
        border-bottom: 1px solid #ff5800;
      }

      .input-group-prepend {
        width: 100%;
        margin-top: 0;
      }

      &.detail-container {
        margin-top: 0;
        margin-bottom: 0;

        .input-group-prepend {
          margin-bottom: 0;
        }

        .sender-div {
          margin-bottom: 30px;
        }

        .error-label {
          color: #ff5800;
          font-size: 0.75rem;
          margin-bottom: 30px;
        }
      }

      &.collapse-detail {
        margin-top: 0;
        margin-bottom: 0;

        .input-group-prepend {
          margin-bottom: 0;
        }

        .form-control {
          height: 25px;
          padding-top: 0;
        }
      }
    }

    .input-group-prepend {
      width: 100%;
      position: relative;
      margin-top: 35px;
      margin-bottom: 20px;

      textarea {
        padding: 15px 15px 25px;
      }

      .action {
        position: absolute;
        right: 8px;
        bottom: 12px;

        svg {
          height: 16px;
          width: 16px;
        }
      }
    }

    .input-field {
      .label {
        color: #707070;
        font-size: 12px;
        margin-left: 5px;
        margin-bottom: 0;
      }

      .custom-select {
        color: #122b28;
        font-size: 1rem;
        background: none;
        border: none;
        border-bottom: 1px solid #dbdbdb;
        padding: 0;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .voice-answer {
      margin-top: 18px;

      .play-pause-text {
        @include themed() {
          color: t("fontColor");
        }
      }

      a[aria-expanded="false"] {
        color: #707070;
        display: flex;
        align-items: center;
        font-size: 12px;

        i {
          margin-left: auto;
        }
      }

      a[aria-expanded="true"] {
        color: #707070;
        display: flex;
        align-items: center;
        font-size: 12px;

        i {
          margin-left: auto;
          transform: rotateX(180deg);
        }
      }

      .themed-container {
        padding: 10px 20px;
        box-shadow: none;
        @include themed() {
          background: t("bg") !important;
        }
      }
    }

    .cost {
      font-size: 16px;
    }

    .call-screen-greeting-textarea {
      border-color: #dbdbdb;
    }
  }

  .message-scheduling {
    background: #fff;

    .title {
      display: flex;
      flex-direction: column-reverse;
      align-items: normal;

      h3 {
        color: #707070;
        flex-grow: 1;
        font-size: 0.88rem;
        font-weight: bold;
        overflow: hidden;
        margin: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .edit-link {
        display: inline-block;
        vertical-align: middle;
        color: $primary;
        cursor: pointer;
      }

      .close-icon {
        vertical-align: middle;
        color: #122b28;
        font-size: 1.19rem;
      }
    }

    p {
      margin: 0;
      color: #707070;
    }

    .date-time-picker {
      position: relative;

      .picker-content-positioner {
        right: -16px;
      }
    }
  }

  .label {
    font-size: 12px;
    margin: 5px 0;
    display: block;
  }

  .broadcast-template-wrapper {
    .info-single {
      display: none;
    }

    .warning-text {
      display: none;
    }

    .custom-switch {
      border-bottom: none;
    }

    &.show-template {
      margin-bottom: 20px;

      .info-single {
        align-items: flex-end;
        border-bottom: 1px solid #e1e1e1;
        display: flex;
        padding-bottom: 5px;

        &.with-collapse {
          flex-direction: column;
          align-items: flex-start;

          label {
            display: block;
            margin-bottom: 2px;
          }

          input {
            font-size: 16px !important;
          }

          .edit {
            color: #5517c1;
            border-radius: 5px;
            font-size: 0.75rem;
            font-weight: 500;
            margin-left: 10px;
          }
        }

        .label {
          margin: 0;
          font-size: 12px;
          font-weight: 400;
          color: #707070;
          margin-bottom: 5px;
        }

        p {
          color: #122b28;
          font-size: 1rem;
          font-weight: 400;
          margin: 0;
          line-height: 20px;
        }

        a {
          color: #5517c1;
          border-radius: 5px;
          font-size: 0.75rem;
          font-weight: 500;
          margin-left: 10px;
        }

        .warning-text {
          display: none;
        }

        &.invalid {
          border-bottom: 1px solid $secondary;
          position: relative;

          .warning-text {
            font-size: 12px;
            color: $secondary;
            display: inline;
            position: absolute;
            bottom: -20px;
            right: 0;
          }
        }
      }
    }
  }

  .action-buttons {
    a {
      background-color: $primary;
      padding: 8px 32px;
      border-radius: 5px;
      border: 1px solid $primary;
    }

    .btn {
      height: 36px;
      min-width: 90px;
      font-weight: 500;
      line-height: 1;

      &:active,
      &:focus,
      &:focus:active {
        box-shadow: none;
      }
    }

    .btn-schedule {
      padding: 0;
      width: 36px;
      min-width: unset;
      border-radius: 50%;

      svg {
        vertical-align: text-top;
      }
    }

    .btn-submit {
      min-width: 90px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  .upload-mp3 {
    padding-top: 40px;

    .dropzone {
      @include themed() {
        background: t("bg");
      }

      border: 1px dashed $primary;
      border-radius: 14px;
      width: 100%;
      min-height: 200px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 40px;
      padding: 20px;
      cursor: pointer;

      .dz-message {
        width: 100%;
        margin: 0;
        text-align: center;
      }

      span {
        svg {
          height: 55px;
          width: 55px;
          margin-bottom: 22px;

          path {
            fill: #b4b4b4;
          }
        }

        p {
          font-size: 14px;
          color: #707070;
        }
      }

      &.dz-drag-hover .dz-message {
        opacity: 0.5;
      }
    }

    .uploading {
      background-color: #fff;
      border: 1px dashed $primary;
      border-radius: 14px;
      width: 100%;
      min-height: 200px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 40px;
      justify-content: center;

      .spinner-border {
        height: 50px;
        width: 50px;
        border-width: 6px;
        @include themed() {
          color: t("primary");
        }
      }
    }

    .uploaded {
      margin-bottom: 100px;
      margin-top: 50px;

      .audioplayer-bar {
        height: 4px;
        top: 20px;
      }

      .info-single {
        align-items: flex-end;
        border-bottom: 1px solid #e1e1e1;
        display: flex;
        padding-bottom: 5px;
        margin-top: 50px;

        .info-left {
          max-width: 80%;

          p {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .label {
          margin: 0;
          font-size: 12px;
          font-weight: 400;
          color: #707070;
          margin-bottom: 5px;
        }

        p {
          color: #122b28;
          font-size: 1rem;
          font-weight: 400;
          margin: 0;
          line-height: 20px;
        }

        a {
          color: #5517c1;
          border-radius: 5px;
          font-size: 0.75rem;
          font-weight: 500;
          margin-left: 10px;
        }
      }
    }
  }

  .broadcast-library {
    .search-wrapper-outer {
      display: flex;
    }

    .search-wrapper {
      @include themed() {
        background: t("textArea");
        border: 1px solid t("fontDarkGray");
      }
      display: flex;
      align-items: center;
      height: 34px;
      padding: 0 10px;
      border-radius: 17px;
      flex-grow: 1;
      margin: 20px 0;

      ::placeholder {
        font-size: 16px;
        color: #8f8f8f;
      }

      &.focused {
        border-color: $primary;
      }
    }

    .broadcast-list {
      overflow: auto;
      max-height: 350px;
      margin-bottom: 30px;
      overflow-x: hidden;
    }

    .meta {
      align-items: center;
      display: flex;
      width: 100%;
      white-space: nowrap;

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #122b28;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include themed() {
          color: t("fontColor");
        }
      }

      i {
        font-size: 18px;
        margin: 0 12px;
      }

      svg {
        height: 15px;
        margin: 0 12px;
      }

      .date {
        font-size: 12px;
        color: #8f8f8f;
        margin-left: auto;
      }
    }

    .detail {
      font-size: 14px;
      color: #8f8f8f;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .custom-control {
      border-bottom: 1px solid #d3d3d3;
      padding-bottom: 16px;
      padding-top: 18px;

      &:last-child {
        border: none;
      }
    }

    .audioplayer-bar {
      height: 4px;
    }

    .custom-control-label:before {
      left: 0;
      top: 20px !important;
    }

    .custom-control-label:after {
      left: 0;
      top: 20px !important;
    }
  }

  .tooltip-text {
    font-size: 12px;
    margin: 5px 0;
    display: block;
    color: #fff;

    .icon {
      margin: 0 0 3px 6px;
    }
  }
}
.voice-broadcast-modal-loading-spinner {
  width: 17px;
  height: 17px;
  border-width: 1.5px;
  color: $primary;
  margin-right: 8px;
}

.estimate-broadcast-container {
  padding: 5px;
}

.broadcast-estimation-wrapper {
  font-size: 16px;
  .estimation-price {
    @include themed() {
      color: t("primary");
    }
  }

  .voice-broadcast-estimation-loader {
    width: 17px;
    height: 17px;
    border-width: 1.5px;
    vertical-align: text-top;
    margin-left: 10px;
  }
}

.broadcast-filter-select {
  width: 250px !important;
  padding: 0px 10px !important;
  @include themed() {
    border: 1px solid t("cardBorder");
  }
  @media (max-width: $screen-md) {
    max-width: 100%;
  }
  .MuiMenuItem-root {
    font-size: 0.9rem !important;
    padding-left: 40px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
}
