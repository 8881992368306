@import "../../../../base/global-theme";

.number-rhs-settings {
  padding-top: 61px;

  .number-avatar-content {
    padding-bottom: 15px;
    .save {
      @include themed() {
        color: t("primary");
      }
    }
    label {
      @include themed() {
        color: t("label");
      }
    }
    .MuiInput-formControl {
      input {
        @include themed() {
          color: t("text");
        }
      }
    }
    .MuiInput-underline::after {
      @include themed() {
        border-bottom-color: t("primary");
      }
    }
    svg#Close {
      @include themed() {
        path:not([fill="none"]) {
          fill: t("iconColor") !important;
        }
      }
    }
  }

  .secondary-number-container {
    padding: 7px 7px 12px 7px;
    border-bottom: 1px solid #e1e1e1;

    .secondary-number-text {
      margin: 0;
      @include themed() {
        background: t("bgRHS");
        border: 1px solid t("rhsDividerColor");
        color: t("text");
      }
      font-size: 12px;
      padding: 7px 10px 7px 16px;
    }

    .secondary-number-info {
      padding: 12px 12px 0 20px;
    }
  }

  .primary-number-container {
    .primary-number-info {
      padding: 25px 28px;
      .edit-button {
        @include themed() {
          color: t("primary");
        }
        font-size: 14px;
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .users-same-number {
      padding: 25px 10px 10px 28px;
      a {
        @include themed() {
          color: t("primary");
        }
      }
    }
  }

  .call-settings-container {
    padding: 25px 16px 16px 24px;
    .themed-primary-color {
      @include themed() {
        color: t("primary");
      }
    }
    .wrapper-input {
      .form-control {
        border-left: 0;
        border-radius: 0;
        border-right: 0;
        border-top: 0;
        @include themed() {
          color: t("fontDarkGray");
        }
        padding: 0;
        background: transparent;
      }
      select.form-control {
        width: 90px;
      }
      input.form-control {
        padding-left: 10px;
      }
    }

    .switch-content {
      position: relative;

      .menu {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }
    }

    .player-switch-container {
      padding-left: 49px;
      padding-right: 15px;
    }
    .greeting-vm-container {
      margin-top: 10px;

      .assign-new-btn {
        position: absolute;
        right: 10%;
        bottom: 23px;
      }
    }

    &__note {
      @include themed() {
        background-color: t("bg");
        border: 1px solid t("lightToneContrast");
      }
    }
  }

  .player {
    display: flex;
    align-items: center;

    .audioplayer {
      height: auto;
      min-width: 20px;
    }
  }

  .do-not-call-setting-container {
    padding: 30px 24px 24px 24px;

    .message-switch {
      margin-top: 30px;
      .label {
        width: 50%;
      }
    }

    .info-text {
      font-size: 16px;
      color: #2c2c2c;
      margin-bottom: 0;
      margin-top: 24px;
    }
  }

  .sub-switch-container {
    margin-top: 10px;
    padding: 14px 10px 14px 23px;
    border-left: 1px solid #c2c2c2;
  }

  .text {
    font-size: 16px;
    color: #202020;
    margin-bottom: 0;
  }

  .text-grey {
    font-size: 16px;
    color: #202020;
    margin-bottom: 0;
  }

  .text-light-grey {
    font-size: 16px;
    color: #808080;
    margin-bottom: 0;
    line-height: 1.4;
  }

  .text-nuantced-black {
    font-size: 16px;
    color: #202020;
    margin-bottom: 0;
  }

  .audio-first-column {
    max-width: 60%;
    flex: 60%;
  }

  .audio-second-column {
    max-width: 40%;
    flex: 40%;
    display: flex;
    align-items: center;
  }

  .status-container,
  .type-container,
  .extension-container {
    padding: 15px 28px 25px 28px;
    @include themed() {
      color: t("fontDarkGray") !important;
    }
  }
  .status-container {
    .themed-primary-color {
      @include themed() {
        color: t("primary");
      }
    }
  }

  .extension-container {
    .save {
      @include themed() {
        color: t("primary");
      }
    }
    label {
      @include themed() {
        color: t("label");
      }
    }
    .MuiInput-formControl {
      input {
        @include themed() {
          color: t("text");
        }
      }
    }
    .MuiInput-underline::after {
      @include themed() {
        border-bottom-color: t("primary");
      }
    }
    svg#Close {
      @include themed() {
        path:not([fill="none"]) {
          fill: t("iconColor") !important;
        }
      }
    }
  }
  .last-activity-container {
    padding: 25px 28px;
  }

  .cost-container {
    padding: 25px 28px;
  }

  .signature-container {
    padding: 30px 20px 30px 28px;
    .indent-signature {
      .indent-signature-info-container {
        border-left: 1px solid #e1e1e1;
        padding-left: 20px;
        margin-top: -2px;
        margin-left: 5px;
        .themed-color {
          @include themed() {
            color: t("primary");
          }
        }
      }
    }
  }

  .capabilities {
    padding: 25px 10px 25px 28px;
    @include themed() {
      color: t("fontDarkGray");
    }

    p {
      display: flex;
      font-size: 14px;
      margin-bottom: 0;
      line-height: 1.7;
    }
    .icon {
      margin-top: 1px;
    }
  }

  .release-number {
    margin-bottom: 25px;
    margin-top: 5px;
    padding: 0 23px;
  }
  .number-type-icon {
    width: 16px;
    height: 16px;
  }
}

.check-number-status-link {
  font-size: 0.8rem !important;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px 4px;
  @include themed() {
    background-color: t("checkStatusLabelBg");
  }
}
