.choose-default-signature-container-modal {
  .modal-dialog {
    width: 675px;
    max-width: 675px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
  .modal-content {
    @media (max-width: $screen-md) {
      width: 100vw;
    }
  }
}

.choose-default-signature-container {
  padding: 13px 30px 24px 30px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 0;
  }

  .default-signature-label {
    @extend .label-fields;
  }

  .choose-default-signature-content {
    margin-top: 54px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .choose-default-signature-content-left {
      max-width: 220px;
      width: 100%;
      .default-signature-select-container {
        max-width: 220px;
      }

      .default-signature-mange {
        font-size: 16px;
        margin-top: 19px;
      }
    }
  }

  .choose-default-signature-footer {
    margin-top: 36px;
    display: flex;
    justify-content: flex-end;
  }
}
