@import "../base/global-theme";
.single-filed-form-custom {
  .title-label {
    color: #a6a6a6;
    font-size: 0.875rem;
    margin-bottom: 5px;
  }

  .edit {
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  }

  p {
    margin: 0;
  }

  .current {
    color: #a6a6a6;
  }

  .empty {
    @include themed() {
      color: t("text");
    }
    color: rgba(0, 0, 0, 0.38);
  }

  input {
    @include themed() {
      color: t("text");
    }
  }
  svg {
    @include themed() {
      path:not([fill="none"]) {
        fill: t("iconColor") !important;
      }
    }
  }
  .save,
  .edit {
    @include themed() {
      color: t("primary");
    }
  }
}
