.app-loading-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #3e374a;
  color: #fff;
  font-size: large;
  text-align: center;

  .app-loading-inner-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .app-loading-spinner {
      width: 17px;
      height: 17px;
      border-width: 1.5px;
    }
  }
}
