.register-container {
  height: 100vh;
  .logo {
    margin-top: 33px;
    margin-bottom: 65px;
    @media (max-width: $screen-md) {
      margin-top: 5px;
      margin-bottom: 0px;
      margin-left: 0px !important;
    }
  }
  .left-column {
    z-index: 100;
    background-color: white;
  }
  .form-container {
    max-width: 385px;
    width: 100%;
    .header-text {
      font-size: 25px;
      color: #808080;
      font-weight: bold;
      margin-bottom: 30px;
      @media (max-width: $screen-md) {
        margin-bottom: 10px;
      }
    }
    .captcha-container {
      width: 233px;
      height: 59px;
    }
    .country-select {
      width: 80px;
      margin-top: 16px;
    }
    .already-text {
      font-size: 16px;
      color: #707070;
    }
    .forgot-password-text {
      font-size: 14px;
      text-align: end;

      .password-text {
        color: #5517c1;
      }
    }
    .btn-primary {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .btn-primary.disabled,
    .btn-primary:disabled {
      background-color: #c9b4eb;
      border: none;
    }
    .agreement-text {
      color: #707070;
    }
    @media (max-width: $screen-md) {
      padding: 20px 20px 0px 20px;
    }
  }

  .copyright {
    color: #b4b4b4;
    font-size: 12px;
  }
  .right-column {
    width: 100%;
    height: 100%;
    position: "relative";
    display: flex;
    align-items: center;
    @media only screen and (max-width: 993px) {
      display: none;
    }
  }
  .banner {
    overflow: hidden;
    position: absolute;
    margin-top: 2%;
    @media only screen and (max-width: 1506px) {
      transform: scale(1.5);
      margin-left: -22%;
    }
    @media only screen and (min-width: 1507px) {
      transform: scale(1.5);
      margin-left: -12%;
    }
    @media only screen and (min-width: 1928px) {
      transform: scale(1.5);
      margin-left: -10%;
    }
    @media only screen and (min-width: 1961px) {
      transform: scale(1.7);
      margin-left: -8%;
    }
    @media only screen and (min-width: 2105px) {
      transform: scale(1.9);
      margin-left: -8%;
    }
  }

  .banner-carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .banner-carousel__image-wrapper {
      display: flex;
      justify-content: center;
      max-width: 700px;
      overflow: hidden;
      width: 90%;
      height: 0;
      opacity: 0%;
      transition: opacity 0.5s ease-out;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .displayed {
      height: 500px;
      opacity: 1 !important;
    }

    .banner-carousel-testimonial {
      max-width: 450px;
      text-align: center;
      padding: 15px;
      padding-top: 50px;

      .avatar {
        background: none;
        height: 100px;
        width: 100px;
        z-index: 10;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
      }

      p {
        background: #e8d9ff;
        border-radius: 10px;
        color: black;
        padding: 50px 50px 20px 50px;
        font-weight: 400;
        margin-top: 20px;
        .name {
          font-size: 15px;
          font-weight: bold;
          margin-top: 22px;
        }

        .title {
          font-size: 15px;
          margin-top: 5px;
          font-weight: bold;
        }
      }
    }

    .banner-carousel__buttons-wrapper {
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 65%;

      .banner-carousel__button {
        z-index: 500;
        width: 12px;
        height: 12px;
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 50%;
        cursor: pointer;
        margin: 0 15px;
      }

      .banner-carousel__button.clicked {
        background-color: white;
      }
    }

    p {
      color: white;
      font-size: 16px;
      margin-top: 40px;
    }
  }
}

.onboarding-links {
  border: none;
  background: none;
}

.success-txt {
  color: green;
  font-size: 16px;
}
