.exited-organizations-modal {
  .modal-dialog {
    width: 775px;
    max-width: 775px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }
  }
}

.exited-organizations-container {
  padding: 21px 30px 40px 44px;
  @media (max-width: $screen-sm) {
    padding: 15px;
  }
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 23px;
  }

  .exited-organizations-empty {
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .exited-organizations-search-box {
    margin-bottom: 23px;
    max-width: 190px;
  }

  .exited-organizations-text {
    font-size: 12px;
    color: #808080;
    margin-left: 7px;
    margin-bottom: 10px;
  }

  .exited-organizations-scroll-table {
    height: 320px;
    .exited-organizations-table-header {
      tr th {
        padding-bottom: 5px;
        border-top: 1px solid #dee2e6;
      }
      th {
        @media (max-width: $screen-sm) {
          font-size: 12px;
          padding: 5px;
        }
      }
    }
  }
  .settings-users-assigned-table-body {
    td {
      @media (max-width: $screen-sm) {
        font-size: 12px;
        padding: 5px;
      }
    }
  }
}
