.checkout-modal-modal {
  .modal-dialog {
    margin: 15px;
    width: calc(100vw - 30px);
    max-width: calc(100vw - 30px);
    height: calc(100vh - 30px);
    max-height: calc(100vh - 30px);
  }
  .modal-content {
    height: 100%;
    border-radius: 0;
  }
  .checkout-close-icon {
    display: none;
  }
}

.checkout-modal-container {
  height: 100%;
  .checkout-header {
    height: 60px;
    @include themed() {
      border: 1px solid t("lightToneContrast");
    }

    padding: 0 10px 0 15px;
    .checkout-header-title {
      color: #808080;
      font-size: 14px;
      font-weight: bold;
    }
    .checkout-header-back {
      font-size: 16px;
    }
    svg#minimaze_maximize,
    svg#Close {
      @include themed() {
        path:not([fill="none"]) {
          fill: t("iconColor") !important;
        }
      }
    }
  }

  .promo-code-container {
    .promo-text {
      font-size: 14px;
      color: #808080;
      text-decoration: underline;
      text-align: center;
    }
    .promo-input {
      padding-left: 10px;
      width: 100%;
      border: 1px solid #c2c2c2;
      color: #808080;
      &.error {
        border: 1px solid #ff5800;
      }
    }
    .button-input {
      font-size: 14px;
      color: #6c37c9;
      border: 1px solid #6c37c9;
      border-radius: 0;
      padding: 3px 26px;
      margin-left: -1px;
      &:disabled {
        color: #808080;
        background: #d9d9d9;
        border: 1px solid #c2c2c2;
      }
      &.error {
        border: 1px solid #ff5800;
        background: transparent;
        color: #666666;
      }
    }
  }

  .valid-promo-code-container {
    padding: 6px 12px;
    color: #00000072;
    background: #f8f8f8;
  }

  .checkout-content {
    height: calc(100vh - 60px - 30px);
    .second-container {
      @include themed() {
        background: t("bgRHS");
      }
      .order-summary-card {
        @include themed() {
          background: t("bgRHS");
        }

        padding: 35px 0;
        margin-top: 20px;
        .order-summary-text {
          font-size: 16px;
          @include themed() {
            color: t("text");
          }
          font-weight: bold;
        }
      }

      .total-container {
        padding-top: 16px;
        padding-left: 35px;
        padding-right: 35px;
        padding-bottom: 20px;
        color: #808080;

        .total-text {
          @include themed() {
            color: t("fontNormalGray");
          }
          font-size: 18px;
          font-weight: bold;
        }
        .total-price-without-discount-text {
          font-size: 20px;
          font-weight: bold;
          color: #c9cecd;
          text-decoration: line-through;
        }
        .total-price-with-discount-text {
          font-size: 20px;
          font-weight: bold;
          @include themed() {
            color: t("primary");
          }
        }
        a {
          @include themed() {
            color: t("primary");
          }
        }
      }
    }

    .step-wrapper {
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 85px;
    }

    .step-container {
      display: flex;
      .step-circle {
        margin-top: 1px;
        width: 23px;
        height: 23px;
        background-color: #808080;
        color: white;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }
      .step-text {
        font-size: 18px;
        @include themed() {
          color: t("text");
        }

        font-weight: bold;
        margin-left: 8px;
      }
      .edit-button {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .choose-plan-container {
    height: calc(100% - 60px);
    .description-text {
      padding-top: 38px;
      margin-right: 138px;
      margin-left: 138px;
      margin-bottom: 30px;
    }
    .buttons-container {
      margin-bottom: 111px;
      margin-left: 90px;
      margin-right: 15px;
      .monthly-button {
        min-width: 106px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .yearly-button {
        min-width: 106px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .features-row {
      color: #808080;
      font-size: 14px;
      padding-left: 102px;
      height: 55px;
      display: flex;
      align-items: center;
    }

    .plan-border-bottom {
      border-bottom: 2px solid #f2f2f2;
    }

    .plan-border-top {
      border-top: 2px solid #f2f2f2;
    }

    .empty-part-padding {
      padding-bottom: 60px;
      flex: 3.5;
    }

    .features-row-value {
      color: #2c2c2c;
      font-size: 15px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .plan-type {
      font-size: 16px;
      color: #565656;
    }
    .plan-price {
      font-size: 20px;
      color: #2c2c2c;
      font-weight: 500;
      margin-bottom: 22px;
    }

    .current-chosen-plan {
      padding-top: 13px;
      background: #f8f8f8;
      min-width: 180px;
      text-align: center;
      flex: 1.5;
      .plan-current-plan {
        font-size: 16px;
        color: #808080;
        margin-bottom: 36px;
      }
    }

    .unordered-list {
      margin-left: 130px;
      padding: 0;
      font-size: 16px;
      color: #565656;
      line-height: 25px;

      li {
        list-style-type: none;
        position: relative;
        margin-bottom: 5px;
      }

      li::before {
        content: "⬤";
        position: absolute;
        color: #6c37c9;
        left: -25px;
        top: -2px;
        font-size: 1.1em;
      }
    }
  }

  .plan-slide-show {
    display: flex;
    flex: 6.5;
    overflow: hidden;
  }

  .plan-to-choose {
    padding-top: 13px;
    text-align: center;
    flex: 1;
    transition: 0.5s transform ease-out;
    min-width: 33.33%;

    .plan-button {
      max-width: 110px;
      margin: 0 auto;
      margin-bottom: 22px;
    }
    &.plan-border-right {
      border-right: 1px solid #d9d9d9;
    }
    &.plan-border-left {
      border-left: 1px solid #d9d9d9;
    }
  }

  .plan-arrow-container {
    .actual-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 149px;
      cursor: pointer;
      padding-left: 30px;
    }
  }
  .empty-fill-plan {
    flex: 1;
    .empty-header {
      height: 149px;
    }
  }

  .success-error-close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .plan-upgraded-successfully {
    padding-top: 100px;
    text-align: center;
    .text-title {
      font-size: 16px;
      font-weight: 500;
      color: #60c288;
      margin-bottom: 30px;
    }

    .text-description {
      max-width: 720px;
      margin: 0 auto;
      font-size: 14px;
      text-align: left;
      margin-bottom: 20px;
    }

    .summary-container {
      max-width: 415px;
      background-color: #fbfbfb;
      padding: 27px 19px;
      margin: 0 auto;
      text-align: left;
      .text-title {
        font-size: 16px;
        color: #666666;
        font-weight: 600;
        margin-bottom: 30px;
      }
      .first-column {
        font-size: 14px;
        color: #808080;
      }

      .second-column {
        font-size: 16px;
        color: #2c2c2c;
      }
    }
  }

  .plan-error-container {
    padding-top: 100px;
    .text-title {
      font-size: 16px;
      font-weight: 500;
      color: #ff5800;
      margin-bottom: 30px;
      text-align: center;
    }
    .text-description {
      max-width: 720px;
      margin: 0 auto;
      font-size: 14px;
      margin-bottom: 28px;
    }
  }

  .plan-options-open-container {
    .text-label {
      font-size: 14px;
      @include themed() {
        color: t("text");
      }
    }
    .text-label-dark {
      font-size: 14px;
      @include themed() {
        color: t("iconColor");
      }
    }

    .numbers-arrow {
      i {
        font-size: 14px;
        @include themed() {
          color: t("text");
        }
      }
    }
  }

  .step-closed-container {
    .text-label {
      font-size: 12px;
      color: #8e8e8e;
    }
    .text-label-dark {
      font-size: 12px;
      @include themed() {
        color: t("iconColor");
      }
    }
  }

  .integrations-open-container {
    .text-label {
      font-size: 14px;
      color: #8e8e8e;
    }
    .added-label {
      color: #60c288;
      font-size: 13px;
      position: absolute;
      right: -40px;
      top: 10%;
    }
  }

  .credit-option-open-container {
    max-width: 260px;
    .text-label {
      @include themed() {
        color: t("text");
      }
      font-size: 14px;
    }
  }

  .payment-method-container {
    width: 220px;
  }

  .order-summary-padding {
    padding: 20px 35px;
    @include themed() {
      color: t("text");
    }
  }

  .actual-number-price {
    font-size: 16px;
    @include themed() {
      color: t("iconColor");
    }
  }

  .order-summary-plan {
    @extend .order-summary-padding;
    font-size: 16px;
    .rotating-arrow {
      i {
        font-size: 14px;
      }
    }
    .old-price {
      color: #c9cecd;
      text-decoration: line-through;
    }
  }

  .phone-numbers-summary {
    @extend .order-summary-padding;
    font-size: 14px;
  }

  .integrations-summary {
    @extend .order-summary-padding;
    font-size: 14px;
  }
}

.checkout-payment-method-paying-card {
  color: #202020 !important;
  padding: 6px 25px 6px 10px;
  .label-fields {
    font-size: 11px;
    margin-top: -7px;
    color: #808080;
  }
}
