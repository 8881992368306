/* Dashboard Setup Page */
.dashboard-setup-container {
  padding: 12px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include themed() {
    color: t("fontColor");
  }
  @media (max-width: $screen-md) {
    padding: 5px 10px 0px 10px;
  }

  .welcome-message {
    line-height: 26px;
    font-size: 22px;
    font-weight: 500;

    text-align: left;
  }
  .setup-items-container {
    width: 97.5%;
    height: auto;

    border-radius: 17px;
    @include themed() {
      border: 1px solid t("fontNormalGray");
    }
    @media (max-width: $screen-md) {
      width: 100%;
    }
    hr {
      margin: 0;
      @include themed() {
        border-color: t("tableDivider");
      }
    }
    .collapse-expand-icon {
      path:last-child {
        @include themed() {
          fill: t("iconColor");
        }
      }
    }
    .setup-item {
      display: flex;
      justify-content: space-between;
      padding: 15px 52px 42px 52px;
      @media (max-width: $screen-md) {
        padding: 20px;
      }
      .left-column {
        width: 50%;
      }
      .item-title {
        font-size: 16px;
        font-weight: 500;
      }
      .setup-level-container {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
      }
      .setup-level {
        border: 1px solid #d9d9d9;
        display: inline-block;
        border-radius: 17px;
        height: 10px;
        width: 60%;
        &.MuiLinearProgress-colorPrimary {
          background-color: white;
        }
        .MuiLinearProgress-barColorPrimary {
          background-color: #60c288;
        }
      }
      .setup-button {
        width: 155px;
      }
    }
    .warning {
      height: 108px;
      position: absolute;
      width: 11px;
      background-color: #fbff57;
      @media (max-width: $screen-md) {
        height: 75px;
      }
    }
    .sub-option {
      display: flex;
      align-items: center;
      padding-top: 30px;
      @media (max-width: $screen-md) {
        padding: 0px;
      }
    }
    .setup-dismiss {
      margin-top: 10px;
      width: fit-content;
    }
  }

  /* Choose A Number modal*/

  .modal-title {
    font-size: 24px;
    color: #202020;
  }
  .modal-container {
    margin: auto;
    text-align: center;
    width: 100%;
    display: inline-block;
    .phone-icon {
      margin: auto;
      margin-top: 60px;
      margin-bottom: 20px;
    }
    .modal-description {
      padding: 10px 20px;
      line-height: 30px;
      color: #565656;
      .added-number-text {
        color: #60c288;
        padding-bottom: 20px;
        padding-top: 30px;
      }
      .send-demo-confirmation-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 50px;
      }
    }
  }
  .number-setup-modal {
    padding: 32px 24px;
    .modal-content {
      width: 394px;
      height: 411px;
    }
    .footer-buttons {
      padding: inherit;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .close-icon {
      z-index: 100;
    }
  }

  .no-border {
    border: none;
    &.btn-outline-primary:not(:disabled):not(.disabled):active,
    &.btn-outline-primary:hover {
      background-color: white;
      color: $primary;
    }
  }
  .modal-content-large {
    .modal-content {
      height: 641px;
      width: 517px;
    }
    .modal-container {
      height: 85%;
    }
    .add-number-option {
      width: 336px !important;
      height: 136px;
    }
    .add-number-icon {
      width: 40px;
      height: 40px;
    }
    .plus-icon {
      color: #393838;
      font-size: 20px;
      font-weight: bold;
      padding: 10px 10px 0px 10px;
    }
    .option-title {
      font-size: 16px;
      font-weight: 500;
    }
  }

  /* Add Users Modal */

  .add-users-wrapper {
    .modal-dialog.modal-dialog-centered {
      max-width: 1100px;
      width: 70vw;
    }

    .modal-content {
      height: auto;
      width: 100%;
      padding: 25px 75px;
    }

    .title {
      font-size: 24px;
      width: 100%;
      text-align: center;
      color: #202020;
    }

    .add-users-rows {
      .add-user-row {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        height: 50px;
        margin: 40px 0;

        .add-user-row__index {
          position: absolute;
          font-size: 1rem;
          margin-top: 10px;
          color: #808080;
          left: -10px;
          top: -10px;
        }

        .add-user-row__input-wrapper {
          margin: 0 2%;
          width: 20%;
          .add-user-row__input {
            width: 100%;
          }
        }

        .add-user-row__select-wrapper {
          margin: 0 2%;
          width: 10%;
          max-width: 150px;
          min-width: 100px;
          .add-user-row__select {
            vertical-align: bottom;
            width: 100%;
          }
        }
      }
    }

    .add-users-rows__add-button {
      width: 150px;
    }

    .add-users-rows__limit {
      margin: 15px 20px;

      p {
        display: inline-block;
        font-size: 0.9rem;
        color: #565656;
      }
      a {
        text-decoration: none;
        display: inline-block;
      }
    }

    .add-users-rows__invite-button {
      width: 200px;
      height: 50px;
      margin: 10px auto;
    }
  }

  /* Send Demo Text modal*/
  .send-demo-text-wrapper {
    .modal-dialog.modal-dialog-centered {
      max-width: 1100px;
      width: 50vw;
    }

    .modal-content {
      height: auto;
      width: 100%;
      padding: 40px 35px;
    }

    .send-demo-text-container {
      h1 {
        font-size: 24px;
        width: 100%;
        color: #202020;
        margin-bottom: 30px;
      }
      .send-demo-text__input-wrapper {
        width: 50%;
        margin-bottom: 30px;
      }
      .send-demo-text__credit {
        display: inline-block;
        margin-left: 30px;
        .send-demo-text__credit-amount {
          display: inline-block;
          background-color: #808080;
          color: white;
          width: 19px;
          height: 19px;
          margin-bottom: 5px;
          margin-left: 5px;
          border-radius: 20%;
          vertical-align: middle;
          text-align: center;
        }
        .send-demo-text__credit-info {
          display: inline-block;
          width: 19px;
          height: 19px;
          margin-bottom: 5px;
          margin-left: 5px;
        }
      }
    }
  }
}
