.select2-container--inline {
  .select2-selection--single {
    align-items: center;
    border: 0;
    display: flex;
    height: auto;

    &:focus {
      border: 0;
      outline: 0;
    }
    .select2-selection__arrow:after {
      content: "\f107";
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      margin-left: 2px;
      position: relative;
    }
  }

  .select2-selection__rendered {
    padding: 0 !important;
  }

  .select2-dropdown {
    border: 1px solid #e1e1e1 !important;
  }
}
