.delete-organization-modal {
  .modal-dialog {
    width: 680px;
    max-width: 680px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }
  }
}

.delete-organization-container {
  padding: 18px 26px 28px 26px;
  font-size: 16px;
  color: #808080;

  h1 {
    @extend .custom-modal-title;
    margin-bottom: 17px;
  }

  .delete-organization-description {
    font-size: 16px;
    margin-bottom: 22px;
  }

  .delete-organization-improve {
    margin-top: 20px;
    margin-bottom: 45px;
    .delete-organization-select {
      max-width: 230px;
      width: 100%;
      margin-top: 8px;
      margin-left: 5px;
    }

    textarea {
      display: block;
      width: 100%;
      resize: none;
      margin-top: 14px;
      padding: 8px;
      &:focus {
        outline: none;
        border: 1px solid $primary;
      }
    }
  }

  .checkboxes-container {
    margin-left: 15px;
    .checkbox-text {
      font-size: 14px;
      margin-left: 10px;
    }
  }

  .confirm-container {
    margin-left: 46px;
    margin-top: 28px;
    margin-bottom: 51px;
    @media (max-width: $screen-sm) {
      margin-left: 0px;
      margin-top: 20px;
    }
  }

  .delete-organization-footer {
    display: flex;
    justify-content: flex-end;
  }
}

.numbers-to-released-modal {
  .modal-dialog {
    width: 450px;
    max-width: 450px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }
  }
}

.numbers-to-released-container {
  padding: 21px 10px;
  .numbers-to-released-header {
    padding: 0 30px 20px 30px;
    h1 {
      font-size: 24px;
      color: $font-color-dark;
      margin-bottom: 23px;
      font-weight: 400;
    }
    .search-container {
      max-width: 180px;
      margin-bottom: 18px;
    }

    .text-container {
      font-size: 12px;
      margin-bottom: 16px;
    }
  }

  .numbers-to-released-scroll-table {
    height: 300px;
    .numbers-to-released-table-header {
      tr th {
        padding-bottom: 5px;
      }
    }

    .numbers-to-released-table-body {
      tr td {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }
}

.confirm-verification-delete-organization-modal {
  .modal-dialog {
    width: 670px;
    max-width: 670px;
  }
}

.confirm-verification-delete-organization-container {
  padding: 23px 30px 42px 30px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 30px;
  }

  .text {
    font-size: 16px;
    color: #808080;
  }

  .confirm-verification-delete-organization-footer {
    display: flex;
    justify-content: center;
    .footer-btn-wrapper {
      width: 155px;
    }
  }
}
