.admin-settings-signatures-container {
  padding: 44px 0;
  .admin-settings-signatures-card-info {
    margin-left: 45px;
    margin-right: 45px;
    margin-top: 44px;
  }

  .admin-settings-signatures-top {
    padding-bottom: 45px;
    .admin-settings-signatures-text {
      font-size: 14px;
      color: #666666;
      margin-left: 45px;
    }
  }

  .admin-settings-signatures-bottom {
    display: flex;
    flex-wrap: wrap;
  }
}
