.edit-user-credits-modal {
  .modal-dialog {
    width: 360px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
}

.edit-user-credits-container {
  padding: 25px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 28px;
  }

  .total-credits {
    font-size: 14px;
    @include themed() {
      color: t("fontColor");
    }

    margin-bottom: 22px;
  }

  .footer-text {
    font-size: 12px;
    @include themed() {
      color: t("fontDarkGray");
    }
  }
}
