@import "../../../../base/global-theme";
.user-settings-user-number {
  padding-right: 0;
  padding-left: 0;
  padding-top: 20px;
  @extend .container-flex-column-100;

  .user-settings-user-number-header-container {
    padding-left: 30px;
    @media (max-width: $screen-md) {
      padding-left: 0px;
    }
    .user-settings-user-number-header-wrapper {
      @include themed() {
        border-bottom: 2px solid t("tableDivider");
      }
      padding-bottom: 25px;
      padding-left: 10px;

      .short-code {
        margin-top: 45px;
      }

      .user-number-header {
        padding-right: 40px;
        padding-left: 0;
        border-bottom: none;
        min-height: 35px;
        height: auto;
        @media (max-width: $screen-md) {
          padding-right: 10px;
        }
      }
    }
  }

  .user-settings-scroll-table {
    @media (max-width: $screen-md) {
      height: calc(100vh - 30vh);
    }
  }

  .user-settings-number-table-header {
    @include themed() {
      border-bottom: 2px solid t("tableDivider");
    }

    tr {
      th {
        padding-bottom: 5px;
      }
      th:first-child {
        width: 1px;
      }
    }
  }
}
