// color
$primary: #5517c1;
$primary-color: $primary;
$primary-light: #6c37c9;
$link: #935ef0;
$secondary: #e24e00;
$color-text: #122b28;
$color-label: #707070; // font-size
$size-text: 0.94rem;
$size-label: 0.75rem;
$light-tone: #f8f8f8;
$light-tone-contrast: #e1e1e1;
$font-color-dark: #202020;
$font-active-color: #d9d9d9;
$dark-top: #272727;
$sidebar-icon-default: #664f8e;
$avatar-text-purple: #c9b4eb;

// Screens sizes
$screen-xxs: 395px;
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
$screen-xl: 1500px;

.color-primary {
  color: $primary;
}

.color-label {
  color: $color-label;
}

a.color-primary:hover {
  color: $primary;
}

.color-secondary {
  color: $secondary;
}

a.color-secondary:hover {
  color: $secondary;
}
// Font family
$poppins: "Poppins", sans-serif;
