.reject-all-unresolved-modal {
  .modal-dialog {
    width: 600px;
    max-width: 600px;
  }
}

.reject-all-unresolved-container {
  padding: 21px 25px;
  font-size: 16px;
  color: #202020;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 10px;
  }
}
