.smart-merge-wizard-modal {
  > .modal-dialog {
    width: 420px;
    max-width: 420px;
  }
}

.smart-merge-wizard-header {
  padding-left: 23px;
  padding-right: 10px;
  margin-bottom: 20px;
  h1 {
    font-weight: 600;
    font-size: 20px;
    color: #122b28;
    margin-bottom: 0;
  }
  .subtitle-text {
    margin-top: -2px;
  }
}

.smart-merge-wizard-container {
  padding: 20px 0 0 0;
  color: #707070;
  font-size: 14px;

  .one-conflict-container {
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .smart-divider {
    background-color: #d3d3d3;
  }

  .smart-step-container {
    padding: 20px;
    .number-step-container {
      flex-shrink: 0;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: 1px solid #ebebeb;
      color: #707070;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
      &.success {
        background-color: #03c186;
        color: white;
        border: none;
      }
    }
    .success-text {
      color: #03c186;
    }
  }
}

.merge-unresolved-conflicts-modal {
  > .modal-dialog {
    margin: 15px 15px 15px 85px;
    width: calc(100vw - 100px);
    max-width: calc(100vw - 100px);
    height: calc(100vh - 30px);
    max-height: calc(100vh - 30px);
    > .modal-content {
      height: 100%;
    }
  }
}

.merge-unresolved-conflicts-container {
  padding: 20px;
  color: #707070;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  height: 100%;

  h1 {
    @extend .custom-modal-title;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .description-text {
    line-height: 1.2;
  }

  .cards-container {
    .conflict-card-on-hover {
      box-shadow: 3px 3px 6px #00000029;
    }

    .conflict-card-container {
      height: calc(100% - 49px); /* 49px from card header*/
    }

    & > div {
      margin-right: 20px;
      flex-shrink: 0;
    }
    & > div:last-child {
      margin-right: 0;
      flex-shrink: 0;
    }
  }
}

.conflict-table {
  z-index: 0;
  thead tr {
    th {
      padding-top: 7px;
      padding-bottom: 7px;
      background: #f2f2f2;
      border-top: 1px solid #e1e1e1;
      &:first-child {
        border-left: 1px solid #e1e1e1;
      }
      &:last-child {
        border-right: 1px solid #e1e1e1;
      }
    }
  }
  tbody tr {
    &.space-under > td {
      padding-bottom: 6px;
    }
    td {
      padding: 0;
      border: none;
      &:first-child {
        .cell {
          border-left: 1px solid #e1e1e1;
          border-bottom-left-radius: 5px;
        }
      }
      &:last-child {
        .cell {
          border-right: 1px solid #e1e1e1;
          border-bottom-right-radius: 5px;
        }
      }
      .cell {
        display: flex;
        align-items: center;
        border-top: 1px solid #e1e1e1;
        border-bottom: 1px solid #e1e1e1;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-right: 16px;
        padding-left: 16px;
        min-height: 42px;
      }
    }
  }
}

.merge-unresolved-conflicted-profile-modal {
  .modal-dialog {
    margin: 15px auto;
    width: 860px;
    max-width: 860px;
    height: calc(100vh - 30px);
    max-height: calc(100vh - 30px);
  }
  .modal-content {
    height: 100%;
  }
  .close-icon {
    position: absolute;
    right: 8px;
    top: 5px;
  }
}

.merge-unresolved-conflicted-profile-container {
  padding: 15px 17px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 12px;
  }
  p {
    font-size: 14px;
    color: #2a2a2a;
  }

  .yellow-banner {
    background-color: #f5e88435;
    padding: 12px 10px;
  }

  .card-info-current-header {
    background-color: #b4b4b4 !important;
    border-top-right-radius: 0 !important;
    color: #fff !important;
  }

  .card-info-current-big-container {
    border-bottom-right-radius: 0 !important;
  }

  .card-info-new-big-container {
    border-bottom-left-radius: 0 !important;
  }

  .card-info-new-header {
    background-color: #64c3e0 !important;
    border-top-left-radius: 0 !important;
    color: #fff !important;
  }

  .card-info-preview-wrapper {
    width: 300px;
    color: #2a2a2a;
    font-size: 14px;
  }
}

.smart-quick-merge-modal {
  .modal-dialog {
    width: 420px;
    max-width: 420px;
  }
}

.smart-quick-merge-container {
  padding-top: 18px;
  padding-bottom: 30px;
  color: #202020;
}

.merge-unresolved-conflicts-profile-table-view-modal {
  .modal-dialog {
    margin: 15px 15px 15px 85px;
    width: calc(100vw - 100px);
    max-width: calc(100vw - 100px);
    height: calc(100vh - 30px);
    max-height: calc(100vh - 30px);
  }
  .modal-content {
    height: 100%;
  }
}

.merge-unresolved-conflicts-profile-table-view-container {
  padding: 20px 30px;
  font-size: 14px;

  @include themed() {
    color: t("fontDarkGray");
  }

  display: flex;
  flex-direction: column;
  height: 100%;

  h1 {
    @extend .custom-modal-title;
    margin-bottom: 5px;
  }
  .text-subtitle {
    font-size: 18px;
    @include themed() {
      color: t("fontColor");
    }
  }
  .btn-primary {
    @include themed() {
      border-color: t("primary");
      background: t("primary");
      color: t("bg");
    }
  }
  .btn-outline-primary {
    @include themed() {
      border-color: t("primary");
      color: t("primary");
    }
  }
  .table-without-header {
    thead {
      display: none;
    }
    thead tr th {
      opacity: 0;
      border: none;
    }
  }

  .merge-table-row-active {
    .darker-color {
      color: #202020;
    }

    td {
      &:first-child {
        .cell {
          border-left: 1px solid #00000042;
          border-bottom-left-radius: 5px;
        }
      }
      &:last-child {
        .cell {
          border-right: 1px solid #00000042;
          border-bottom-right-radius: 5px;
        }
      }

      .cell {
        @include themed() {
          background: t("bgRHS");
        }

        border-top: 1px solid #00000042;
        border-bottom: 1px solid #00000042;
      }
    }
  }
}
