.campaign-item-container {
  font-size: 16px;
  color: #808080;
  padding: 14px 20px 15px 34px;
  cursor: pointer;
  transition: all 0.4s ease;
  &:hover {
    background-color: #3e374a;
    color: #ffffff;
  }
}

.campaign-header-container {
  padding: 10px 20px 3px 50px;
  background: #f8f8f8;
  border-bottom: 1px solid #e1e1e1;
  .title-name {
    color: #202020;
    font-size: 18px;
  }
  .subtitle-text {
    font-size: 12px;
    color: #808080;
    margin-top: -5px;
    margin-left: 2px;
  }
}

.campaign-people-container {
  .table-row {
    cursor: pointer;
    &.active {
      background: #f2f2f2;
    }
    &:hover {
      background: #f2f2f2;
    }
  }

  .filters-container {
    padding-left: 40px;
    padding-top: 20px;
    border-bottom: 2px solid #dee2e6;
  }
}

.campaign-people-rhs {
  padding-top: 40px;
  font-size: 16px;
  color: #202020;

  .close-icon {
    left: 15px;
    right: auto;
  }
  .big-avatar {
    width: 70px;
    height: 70px;
  }

  .user-profile-container {
    margin-top: 25px;
    margin-bottom: 20px;
    .title-text {
      text-align: center;
      font-size: 20px;
      color: #202020;
      margin-bottom: -5px;
      font-weight: 500;
    }
  }
}

.campaign-reports-and-actions-default-container {
  padding-top: 16px;
  .table-row-tr {
    cursor: pointer;
    &:hover,
    &.active {
      background: #f4f4f4;
    }
  }
}

.campaign-reports-and-actions-default-container-rhs {
  padding-top: 40px;
  font-size: 16px;
  color: #2c2c2c;

  .close-icon {
    left: 15px;
    right: auto;
  }

  .user-profile-container {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    .title-text {
      font-size: 20px;
      color: #202020;
      font-weight: 500;
    }
    .subtitle-text {
      font-size: 14px;
      color: #808080;
    }
  }
}

.campaign-reports-and-actions-contest-container {
  .campaign-reports-and-actions-contest-sidebar {
    background: #f8f8f8;
    width: 215px;
    display: flex;
    flex-direction: column;

    .sidebar-item {
      padding: 15px 21px;
      cursor: pointer;
      &:hover,
      &.active {
        background: #e6e6e6;
      }
      .text-container {
        .title-text {
          font-size: 16px;
          color: #3e374a;
          font-weight: 500;
        }
        .subtitle-text {
          font-size: 14px;
          color: #3e374a;
          margin-top: -3px;
        }
      }
    }
  }
}

.campaign-overview-container {
  .notification-container {
    padding: 15px 30px;
  }

  .overview-header-container {
    border-bottom: 2px solid #e4e6e6;
    padding-bottom: 15px;
    font-size: 14px;
    color: #808080;

    .padding-header {
      padding-left: 45px;
      padding-right: 55px;
    }

    .data-from-wrapper {
      width: 175px;
    }

    .overview-header-data-container {
      font-size: 14px;
      display: flex;
      border-top: 1px solid #e4e6e6;

      .left-data-side {
        padding-top: 15px;
        padding-bottom: 15px;
        display: flex;
        flex: 3;
        border-right: 1px solid #e4e6e6;

        .progress-circulars-container {
          padding-top: 20px;
          padding-left: 30px;
          padding-right: 30px;
          font-size: 12px;
          color: #2c2c2c;
          font-weight: 400;
          display: flex;
          justify-content: center;
        }
      }

      .right-data-side {
        font-size: 14px;
        font-weight: 400;
        color: #2c2c2c;
        padding: 40px 0 40px 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
      }
    }
  }
}
