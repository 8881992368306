.register-email {
  min-height: 100vh;
  //old icons - missing file in old icon
  background: url("../img/login-banner.svg") no-repeat center;
  background-size: cover;

  .header {
    align-items: center;
    width: 1280px;
    height: 50px;
    background: linear-gradient(to right, #935ef0 0%, #5517c1 100%);
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 5px 30px;
    position: sticky;
    top: 0;
    @media (max-width: 768px) {
      padding: 5px 15px;
    }

    .logo {
      line-height: 0;
      margin-left: 0 !important;
      @media (max-width: 600px) {
        .full {
          display: none;
        }

        .icon {
          display: inline-block !important;
        }
      }

      .icon {
        display: none;
      }
    }
  }

  .content {
    background: #fff;
    padding: 60px 15px;
    text-align: center;
    max-height: calc(100vh - 88px);
    overflow: auto;

    .filled-input {
      margin-bottom: 32px;

      .label {
        color: #8f8f8f;
        font-family: $poppins;
        font-size: 0.63rem;
      }

      .value {
        color: #122b28;
        font-family: $poppins;
        font-size: 1.38rem;
        font-weight: 600;

        .edit {
          color: $primary;
          display: inline-block;
          font-size: 12px;
          font-weight: 400;
          margin-left: 10px;
        }
      }
    }

    .title {
      h3 {
        color: $primary;
        font-size: 1.88rem;
        font-weight: bold;
      }

      p {
        color: #707070;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 24px;
        margin: 15px auto 0;

        small {
          font-size: 0.88rem;
          font-weight: 400;
        }
      }
    }

    .email {
      margin: 0 auto;
      max-width: 25%;
      margin-top: 70px;
      @media (max-width: 600px) {
        margin-top: 50px;
      }

      a {
        color: $primary;
      }
    }

    .message {
      margin-top: 60px;
      @media (max-width: 600px) {
        margin-top: 50px;
      }

      p {
        color: #707070;
        font-size: 0.88rem;
        margin: 0 auto;
      }
    }

    .buttons {
      margin-top: 50px;

      .btn {
        font-size: 0.88rem;
        min-width: 160px;
        margin: 0 5px;
        @media (max-width: 600px) {
          min-width: 100px;
        }

        &:focus {
          outline: 0;
          color: #fff;
          box-shadow: none !important;
        }

        &.btn-primary {
          background: $primary;
          border-color: $primary;
        }

        &.btn-secondary {
          background: $secondary;
          border-color: $secondary;
        }
      }
    }

    .input-field {
      max-width: 500px;
      margin: 0 auto;
      position: relative;

      input {
        border: 0;
        border-bottom: 1px solid #dbdbdb;
        border-radius: 0;
        color: $color-text;
        padding: 0;

        &:focus {
          box-shadow: none;
          border-color: $primary;

          & + .clear-icon {
            opacity: 1;
          }
        }
      }

      .clear-icon {
        color: $primary;
        position: absolute;
        top: 0;
        right: 10px;
        font-size: 1.25rem;
        text-decoration: none;
        opacity: 0;
      }
    }
  }

  footer {
    position: sticky;
    top: 100%;
    text-align: center;
    padding: 10px;

    p {
      color: #fff;
      font-size: 12px;
      margin-bottom: 0;
    }
  }
}
