@import "../../base/global-theme";

.choose-outgoing-signature-modal {
  .modal-dialog {
    width: 670px;
    max-width: 670px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
  .modal-content {
    @media (max-width: $screen-md) {
      height: auto;
      max-height: 80vh;
    }
  }
}

.choose-outgoing-signature-container {
  padding: 13px 30px 24px 30px;
  @media (max-width: $screen-md) {
    overflow-y: auto;
  }
  h1 {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 54px;
    color: $font-color-dark;
    @media (max-width: $screen-md) {
      margin-bottom: 15px;
    }
  }

  .default-signature-label {
    @extend .label-fields;
  }

  .margin-label {
    margin-top: 33px;
  }

  .choose-outgoing-signature-content {
    margin-top: 54px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: $screen-md) {
      margin-top: 0px;
      flex-direction: column-reverse;
    }

    .choose-outgoing-signature-content-left {
      max-width: 220px;
      width: 100%;
      @media (max-width: $screen-md) {
        max-width: 100%;
      }
      .default-signature-select-container {
        max-width: 220px;
        @media (max-width: $screen-md) {
          max-width: 100%;
        }
      }

      .default-signature-mange {
        font-size: 16px;
        margin-top: 19px;
      }
    }
  }

  .preview-text {
    @media (max-width: $screen-md) {
      margin: 0px !important;
    }
  }

  .preview-signature-content-container {
    width: 285px;
    padding: 16px;
    @include themed() {
      background: t("sentMessage");
    }
    border-radius: 23px;
    word-break: break-word;
    @media (max-width: $screen-md) {
      width: 100%;
      margin-bottom: 20px;
    }
    .preview-signature {
      font-size: 16px;
      @include themed() {
        color: t("iconMidGray");
      }
      margin-top: 32px;
      margin-bottom: 12px;
    }
    a {
      @include themed() {
        color: t("primary");
      }
    }
  }

  .choose-outgoing-signature-footer {
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    @media (max-width: $screen-md) {
      margin-top: 15px;
    }
  }
}
