.templates-shortened-links {
  @extend .container-flex-column-100;

  .shortened-links-header {
    border-bottom: none;
  }

  .shortened-links-info {
    font-size: 14px;
    padding: 0 40px;
    margin-bottom: 20px;
  }

  .short-link-templates-table {
    table-layout: fixed;
    width: 100%;

    @media (max-width: $screen-md) {
      table-layout: auto;
    }
  }

  .templates-shortened-table-body {
    td {
      @media (max-width: $screen-md) {
        padding: 10px;
      }
    }

    td:first-child {
      padding-left: 40px;
    }

    .active {
      background: #f5f4f4;
    }
  }

  .shortened-links-table-header {
    tr th {
      border-top: 1px solid #e1e1e1;
    }
  }
}

.shortlink-template-scroll-table {
  @media (max-width: $screen-md) {
    height: 80vh;
  }
}

.mobileRefreshCell {
  display: none;

  @media (max-width: $screen-md) {
    display: flex;
    height: 100% !important;
  }
}
