@import "../base/global-theme";

.card-info-container {
  border-radius: 17px;
  @include themed() {
    border: 1px solid t("cardBorder");
  }
  .description {
    @include themed() {
      color: t("fontColor");
    }
  }
  .card-info-title-container {
    @include themed() {
      color: t("text");
      background: t("bgRHS");
      border: 1px solid t("cardBorder");
    }
    font-size: 16px;
    font-weight: 600;
    border-radius: 17px 17px 0 0;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    .manage-link {
      @include themed() {
        color: t("primary");
      }
    }
  }

  .card-info-content-container {
    word-break: break-word;
    padding: 12px 18px;
    position: relative;
    overflow: hidden;
    @include themed() {
      color: t("fontColor");
    }
    .list-group-item {
      @include themed() {
        background: t("bg");
        color: t("rhsIconColor") !important;
      }
      .list-group-item-text {
        @include themed() {
          color: t("fontColor");
        }
        .simple-purple-button-text,
        a {
          @include themed() {
            color: t("primary");
          }
        }
      }
      .MuiInput-underline.Mui-focused::after {
        @include themed() {
          border-bottom-color: t("primary") !important;
        }
      }
      input {
        @include themed() {
          color: t("fontColor");
        }
      }
      .save {
        @include themed() {
          color: t("primary");
        }
      }
      svg {
        @include themed() {
          path:not([fill="none"]) {
            fill: t("iconColor");
          }
        }
      }
    }
    .card-info-content-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0;
      z-index: 100;
    }
  }
  @media (max-width: $screen-lg) {
    margin-left: 10px;
    margin-top: 10px;
  }
}

.sub-card-info-container {
  border: 1px solid $font-active-color;
  border-radius: 17px;
  .sub-card-info-title-container {
    color: #666666;
    font-size: 13px;
    font-weight: 600;
    border-radius: 17px 17px 0 0;
    padding: 10px 16px;
    display: flex;
    align-items: center;
  }

  .disabled-title {
    color: #d9d9d9;
  }

  .sub-card-info-content-container {
    border-top: 1px solid $font-active-color;
    padding: 10px 16px;
  }
}
