.subtitle-filters-group {
  font-size: 12px;
  font-weight: 500;
  .highlight {
    color: $primary;
  }
  .highlight-underline {
    color: $primary;
    text-decoration: underline;
  }
}

.filter-container {
  min-width: 160px;

  &:not(:last-child) {
    margin-right: 20px;
  }

  .title {
    color: #6c757d;
    font-size: 14px;
    padding-left: 2px;
  }
  .subtitle {
    color: #6c757d;
    font-size: 10px;
    padding-left: 2px;
  }
  .sub-filter-container {
    max-width: 130px;
  }
}

.conditioners-container {
  display: flex;
  > div {
    margin-left: 147px;
  }
}
