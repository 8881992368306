.transaction-history-filters-modal {
  .modal-dialog {
    width: 730px;
    max-width: 730px;
  }
}

.transaction-history-filters-container {
  padding: 25px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 38px;
  }
}
