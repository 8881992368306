.session-expired-modal {
  .modal-dialog {
    width: 300px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
}

.session-started-modal {
  .modal-dialog {
    width: 400px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
}

.server-error-modal {
  .modal-dialog {
    width: 400px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
}

.session-expired-container {
  padding: 24px;
  h1 {
    @extend .custom-modal-title;
    @include themed() {
      color: t("fontColor");
    }
  }
  p {
    @include themed() {
      color: t("fontColor");
    }
  }
}

.legacy-redirect-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .legacy-reidrect-wrapper {
    @extend .session-expired-container;
    box-shadow: 0 3px 18px rgb(0 0 0 / 26%);
  }
}
