body,
html {
  height: 100%;
}

body {
  &.login {
    font-family: $poppins !important;
    overflow: auto;
  }
}

.login-wrapper {
  //old icon - missing file in old icon
  background-image: url("../img/login-banner.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 100%;
  @media (max-width: 1024px) {
    display: block;
  }

  &.register {
    .form-group {
      margin-bottom: 0;
      width: 50%;
      @media (max-width: 1024px) {
        width: 100%;
      }
    }

    .login-banner {
      font-family: $poppins;
      padding-right: 200px;
      width: 50%;
      @media (max-width: 1024px) {
        padding-right: 0;
        width: 100%;
      }
      img {
        max-width: 100%;
      }
    }

    .form-group-inner {
      max-width: 400px;
      @media (max-width: 600px) {
        max-width: 100%;
      }
    }

    .devices {
      color: #fff;
      margin-left: 0;
      text-align: center;

      img {
        width: 483px;
        max-width: 100%;
        @media (max-width: 1024px) {
          max-width: 100%;
        }
      }
    }
  }

  .logo {
    margin-bottom: 50px;

    img {
      width: 220px;
    }
  }

  .form-group {
    background: #fff;
    padding: 50px 20px 20px 200px;
    position: relative;
    width: 50%;

    margin-bottom: 0;
    @media (max-width: 1024px) {
      width: 100%;
      padding: 50px 20px;
      display: flex;
      justify-content: center;
    }
    @media (max-width: 767px) {
      padding: 20px;
    }

    .forget-password {
      font-size: $size-text;
      font-weight: 400;
      margin-top: 100px;

      a {
        color: $primary;
      }
    }

    .not-register {
      color: #707070;
      font-size: $size-text;
      margin-top: 30px;

      a {
        color: $primary;
      }
    }
  }

  .form-group-inner {
    max-width: 300px;
    margin: 0 auto;
    @media (max-width: 600px) {
      max-width: 100%;
    }
  }

  .input-field {
    input {
      border: 0;
      border-bottom: 1px solid #dbdbdb;
      border-radius: 0;
      color: $color-text;
      font-size: 1.38rem;
      font-family: $poppins;
      height: 30px;
      margin-bottom: 30px;
      padding: 0;

      &:focus {
        box-shadow: none;
      }
    }

    ::placeholder {
      color: #b4b4b4;
      font-family: $poppins;
      font-size: 1.38rem;
      font-weight: 400;
    }
  }

  .filled-input {
    margin-bottom: 32px;

    .label {
      color: #8f8f8f;
      font-family: $poppins;
      font-size: 0.63rem;
    }

    .value {
      color: #122b28;
      font-family: $poppins;
      font-size: 1.38rem;
      font-weight: 600;

      .edit {
        color: $primary;
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        margin-left: 10px;
      }
    }
  }

  .button-wrapper {
    text-align: center;
    margin-top: 80px;
    .button {
      background: $primary;
      border: 0;
      border-radius: 5px;
      color: #fff;
      font-size: 14px;
      min-width: 150px;
      padding: 6px 25px;
      text-align: center;

      &:focus {
        outline: 0;
      }
    }
  }

  .login-banner {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 50%;
    padding-right: 90px;
    padding-left: 60px;
    @media (max-width: 1024px) {
      margin-top: 100px;
      padding: 100px 0;
      width: 100%;

      .devices {
        margin-left: 0;

        img {
          max-width: 100%;
        }
      }
    }
    .greeting {
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      font-family: $poppins;
    }
    .testimonial {
      max-width: 250px;
      text-align: center;

      .avatar {
        background: none;
        height: 100px;
        width: 100px;
      }

      .name {
        font-size: 15px;
        font-weight: bold;
        margin-top: 22px;

        a {
          color: #fff;
        }
      }

      .title {
        font-size: 15px;
        font-weight: 400;
        margin-top: 5px;

        a {
          color: #fff;
        }
      }

      p {
        color: #fff;
        font-size: 14px;
        font-weight: 300;
        margin-top: 30px;
      }
    }
  }

  .has-account {
    color: #707070;
    font-size: 14px;
    margin-top: 50px;

    a {
      color: $link;
    }
  }

  .footer-text {
    bottom: 15px;
    color: #b4b4b4;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    position: absolute;
    @media (max-width: 1024px) {
      background: #fff;
      bottom: 0;
      position: fixed;
      left: 0;
      right: 0;
      padding: 10px;
    }
  }

  .privacy-text {
    color: $color-label;
    font-size: 15px;
    margin: 60px 0;

    a {
      color: $link;
    }
  }
}

.register-toast {
  background: $secondary;
  bottom: 50px;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
  left: 55px;
  opacity: 1;
  position: fixed;
  z-index: 99;

  .toast-body {
    color: #fff;
  }
}

.react-turnstile-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
