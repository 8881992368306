.mobile-contact-detail {
  padding: 0;
  top: 106px;

  .fixed-header {
    border-top: 1px solid #b69be4;
  }

  .nav-tabs {
    border: 0;

    .nav-item .nav-link {
      margin-left: -20px;
    }
  }

  .tab-content {
    background-color: #f8f8f8;
  }

  .back-arrow {
    align-items: center;
    display: flex;
    height: 100%;
    margin-left: 17px;
    padding: 0 !important;

    &:focus {
      background: none;
    }

    &:hover {
      background: none;
    }
  }
}
