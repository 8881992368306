.dashboard-setup-add-new-number-modal {
  .modal-dialog {
    width: 360px;
    max-width: 360px;
  }
  .add-new-number-container {
    padding: 30px;
    h1 {
      @extend .custom-modal-title;
      margin-bottom: 25px;
    }
    .add-new-number-section-title {
      @extend .custom-modal-steps-section-title;
      margin-bottom: 28px;
    }
    .description {
      font-size: 14px;
      color: #808080;
      text-align: center;
      padding-top: 40px;
    }
    a {
      color: $primary;
      font-weight: 500;
    }
    .chosen-number {
      padding: 30px 0px 20px 0px;
      font-weight: 500;
      color: $black;
      font-size: 16px;
    }
    .prices-list {
      line-height: 30px;
    }
    .blank-row {
      height: 30px;
    }
  }
}
