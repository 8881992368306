.admin-settings-add-new-number-modal {
  .modal-dialog {
    width: 400px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
}

.pick-different-number-close-icon {
  top: 26px;
  right: 35px;
}

.admin-settings-add-new-number-container {
  .header-padding {
    padding: 21px 28px 0 28px;
  }

  .admin-settings-add-new-number-title {
    font-size: 24px;
    color: $font-color-dark;
    font-weight: 400;
    margin-bottom: 0;
  }
  .admin-settings-add-new-number-subtitle {
    font-size: 16px;
    text-align: center;
    @include themed() {
      color: t("primary");
    }

    margin-top: 30px;
    margin-bottom: 20px;
  }

  .type-number-chooser-container {
    height: 60px;
    display: flex;
    justify-content: center;
  }

  .disabled-option-wrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .type-number-chooser {
    cursor: pointer;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 16px 5px 16px 5px;
    width: 75%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      @include themed() {
        border: 2px solid t("primary");
      }
    }

    &.selected {
      @include themed() {
        border: 2px solid t("primary");
      }
    }
    p {
      margin-bottom: 0;
      font-weight: 600;
      @include themed() {
        color: t("primary");
      }
      font-size: 0.9em;
      text-align: center;
      &.disabled {
        color: #d9d9d9;
      }
    }
    &.disabled {
      border-color: #d9d9d9;
      cursor: default;
      &:hover {
        border: 1px solid #d9d9d9;
      }
    }
  }

  .more-options-text {
    margin-bottom: 0;
    font-weight: 600;
    cursor: pointer;
    @include themed() {
      color: t("primary");
    }
    font-size: 0.9em;
    text-align: center;
    &.disabled {
      color: #d9d9d9;
    }
  }

  .more-options-container {
    margin-top: 25px;
  }

  .admin-settings-add-new-number-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    padding: 0 28px 28px 28px;
  }

  .add-new-number-voice-broadcast {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    .voice-broadcast-tile {
      font-size: 18px;
      text-align: center;
      color: #5517c1;
      margin-bottom: 45px;
    }
    .voice-broadcast-number {
      font-size: 24px;
      font-weight: 600;
      color: #3e374a;
      text-align: center;
      margin-bottom: 19px;
    }

    .voice-broadcast-number-container {
      padding: 0 28px 28px 30px;
      .voice-broadcast-pick-different-number {
        display: block;
        font-size: 16px;
        text-align: center;
        margin-bottom: 39px;
        cursor: pointer;
        color: $primary;
      }

      .voice-broadcast-number-info {
        font-size: 14px;
        color: #565656;
        max-width: 266px;
        margin-left: auto;
        margin-right: auto;
      }

      .paying-info {
        margin-bottom: 5px;
        display: flex;
        justify-content: flex-start;
        color: #565656;
        span:first-child {
          width: 160px;
        }
        span:nth-child(2) {
          margin-left: 5px;
        }
      }

      .voice-broadcast-second-step {
        padding: 0 15px;
        color: #565656;
        display: flex;
        flex-direction: column;
        flex: 1;

        .paying-info-container {
          margin-bottom: 28px;
        }
      }
    }
  }

  .add-new-number-landline {
    margin-top: 20px;
    padding: 0 15px 15px 15px;

    .add-new-number-landline-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .landline-tile {
      font-size: 18px;
      text-align: center;
      color: #5517c1;
      margin-bottom: 0;
    }

    .input-label {
      font-size: 12px;
      color: #707070;
      margin-bottom: -5px;
    }

    .steps-info-list {
      color: #8e8e8e;
      font-size: 14px;
      padding-left: 16px;
      margin-left: 8px;
      list-style-type: none;
      position: relative;

      li {
        line-height: 1.3;
        margin-bottom: 15px;
      }

      li::before {
        content: "\2022";
        color: #a6a6a6;
        position: absolute;
        left: -7px;
        font-size: 2.35em;
        margin-top: -11px;
      }
    }

    .first-step {
      margin-top: 54px;
    }

    .first-to-second-processing {
      margin-top: 90px;
      color: #8e8e8e;
    }

    .what-happens {
      color: #6c37c9;
      font-size: 14px;
      cursor: pointer;
      margin-bottom: 0.55rem;

      .what-happens-icon {
        font-size: 12px;
      }
    }

    .second-step {
      margin-top: 43px;
      .second-step-text-info {
        font-size: 16px;
        color: #8e8e8e;
        margin-bottom: 25px;
      }

      .second-step-what-happens {
        text-align: center;
        margin-bottom: 27px;
      }

      .second-step-call-attempt {
        font-size: 16px;
        color: #ff5800;
        text-align: center;
        margin-top: 80px;
      }

      .code-screen-container {
        padding: 0 10px;
        margin-top: 20px;
        .second-step-text-info-verifying {
          font-size: 14px;
          color: #8e8e8e;
          margin-bottom: 6px;
        }
        .code-text {
          margin-top: 55px;
          margin-bottom: 40px;
          font-size: 24px;
          color: #202020;
          font-weight: 500;
          text-align: center;
        }

        .code-text-verified {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #60c288;
          font-weight: 600;
        }
      }
    }

    .third-step {
      margin-top: 28px;
      padding: 0 4px;

      .third-step-text-info {
        font-size: 14px;
        color: #8e8e8e;
      }

      .third-step-expand-text-info {
        font-size: 14px;
        line-height: 1.3;
        color: #8e8e8e;
      }

      .third-step-state {
        width: 100px;
      }

      .third-step-zip-code {
        width: 140px;
      }
    }

    .forth-step {
      padding: 0 19px;
      margin-top: 24px;
      .forth-step-text {
        font-size: 16px;
        text-align: center;
        color: #565656;
      }
      .forth-step-what-happens {
        text-align: center;
        margin-bottom: 25px;
      }
    }

    .add-new-number-landline-footer {
      margin-top: 5px;
      display: flex;
      justify-content: flex-end;
      padding: 3px;
    }
  }
}

.add-new-number-purchase-container {
  .title-text {
    color: #202020;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }

  .pricing-container {
    color: #3e374a;
    font-size: 12px;
  }

  .us-phone-number-cost-container {
    color: #3e374a;
    font-size: 14px;
  }
}
