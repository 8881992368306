.app-settings-container {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 40px;
  flex-wrap: wrap;
  @media (max-width: $screen-lg) {
    padding: 5px;
    justify-content: center;
  }

  .left-column {
    max-width: 350px;
    min-width: 320px;
    margin-right: 3rem;
    .card-info-container {
      margin-bottom: 25px;
      @media (max-width: $screen-lg) {
        margin-bottom: 10px;
      }
      .theme-setting-select {
        width: 90%;
        .MuiInputBase-root {
          font-weight: 500;
        }
      }
    }

    .card-info-content-container {
      padding-bottom: 48px;
      .app-settings-switch {
        span[label] {
          font-size: 16px;
          font-weight: 500;
        }
      }
      .app-settings-select {
        width: 75%;
      }
      @media (max-width: $screen-lg) {
        margin-bottom: 10px;
        padding-bottom: 15px;
      }
    }
    @media (max-width: $screen-lg) {
      margin-right: 0px;
    }
    @media (max-width: $screen-xs) {
      min-width: 300px;
      max-width: 300px;
    }
  }

  .right-column {
    max-width: 350px;
    min-width: 320px;
    .app-settings-select {
      width: 75%;
      .MuiInputBase-root {
        font-weight: 500;
      }
    }
    .theme-setting-select {
      width: 90%;
      .MuiInputBase-root {
        font-weight: 500;
      }
    }
    .volume-slider-container {
      width: 75%;
    }
    .volume-slider {
      width: 80%;
    }
    @media (max-width: $screen-xs) {
      min-width: 300px;
      max-width: 300px;
    }
  }
  .default-label {
    @extend .label-fields;
  }
  .audio-input-level {
    display: inline-block;
    width: 12px;
    height: 25px;
    border-radius: 50px;
    margin: 0px 5px;
  }
  .active-level {
    background-color: #5517c1;
  }
  .disabled-level {
    background-color: rgba(#a6a6a6, 0.5);
  }
  .app-behavior-card-container {
    min-width: 350px;
    @media (max-width: $screen-xs) {
      min-width: 300px;
      max-width: 300px;
    }
    .app-behavior-switch {
      span[label] {
        font-size: 16px;
      }
    }
  }
}

.move-things-around-text-container {
  font-size: 1rem;
  @include themed() {
    color: t("fontDarkGray");
  }

  svg,
  path,
  g {
    @include themed() {
      fill: t("primary") !important;
    }
  }
}
