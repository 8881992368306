.short-code-toll-free-container {
  .question-text {
    font-size: 14px;
    color: #bcbcbc;
    margin-bottom: 20px;
  }

  .response-text {
    font-size: 16px;
  }

  .short-code-toll-free-header-container {
    padding: 12px 18px;
  }
  .short-code-toll-free-body-container {
    padding: 12px 18px;
  }
  .short-code-toll-free-custom-select {
    min-width: 100px;
  }
  .enable-short-code-toll-free-container {
    padding: 14px 12px 0px 18px;

    .disabled {
      opacity: 0.5 !important;
    }

    .enable-short-code-sub-options-container {
      display: flex;
      align-items: center;
      margin-left: 20px;
      .enable-short-code-sub-option-wrapper {
        margin-left: 28px;
        padding-top: 15px;
      }
    }
  }
}

.switch-label {
  font-size: 14px;
  @include themed() {
    color: t("fontDarkGray");
  }
}
.switch-text {
  font-size: 16px;
  @include themed() {
    color: t("fontColor");
  }
}
