.add-new-voice-template-modal {
  .modal-dialog {
    width: 380px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }
  }
}

.add-new-voice-template-container {
  padding: 21px 28px 28px 30px;
  .step-three-review-container {
    font-size: 12px;
    @include themed() {
      color: t("fontColor");
    }

    .arrow-title {
      font-size: 11px;
      margin-left: 5px;
    }
    .card {
      @include themed() {
        background: t("bgRHS");
      }
    }
  }
}
