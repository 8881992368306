.select-new-assigned-number-modal {
  .modal-dialog {
    width: 420px;
    max-width: 420px;
  }
}

.select-new-assigned-number-container {
  padding: 22px 34px 32px 26px;
  .select-new-assigned-number-title {
    @extend .custom-modal-title;
    margin-bottom: 21px;
  }

  .select-new-assigned-number-search {
    max-width: 230px;
    margin-bottom: 20px;
  }

  .select-new-assigned-number-scroll-table {
    height: 250px;

    .select-new-assigned-number-table-header {
      tr {
        th:nth-child(2) {
          padding-left: 14px !important;
        }
        th {
          padding-top: 9px;
          padding-bottom: 9px;
        }
      }
    }

    .select-new-assigned-number-table-body {
      tr {
        td {
          padding-top: 13px;
          padding-bottom: 13px;
        }
        td:nth-child(1) {
          padding-left: 0;
        }
        td:nth-child(2) {
          padding-left: 5px;
        }
        td:nth-child(3) {
          font-weight: 500;
        }
      }
    }
  }

  .select-new-assigned-number-footer {
    margin-top: 28px;
    display: flex;
    justify-content: flex-end;
  }
}

.new-assigned-number-modal {
  .modal-dialog {
    width: 690px;
    max-width: 690px;
  }
}

.new-assigned-number-container {
  padding: 18px 29px 36px 26px;
  .new-assigned-number-title {
    font-size: 24px;
    color: $font-color-dark;
    margin-bottom: 17px;
    font-weight: normal;
  }
  .new-assigned-number-info {
    font-size: 16px;
  }

  .new-assigned-number-footer {
    display: flex;
    justify-content: flex-end;
    .yes-button {
      max-width: 135px;
    }
  }
}
