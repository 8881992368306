.check-number-status-modal {
  .modal-dialog {
    width: 360px;
    max-width: 360px;
  }
}

.check-number-status-container {
  padding: 21px 10px 25px 10px;
  .check-number-status-title {
    font-size: 24px;
    color: $font-color-dark;
    font-weight: 400;
    margin-bottom: 40px;
    margin-left: 20px;
  }
  .check-number-status-subtitle {
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    @include themed() {
      color: t("primary");
    }
    margin-bottom: 16px;
  }
  .check-number-body {
    font-size: 14px;
    @include themed() {
      color: t("fontDarkGray");
    }
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 38px;
    margin-bottom: 40px;
  }
  .check-number-pending-letter {
    .check-number-pending-letter-footer {
      font-size: 16px;
      color: #707070;
      text-align: center;
    }
  }

  .check-number-cancelled-expired-container {
    .check-number-cancelled-expired-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 39px;
    }
  }
}
