.duplicate-campaign-modal {
  .modal-dialog {
    width: 500px;
    max-width: 500px;
  }
}

.duplicate-campaign-container {
  padding: 25px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 45px;
  }
}
