.mobile-tab-container {
  @include themed() {
    background-color: t("bgRHS");
  }
  width: 100%;
  border-bottom: 1px solid #d7d7d7;
  padding: 16px 25px 16px 25px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .mobile-tab-text {
    display: flex;
    align-items: center;
    @include themed() {
      color: t("fontColor");
    }
  }

  .action-tabs {
    display: flex;
    align-items: center;
    @include themed() {
      color: t("primary");
    }
    svg {
      @include themed() {
        path:not([fill="none"]),
        circle {
          fill: t("primary") !important;
        }
      }
    }
  }

  .marked-warning {
    display: flex;
    align-items: center;
    color: #ff5800;
    svg {
      path:not([fill="none"]),
      circle {
        fill: #ff5800 !important;
      }
    }
  }

  .small-text {
    font-size: 12px;
    @include themed() {
      color: t("fontDarkGray");
    }
  }
}
