.campaign-setup-modal {
  .modal-dialog {
    width: 1100px;
    max-width: 1100px;
  }
}

.campaign-setup-container {
  padding-top: 20px;
  font-size: 16px;
  color: #777777;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 18px;
    text-align: center;
  }

  .content-container {
    display: flex;
    max-height: 1100px;
    position: relative;
    .content-coming-soon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      width: 230px;
      border: 1px solid #e2e2e2;
      font-size: 14px;
      font-weight: 600;
      color: #777777;
      position: absolute;
      top: 30%;
      left: calc(50% - 115px);
      z-index: 20;
      background-color: #fff;
      border-radius: 2px;
    }
    .content-cover {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 10;
      top: 0;
      left: 0;
    }
    .content-menu {
      width: 240px;
      flex-shrink: 0;
      background-color: #f8f8f8;
      padding: 14px;
      h3 {
        color: #122b28;
        font-size: 14px;
        margin-bottom: 20px;
      }
      .content-sub-menus {
        margin-left: 20px;
        margin-bottom: 20px;
        p {
          font-size: 14px;
          color: #777777;
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .content-header {
      background-color: #f8f8f8;
      padding: 8px 28px;
      h1 {
        color: #122b28;
        font-size: 16px;
        text-align: left;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }

    .content-body {
      padding: 26px 23px;
      filter: blur(4px);
      opacity: 0.5;
    }
  }
}

.campaign-modal-card {
  box-shadow: 0px 0px 3px #00000058;
  border-radius: 5px;
  background-color: #fff;
  .top-part {
    padding: 10px 6px;
    .title {
      font-size: 12px;
      color: #2c2c2c;
      font-weight: bold;
    }
    .sub-title {
      font-size: 10px;
      color: #777777;
      margin-bottom: 8px;
    }
    p {
      font-size: 10px;
      color: #2c2c2c;
    }
  }

  .campaign-image {
    height: 74px;
    width: 100%;
    object-fit: cover;
  }
}
