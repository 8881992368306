.exit-embeded-full-mode-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 2px 9px 2px 4px;
  border-radius: 5px;
  outline: none;
  border: none;
  transition: background-color 0.2s ease;
  cursor: pointer;
  @include themed() {
    color: t("primaryActionButtonText");
    background-color: t("primaryActionButtonBg");
  }
  &:hover {
    @include themed() {
      background-color: t("primary");
    }
  }
}

.embeded-full-mode-reload-btn {
  @include themed() {
    color: t("primary");
  }
  cursor: pointer;
  margin-right: 15px;
}

.auto-reply-embeded-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  font-size: 18px;
}

.iframe-launcher-container {
  height: 75%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .desc {
    margin-top: 12px;
    @include themed() {
      color: t("text") !important;
    }
  }
}

.launch-full-mode-button {
  @include themed() {
    background: t("primary");
    color: t("bg");
  }
}
