.add-edit-signature-modal {
  .modal-dialog {
    width: 670px;
    max-width: 670px;
  }
}

.add-edit-signature-container {
  padding: 13px 30px 24px 30px;
  font-size: 16px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 54px;
    .draft-tag {
      font-size: 18px;
      @include themed() {
        color: t("fontDarkGray");
      }

      margin-left: 26px;
    }
    @media (max-width: $screen-md) {
      margin-bottom: 10px;
    }
  }
  input {
    @include themed() {
      color: t("fontLightGray");
    }
  }

  .default-signature-label {
    @extend .label-fields;
    @media (max-width: $screen-md) {
      padding-top: 15px;
    }
    @include themed() {
      color: t("label");
    }
  }

  .add-edit-signature-left-side {
    flex: 1;
    .switch-wrapper {
      margin-top: 50px;
      @media (max-width: $screen-md) {
        margin-top: 10px;
      }
    }

    .signature-text-field-container {
      margin-top: 84px;
    }
    @media (max-width: $screen-md) {
      width: 100%;
      flex: auto;
    }
  }

  .short-code-notice {
    font-size: 12px;
    max-width: 240px;
    margin-left: 10px;
    display: flex;
    align-items: flex-start;
    @include themed() {
      color: t("label");
    }
    .grey-warning {
      @include themed() {
        color: t("fontDarkGray");
      }
    }
  }

  .add-edit-signature-right-side {
    flex: 1;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: $screen-md) {
      margin-left: 0px;
      flex: auto;
    }
    .siganture-name-field {
      @media (max-width: $screen-md) {
        width: 100%;
      }
      input {
        @include themed() {
          color: t("fontColor");
        }
      }
    }
  }

  .default-signature-select-container {
    max-width: 215px;
    @media (max-width: $screen-md) {
      max-width: 100%;
    }
  }

  .add-edit-signature-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 46px;
    padding: 3px;
    padding-top: 0;
  }
}

.settings-numbers-assigned-table-header-signatures {
  tr th {
    padding-left: 0.5rem !important;
  }
}
