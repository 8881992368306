.templates-email-templates-container {
  .email-templates-table {
    table-layout: fixed;
    th:first-child {
      padding-left: 40px;
    }
    .email-templates-table-body {
      td:first-child {
        padding-left: 40px;
      }
      .active {
        background: #f5f4f4;
      }
    }
  }
}

.templates-email-templates-rhs {
  .close-icon {
    left: 11px;
    top: 18px;
  }
  .email-template-menu {
    position: absolute;
    top: 12px;
    right: 11px;
  }
  .text-description {
    color: #808080;
  }
}
