@import "../.././../base/global-theme";

.upload-mp3 {
  padding-top: 40px;

  .dropzone {
    @include themed() {
      background: t("bg");
      border-color: t("primary");
    }
    border: 1px dashed;
    border-radius: 14px;
    width: 100%;
    min-height: 200px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 20px;
    cursor: pointer;

    .dz-message {
      width: 100%;
      margin: 0;
      text-align: center;
    }

    span {
      svg {
        height: 55px;
        width: 55px;
        margin-bottom: 22px;

        path {
          @include themed() {
            fill: t("text");
          }
        }
      }

      p {
        font-size: 14px;
        @include themed() {
          color: t("text");
        }
      }
    }

    &.dz-drag-hover .dz-message {
      opacity: 0.5;
    }
  }

  .uploading {
    background-color: #fff;
    border: 1px dashed $primary;
    border-radius: 14px;
    width: 100%;
    min-height: 200px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 40px;
    justify-content: center;

    .spinner-border {
      height: 50px;
      width: 50px;
      border-width: 6px;
      @include themed() {
        color: t("primary");
      }
    }
  }

  .uploaded {
    margin-bottom: 100px;
    margin-top: 50px;

    .info-single {
      align-items: flex-end;
      border-bottom: 1px solid #e1e1e1;
      display: flex;
      padding-bottom: 5px;
      margin-top: 50px;

      .info-left {
        max-width: 80%;

        p {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .label {
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        color: #707070;
        margin-bottom: 5px;
      }

      p {
        color: #122b28;
        font-size: 1rem;
        font-weight: 400;
        margin: 0;
        line-height: 20px;
      }

      a {
        color: #5517c1;
        border-radius: 5px;
        font-size: 0.75rem;
        font-weight: 500;
        margin-left: 10px;
      }
    }
  }
}
