.rename-broadcst-group-modal {
  .modal-dialog {
    width: 500px;
    max-width: 500px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }
  }
}

.rename-broadcst-group-container {
  padding: 22px 26px 25px 26px;
  .rename-broadcst-group-title {
    @extend .custom-modal-title;
    margin-bottom: 21px;
    font-weight: bold;
  }
  .rename-broadcst-group-text {
    margin-bottom: 30px;
  }

  .rename-broadcst-group-button {
    float: right;
  }
}
