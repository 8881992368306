.choose-sender-number-container-modal {
  .modal-content {
    max-width: 418px;
    width: 418px;
  }
}

.choose-sender-number-container {
  padding: 22px 26px;

  .choose-sender-number-search-wrapper {
    width: 70%;
  }

  .choose-sender-number-scroll-table {
    height: 330px;
    @include themed() {
      background: t("bgRHS");
    }
    .table {
      @include themed() {
        background: t("bgRHS");
      }
    }
  }

  h1 {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 20px;
    color: $font-color-dark;
  }

  .choose-sender-number-table-header {
    tr th {
      padding-bottom: 5px;
      @include themed() {
        background: t("bgRHS");
      }
    }
  }

  .choose-sender-number-table-body {
    tr td:first-child {
      padding-left: 5px;
      padding-right: 5px;
    }
    tr td:nth-child(2) {
      padding-left: 5px;
      padding-right: 5px;
      width: 165px;
    }
    tr td {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .use-secondary-num-text {
      font-size: 12px;
    }

    .checkbox {
      margin-top: 2px;
    }
  }
}
