@import "../../../base/global-theme";
.re-record-modal {
  .modal-dialog {
    width: 380px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }
  }
}

.re-record-container {
  padding: 30px 28px 28px 30px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 37px;
  }
  .re-record-section-title {
    @extend .custom-modal-steps-section-title;
  }
  .step-one-container {
    margin-top: 60px;
    margin-bottom: 59px;
    &.web-record {
      margin-top: 67px;
      margin-bottom: 145px;
    }
    &.mobile-record {
      padding-top: 0 !important;
      margin-top: 0;
    }
    &.upload-mp3 {
      padding-top: 0 !important;
      margin-top: 0;
    }
  }
  .btn-primary {
    @include themed() {
      border-color: t("primary");
      background: t("primary");
      color: t("bg");
    }
  }
  .btn-outline-primary {
    @include themed() {
      border-color: t("primary");
      color: t("primary");
      &:hover {
        background: t("primary");
        color: t("bg");
      }
    }
  }
}
