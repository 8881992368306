@import "../../../base/global-theme";
.switch-organization-modal {
  .modal-dialog {
    width: 360px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }
  }
}

.switch-organization-container {
  padding: 20px 26px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 53px;
  }
  .switch-organization-item-container {
    padding: 25px;
    cursor: pointer;
    .company {
      font-size: 16px;
      @include themed() {
        color: t("fontColor");
      }
    }

    .role {
      font-size: 12px;
      @include themed() {
        color: t("fontDarkGray");
      }
    }
  }
}
