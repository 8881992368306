.scheduled-message-summary-modal {
  .modal-dialog {
    width: 420px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
}

.broadcast-header-icon {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #565656;
  color: white;
}

.broadcast-subheader-text {
  color: #808080;
}

.type-pills {
  background-color: #e1e1e1;
  color: #666666;
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 4px;
}

.text-successfull {
  color: #00c308;
}

.text-failed {
  color: #ff5800;
}

.group-name {
  cursor: pointer;
  @include themed() {
    color: t("primary");
  }
}

.broadcast-quick-filter {
  text-decoration: underline;
  cursor: pointer;
  @include themed() {
    color: t("primary");
  }
}

.status-bg-light-blue {
  background-color: #64c3e0 !important;
  color: white;
  border-radius: 7px;
  padding: 2px 10px;
}

.status-bg-light-green {
  background-color: #08ae8f !important;
  color: white;
  border-radius: 7px;
  padding: 2px 12px;
}

.status-bg-red {
  background-color: #fc4242 !important;
  color: white;
  border-radius: 7px;
  padding: 2px 12px;
}

.broadcast-thread-actions-btn {
  border: none;
  border-radius: 5px;
  font-size: 14px;
  @include themed() {
    background-color: t("defaultActionButtonBg");
    color: t("defaultActionButtonText");
    svg path:not([fill="none"]) {
      fill: t("defaultActionButtonText") !important;
    }
  }
}

.text-capitalize {
  text-transform: capitalize;
}

.ellipsis-text {
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter-dropdown-menu {
  width: 200px;
  height: fit-content;
}
