.group-external-result-container {
  width: 100%;
  margin-top: 20px;
  .group-external-result-title-wrapper {
    padding: 00px 0px 0px 30px;
    @include themed() {
      color: t("fontDarkGray");
    }
  }
  .external-result-list-wrapper {
    padding: 30px;
    @media (max-width: $screen-lg) {
      padding: 18px;
    }

    @include themed() {
      border-top: 1px solid t("fontDarkGray");
      border-bottom: 1px solid t("fontDarkGray");
    }
    .external-integration-identity {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include themed() {
        color: t("fontDarkGray");
      }
    }

    .external-integration-content-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      font-size: 16px;
      .external-group-name {
        margin-left: 10px;
        @include themed() {
          color: t("fontDarkGray");
        }
      }
      .external-group-link {
        cursor: pointer;
        font-weight: bold;
        @include themed() {
          color: t("inverseFontColor");
        }
      }
    }
  }
}

.group-list-options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 40px;
  margin-top: 15px;
  .title {
    @include themed() {
      color: t("fontDarkGray");
    }
    font-size: 0.9rem;
  }

  .show-more-link {
    @include themed() {
      color: t("inverseFontColor");
    }
    cursor: pointer;
    font-size: 0.9rem;
  }
}

.view-more-link {
  @include themed() {
    color: t("inverseFontColor");
  }
  cursor: pointer;
  text-align: right;
  margin-right: 30px;
  margin-top: 7px;
  font-size: 0.9rem;
}
