.broadcast-group-warning-modal {
  .modal-dialog {
    width: 688px;
    max-width: 688px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }
  }
}

.broadcast-group-warning-container {
  padding: 22px 26px 25px 26px;
  .broadcast-group-warning-title {
    @extend .custom-modal-title;
    margin-bottom: 21px;
    font-weight: bold;
  }
  .broadcast-group-warning-text {
    font-size: 16px;
    @include themed() {
      color: t("fontDarkGray");
    }

    margin-bottom: 30px;
  }

  .broadcast-group-warning-button {
    float: right;
  }
}
