@import "../../base/global-theme";

.log-details-container-modal {
  .modal-dialog {
    width: 675px;
    max-width: 675px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
  .modal-content {
    @media (max-width: $screen-md) {
      width: 100vw;
    }
  }
}

.log-details-container {
  padding: 13px 30px 24px 30px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 0;
  }

  .log-details-table-body {
    td {
      @include themed() {
        border: 1px solid;
      }
      text-transform: capitalize;
    }
  }
}
