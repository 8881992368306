@import "../base/global-theme";

.chat-empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  .themed-color {
    @include themed() {
      color: t("primary");
    }
    svg {
      @include themed() {
        path:not([fill="none"]) {
          fill: t("primary") !important;
        }
      }
    }
  }
}
.chat-empty {
  @include themed() {
    color: t("fontColor");
  }
  font-size: 16px;
  font-family: "Roboto Bold", "Helvetica Neue", sans-serif;
  text-align: center;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}

.chat-empty-center {
  position: absolute;
  top: 42%;
  width: 100%;
}

.chat-empty-title {
  font-size: 20px;
  @include themed() {
    color: t("text");
  }
  font-weight: bold;
}

.menu-empty-title {
  font-size: 18px;
  @include themed() {
    color: t("text");
  }
  font-weight: bold;
}

.chat-empty-link {
  margin-top: -15px;
  text-align: start;
  @include themed() {
    color: t("primary");
  }
}
.chat-empty-text {
  @include themed() {
    color: t("text");
  }
}
.link-text {
  @include themed() {
    color: t("primary");
  }
  cursor: pointer;
}
.banner-link-text {
  color: white;
  font-weight: 600;
  margin-left: 4px;
  cursor: pointer;
  text-decoration: none;
  :hover {
    color: white;
  }
}
