.landline-verify-call {
  &.show {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 925px;
    min-height: 570px;
  }

  .modal-content {
    font-family: $poppins;
    width: 90% !important;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    padding: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title {
      font-size: 30px;
      font-weight: 700;
      color: $primary;
      margin-bottom: 40px;
    }

    .aware-info {
      font-size: 20px;
      color: #707070;
      font-weight: 500;
      margin-bottom: 22px;
    }

    .verification-number {
      font-size: 22px;
      font-weight: 600;
      color: #122b28;
      margin-top: 30px;
    }

    .end-call {
      height: 58px;
      width: 58px;
      color: #fff;
      background-color: #dd0000;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-top: 42px;

      i {
        font-size: 40px;
        cursor: pointer;
      }
    }

    .verification-data p {
      font-size: 14px;
      color: #707070;
    }
  }

  &.confirmation .modal-dialog {
    max-width: 700px;

    .btn-next {
      width: 150px;
      margin-top: 40px;
    }
  }
  @media (max-width: 768px) {
    .modal-content {
      padding: 24px 14px;
      .title {
        font-size: 24px;
        margin-bottom: 28px;
      }
      .aware-info {
        font-size: 18px;
        margin-bottom: 12px;
      }
      .verification-number {
        font-size: 22px;
        margin-top: 16px;
      }
      .end-call {
        margin-top: 20px;
        height: 46px;
        width: 46px;
        i {
          font-size: 32px;
        }
      }
    }
  }
}
