.video-trimmer-modal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  .modal-dialog {
    margin: 15px;
    width: 75vw;
    max-width: 75vw;
    height: 90vh !important;
    max-height: 90vh !important;
    @media (max-width: $screen-md) {
      width: 100vw !important;
      max-width: 100vw !important;
      margin: 0px !important;
    }
  }

  .modal-content {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .video-trimmer-modal-body {
    height: 70%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 2px solid gray;
    @media (max-width: $screen-md) {
      height: 65%;
    }
  }

  .video-trimmer-timer {
    color: gray;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 5px;
    margin-top: 5px;
    @media (max-width: $screen-md) {
      font-size: 0.8rem;
    }
  }

  .video-trimmer-timer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    width: 98%;
    margin-top: 10px;
  }

  .video-trimmed-timer {
    @include themed() {
      color: t("fontColor");
    }
    font-size: 1rem;
    font-weight: 600;
    margin-left: 5px;
    margin-top: 5px;
    @media (max-width: $screen-md) {
      font-size: 0.8rem;
    }
  }

  .video-trimmer-footer {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
  }

  .video-trimmer-border-buttons {
    &:hover {
      color: white !important;
    }
    @media (max-width: $screen-md) {
      font-size: 0.8rem;
    }
  }
}
