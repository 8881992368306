.add-people-to-group {
  .modal-dialog {
    max-width: 1100px;
  }

  &.add-people-to-group-on-compose {
    .modal-dialog {
      max-width: 1100px !important;
    }

    .modal-content {
      height: 55vh !important;
    }
  }

  .modal-body {
    display: flex;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    @media (max-width: 600px) {
      flex-direction: column;
      padding-bottom: 0;
    }

    .left {
      display: flex;
      flex-direction: column;
      @media (max-width: 600px) {
        border-bottom: 1px solid #ddd;
        margin-bottom: 10px;
        overflow: auto;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      @media (max-width: 600px) {
        overflow: auto;
      }
    }
  }

  .search-wrapper {
    max-height: 34px;

    &.focused {
      border-color: $primary;
    }
  }

  .modal-content {
    // background: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    max-height: 80vh;
    height: 80vh;
    padding: 30px 30px 5px;
    @media (max-width: 600px) {
      padding: 15px 15px 0;
      max-height: 100% !important;
      height: 100% !important;
    }
  }

  .amc-alert {
    align-items: center;
    background: #fc7e00;
    border-radius: 5px 5px 0 0;
    color: #fff;
    display: flex;
    justify-content: center;
    left: 0;
    padding: 10px 10px 20px;
    position: absolute;
    right: 0;
    top: -40px;
    z-index: -1;
    @media (max-width: 600px) {
      padding-left: 5px;
      padding-right: 5px;
    }

    .icon {
      font-size: 16px;
      margin-right: 10px;
    }
  }

  .contact-wrapper {
    background: none;
    border-right: 0;
    margin-top: 10px;
    overflow: auto;

    .message-single {
      background: none;
      padding-left: 20px;

      .name {
        display: block;
      }

      .icon {
        margin-left: 10px;
      }
    }
  }

  .thread-list {
    @media (max-width: 600px) {
      height: 250px !important;
    }
  }

  .disable-add-new-contact {
    cursor: default !important;
    &.message-single {
      .detail .name {
        color: #d9d9d9;
      }
    }
    &.message-single:hover {
      background-color: white;
    }
  }

  .modal-head {
    align-items: center;
    display: flex;
    @media (max-width: 600px) {
      align-items: flex-start;
      flex-direction: column;

      .title {
        margin-bottom: 10px;
      }
    }

    .title {
      // color: #122b28;
      flex-grow: 1;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0;
      margin-right: 14px;
      white-space: nowrap;
    }

    .group-name {
      align-items: center;
      display: flex;
      max-width: 100%;
      overflow: hidden;

      h3 {
        // color: #122b28;
        font-size: 20px;
        font-weight: 500;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .icon {
        margin-left: 10px;
      }
    }
  }

  .people-count {
    color: #122b28;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .people-count-detail {
    margin-bottom: 30px;
    @media (max-width: 600px) {
      margin-bottom: 10px;
    }

    .single {
      align-items: center;
      color: #b4b4b4;
      display: inline-flex;
      margin-right: 20px;

      .icon {
        margin-left: 8px;
      }
    }
  }

  .selected-people {
    margin-bottom: 20px;
    overflow: auto;
    @media (max-width: 600px) {
      border-bottom: 1px solid #ddd;
      height: 120px !important;
      overflow-y: scroll !important;
    }

    .single {
      align-items: center;
      background: #f8f8f8;
      border: 1px solid #dbdbdb;
      border-radius: 5px;
      color: #122b28;
      display: inline-flex;
      height: 32px;
      margin-bottom: 6px;
      margin-right: 4px;
      padding: 0 8px;

      &.group {
        transform: translate(0, 2px);
      }
    }

    .avatar {
      height: 14px;
      min-width: 14px;
      width: 14px;
    }

    .user-icon {
      margin-left: 6px;
    }

    .close-icon {
      color: #122b28;
      font-size: 12px;
      margin-left: 14px;
    }
  }

  .custom-switch {
    padding-left: 3rem;

    .info-icon {
      color: #122b28;
      font-size: 16px;
      margin-top: 3px;
    }

    .custom-control-label {
      align-items: center;
      color: #122b28;
      display: flex;
      font-size: 16px;

      .material-icons {
        color: #122b28;
        font-size: 16px;
        margin-left: 12px;
      }
    }
  }

  .helper-text {
    color: #e03737;
    font-size: 12px;
    margin-left: -50px;
    margin-top: 10px;
    max-width: 382px;
    text-align: right;
    @media (max-width: 600px) {
      margin-bottom: 0;
    }
  }

  .custom-switch .custom-control-label::before {
    background: rgba(180, 180, 180, 0.4);
    border: 0;
    height: 16px;
    left: -3rem;
    width: 30px;
  }

  .custom-switch .custom-control-label::after {
    background: #fff;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.16));
    left: calc(-3rem + 2px);
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background: rgba($primary, 0.4);
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background: $primary;
  }

  .modal-footer {
    border-top: 0;
    padding-right: 0;

    @media (max-width: 600px) {
      padding-top: 10px;
    }

    .btn {
      min-width: 90px;
    }
  }
}

.modal {
  @media (max-width: 600px) {
    z-index: 10000;
  }
}
