@import "../../../base/global-theme";
.mobile-record {
  padding-top: 40px;

  .short-info {
    font-size: 14px;
    @include themed() {
      color: t("fontDarkGray");
    }
  }

  .search-wrapper-outer {
    display: flex;
  }

  .search-wrapper {
    @include themed() {
      background: t("textArea");
      border: 1px solid t("fontDarkGray");
    }
    display: flex;
    align-items: center;
    height: 34px;
    padding: 0 10px;
    border-radius: 17px;
    flex-grow: 1;
    margin: 25px 0;

    &.focused {
      border-color: $primary;
    }
  }

  .scrolling-content {
    margin-bottom: 50px;
    margin-top: 25px;

    .number {
      font-size: 14px;
      @include themed() {
        color: t("iconColor");
      }
    }

    .name {
      font-size: 14px;
      @include themed() {
        color: t("text");
      }

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    a i {
      font-size: 16px;
    }

    p {
      margin: 0;
    }

    .single {
      padding: 10px 10px 10px 0;
      border-top: 1px solid #d3d3d3;
      border-bottom: 1px solid #d3d3d3;
      justify-content: space-between;
      align-items: center;

      & + .single {
        border-top: 0;
      }

      .content {
        overflow: hidden;
      }
    }
  }

  .connecting-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
    font-size: 14px;

    .title {
      color: #707070;
    }

    .spinner-border {
      margin-top: 16px;
      margin-bottom: 42px;
      @include themed() {
        color: t("primary");
      }
    }
  }

  .connected-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    color: #707070;

    .time {
      font-size: 20px;
    }

    i {
      color: #b4b4b4;
      font-size: 60px;
      margin: 15px 0;
    }

    .label {
      font-size: 14px;
    }
  }
}
