.add-people-to-campaign-modal {
  .modal-dialog {
    width: 600px;
    max-width: 600px;
  }
}

.add-people-to-campaign-container {
  padding: 35px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 17px;
    text-align: center;
  }
  .gray-box {
    margin-top: 40px;
    width: 100%;
    background: #f4f4f4;
    border-radius: 11px;
    padding: 23px 10px;
    font-size: 12px;
    color: #808080;
  }
}
