@keyframes minimizeMacEffectUp {
  0% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
  50% {
    transform: scale(0.5) translateY(-50%);
    opacity: 0.5;
  }
  100% {
    transform: scale(0.1) translateY(-100%);
    opacity: 0;
  }
}

@keyframes maximizeMacEffect {
  0% {
    transform: scale(0.1) translateY(-100%);
    opacity: 0;
  }
  50% {
    transform: scale(0.5) translateY(-50%);
    opacity: 0.5;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

.voice-dialer-paper {
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1),
    opacity 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.minimized-effect {
  animation: minimizeMacEffectUp 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
}

.maximized-effect {
  animation: maximizeMacEffect 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
}

@keyframes slideLeftEnter {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideLeftExit {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

// Define keyframes for slide left-in animation
.slide-left-enter {
  animation: slideLeftEnter 0.5s forwards;
}

.slide-left-exit {
  animation: slideLeftExit 0.5s forwards;
}
