.edit-do-not-call-message-modal {
  .modal-dialog {
    width: 690px;
    max-width: 690px;
  }
}

.edit-do-not-call-message-container {
  padding: 18px 26px 22px 26px;
  .edit-do-not-call-message-title {
    @extend .custom-modal-title;
    margin-bottom: 20px;
  }

  .edit-do-not-call-message-control-container {
    margin-top: 25px;
  }

  .edit-do-not-call-message-control-footer {
    margin-top: 61px;
    display: flex;
    justify-content: flex-end;
  }
}
