.contest-history-modal {
  .modal-dialog {
    width: 670px;
    max-width: 670px;
  }
}

.contest-history-container {
  padding: 20px 15px;
  .header-container {
    padding: 0 30px;
    h1 {
      @extend .custom-modal-title;
      margin-bottom: 15px;
    }
    .search-wrapper {
      max-width: 230px;
    }
  }

  .contest-history-table-scroll {
    height: 300px;
  }
}
