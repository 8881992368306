.legacy-gift-claiming-wrapper {
  .modal-dialog.modal-dialog-centered {
    max-width: 900px;
    width: 750px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: 100% !important;
    }
  }
  .modal-content {
    width: 100%;
    height: 600px;
  }
}
