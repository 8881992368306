.delete-tag-modal {
  .modal-content {
    padding: 20px 25px 25px 25px;

    .close {
      opacity: 1;
      outline: 0;
      position: absolute;
      right: 15px;
      top: 10px;
    }

    .title {
      font-size: 22px;
    }

    .modal-body {
      margin-top: 31px;
      padding: 0;

      p {
        margin: 0;
      }

      .button-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 33px;

        .button-delete {
          background: #ff5800;
          border-color: #ff5800;

          &:focus {
            box-shadow: 0 0 0 0.2rem rgba(255, 88, 0, 0.5);
          }
        }
      }
    }
  }
}
