.cancel-plan-modal {
  .modal-dialog {
    width: 680px;
    max-width: 680px;
  }
}

.cancel-plan-container {
  padding: 25px;
  color: #808080;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 17px;
  }

  .cancel-plan-description {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .checkboxes-container {
    margin-left: 15px;
  }

  .confirm-container {
    margin-left: 46px;
    margin-bottom: 51px;
  }

  textarea {
    resize: none;
    width: 100%;
    height: 70px;
    padding: 10px;
  }
}
