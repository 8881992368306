.campaign-people-filters-modal {
  .modal-dialog {
    width: 420px;
    max-width: 420px;
  }
}

.campaign-people-filters-container {
  padding: 25px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 40px;
  }
}
