@import "../../base/global-theme";

.message-tab {
  overflow: hidden;

  .dropdown-menu {
    line-height: 1.5 !important;
  }

  .message-unread-info {
    align-items: center;
    border-bottom: 2px solid #202020;
    display: flex;
    font-size: 0.81rem;
    font-weight: 400;
    height: 34px;
    margin-top: 46px;
    padding: 0 15px;
    top: 0;
    z-index: 9;

    a {
      color: #122b28;
    }

    .no-unread {
      text-align: center;
      width: 100%;
    }
  }

  .message-list {
    @include themed() {
      background: t("leftSideMenu") !important;
    }

    border-top: 0;
    border-bottom: 0;
    padding-bottom: 120px;

    .message-list-thread-notification {
      padding: 8px 5px 7px 36px;
    }
  }

  .message-single {
    // background-color: #2C2C2C;
    // border-bottom: 1px solid #202020;
    // border-top: 1px solid #202020;
    position: relative;
    display: flex;
    height: 82px;
    align-items: center;
    padding: 14px 20px 15px 10px;

    &.duplicate-contact-thread {
      height: 98px !important;
    }

    .duplicate-profile-count {
      @include themed() {
        color: t("inverseFontColor");
      }
    }

    @media (max-width: $screen-md) {
      padding: 14px 20px 15px 20px;
    }

    .new-contact-icon {
      svg,
      path,
      g {
        @include themed() {
          fill: t("primary");
        }
      }
    }

    &:hover {
      @include themed() {
        background: t("filterBackgroundHover");
      }

      .new-contact-icon {
        svg,
        path,
        g {
          @include themed() {
            fill: t("newContactIcon");
          }
        }

        &:hover {
          svg,
          path,
          g {
            fill: #c9b4eb !important;
          }
        }
      }

      // background-color: #40394c;

      .detail .name {
        @include themed() {
          color: t("leftMenuText");
        }
      }

      .detail .message-info .message-text {
        @include themed() {
          color: t("threadMessage");
        }
      }

      .avatar {
        opacity: 1;
      }
    }

    &.active {
      @include themed() {
        background: t("filterBackgroundHover");
        color: t("leftMenuText");
      }

      .detail .name {
        @include themed() {
          color: t("leftMenuText");
        }
      }

      .detail .message-info .message-text {
        @include themed() {
          color: t("threadMessage");
        }
      }

      .avatar {
        opacity: 1;
      }
    }

    &.selected {
      background: #fff;
    }

    &.new-contact {
      align-items: center;

      .action {
        display: flex;

        a {
          line-height: 0;
          margin-left: 10px;
        }

        i {
          color: $primary-light;
          font-size: 20px;
        }
      }
    }

    &.inactive-contact {
      opacity: 0.3;
    }

    &.thread-item-without-second-row {
      height: 68px !important;
    }

    &.duplicate-contact-thread-without-second-row {
      height: 80px !important;
    }

    &.contact-tab-single {
      align-items: center;

      &.with-detail {
        align-items: flex-start;

        .message-text {
          display: block;
          font-size: 13px;
          color: #707070;
        }

        .info {
          display: flex;
        }

        .detail {
          flex-direction: column;
        }

        .detail-single {
          display: flex;
          align-items: center;
          white-space: nowrap;
          overflow: hidden;

          .message-text {
            margin-top: 0;

            &.number {
              color: #122b28;
              font-size: 13px !important;
            }
          }

          .info .phone-icon,
          .optedout-icon svg {
            max-height: 12px;
          }
        }
      }

      .name {
        color: #d9d9d9;

        .duplicate {
          opacity: 0.5;
        }
      }

      .message-text {
        display: none;
      }

      .info {
        align-items: center;
        display: none;

        .status-icon {
          display: inline-flex;
          margin-right: 10px;

          &.group-icon {
            svg {
              width: 17px;
            }
          }

          &.optedout-icon {
            svg {
              width: 15px;
            }
          }

          &.phone-icon {
            svg {
              width: 8px;
            }
          }

          svg {
            width: 16px;

            path {
              fill: #b4b4b4;
            }
          }
        }
      }
    }
    h3.name {
      display: flex;
      justify-content: flex-start;
      padding-top: 0;
    }

    &.group {
      align-items: center;
      padding: 13px 20px;
      border-bottom: none;

      .name {
        color: #122b28;
      }

      .number {
        align-items: center;
        color: #122b28;
        display: inline-flex;
        font-size: 16px;
        justify-content: center;
        margin-left: 15px;
        margin-top: 0;
      }

      .total-number {
        color: #b4b4b4;
        font-size: 16px;
        margin-left: 10px;
      }

      .icon {
        line-height: 0;
        margin-left: auto;
        min-width: 24px;
        text-align: right;

        img {
          opacity: 0.5;
        }

        &.info {
          display: flex;

          svg {
            width: 14px;
            max-height: 14px;

            path {
              fill: #b4b4b4;
            }

            rect {
              fill: #b4b4b4;
            }
          }
        }
      }
    }

    &.unread {
      .message-text {
        font-weight: 600;
        @include themed() {
          color: t("leftMenuText");
        }
        font-size: 12px;

        svg path {
          fill: #122b28;
        }
      }

      .name {
        font-size: 1rem;
        font-weight: 600;
        @include themed() {
          color: t("leftMenuText");
        }
      }

      h3 {
        display: flex;
        margin: 0;
        font-size: 1rem;
        align-items: center;
        justify-content: flex-start;
      }
    }

    &.undelivered {
      h3 {
        display: flex;
        margin: 0;
        font-size: 1rem;
        align-items: center;
        justify-content: flex-start;

        .undelivered {
          font-size: 1rem;
          color: $secondary;

          &.cleared {
            color: #dbdbdb;
          }
        }
      }
    }

    &.my-contact {
      display: flex;
      align-items: center;
      border-bottom: none;
      padding: 13px 20px;

      &.pressed {
        .info {
          display: flex;
        }
      }

      .info {
        display: none;
        align-items: center;
        margin-top: 8px;

        .number {
          margin-top: 0;
        }

        .status-icon {
          margin-left: 10px;

          img {
            vertical-align: middle;
          }
        }
      }

      .icon {
        img {
          vertical-align: middle;
        }
      }
    }

    .avatar {
      width: 36px;
      min-width: 36px;
      height: 36px;
      line-height: 36px;
      font-size: 1.06rem;
      text-align: center;
      border-radius: 50%;
      margin: 0 4px 0 15px;
      color: $avatar-text-purple;
      font-weight: 400;
      opacity: 0.75;

      &.avatar-with-checkbox {
        width: 20px;
        min-width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin: 0 4px 0 10px;
      }
      svg {
        transform: scale(1.06);
      }
    }

    .detail {
      overflow: auto;
      overflow-y: hidden;
      flex-grow: 1;
      margin-left: 10px;

      .name {
        margin: 0;
        color: #808080;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        i {
          color: #707070;
          font-size: 1rem;
          margin-left: 5px;
          margin-top: -2px;
          vertical-align: middle;
        }

        .icon {
          margin-left: 5px;
        }
      }

      .nothing-found {
        font-size: 0.8rem;
        white-space: normal;

        a {
          @include themed() {
            color: t("nothingFoundLinkText") !important;
          }
        }
      }

      &.detail-active {
        svg.icon-grey-dark path:not([fill="none"]) {
          @include themed() {
            fill: t("selectedThreadItem") !important;
          }
        }

        .name,
        .message-text {
          @include themed() {
            color: t("selectedThreadItem");
          }
          font-weight: 500;
        }
      }
      .one-way-integration-badge {
        background-color: #a6a6a6;
        padding: 4px 0px;
        margin-left: 4px;
        margin-top: 4px;
        width: 70px;
        svg {
          padding-bottom: 1px;
        }
      }
    }

    .archive-icon-threaditems {
      font-size: 12px;
      @include themed() {
        color: t("threadActionIcon");
      }

      display: flex;

      .archive-btn {
        z-index: 1;
        cursor: pointer;
        background: transparent;
        border: none;
        outline: none;
        display: flex;

        .caption {
          margin-left: 4px;
          margin-top: 1px;
          @include themed() {
            path:not([fill="none"]) {
              fill: t("threadActionIcon") !important;
            }
          }

          position: unset;
        }
      }

      .caption {
        position: relative;
        top: 2px;
      }

      svg {
        @include themed() {
          path:not([fill="none"]) {
            fill: t("threadActionIcon") !important;
          }
        }
      }
      &:hover {
        svg {
          @include themed() {
            path:not([fill="none"]) {
              fill: t("threadItemsHoverColor") !important;
            }
          }
        }
      }
    }

    .lhs-archive-popover {
      button {
        border: none;
        background: none;
        outline: none;
        color: #fff;
      }
    }

    h3 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      padding-top: 3px;
      line-height: 1;
      white-space: nowrap;

      .date {
        font-size: 0.81rem;
        display: flex;
        align-items: center;
        color: #707070;
        margin-top: 1px;
        font-weight: 400;
        margin-left: auto;

        i {
          font-size: 0.75rem;
          margin-right: 5px;
        }
      }
    }

    .number {
      margin: 0;
      font-weight: 300;
      font-size: 0.94rem;
      margin-top: 8px;
      color: $color-text;
    }

    .message-info {
      display: flex;
      align-items: center;
      // justify-content: space-between;

      .info {
        display: flex;
        align-items: center;
        margin-top: 6px;

        i,
        span {
          margin-left: 10px;
        }

        i {
          font-size: 0.75rem;
        }

        .unread-noti {
          color: #d9d9d9;
        }

        .undelivered-noti {
          color: #ff5800;
        }

        .circle-noti {
          font-weight: 500;
          font-size: 10px;
          height: 15px;
          width: 15px;
          background-color: #565656;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .info-position {
        position: static !important;
        right: 7%;
        margin-left: auto;
        margin-right: 0;
      }
      .icon > svg {
        @include themed() {
          path:not([fill="none"]) {
            fill: t("threadMessage") !important;
          }
        }
      }
    }

    .threadItemHeading {
      display: flex;
      max-width: 100%;
      max-height: 40px;
    }

    .thread-item-title-text {
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      &.unread {
        font-weight: 500;
        @include themed() {
          color: t("leftMenuText");
        }
      }
      &:hover {
        @include themed() {
          color: t("inverseFontColor") !important;
        }
      }
    }

    .thread-item-sync {
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
      background-color: transparent;
      outline: none;
      border: none;
      @include themed() {
        color: t("threadActionIcon") !important;
      }
    }

    .groupMemberCount {
      align-items: center;
      font-size: 16px;
      margin: inherit;
    }

    .message-text {
      margin: 0;
      color: #808080;
      font-size: 12px !important;
      margin-top: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
      display: block;
      align-items: center;

      .highlightText {
        background-color: yellow;
        color: black;
      }

      svg {
        margin-right: 5px;
        max-height: 14px;
        min-width: 15px;
        display: none;

        path {
          fill: #707070;
        }
      }

      .icon {
        vertical-align: middle;
        margin-right: 7px;
      }
    }
    .thread-item-icons-container {
      display: flex;
      flex-direction: row;
      gap: 5px;
      margin-top: 2px;
      align-items: center;
      svg {
        @include themed() {
          path:not([fill="none"]) {
            fill: t("rhsIconColor") !important;
          }
        }
      }

      .thread-item-orange-label-icon {
        color: #ff5800 !important;
        svg path:not([fill="none"]) {
          fill: #ff5800 !important;
        }
      }
    }
  }
  .no-data-text {
    font-size: 12px;
  }
}

.thread-actions-container-group-view {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-right: 10px;
  padding-left: 44px;
  position: relative;

  @media (max-width: $screen-md) {
    flex-direction: column;
    padding-top: 5px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .left-actions {
    position: relative;

    input {
      opacity: 0;
      position: absolute;
      left: 0px;
      top: 1px;
      width: 18px;
      height: 18px;
      z-index: 1;
    }

    .selection-container {
      margin-left: 25px;

      .arrow {
        position: relative;
        top: 1px;
        cursor: pointer;
        margin-left: 5px;
        &.all-groups-menu {
          top: -7px;
        }
      }

      .caption {
        margin-right: 8px;
        margin-left: 3px;
        color: #808080;
        position: relative;
        top: 1px;
        font-size: 12px;
      }

      .group-view-caption {
        margin-left: 15px;
      }
    }

    .checkmark {
      border: 1px solid #666666;
      width: 18px;
      height: 18px;
      position: absolute;
      top: 2px;

      &.checkmark-checked {
        border: none;
        background: $sidebar-icon-default;
        svg {
          position: absolute;
          left: -2px;
          top: -2px;
        }
      }

      &.checkmark-checked-line {
        border: none;
        background: $sidebar-icon-default;
        svg {
          position: absolute;
          left: 3px;
          top: 7px;
        }
      }
    }
  }
  .right-actions {
    .group-selected {
      display: flex;
      .group-selected-item {
        margin: 0 10px;
        @include themed() {
          color: t("primary");
        }

        display: flex;
        align-items: center;

        span {
          font-size: 12px;
        }

        svg {
          @include themed() {
            path:not([fill="none"]) {
              fill: t("primary") !important;
            }
          }
        }
      }
    }
  }
}

.threads-actions-container {
  display: flex;
  @include themed() {
    background: t("leftMenu");
  }
  padding: 10px 16px 15px 28px;
  position: relative;

  .loading-text {
    color: #fff;
  }

  .left-actions {
    flex: 1;
    position: relative;

    input {
      opacity: 0;
      position: absolute;
      left: 0px;
      top: 5px;
      width: 18px;
      height: 18px;
      z-index: 1;
    }

    .selection-container {
      margin-left: 25px;

      .arrow {
        position: relative;
        top: 5px;
        cursor: pointer;
      }

      .caption {
        margin-right: 8px;
        margin-left: 3px;
        font-size: 14px;
        color: #808080;
        position: relative;
        top: 5px;
      }
    }

    .checkmark {
      border: 1px solid #666666;
      width: 18px;
      height: 18px;
      position: absolute;
      top: 5px;

      &.checkmark-checked {
        border: none;
        background: $sidebar-icon-default;
        svg {
          position: absolute;
          left: -2px;
          top: -2px;
        }
      }

      &.checkmark-checked-line {
        border: none;
        background: $sidebar-icon-default;
        svg {
          position: absolute;
          left: 3px;
          top: 7px;
        }
      }
    }
  }

  .right-actions {
    display: flex;

    .total-items-selected {
      color: #808080;
      margin-top: 3px;
    }
    .actions-btns {
      border: none;
      background: transparent;
      cursor: pointer;
      outline: none;
    }
  }
  @media (max-width: $screen-md) {
    padding-left: 10px;
  }
}

.delete-btn {
  color: #ff5800;
}

.thread-other-actions-popover-with-more {
  .popover {
    .popover-body {
      width: 230px;
      .collapsable-menu-wrapper {
        display: flex;
      }
    }
  }
}

.thread-other-actions-popover-unresolved-conflicts {
  .popover {
    .popover-body {
      width: 280px;
      .collapsable-menu-wrapper {
        display: flex;
      }
    }
  }
}

.thread-other-actions-popover .popover {
  min-width: 176px;
  max-width: 320px;
  z-index: 1305;
  button {
    border: none;
    background: none;
    outline: none;
    display: block;
    text-align: left;

    &.marked-warning {
      color: #ff5800;
    }
    &.marked-sync {
      color: #5517c1;
    }
  }
  .popover-body {
    .popover-thread-group {
      display: flex;
    }
  }
}

.people-sorting-popover {
  width: 180px;
  padding: 0.75rem;
  button {
    border: none;
    background: none;
    outline: none;
    display: flex;
    // justify-content: space-between;
    width: 100%;
    text-align: left;
    line-height: 2.2;
  }
}
.people-action-icon {
  margin-left: 4px;
}

.people-sorting-name {
  color: #6c37c9;
}

.people-sorting-action-bar {
  color: #808080;
  font-size: 0.9rem;

  &:hover {
    cursor: pointer;
  }
}

.people-sorting-btn {
  display: flex;
  padding-left: 20px;
  position: relative;

  .sorting-btn-active-icon {
    position: absolute;
    left: 0px;

    svg rect {
      fill: #6c37c9;
    }
  }

  .people-actions-popover-rightpanel {
    flex: 1;
    text-align: left;

    &:hover {
      color: #6c37c9;
    }
  }
}

.threads-actions-container-groups {
  display: flex;
  @include themed() {
    background: t("bgRHS");
    border-bottom: 1px solid t("lightToneContrast");
    border-top: 1px solid t("lightToneContrast");
  }

  padding-top: 10px;
  padding-bottom: 15px;
  padding-right: 10px;
  padding-left: 24px;
  position: relative;

  .loading-text {
    color: #fff;
  }

  .left-actions {
    flex: 1;
    position: relative;

    input {
      opacity: 0;
      position: absolute;
      left: 0px;
      top: 5px;
      width: 18px;
      height: 18px;
      z-index: 1;
    }
    .arrow {
      position: relative;
      top: 5px;
      cursor: pointer;
    }
    .caption {
      margin-left: 25.5px;
      margin-right: 5px;
      font-size: 14px;
      color: #808080;
      position: relative;
      top: 5px;
    }

    .checkmark {
      border: 1px solid #666666;
      width: 17px;
      height: 17px;
      position: absolute;
      top: 5px;

      &.checkmark-checked {
        border: none;
        background: $sidebar-icon-default;
        svg {
          position: absolute;
          left: -2px;
          top: -2px;
        }
      }
    }
  }

  .right-actions {
    display: flex;

    .total-items-selected {
      color: #808080;
      margin-top: 3px;
    }
    .actions-btns {
      border: none;
      background: transparent;
      cursor: pointer;
      outline: none;
    }
  }
}

.skip-sendng-actions {
  margin-left: 8px;
  margin-bottom: 5px;
}

.skip-sending-text {
  margin-left: -34px;
}

.skip-sending {
  margin-bottom: 10px;

  i {
    font-size: 12px;
    margin-left: 8px;
    margin-top: 1px;
  }
}

.skip-sending-btn {
  margin-left: -25px;
  margin-bottom: 5px;
}

#accordionRhsGroups {
  margin-bottom: -7px;
}

.collapsible-header[aria-expanded="true"] .im-angle-down .fa-angle-down {
  transform: rotate(180deg);
  transition: transform 150ms ease;
}
.collapsible-header[aria-expanded="false"] .im-angle-down .fa-angle-down {
  transition: transform 150ms ease;
}

.thread-container {
  position: relative;
  .im-phone {
    color: #b4b4b4 !important;
  }
}

.archival-progress-overlay {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #27272799;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .archival-progress-text {
    font-size: 12px;
    color: #8e8e8e;
  }
}

.archival-header-overlay {
  position: absolute;
  padding-left: 5px;
  left: 0px;
  top: 0;
  @include themed() {
    background-color: t("leftMenu");
  }
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .archival-progress-text {
    font-size: 12px;
    color: #8e8e8e;
  }

  &.archival-members {
    flex-direction: row;
    background-color: #f8f8f8;
    justify-content: flex-start;
    z-index: 9;

    .archival-progress-text {
      margin-left: 20px;
    }
  }

  &.archival-header-rhs {
    background-color: #f8f8f8;
  }
}

.dropdown-popover {
  .popover {
    z-index: 1500;
  }
  .popover-body {
    padding-left: 0px;
    padding-right: 0px;
  }
  .dropdown-popover-item {
    font-weight: 500;
    padding: 0px 10px 0px 10px;
    line-height: 30px;
    &:hover {
      background: #f5f5f5;
    }
  }
}

.filter-thread-menu {
  padding: 16px 48px 16px 12px;

  .filter-thread-menu-header {
    font-size: 14px;
    color: #a6a6a6;
    margin-bottom: 16px;
    line-height: 19px;
  }
  .filter-thread-menu-option {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .filter-thread-menu-text {
      font-size: 16px;
      padding-left: 11px;
      line-height: 21px;
      margin-top: 2px;
    }
  }
}

.sync-icon {
  animation: rotate 3s linear infinite;
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }
}

.group-info.hub-thread-group-table-view .sync-icon-tooltip > svg > path {
  fill: rgb(100, 195, 224) !important;
}

.linear-progress-bar-wrapper {
  background-color: #c9b4eb;
  padding: 5px 10px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .title {
    color: #5517c1;
    font-size: 12px;
    font-weight: 600;
    width: 100px;
    @media (max-width: $screen-md) {
      font-size: 10px;
    }
  }
}

.image-video-container {
  display: flex;
  align-items: center;
  @include themed() {
    background: t("bg");
  }
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  padding: 5px 10px;
}

.thumbnail-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  border: 1px solid #666666;
  .thumbnail {
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
  }

  .thumbnail-detail-type {
    text-transform: capitalize;
    font-size: 0.8rem;
    @include themed() {
      color: t("fontMidGray");
    }
  }

  .thumbnail-detail-name {
    font-size: 0.8rem;
    @include themed() {
      color: t("text");
    }
  }
}
.close-icon-container {
  top: 0;
  right: 0;
  background: #fff;
  padding: 0 5px;
  border: 1px solid black;
}

.disabled-feature {
  opacity: 0.6;
  cursor: not-allowed !important;
}

.image-loader-container {
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #666666;
}

.video-play-circle-icon {
  position: absolute;
  path:not([fill="none"]) {
    fill: #fff !important;
  }
}

.video-thumbnail {
  object-fit: cover;

  &.corrupt-video {
    background: black;
  }
}

.message-delivery-alert-container {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  padding: 3px 10px 3px 8px !important;
  border-radius: 30px !important;
  @include themed() {
    border: 1px solid t("fontDarkGray") !important;
  }

  .message-deleivery-alert-text {
    @include themed() {
      color: t("fontDarkGray") !important;
    }
  }

  .message-delivery-alert-link {
    @include themed() {
      color: t("fontDarkGray") !important;
    }
    text-decoration: underline !important;
    margin-left: 5px !important;
    font-weight: bold !important;
  }

  .message-delivery-alert-close-icon {
    svg,
    path,
    g {
      @include themed() {
        fill: t("fontDarkGray") !important;
      }
    }
  }

  .message-delivery-alert-icon {
    svg,
    path,
    g {
      @include themed() {
        fill: t("fontDarkGray") !important;
      }
    }
  }
}

.hub-thread-header-duplicate-profile-count {
  @include themed() {
    color: t("primary");
  }
}
