.video-viewer-modal {
  .modal-content {
    border: none;
    background-color: transparent;
  }

  .modal-backdrop {
    opacity: 0.7;
  }
}
