.transfer-ownership-modal {
  .modal-dialog {
    width: 390px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }
  }
}

.transfer-ownership-container {
  padding: 14px 17px 21px 20px;
  color: #808080;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 30px;
  }
  .transfer-ownership-section-title {
    @extend .custom-modal-steps-section-title;
    margin-bottom: 50px;
  }

  .transfer-ownership-content-step-one {
    padding: 0 25px;
    .select-user-container {
      margin-bottom: 40px;
    }
    .numbers-link {
      @include themed() {
        color: t("primary");
      }

      cursor: pointer;
    }
  }

  .switch-container {
    margin-top: 38px;
    margin-bottom: 54px;
  }

  .transfer-ownership-content-step-two {
    padding: 0 5px 0 10px;
    font-size: 16px;
    color: #808080;

    .new-owner-text {
      @include themed() {
        color: t("iconColor");
      }
    }

    .checkbox-container {
      display: flex;
      margin-top: 40px;
      font-size: 14px;
      margin-bottom: 25px;
    }

    .switch-container {
      margin-bottom: 85px;
      display: flex;
    }

    .delete-account-warning {
      margin-left: 52px;
      margin-top: 5px;
      color: #ff5800;
      font-size: 12px;
    }
  }
}
