@import "../../base/global-theme";

.delete-duplicates-modal {
  .modal-dialog {
    max-height: 100%;
    @media (min-width: 576px) {
      max-width: 1000px !important;
    }
  }

  .modal-content {
    border: 0;
    border-radius: 5px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    padding: 28px 31px;
    max-height: 90%;
  }

  .modal-header {
    border-bottom: 0;
  }

  .content {
    flex-grow: 1;
    overflow: auto;
    max-height: 350px;
    min-height: 350px;
  }

  .modal-footer {
    border-top: 0;
  }

  .modal-title {
    font-weight: bold;
    font-size: 20px;
    color: #122b28;
  }

  .short-info {
    padding: 20px 0;
    font-size: 14px;
    @include themed() {
      color: t("text");
    }
  }

  table {
    position: relative;
  }

  thead {
    .header-row {
      .custom-checkbox
        .custom-control-input:checked
        ~ .custom-control-label::before {
        @include themed() {
          background: t("primary") !important;
        }
      }

      th {
        position: sticky;
        top: 0;
        border-top: none;
        border-bottom: none;
        padding: 0;
        height: auto;
        z-index: 9;

        span {
          z-index: 9;
          font-size: 12px;
          color: #707070;
          font-weight: 400;
          padding: 12px;
          display: inline-block;
          border-top: 1px solid #d3d3d3;
          border-bottom: 1px solid #d3d3d3;
          width: 100%;
        }

        .custom-control-label {
          vertical-align: middle;

          &::before {
            border-radius: 0;
          }

          &::after {
            background-color: transparent;
            border: 1px solid;
            @include themed() {
              border-color: t("primary");
            }
          }
        }
      }
    }
  }

  .header-row {
    border-bottom: none;
  }

  .single-row {
    border-bottom: 1px solid;
    @include themed() {
      border-bottom-color: t("bgRHS");
    }

    &:hover {
      @include themed() {
        background: t("bg");
      }
    }

    .custom-checkbox
      .custom-control-input:checked
      ~ .custom-control-label::before {
      @include themed() {
        background: t("primary");
      }
    }

    td {
      @include themed() {
        background: t("bg");
      }
      font-size: 15px;
      font-weight: 500;
      padding-top: 18px;
      padding-bottom: 18px;
      border-top: none;

      .custom-control-label {
        vertical-align: middle;

        &::before {
          border-radius: 0;
        }

        &::after {
          background-color: transparent;
          border: 1px solid;
          @include themed() {
            border-color: t("primary");
          }
        }
      }
    }

    .name {
      max-width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .number {
    display: flex;
    align-items: center;

    .icon {
      margin-left: 8px;

      &.ccb {
        svg path:not([fill="none"]) {
          fill: #fc7e00;
        }
      }

      svg {
        height: 16px;
        vertical-align: middle !important;

        path:not([fill="none"]) {
          fill: #b4b4b4;
        }
      }
    }
  }

  .modal-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }

  .delete-alert {
    align-items: center;
    background: #fc7e00;
    border-radius: 5px 5px 0 0;
    color: #fff;
    display: flex;
    justify-content: center;
    left: 0;
    padding: 10px 10px 20px;
    position: absolute;
    right: 0;
    top: -40px;
    z-index: -1;
    @media (max-width: 600px) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
