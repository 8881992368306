.release-short-code-container-modal {
  .modal-dialog {
    width: 680px;
    max-width: 680px;

    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
  @media (max-width: $screen-lg) {
    z-index: 140000;
  }
}

.release-short-code-container {
  @include themed() {
    background: t("bg");
  }
  padding: 21px 26px;
  .release-short-code-title {
    @extend .custom-modal-title;
    margin-bottom: 25px;
    @include themed() {
      color: t("fontColor");
    }
  }

  .release-edit-reprocessing-select {
    width: 90%;
  }

  .review-changes-text {
    font-size: 18px;
    text-align: center;
    @include themed() {
      color: t("primary");
    }
  }

  .release-short-code-scroll-table-container {
    margin-top: 15px;
    height: 170px;
  }

  .release-short-code-scroll-table-less-users-container {
    margin-top: 15px;
    height: 250px;
    margin-bottom: 20px;
  }

  .more-text {
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    @include themed() {
      color: t("primary");
    }
  }

  .release-short-code-sub-container {
    padding-right: 20px;

    .release-short-code-description {
      font-size: 14px;
      @include themed() {
        color: t("fontDarkGray");
      }
      margin-top: 25px;
      margin-bottom: 10px;
    }

    .release-short-code-agree-text {
      font-size: 14px;
      @include themed() {
        color: t("fontDarkGray");
      }
    }

    .release-short-code-description-point {
      font-size: 16px;
      @include themed() {
        color: t("fontColor");
      }
    }

    .release-short-code-table-header {
      border-top: 1px solid #dee2e6;
      border-bottom: 2px solid #dee2e6;
      tr th {
        padding-top: 9px;
        padding-bottom: 9px;
      }
    }

    .release-short-code-table-body {
      td {
        font-size: 14px;
      }
      @include themed() {
        color: t("fontColor");
      }
    }
  }

  .release-short-code-footer {
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .footer-action-button {
      width: 105px;
    }
  }
}
.confirm-container {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  @media (max-width: $screen-xs) {
    margin-top: 15px;
    flex-direction: column;
    align-items: center;
  }
  input {
    @media (max-width: $screen-xs) {
      margin-top: 10px;
    }
  }
}
.confirm-text {
  margin-top: 4px;
  @include themed() {
    color: t("iconMidGray");
  }
}

.release-assigned-number-step-one-modal {
  .modal-dialog {
    width: 492px;
    max-width: 492px;
  }
  .step-one-container {
    padding: 0px 16px;
  }
}
.release-assigned-number-step-two-modal {
  .modal-dialog {
    width: 647px;
    max-width: 647px;
  }
  .step-two-container {
    padding: 0px 16px;
  }
  .email-overflow {
    text-overflow: ellipsis;
  }
}

.release-assigned-number-section-title {
  @extend .custom-modal-steps-section-title;
  margin-bottom: 17px;
  margin-top: 15px;
  @include themed() {
    color: t("fontColor");
  }
}
.step-text {
  color: #808080;
  font-size: 12px;
  text-align: left;
}
.release-assigned-number-scroll-table {
  height: 300px;
  margin: 24px 0px;

  .release-assigned-number-table-header {
    tr {
      th {
        padding-top: 9px;
        padding-bottom: 9px;
        border-top: 1px solid #dee2e6;
      }
    }
  }
}

.refund-yearly-payment-box {
  @include themed() {
    background-color: t("bgRHS");
  }
  width: 70%;
  padding: 10px;

  .refund-amount {
    color: #60c288 !important;
    font-weight: 600;
  }

  .balance-amount {
    font-weight: 600;
    @include themed() {
      color: t("fontDarkGray");
    }
  }
}
