.settings-numbers-assigned-modal {
  .modal-dialog {
    width: 450px !important;
    @media (max-width: $screen-sm) {
      width: auto !important;
      max-width: auto !important;
    }
  }
}

.settings-numbers-assigned-container {
  padding: 22px 26px 25px 26px;
  .settings-numbers-assigned-title {
    @extend .custom-modal-title;
    margin-bottom: 21px;
  }

  .settings-numbers-assigned-search {
    max-width: 230px;
    margin-bottom: 10px;
  }

  .settings-numbers-assigned-scroll-table {
    height: 320px;

    .settings-numbers-assigned-table-header {
      tr th {
        padding-left: 0;
      }
    }

    .settings-numbers-assigned-table-body {
      tr td {
        padding: 1rem 0.5rem;
      }
      tr td:nth-child(2) {
        min-width: 150px;
      }
    }
  }

  .settings-numbers-assigned-footer {
    margin-top: 25px;
    .text {
      font-size: 12px;
      color: #808080;
      margin-bottom: 0;
      max-width: 230px;
    }
  }
}
