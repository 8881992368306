.sidebar-settings-dropright {
  .dropdown-menu {
    &.show {
      padding: 24px 12px;
      width: 360px;
    }

    .title {
      font-size: 16px;
      font-weight: bold;
      padding-left: 12px;
    }
    .close-popup {
      position: absolute;
      right: 4px;
      top: 24px;
    }
    .notification {
      width: 100%;
      padding: 12px;
      position: relative;
      .avatar {
        display: inline-block;
        width: 36px;
        min-width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 50%;
        margin-right: 12px;
        font-size: 0.9rem;
        color: $avatar-text-purple;
        font-weight: 400;
        vertical-align: top;
        svg {
          transform: scale(1.06);
        }
      }
      .content {
        display: inline-block;
        width: 70%;
        position: relative;
        p {
          font-size: 14px;
          margin-bottom: 0px;
        }
        .received-time {
          color: #565656;
        }
        .claim-points-button {
          position: absolute;
          right: 0px;
          cursor: pointer;
          font-weight: bold;
          color: $primary;
        }
      }
      .close-button {
        display: inline-block;
        margin-right: 12px;
        position: absolute;
        right: 0px;
      }
    }
  }
  .view-all-button {
    color: $primary;
    width: 100%;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    padding-top: 20px;
  }
}
