@import "../base/global-theme";

.skeleton {
  // background: #fff;

  &.chat-loader {
    margin-bottom: 30px;
  }
  &.thread-loader {
    .skeleton-wrapper-inner {
      height: 80px;
    }
  }
  &.user-detail-loader {
    // height: 100vh;
    // background: #f8f8f8;
  }
}

.skeleton-dark {
  @include themed() {
    background: t("threadListBackground");
  }
}

.skeleton-grey {
  background: #f8f8f8;
}

.skeleton-wrapper-inner {
  height: 215px;
  position: relative;
  padding: 25px 0;
}

// .skeleton-wrapper-body div {
// 	-webkit-animation-duration: 1s;
// 	animation-duration: 1s;
// 	-webkit-animation-fill-mode: forwards;
// 	-webkit-animation-iteration-count: infinite;
// 	-webkit-animation-name: placeholderSkeleton;
// 	-webkit-animation-timing-function: linear;
// 	background: #f6f7f8;
// 	background-image: -webkit-gradient(
// 		linear,
// 		left center,
// 		right center,
// 		from(#f6f7f8),
// 		color-stop(0.2, #edeef1),
// 		color-stop(0.4, #f6f7f8),
// 		to(#f6f7f8)
// 	);
// 	background-image: -webkit-linear-gradient(
// 		left,
// 		#f6f7f8 0%,
// 		#edeef1 20%,
// 		#f6f7f8 40%,
// 		#f6f7f8 100%
// 	);
// 	background-repeat: no-repeat;
// 	background-size: 800px 104px;
// 	height: 104px;
// 	position: relative;
// }

.skeleton-wrapper-body-white div {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderSkeleton;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  @include themed() {
    background: t("bgRHS");
  }
  background-image: -webkit-linear-gradient(
    left,
    #f6f7f8,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8
  );
  background-size: 800px 104px;
  position: absolute;
}

.skeleton-wrapper-body-dark div {
  background: #262626;
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderSkeleton;
  animation-timing-function: linear;

  background-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(#262626),
    color-stop(0.18, #3c3c3c),
    color-stop(0.4, #262626)
  );
  // background: -webkit-linear-gradient(
  // 	left,
  // 	#262626 0%,
  // 	#3c3c3c 18%,
  // 	#262626 40%
  // );
  background-size: 800px 104px;
  height: 104px;
  position: relative;
}

.skeleton-wrapper-body-white-theme div {
  background: #807f82;
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderSkeleton;
  animation-timing-function: linear;

  background-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(#807f82),
    color-stop(0.18, #565656),
    color-stop(0.4, #807f82)
  );

  background-size: 800px 104px;
  height: 104px;
  position: relative;
}

.skeleton-wrapper-body {
  -webkit-animation-name: skeletonAnimate;
  background-image: -webkit-gradient(
    linear,
    center top,
    center bottom,
    from(deg),
    color-stop(0, red),
    color-stop(0.15, orange),
    color-stop(0.3, yellow),
    color-stop(0.45, green),
    color-stop(0.6, blue),
    color-stop(0.75, indigo),
    color-stop(0.8, violet),
    to(red)
  );
  background-image: -webkit-linear-gradient(
    135deg,
    red 0%,
    orange 15%,
    yellow 30%,
    green 45%,
    blue 60%,
    indigo 75%,
    violet 80%,
    red 100%
  );
  background-repeat: repeat;
  background-size: 50% auto;
}

.skeleton-wrapper-body div {
  position: absolute;
  right: 15px;
  left: 15px;
  top: 15px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderSkeleton;
  -webkit-animation-timing-function: linear;
  // background: #f6f7f8;
  // background-image: -webkit-gradient(
  // 	linear,
  // 	left center,
  // 	right center,
  // 	from(#f6f7f8),
  // 	color-stop(0.2, #edeef1),
  // 	color-stop(0.4, #f6f7f8),
  // 	to(#f6f7f8)
  // );
  // background-image: -webkit-linear-gradient(
  // 	left,
  // 	#f6f7f8 0%,
  // 	#edeef1 20%,
  // 	#f6f7f8 40%,
  // 	#f6f7f8 100%
  // );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  height: 104px;
}

div.skeleton-incoming {
  border-radius: 10px;
  height: 80px;
  right: auto;
  width: 300px;
}

div.skeleton-outgoing {
  border-radius: 10px;
  height: 80px;
  left: auto;
  right: 50px;
  bottom: 0px;
  width: 300px;
}

div.skeleton-outgoing-avatar {
  border-radius: 15px;
  height: 30px;
  left: auto;
  right: 0;
  bottom: 0;
  width: 30px;
}

div.skeleton-thread-avatar {
  border-radius: 20px;
  height: 40px;
  right: auto;
  width: 40px;
}
div.skeleton-thread-name {
  border-radius: 4px;
  height: 16px;
  right: auto;
  left: 60px;
  width: 60%;
}

div.skeleton-thread-message {
  border-radius: 2px;
  height: 10px;
  right: auto;
  left: 60px;
  top: 40px;
  width: 75%;
}

div.skeleton-user-detail-avatar {
  // background: #f1f1f1;

  @include themed() {
    background: t("cardBorder");
  }

  border-radius: 50px;
  height: 100px;
  left: 50%;
  right: auto;
  transform: translate(-50%, 0);
  width: 100px;
}

div.skeleton-user-detail-name {
  // background: #f1f1f1;
  @include themed() {
    background: t("cardBorder");
  }
  border-radius: 5px;
  height: 20px;
  left: 50%;
  right: auto;
  transform: translate(-50%, 0);
  width: 130px;
  top: 130px;
}

div.skeleton-user-detail-action {
  // background: #f1f1f1;
  @include themed() {
    background: t("cardBorder");
  }
  border-radius: 5px;
  height: 50px;
  left: 50%;
  right: auto;
  transform: translate(-50%, 0);
  width: 80%;
  top: 190px;
}

div.skeleton-user-detail-list {
  // background: #f1f1f1;
  @include themed() {
    background: t("cardBorder");
  }
  border-radius: 5px;
  height: 20px;
  right: auto;
  width: 70%;
  top: 280px;
  &.list-2 {
    top: 320px;
    width: 30%;
  }
  &.list-3 {
    top: 360px;
    width: 50%;
  }
  &.list-4 {
    top: 400px;
    width: 80%;
  }
  &.list-5 {
    top: 440px;
    width: 55%;
  }
}

@-webkit-keyframes placeholderSkeleton {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@-webkit-keyframes skeletonAnimate {
  from {
    background-position: top left;
  }
  to {
    background-position: top right;
  }
}

body {
  .has-content-loader {
    display: none;
  }
  &.content-loaded {
    .content-loader {
      display: none;
    }
    .has-content-loader {
      display: block;
    }
  }
}
