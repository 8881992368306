.text-to-voice-textarea {
  .label {
    color: #707070;
    font-size: 0.75rem;
    margin-bottom: 5px;
  }

  .input-group-prepend {
    width: 100%;
    position: relative;
    padding-left: 6px;

    textarea {
      padding: 15px 15px 45px;
      width: 100%;
      resize: none;

      ::placeholder {
        font-size: 15px;
        color: #8f8f8f;
      }
    }

    .action {
      position: absolute;
      right: 8px;
      bottom: 12px;

      svg {
        height: 16px;
        width: 16px;
      }

      a {
        border: none;
        padding: 0;
      }

      .play {
        margin-right: 8px;
      }
    }
  }
}

.templates {
  border: 1px solid #dbdbdb;
  padding: 15px;
  overflow: auto;

  .search-wrapper-outer {
    display: flex;
  }

  .search-wrapper {
    @include themed() {
      background: t("textArea");
      border: 1px solid t("fontDarkGray");
    }
    display: flex;
    align-items: center;
    height: 34px;
    padding: 0 10px;
    border-radius: 17px;
    flex-grow: 1;
    margin: 20px 0;

    &::placeholder {
      font-size: 18px;
      color: #8f8f8f;
    }

    &.focused {
      border-color: $primary;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      border-bottom: 1px solid #dbdbdb;
      padding-bottom: 10px;
      margin-top: 20px;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }
    }

    .meta {
      align-items: center;
      display: flex;
      width: 100%;
      white-space: nowrap;

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #122b28;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      svg {
        height: 14px;
        margin-left: 7px;
        margin-right: 12px;

        path {
          fill: #122b28;
        }
      }

      .date {
        font-size: 12px;
        color: #8f8f8f;
        margin-left: auto;
      }
    }

    .detail {
      font-size: 14px;
      color: #8f8f8f;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
