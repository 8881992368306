@import "../base/global-theme";

.dropdown-menu {
  .dropdown-item {
    padding: 10px 25px;
    &:active {
      .menu-icon {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
    .menu-icon {
      display: inline-block;
      margin-right: 5px;
      width: 16px;
      svg {
        max-width: 100%;
        path {
          @include themed() {
            fill: t("iconColor") !important;
          }
        }
      }
    }
  }

  .dropdown-divider {
    background: #8f8f8f;
    margin: 8px auto;
    width: calc(100% - 50px);
  }
}
