.select-new-greeting-container-modal {
  .modal-dialog {
    width: 380px;
    max-width: 380px;
  }
}

.select-new-greeting-container {
  height: 570px;
  .select-new-greeting-upper {
    padding: 11px 21px 29px 21px;
    h1 {
      @extend .custom-modal-title;
      margin-bottom: 13px;
    }

    .select-new-greeting-info-text {
      font-size: 12px;
      @include themed() {
        color: t("text");
      }

      line-height: 1.4;
    }

    .select-new-greeting-search-box {
      margin-top: 27px;
      margin-left: 5px;
    }
  }

  .greeting-middle {
    padding: 0 6px;
    height: 300px;
  }

  .select-new-greeting-footer {
    padding: 42px 19px 29px 19px;
    display: flex;
    justify-content: flex-end;
  }
}
