@import "../../base/global-theme";

.incoming-call-modal {
  .modal-dialog {
    width: 420px;
    max-width: 420px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }

  .modal-content {
    height: auto !important;
    @include themed() {
      background-color: t("bg") !important;
    }

    .big-avatar {
      width: 35px;
      height: 35px;
    }

    .caller-name {
      font-size: 1.3rem;
      @include themed() {
        color: t("fontColor") !important;
      }
      font-weight: 400;
    }

    .caller-number {
      font-size: 1rem;
      @include themed() {
        color: t("fontColor") !important;
      }
    }

    .incoming-modal-light-text {
      font-size: 1.1rem;
      @include themed() {
        color: t("fontMidGray") !important;
      }
    }

    .incoming-modal-light-small-text {
      font-size: 1rem;
      @include themed() {
        color: t("fontMidGray") !important;
      }
    }

    .incoming-modal-dark-small-text {
      font-size: 1rem;
      @include themed() {
        color: t("fontDarkGray") !important;
      }
    }

    .incoming-modal-dark-text {
      font-size: 1.1rem;
      @include themed() {
        color: t("fontDarkGray") !important;
      }
    }

    .microphone-error-message {
      color: #eb6033;
    }

    .accept-call-btn {
      background-color: #00c186 !important;
      border: 1px solid #00c186 !important;
      color: white !important;
    }

    .decline-call-btn {
      background-color: #ff5800 !important;
      border: 1px solid #ff5800 !important;
      color: white !important;
    }

    .select-label {
      @include themed() {
        color: t("fontMidGray") !important;
      }
    }

    .collapse-icon {
      svg,
      path {
        @include themed() {
          fill: t("fontColor") !important;
        }
      }
    }
  }
}
