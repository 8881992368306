.text-area-with-features-container {
  position: relative;

  .warning {
    border: 1px solid #ff5801;
  }

  textarea {
    resize: none;
    border-radius: 23px;
    border: 1px solid #c6c6c6;
    padding: 10px 10px 10px 10px;
    width: 100%;

    @include themed() {
      background: t("textArea");
      border: 1px solid t("textArea");
      color: t("fontColor");
    }

    &:focus {
      outline: none;
      border: 1px solid $primary;
    }
  }

  .icons-container {
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 8px;
    padding-right: 13px;
  }

  .icons-container-bottom {
    position: absolute;
    right: 9px;
    bottom: 9px;
    padding-top: 8px;
    padding-right: 13px;
  }
}
