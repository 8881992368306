@import "../base/global-theme";
.header-navigation {
  margin-left: 5px;
  .bg-light,
  .nav-background {
    @include themed() {
      background: t("bgRHS") !important;
    }
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        font-size: 0.875rem;
        font-weight: 400;
        @include themed() {
          color: t("fontColor");
        }
        &:hover {
          @include themed() {
            color: t("primary");
          }
        }
        &:focus {
          @include themed() {
            color: t("primary");
          }
          font-weight: 500;
        }
      }
      .nav-link-active {
        @include themed() {
          color: t("primary");
        }
        font-weight: 500;
      }
      .nav-link-active-gray {
        @include themed() {
          color: t("text");
        }

        font-weight: 500;
      }
    }
  }
}
