.payment-information-modal {
  .modal-dialog {
    width: 410px;
    max-width: 410px;
  }
}

.payment-information-container {
  padding: 21px 28px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 31px;
  }
  .description-text {
    font-size: 14px;
    color: #808080;
    margin-bottom: 10px;
  }

  .payment-card {
    padding: 19px;
    .menu-container {
      margin-top: 3px;
      margin-right: -21px;
    }
  }
}
