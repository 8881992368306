@import "../base/global-theme";
.view-group {
  opacity: 0;
  border: none;
  svg {
    @include themed() {
      path:not([fill="none"]) {
        fill: t("fontColor") !important;
      }
    }
  }
  @include themed() {
    fill: t("primary");
  }
  width: 50px;
  padding-left: 10px;
}

tr:hover {
  td:nth-child(2) {
    .view-group {
      opacity: 1;
    }
    label.custom-control-label.overflow,
    .text-name {
      @include themed() {
        color: t("primary");
      }
    }
    .text-name {
      text-decoration: underline;
    }
  }
}

.name-group-flex {
  display: flex;
}
td.border-bottom-none {
  border-bottom: none;
}
th.w-2 {
  min-width: 200px;
  // overflow: hidden;
}
.icon-details {
  padding: 0 2px;
  display: inline;
}
td {
  svg {
    @include themed() {
      path:not([fill="none"]) {
        fill: t("fontColor");
      }
    }
  }
}
.change-confirm {
  align-items: center;
  vertical-align: bottom;
  @include themed() {
    background: t("bg");
  }
  border-bottom: 1px solid #dee2e6;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 400;
  .btn-primary {
    @include themed() {
      background: t("primary");
    }
  }
}
.change-confirm-text {
  margin: 1rem 0;
  color: #6c757d;
}
.confirm {
  color: #5517c1 !important;
  white-space: nowrap;
}
.confirm:hover {
  cursor: pointer;
}
