@import "../base/global-theme";

.new-buttons-wrapper-div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  @include themed() {
    background-color: t("primary");
  }

  .vertical-line {
    height: 15px;
    width: 1px;
    margin-left: 5px;
    @include themed() {
      background-color: t("bg");
    }
  }

  .fa-drop-icon {
    @include themed() {
      color: t("bg");
    }
  }

  .send-button-text {
    @include themed() {
      color: t("bg");
    }
  }

  .send-btn-more-options {
    margin-left: 3px;
    margin-right: 3px;
    font-size: 16px;
  }

  .send-btn {
    font-size: 1rem;
    height: 30px;
    width: 45px;
  }

  .send-btn-dropdown-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    cursor: pointer;
  }
}

.send-btn-disable {
  background-color: #c9b4eb;
}

.schedule-btn {
  font-size: 1rem;
  height: 30px;
  @include themed() {
    background-color: t("primary");
    color: t("bg");
  }

  svg {
    path:not([fill="none"]) {
      @include themed() {
        fill: t("bg");
      }
    }
  }
}
