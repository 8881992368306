.date-time-picker {
  @media (min-width: 576px) {
    position: relative;
  }

  .picker-content-positioner {
    position: absolute;
    top: 0;
    right: 0;
  }

  .picker-content {
    position: absolute;
    z-index: 9;
    bottom: 5px;
    right: 0;
    overflow: hidden;
    border-radius: 5px;
    border: none;
    background-color: #fff;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
  }

  .react-calendar__month-view__days__day--weekend {
    color: #000000;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }

  .react-calendar {
    width: 321px;
    padding: 10px;
    border: none;
    font-family: "Roboto", "Helvetica Neue", sans-serif !important;

    .react-calendar__navigation {
      button {
        border-radius: 50%;

        &.react-calendar__navigation__label {
          border-radius: 5px;
        }

        &[disabled] {
          background: none;
          opacity: 0.5;
          cursor: not-allowed;
        }

        &:enabled:hover,
        &:enabled:focus {
          background-color: #f2f2f2;
        }
      }

      .react-calendar__navigation__arrow {
        position: relative;
        overflow: hidden;
        height: 0;
        padding-top: 44px;

        &::after {
          content: "";
          position: absolute;
          display: block;
          width: 14px;
          height: 14px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        &.react-calendar__navigation__prev2-button::after {
          background: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 17"><path d="m8.01017,8.471l7.146,7.147l-0.707,0.707l-7.853,-7.854l7.854,-7.853l0.707,0.707l-7.147,7.146z"/><path d="m2.70418,8.471l7.146,7.147l-0.707,0.707l-7.853,-7.854l7.854,-7.853l0.707,0.707l-7.147,7.146z"/></svg>');
        }

        &.react-calendar__navigation__prev-button::after {
          background: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 17"><g></g><path d="M5.207 8.471l7.146 7.147-0.707 0.707-7.853-7.854 7.854-7.853 0.707 0.707-7.147 7.146z"></path></svg>');
        }

        &.react-calendar__navigation__next-button::after {
          background: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 17"><g></g><path d="M13.207 8.472l-7.854 7.854-0.707-0.707 7.146-7.146-7.146-7.148 0.707-0.707 7.854 7.854z"></path></svg>');
        }

        &.react-calendar__navigation__next2-button::after {
          background: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 17"><path d="m15.70982,8.472l-7.854,7.854l-0.707,-0.707l7.146,-7.146l-7.146,-7.148l0.707,-0.707l7.854,7.854z"/><path d="m10.30373,8.472l-7.854,7.854l-0.707,-0.707l7.146,-7.146l-7.146,-7.148l0.707,-0.707l7.854,7.854z"/></svg>');
        }
      }
    }

    .react-calendar__month-view__weekdays__weekday abbr[title] {
      text-decoration: none;
      cursor: auto;
    }

    .react-calendar__month-view__days__day {
      height: 43px;
      border-radius: 50%;
    }

    .react-calendar__tile {
      &[disabled] {
        background: none;
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:enabled:hover,
      &:enabled:focus {
        background-color: #f2f2f2;
      }
    }

    .react-calendar__tile--active,
    .react-calendar__tile--hasActive {
      &,
      &:enabled:hover,
      &:enabled:focus {
        background-color: #5517c1;
        color: #fff;
      }
    }

    .react-calendar__year-view .react-calendar__tile,
    .react-calendar__decade-view .react-calendar__tile,
    .react-calendar__century-view .react-calendar__tile {
      height: 59.7px;
      border-radius: 5px;
    }
  }

  .time-form {
    padding: 0 10px;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
  }

  .time-part {
    display: inline-flex;

    input[type="text"] {
      display: inline-block;
      height: 36px;
      width: 100%;
      margin-top: -1px;
      margin-bottom: -1px;
      vertical-align: middle;
      border: 1px solid transparent;
      border-top: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
      text-align: center;
      font-size: 14px;

      &:focus,
      &:hover {
        border-color: #f2f2f2;
        background-color: #f2f2f2;
      }

      &:focus {
        border-color: #5517c1;
      }
    }

    &:hover .steps-arrows,
    input[type="text"]:focus + .steps-arrows {
      visibility: visible;
    }
  }

  .time-separator {
    padding: 0 4px;
    vertical-align: middle;
    font-size: 14px;
    font-weight: bold;
  }

  .steps-arrows {
    display: inline-flex;
    visibility: hidden;
    flex-direction: column;
    width: 20px;
    height: 34px;
    margin-left: -21px;
    vertical-align: middle;

    button {
      display: block;
      position: relative;
      height: 50%;
      width: 100%;
      padding: 0;
      border: 1px solid #f2f2f2;
      background: none;
      font-size: 14px;

      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &.step-arrow-up::after {
        border-bottom: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-top: 0;
        border-left: 0.3em solid transparent;
      }

      &.step-arrow-down::after {
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
      }
    }
  }

  .am-pm-switch {
    display: inline-block;
    height: 36px;
    margin-top: -1px;
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    background: none;

    &:focus,
    &:hover {
      border-color: #f2f2f2;
      background-color: #f2f2f2;
    }
  }

  .nav-buttons {
    padding: 10px;
  }
}
