.view-on-CCB-modal {
  .modal-dialog {
    width: 650px;
    max-width: 650px;
  }
}

.view-on-CCB-container {
  padding: 21px 26px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 17px;
  }
  .view-on-ccb-description {
    font-size: 12px;
    color: #afafaf;
  }

  .view-on-ccb-scroll-table {
    height: 500px;
  }

  .view-on-CCB-table-header {
    border-top: 1px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
    tr th {
      padding-top: 9px;
      padding-bottom: 9px;
    }

    .view-on-ccb-table-body {
      color: $font-color-dark;
    }
  }
}
