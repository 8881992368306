.large-video-confirmation-modal-container-modal {
  .modal-dialog {
    width: 650px;
    max-width: 650px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
  @media (max-width: $screen-lg) {
    z-index: 140000;
  }
}

.large-video-confirmation-modal-container {
  @include themed() {
    background: t("bg");
  }
  padding: 21px 26px;
  .large-video-confirmation-modal-title {
    @extend .custom-modal-title;
    margin-bottom: 17px;
    margin-top: 5px;
    @include themed() {
      color: t("fontColor");
    }
  }
  .large-video-confirmation-modal-sub-container {
    padding-right: 20px;

    .large-video-confirmation-modal-description {
      font-size: 14px;
      @include themed() {
        color: t("iconMidGray");
      }
    }
  }

  .large-video-confirmation-modal-footer {
    margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .large-video-border-buttons {
      &:hover {
        color: white !important;
      }
      @media (max-width: $screen-md) {
        font-size: 0.8rem;
      }
    }
  }
}
