.greeting-name-selection-container {
  width: 100%;
  &__name {
    input {
      background-color: #FFFFFF;
      color: #222222 !important;
      border-radius: 4px;
    }
  }
  .radio-checks-container {
    margin-top: 30px;
    margin-left: -10px;
  }
  .assigned-numbers {
    span {
      @include themed() {
        color: t("primary");
      }
      cursor: pointer;
      font-size: 16px;
    }
  }
}
