@import "../../base/global-theme";
.choose-organization {
  z-index: 10100;
  @media (max-width: $screen-sm) {
    z-index: 1200 !important;
  }

  .modal-dialog {
    width: 320px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: 100% !important;
      margin: auto !important;
    }
  }

  .modal-content {
    border: 0;
    border-radius: 5px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    max-width: 320px;
    min-height: 420px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    max-height: 90vh;

    @media (max-width: $screen-sm) {
      width: 100vw;
      height: 100vh;
      max-width: 100vw;
      max-height: 100vh;
    }

    .modal-header {
      border-bottom: 0;
      padding: 30px 25px 15px;
      @media (max-width: $screen-sm) {
        padding: 20px 10px 10px 10px;
      }
    }

    .modal-body {
      padding: 15px 25px;
      overflow-y: auto;
      @media (max-width: $screen-sm) {
        padding: 20px 0px;
        flex: none;
        height: 55%;
      }
    }

    .modal-title {
      font-size: 1.25rem;
      font-weight: bold;
      margin: 0;
      text-align: center;
    }

    .custom-radio {
      border-bottom: 1px solid #d3d3d3;
      cursor: default;
      padding-bottom: 13px;
      padding-left: 0;
      padding-top: 13px;

      &:last-child {
        border-bottom: 0;
      }

      .name {
        @include themed() {
          color: t("iconColor");
        }
        font-size: 0.88rem;
        font-weight: 500;
        margin-bottom: 5px;
        @media (max-width: $screen-sm) {
          font-size: 1rem;
        }
      }

      .role {
        @include themed() {
          color: t("fontDarkGray");
        }
        font-size: 0.88rem;
        margin: 0;
      }
    }

    .custom-radio:first-child {
      @media (max-width: $screen-sm) {
        border-top: 1px solid #d3d3d3;
      }
    }

    .custom-control-label {
      cursor: pointer;
      @media (max-width: $screen-sm) {
        margin-left: 40px;
      }
    }
    .active {
      .name {
        @media (max-width: $screen-sm) {
          @include themed() {
            color: t("primary") !important;
          }
        }
      }
      .role {
        @media (max-width: $screen-sm) {
          @include themed() {
            color: t("primary") !important;
          }
        }
      }
    }

    .custom-control-label::before {
      display: none;
      height: 17px;
      width: 17px;
    }

    .custom-radio .custom-control-label::before {
      @include themed() {
        border-color: t("primary");
      }
      top: 50%;
      transform: translate(0, -50%);
      display: none;
    }

    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
      @include themed() {
        border-color: t("primary");
      }
      background-image: none;
      border-radius: 50%;
      height: 10px;
      left: -21px;
      top: 50%;
      transform: translate(0, -50%);
      width: 10px;
      display: none;
    }
  }

  .modal-footer {
    border-top: 0;
    padding: 15px 25px;
    text-align: center;
  }
}
