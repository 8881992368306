.restore-default-notifications-modal {
    .modal-dialog {
      width: 688px;
      max-width: 688px;
      @media (max-width: $screen-sm) {
        width: auto;
        max-width: auto;
      }
    }
  }
  
  .restore-default-notifications-container {
    padding: 22px 26px 25px 26px;
    .restore-default-notifications-title {
      @extend .custom-modal-title;
      @include themed() {
        color: t("text") !important;
      }
      margin-bottom: 21px;
    }
    .restore-default-notifications-text {
      font-size: 16px;
      @include themed() {
        color: t("fontDarkGray");
      }
  
      margin-bottom: 30px;
    }
  
    .restore-default-notifications-button {
      float: right;
    }
  }
  