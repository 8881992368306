.verify-your-email-container {
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .background {
    position: absolute;
    transform: scale(3.4);
    margin-bottom: 10%;
  }
  .logo-container {
    position: absolute;
    top: 16px;
    left: 30px;
    width: 133px;
    height: 27px;
  }

  .copyright {
    position: absolute;
    bottom: 0;
    left: auto;
    right: auto;
    color: #ffffff;
    font-size: 12px;
  }

  .white-container {
    max-width: 800px;
    width: 100%;
    background: #ffffff;
    box-shadow: 10px 3px 30px #2d16559f;
    border-radius: 10px;
    padding: 36px 36px 50px 36px;
    position: relative;

    .email-icon {
      margin: 0 auto;
      margin-bottom: 65px;
      width: 108px;
      height: 108px;
    }

    .logo-container {
      position: absolute;
      top: 30px;
      left: 16px;
      width: 133px;
      height: 27px;
    }

    .title {
      color: #808080;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;
    }

    .text-container {
      max-width: 550px;
      margin: 0 auto;
      .text {
        color: #808080;
        font-size: 16px;
        text-align: center;
        margin-bottom: 30px;
      }
      .link-container {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        margin: 0 auto;
        margin-top: 40px;
        max-width: 400px;
      }
    }
  }
}
