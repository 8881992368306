.add-new-organization-modal {
  .modal-dialog {
    width: 420px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }
  }
}

.add-new-organization-container {
  padding: 21px 22px 29px 22px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 27px;
  }
  .add-new-organization-section-title {
    @extend .custom-modal-steps-section-title;
    margin-bottom: 27px;
  }

  .description-text {
    font-size: 14px;
    color: #808080;
    margin-bottom: 48px;
  }

  .label {
    font-size: 12px;
    // color: #808080;
  }

  .value-text {
    font-size: 16px;
    color: #565656;
  }

  .description-text-step2 {
    font-size: 14px;
    color: #808080;
    margin-top: 50px;
  }

  .add-new-organization-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 80px;

    .next-wrapper {
      width: 100%;
      max-width: 120px;
    }
  }
}
