@import "../../../base/global-theme";

.templates-voice-templates-container {
  @extend .container-flex-column-100;

  .voice-templates-table-body {
    .active {
      @include themed() {
        background: t("bgRHS");
      }
    }
  }
}

.voice-template-scroll-table {
  @media (max-width: $screen-md) {
    height: 80vh;
  }
}

.templates-voice-templates-rhs {
  font-size: 14px;

  .text-arrow-black {
    font-weight: 500;

    @include themed() {
      color: t("iconColor");
    }

    display: flex;
    align-items: center;
  }

  .arrow-text-black {
    color: #707070;
    font-size: 12px;
  }

  @media (max-width: $screen-md) {
    z-index: 100;
  }

  .re-record-button {
    cursor: pointer;
    font-size: 14px;

    @include themed() {
      color: t("primary");
    }
  }

  .themed-primary-color {
    @include themed() {
      color: t("primary");
    }
  }

  .card {
    @include themed() {
      background: t("bgRHS");
    }

    .MuiInput-underline::after {
      @include themed() {
        border-bottom-color: t("primary");
      }
    }

    .MuiInputBase-input {
      @include themed() {
        color: t("text");
      }
    }
  }

  .delete-voice-template {
    .modal-dialog {
      width: 680px;
      max-width: 680px;

      @media (max-width: $screen-sm) {
        width: auto;
        max-width: auto;
      }
    }
  }
}
