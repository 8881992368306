.radio-button-with-label-container {
  color: #565656;
  .radio-button-label {
    font-size: 16px;
  }
  .radio-button-sub-label {
    font-size: 11px;
    margin-left: 42px;
    margin-top: -8px;
  }

  .not-checked {
    color: #808080;
  }
}
