.duplicate-profile-updated-info-modal {
  .modal-dialog {
    width: 730px;
    max-width: 730px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
  @media (max-width: $screen-lg) {
    z-index: 140000;
  }
}

.duplicate-profile-updated-info-container {
  padding: 15px 26px 21px 26px;
  .duplicate-profile-updated-info-title {
    @extend .custom-modal-title;
    margin-bottom: 17px;
    margin-top: 0px;
    font-size: 1.8rem;
    @include themed() {
      color: t("fontDarkGray");
    }
  }
  .duplicate-profile-updated-info-sub-container {
    padding-right: 20px;

    .duplicate-profile-updated-info-description {
      font-size: 1rem;
      @include themed() {
        color: t("text");
      }
    }

    .duplicate-profile-updated-info-details-link {
      font-size: 1.1rem;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
      @include themed() {
        color: t("primary");
      }
    }
  }

  .contact-tab-single {
    align-items: center;
    display: flex;

    .avatar {
      height: 36px;
      width: 36px;
      min-width: 36px;
      font-size: 1.06rem;
      color: #c9b4eb !important;
    }

    .detail {
      display: flex;
      justify-content: space-between;
      overflow: inherit;
      width: calc(100% - 46px);
      margin-left: 10px;
    }

    .detail-single {
      display: flex;
      max-width: 188px;
      width: 100%;
    }

    .info {
      display: flex;

      &.contact .group-icon {
        opacity: 0.25;
      }
      svg.themed-icon {
        @include themed() {
          path:not([fill="none"]) {
            fill: t("iconColor") !important;
          }
        }
      }
    }

    .name {
      @include themed() {
        color: t("iconColor");
      }
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      font-weight: 400;
      position: relative;
      text-overflow: ellipsis;
      margin-bottom: 0px !important;
    }
  }
}

.duplicate-contacts-radio-switcher {
  svg {
    @include themed() {
      fill: t("primary") !important;
    }
  }
}
