.text-to-voice {
  .input-field {
    margin-top: 20px;
    margin-bottom: 30px;

    .label {
      color: #707070;
      font-size: 0.75rem;
      margin-bottom: 0;
    }

    .input-group {
      display: flex;
      position: relative;
      width: 100%;
    }

    .form-control {
      border: none;
      width: 100%;
      padding-left: 0;
      border-bottom: 1px solid #dbdbdb;
    }

    .input-group-prepend {
      width: 100%;
    }
  }
}

.text-to-voice-preview-modal {
  .modal-dialog {
    width: 500px;
    max-width: 500px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }

    .modal-content {
      min-height: initial;
    }
  }
}
