.preview-signature-main-container {
  margin-right: 10px;
  margin-left: 30px;
  .preview-signature-content-container {
    width: 285px;
    padding: 16px;
    background: #f8f8f8;
    border-radius: 23px;
    word-break: break-word;
    @include themed() {
      background: t("textArea") !important;
    }

    .preview-text {
      font-size: 16px;
      @include themed() {
        color: t("fontDarkGray") !important;
      }

      margin: 0;
    }
    .preview-signature {
      font-size: 16px;
      @include themed() {
        color: t("fontColor") !important;
      }
      margin-top: 32px;
      margin-bottom: 12px;
    }
    @media (max-width: $screen-md) {
      width: 100%;
    }
    a {
      @include themed() {
        color: t("primary");
      }
    }
  }
  @media (max-width: $screen-md) {
    margin: 0px;
    flex-direction: column;
  }
}
