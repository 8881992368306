.pending-ownership-transfer-modal {
  .modal-dialog {
    width: 380px;
    max-width: 380px;
  }
}

.pending-ownership-transfer-container {
  padding: 30px 25px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 40px;
    text-align: center;
  }

  p {
    font-size: 14px;
    @include themed() {
      color: t("text");
    }
    margin-bottom: 25px;
  }
  .btn-link {
    @include themed() {
      color: t("fontColor");
    }
  }
}
