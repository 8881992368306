@import "../../base/global-theme";

.search-filter.has-search {
  background: $dark-top;

  .search-wrapper-outer {
    display: flex;
    max-width: 100%;
  }

  .message-icon,
  .right-action,
  .sidenav-trigger,
  .tab-title,
  .user-action-single,
  .warning-icon {
    display: none;
  }
}

.search-wrapper-outer {
  display: none;
  align-items: center;
  flex-grow: 1;
  padding-right: 9px;

  .thread-search-inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    position: relative;
    margin-left: 20px;
  }

  .thread-search-filter {
    @include themed() {
      background-color: t("filterBackground");
      color: t("filterText");
    }

    padding: 0px 15px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
    text-transform: lowercase;
    z-index: 1;
  }

  .hide-search {
    @include themed() {
      color: t("leftMenuText");
    }
    font-size: 0.81rem;
    margin-left: 10px;
    cursor: pointer;
  }

  .country-select-wrapper {
    margin-right: 5px;
    display: none;
    height: 42px;
    position: relative;
    color: #d9d9d9;

    &.active {
      display: flex;
    }

    select {
      background: none;
      border: none;
      outline: 0;
      color: #d9d9d9;
      font-size: 0.81rem;
      width: 87px;
      text-align-last: right;

      &.inverse-color {
        @include themed() {
          color: t("fontColor");
        }
      }

      option {
        color: #000;
      }
    }

    select::-ms-expand {
      display: none;
    }
  }

  .search-wrapper-active-item {
    color: black;
  }
}

.menus-search-box {
  height: auto !important;
  border: none !important;
  padding: 0px !important;
}

.search-wrapper {
  @include themed() {
    background: t("textArea");
    border: 1px solid t("fontDarkGray");
  }
  display: grid;
  align-items: center;
  height: 34px;
  padding: 0 10px;
  border-radius: 17px;
  flex-grow: 1;
  position: relative;

  &.with-icon {
    padding-left: 35px;
    .search-icon {
      position: absolute;
      left: 7px;
      top: 4px;
    }
  }

  .search-loading {
    width: 17px;
    height: 17px;
    border-width: 1.5px;
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 9999;
    opacity: 1;
  }

  &.focused {
    border-color: $primary;
  }

  &.has-value {
    .clear-icon {
      opacity: 1;
    }
  }

  input {
    border: none !important;
    background: none;
    padding-left: 5px;
    min-width: 1px;
    flex-grow: 1;
    margin-bottom: 0 !important;
    height: 32px;
    padding-right: 20px;

    &:focus {
      border-bottom: none !important;
      box-shadow: none !important;
    }
  }

  .search-action {
    display: flex;
    align-items: center;
  }

  .clear-icon {
    opacity: 1;
    font-size: 20px;
    color: #6c757d;
    position: absolute;
    right: 10px;
    cursor: pointer;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spinner {
    @include themed() {
      color: t("primary");
    }
  }

  .search-icon {
    font-size: 0.8125rem;
  }
}
.round-border {
  border-radius: 17px !important;
}

.thread-search-wrapper {
  @include themed() {
    background: t("searchInputBackground");
    border: 1px solid t("searchInputBorder");
  }

  display: grid;
  align-items: center;
  height: 34px;
  padding: 0 10px;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;

  flex-grow: 1;
  position: relative;
  z-index: 1;

  .search-loading {
    width: 17px;
    height: 17px;
    border-width: 1.5px;
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 9999;
    opacity: 1;
  }

  &.focused {
    border-color: $primary;
  }

  &.has-value {
    .clear-icon {
      opacity: 1;
    }
  }

  input {
    border: none !important;
    background: none;
    padding-left: 5px;
    min-width: 1px;
    flex-grow: 1;
    margin-bottom: 0 !important;
    height: 32px;
    padding-right: 20px;

    @include themed() {
      color: t("fontColor");
    }

    &:focus {
      border-bottom: none !important;
      box-shadow: none !important;
    }
  }

  .search-action {
    display: flex;
    align-items: center;
  }

  .clear-icon {
    opacity: 1;
    font-size: 20px;

    @include themed() {
      color: t("searchClear");
    }

    position: absolute;
    right: 10px;
    cursor: pointer;
  }

  .search-icon {
    font-size: 0.8125rem;
  }

  .query-loader {
    width: 17px;
    height: 17px;
    border-width: 1.5px;
    position: absolute;
    right: 12px;
    top: 8px;
    z-index: 9999;
    opacity: 1;
  }
}

.search-options-container {
  @include themed() {
    background-color: t("textArea");
  }

  position: absolute;
  top: 40%;
  width: 100%;
  height: auto;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
  padding: 25px 30px;

  .search-option {
    @include themed() {
      color: t("filterText");
    }
  }

  .searched-text {
    z-index: 1000;
  }

  .view-all-link {
    font-size: 12px;
  }

  .last-message-wrapper {
    cursor: pointer;
  }

  .small-avatar {
    height: 25px;
    width: 25px !important;
    min-width: 0px !important;
    font-size: 0.8rem;
  }

  .search-option-text {
    color: #202020;
    margin-top: 5px;
  }

  .search-option-result {
    @include themed() {
      color: t("fontColor");
    }

    svg path:not([fill="none"]) {
      @include themed() {
        fill: t("iconPurple");
      }
    }

    &:hover {
      @include themed() {
        color: t("iconPurple");
      }

      svg path:not([fill="none"]) {
        @include themed() {
          fill: t("fontColor");
        }
      }
    }

    span {
      cursor: pointer;
    }
  }

  .search-option-category {
    @include themed() {
      color: t("fontColor");
    }

    &:hover {
      @include themed() {
        color: t("iconPurple");
      }
    }

    svg path:not([fill="none"]) {
      fill: currentColor;
    }
  }
}

.thread-list-wrapper {
  border-bottom: 1px solid #808080;
  padding-bottom: 10px;

  .thread-list-options {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;
    margin-top: 15px;
  }

  .title {
    color: #808080;
  }

  .show-more-link {
    color: #c9b4eb;
    cursor: pointer;
  }
}
.restricted-user-search-text {
  font-size: 12px;
  color: #808080;
  padding: 8px 48px;
  text-align: center;
}
.search-settings-container {
  background-color: white;
  height: auto;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
  padding: 25px 30px;

  .search-option {
    color: #666666;
  }

  .searched-text {
    z-index: 1000;
  }

  .view-all-link {
    font-size: 12px;
  }

  .last-message-wrapper {
    cursor: pointer;
  }

  .small-avatar {
    height: 25px;
    width: 25px !important;
    min-width: 0px !important;
    font-size: 0.8rem;
  }

  .search-option-text {
    color: #202020;
    margin-top: 5px;
  }
}

.search-setting-alignment-settings {
  margin-top: -20px;
  width: 87%;
  padding: 20px;
}

.thread-filter-container {
  height: 76vh;
}

.main-threads-container {
  margin-bottom: 62px;
}

.show-closed-box {
  position: fixed;
  width: 545px;
  border-top: 1px solid #808080;
  bottom: 0;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #2a2a2a;

  .message {
    color: #808080;
  }

  .link {
    color: #c9b4eb;
    cursor: pointer;
  }
}

.search-filter {
  align-items: center;
  @include themed() {
    background: t("leftSideMenu") !important;
  }
  display: flex;
  height: 45px;
  justify-content: center;
  left: 0;
  line-height: 0;
  padding: 0 15px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.4s ease-in-out;
  z-index: 0;

  @media (max-width: $screen-md) {
    z-index: 2;
    padding: 0px;
  }

  &.no-fixed {
    transform: translate(0, -100%);
  }

  &.align-left {
    justify-content: flex-start;
  }

  .available-option {
    cursor: pointer;
  }

  .disable-option {
    cursor: not-allowed;
    color: #c9cecd;
  }

  .tab-title {
    color: #d9d9d9;
    font-size: 1rem;
    font-weight: 500;
    margin-left: 0;
    display: flex;
    width: 100%;
    padding-left: 12px;
    padding-right: 5px;

    @media (max-width: $screen-md) {
      padding-left: 0px;
    }

    .selection-title {
      white-space: nowrap;
      line-height: 1;

      @include themed() {
        color: t("leftMenuText");
      }
    }

    .toggle-filter-btn {
      margin-right: 10px;
      cursor: pointer;
      background: transparent;
      outline: none;
      border: none;
    }

    .filter-icon,
    .right-action {
      display: flex;
      align-items: center;

      svg path:not([fill="none"]) {
        fill: #808080;
        margin-right: 10px;

        &:hover {
          cursor: pointer;
          path {
            fill: #c9cecd;
          }
        }
      }

      &.filtersActive {
        svg {
          @include themed() {
            path:not([fill="none"]) {
              fill: t("primary") !important;
            }
          }
        }
      }
      .show-search {
        margin-right: 7px;
        margin-left: 7px;
        @media (max-width: $screen-md) {
          margin-right: 10px;
          margin-left: 12px;
        }
      }
    }

    .filter-icon.filter:active,
    .filter-icon.filter:focus {
      svg {
        @include themed() {
          path:not([fill="none"]) {
            fill: t("primary") !important;
          }
        }
      }
    }

    .hub-tab-select {
      cursor: pointer;

      .hubtab__single-value {
        overflow: initial;
        font-size: 16px;
        color: #d9d9d9;
      }

      .hubtab__control {
        background: transparent;
        border: none;
      }

      .hubtab__indicator-separator {
        display: none;
      }

      .hubtab__indicator {
        color: #d9d9d9;
        padding: 0;
      }

      .hubtab__menu {
        min-width: 98px;
      }

      .hubtab__menu-list {
        min-width: 98px;
      }

      .hubtab__option {
        padding: 12px;
        color: #2a2a2a;
        background-color: #fff;

        &:hover {
          background-color: #6c37c9;
          color: #fff;
        }

        &:focus {
          background-color: #6c37c9;
          color: #fff;
        }

        &.hubtab__option--is-selected {
          background-color: #6c37c9;
          color: #fff;
        }
      }
    }
  }

  .filter-icon {
    color: #fff;
    font-size: 20px;
    line-height: 0;
    margin-left: 10px;

    svg {
      path {
        fill: #122b28;
      }
    }

    i {
      font-size: 21px;
    }
  }

  .right-action {
    align-items: center;
    display: flex;
    margin-left: auto;

    .dropdown-toggle {
      color: #122b28;
      font-size: 18px;
      margin-left: 6px;

      i {
        font-size: 18px;
      }

      &:after {
        display: none;
      }

      &:before {
        display: none;
      }
    }

    .dropdown-menu {
      border: 1px solid #707070;
      box-shadow: none;
      min-width: 200px;
      padding: 10px 0;

      li {
        a {
          align-items: center;
          border-radius: 0;
          color: #122b28;
          display: flex;
          font-size: 14px;
          font-weight: 500;
          padding-left: 20px;
          padding-right: 20px;

          &.active {
            background: #fff;
            box-shadow: none;
          }

          &:focus {
            background: #fff;
            box-shadow: none;
          }

          .icon {
            display: inline-block;
            height: 24px;
            margin-right: 10px;
            width: 24px;

            img {
              vertical-align: middle;
            }
          }
        }

        .show-hide-detail {
          .hide-detail {
            display: none;
          }

          &.active {
            .show-detail {
              display: none;
            }

            .hide-detail {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

.new-contact-icon {
  margin-right: 10px;
  &:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 35px;
    border-radius: 6px;
    background-color: #664f8e;
  }
}

.new-call-icon {
  height: 30px;
  width: 35px;
  &:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: #664f8e;
  }
}

.group-tab-low-resolution-screen-header {
  padding: 0px;
  .selection-title {
    font-size: 14px !important;
  }
  .toggle-filter-btn {
    margin-right: 0px !important;
  }
  .right-action {
    padding-right: 10px !important;
  }
}
