.use-this-template-voice-modal {
  .modal-dialog {
    width: 380px;
    max-width: 380px;
  }
}

.use-this-template-voice-container {
  padding: 30px 28px 28px 30px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 37px;
  }
  .step-two-container {
    .arrow-text-black {
      @include themed() {
        color: t("text");
      }

      font-size: 12px;
    }
    .card {
      @include themed() {
        background: t("bgRHS");
      }
    }
  }
  .message-tab {
    @include themed() {
      color: t("text");
    }
  }
}
