.send-message-header {
  &__icon {
    filter: brightness(0);

    &.dark {
      filter: brightness(0) invert(100%);
    }
  }

  .h4 {
    @include themed() {
      color: t("fontColor");
    }
  }
}

.send-new-message-beta-chip {
  color: white !important;
  background-color: #4caf50 !important;
  font-size: 0.7rem !important;
  height: 20px !important;
  font-weight: 400 !important;
  margin-left: 5px;

  .send-new-message-beta-chip-label {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 2px !important;
  }
}

.basic-message-switcher {
  font-size: 0.8rem;

  &.desktop-old-message-creator-switcher {
    margin-left: 7px !important;
  }
}

.header-button {
  margin-right: 12px;

  &__element {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding: 0px 20px;
    line-height: 1;
    transition: background-color 0.2s ease;

    &.muted {
      @include themed() {
        background-color: t("incomingMessage");
        color: t("fontDarkGray");
      }
    }

    &.send {
      color: #fff;
      @include themed() {
        background-color: t("primaryActionButtonDisabledBg");
      }
      &.active {
        @include themed() {
          background-color: t("primary");
        }
      }
    }

    &.with-icon {
      padding: 0 12px;
    }

    &.with-more {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__more {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 24px;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 1px;
    @include themed() {
      background-color: t("primaryActionButtonDisabledBg");
    }
    &.active {
      @include themed() {
        background-color: t("primary");
      }
    }
  }

  &.yellow-bg {
    background-color: #faf98b !important;
    @include themed() {
      color: t("primaryActionButtonBg") !important;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &__help {
    font-size: 11px;
    cursor: pointer;
    @include themed() {
      color: t("primaryActionButtonBg") !important;
    }
  }

  &__more-arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-top: 6px solid #fff;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    &.black-more {
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
      @include themed() {
        border-top: 6px solid t("fontColor");
      }
    }
  }
}

.messages-card {
  font-size: 16px;
  overflow: hidden;
  border-radius: 10px;
  @include themed() {
    border: 1px solid t("searchInputBorder");
    color: t("fontColor");
  }

  &__filled {
    border: none !important;
    @include themed() {
      background: t("incomingMessage");
    }
  }

  &__label {
    font-size: 12px;
    margin-left: 10px;
    margin-bottom: 5px;
    @include themed() {
      color: t("filterText");
    }
  }

  &__label-red {
    color: #ffffff !important;
    background-color: #eb6033;
    padding: 2px 5px;
    border-radius: 4px;
  }

  &__title {
    font-size: 14px;

    input {
      @include themed() {
        color: t("text");
        background-color: t("bg");
      }
    }
  }

  &__textarea {
    textarea {
      outline: none;
      @include themed() {
        color: t("fontColor");
        background-color: t("bg");
      }
    }
  }

  &__textarea-container {
    min-height: 285px;
  }

  &__textarea-attachments {
    padding: 8px 10px 10px 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__textarea-image {
    img {
      border-radius: 3px;
      height: 68px;
      width: 68px;
      object-fit: cover;
    }

    &.video-thumbnail {
      border-radius: 3px;
      height: 68px;
      width: 68px;
      object-fit: cover;
    }
  }

  &__textarea-image-close {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -4px;
    right: -4px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;
    @include themed() {
      background-color: t("fontColor");
    }
  }

  &__textarea-video-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 3px;
    background-color: rgba($color: #000000, $alpha: 0.3);
    svg {
      fill: #ffffff;
    }
  }

  &__textarea-chars {
    padding: 12px;
    &.txt-red {
      @include themed() {
        color: t("additionalRedText");
      }
    }
  }

  &__signature-input {
    padding: 12px;
    outline: none;
    border: none;
    width: 100%;
    @include themed() {
      background-color: t("bg");
      color: t("fontColor");
    }
  }

  &__textarea-field {
    position: relative;
  }

  &__textarea-field-highlight {
    position: absolute;
    top: 0;
    left: 0;
    padding: 12px;
    color: transparent;
    white-space: pre-line;
    word-break: break-word;
    pointer-events: none;

    .with-red {
      color: #eb6033;
    }
  }

  &__textarea-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__recipients {
    font-weight: 500;
    @include themed() {
      color: t("userProfileMenuItemColor");
    }
  }

  &__popover {
    padding: 10px;
    width: 180px;
    cursor: pointer;

    #messages-card-from {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__features {
    padding: 10px;
    @include themed() {
      background-color: t("bg");
    }
  }

  &__filled {
    border: none !important;
    @include themed() {
      background: t("incomingMessage");
    }
  }

  &__label {
    font-size: 12px;
    margin-left: 10px;
    margin-bottom: 5px;
    @include themed() {
      color: t("filterText");
    }

  }
  &__active {
    padding: 3px 4px !important;
    @include themed() {
      border: 1px solid t("primary") !important;
    }
    border-radius: 5px !important;
  }

  &__label-red {
    color: #ffffff !important;
    background-color: #eb6033;
    padding: 2px 5px;
    border-radius: 4px;
  }

  &__title {
    font-size: 14px;

    input {
      @include themed() {
        color: t("text");
        background-color: t("bg");
      }
    }
  }

  &__textarea {
    textarea {
      outline: none;
      @include themed() {
        color: t("fontColor");
        background-color: t("bg");
      }
    }

    .alert.top-alert {
      display: flex;
      margin: 12px;
    }
  }

  &__textarea-container {
    min-height: 285px;
  }

  &__textarea-attachments {
    padding: 8px 10px 10px 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__textarea-image {
    img {
      border-radius: 3px;
      height: 68px;
      width: 68px;
      object-fit: cover;
    }
  }

  &__textarea-image-close {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -4px;
    right: -4px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;
    @include themed() {
      background-color: t("fontColor");
    }
  }

  &__textarea-video-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 3px;
    background-color: rgba($color: #000000, $alpha: 0.3);
    svg {
      fill: #ffffff;
    }
  }

  &__textarea-chars {
    padding: 12px;
    &.txt-red {
      @include themed() {
        color: t("additionalRedText");
      }
    }
  }

  &__signature-input {
    padding: 12px;
    outline: none;
    border: none;
    width: 100%;
    @include themed() {
      background-color: t("bg");
      color: t("fontColor");
    }

    &.disabled {
      @include themed() {
        color: t("fontMidGray") !important;
      }
  
      cursor: not-allowed;
    }
  }

  &__textarea-field {
    position: relative;
  }

  &__textarea-field-highlight {
    position: absolute;
    top: 0;
    left: 0;
    padding: 12px;
    color: transparent;
    white-space: pre-line;
    word-break: break-word;
    pointer-events: none;

    .with-red {
      color: #eb6033;
    }
  }

  &__textarea-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__recipients {
    font-weight: 500;
    @include themed() {
      color: t("userProfileMenuItemColor");
    }
  }

  &__popover {
    padding: 10px;
    width: fit-content;
    cursor: pointer;

    #messages-card-from {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__features {
    padding: 10px;
    @include themed() {
      background-color: t("bg");
    }
  }

  &__scheduling-button {
    font-size: 16px;
    padding: 0 12px;
    outline: none;
    border: none;
    background: none;

    @include themed() {
      color: t("primary");
    }

    &.scheduling-remove {
      @include themed() {
        color: t("additionalRedText");
      }
    }
  }
}

.send-message-preview {
  position: relative;
  border-radius: 29px;
  width: 240px;
  height: 497px;
  padding: 92px 15px 20px 20px;
  @include themed() {
    background-color: t("previewDeviceBorderColor");
  }

  &::before {
    content: "";
    position: absolute;
    top: 9px;
    left: 9px;
    height: calc(100% - 18px);
    width: calc(100% - 18px);
    border-radius: 22px;
    @include themed() {
      background-color: t("bg");
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 26px;
    width: 120px;
    border-radius: 9px;
    @include themed() {
      background-color: t("previewDeviceBorderColor");
    }
  }

  &__wrap {
    min-width: 240px;
  }

  &__number {
    position: absolute;
    top: 32px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding-bottom: 6px;
    font-size: 16px;
    text-align: center;
    @include themed() {
      color: t("filterText");
      border-bottom: 1px solid t("previewDeviceBorderColor");
    }
  }

  &__box {
    position: relative;
    padding: 9px 12px 12px;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    font-size: 14px;
    white-space: pre-line;
    word-break: break-all;
    @include themed() {
      color: t("text");
      background-color: t("previewDeviceBorderColor");
    }
  }

  &__video-thumb {
    position: relative;
    height: 96px;
    width: 96px;
  }

  &__img-stack {
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.send-message-body {
  padding-bottom: 80px;

  .recipient-added-pill {
    width: auto;
    max-width: 170px !important;
    min-width: 70px !important;

    .recipient-pill-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .recipient-pill-group-avatar {
      width: 17px !important;
      min-width: 17px !important;
      height: 17px !important;
    }
  }

  .recipient-added-pill-container {
    &:hover {
      @include themed() {
        box-shadow: 0px 0px 6px 0px t("primary");
        -webkit-box-shadow: 0px 0px 6px 0px t("primary");
        -moz-box-shadow: 0px 0px 6px 0px t("primary");
      }
    }
  }
}

.send-new-message-layout {
  .chat-sidebar {
    &__recipients {
      position: fixed;
      top: 0;
      right: 0;
      width: 750px;
      box-shadow: 0px 3px 6px #00000029;
      z-index: 10;
      @include themed() {
        background-color: t("bg");
      }
    }

    &.minimized-right-panel {
      width: 0px;
    }

    &__body {
      padding: 14px 75px;
    }

    .scroll-table {
      background-color: transparent;
    }

    &__remove-button {
      outline: none;
      color: #eb6033;
      background-color: transparent;
      @include themed() {
        border: 1px solid t("searchInputBorder");
      }

      &:hover {
        background-color: #88888847;
      }
    }

    &__recipients-title {
      @include themed() {
        color: t("defaultActionButtonText");
      }
    }
  }

  .feature-container {
    cursor: pointer;

    span > small {
      font-size: 14px;
    }

    .feature-label {
      @include themed() {
        color: t("text");
      }
    }
  }
}

// Mobile
@media screen and (max-width: 768px) {
  .send-message-header {
    position: relative;
    @include themed() {
      border-bottom: 1px solid t("searchInputBorder");
    }

    &__title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .h4 {
      font-size: 24px;
    }

    .header-button {
      &:first-child,
      &:nth-child(2) {
        display: none;
      }
    }
  }

  .messages-card {
    &__popover {
      width: 100%;
    }
  }

  .send-new-message-layout {
    .chat-sidebar {
      &__recipients {
        width: 98vw;
      }

      &__body {
        padding: 14px;
      }
    }
  }
}

@media screen and (max-width: 991.98px) {
  .send-new-message-layout {
    .chat-sidebar {
      &__recipients {
        margin-right: 0;
      }
    }
  }
}

.button-delete {
  background-color: #ff5800 !important;
  border-color: #ff5800 !important;
  background: #ff5800 !important;
  color: white !important;


  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 88, 0, 0.5);
  }

  &:hover {
    background-color: #ff5800 !important;
    border-color: #ff5800 !important;
    background: #ff5800 !important;
    color: white !important;
  }
}

.recipient-avatar {
  height: 36px !important;
  width: 36px !important;
  min-width: 36px !important;
}

.duplicate-recipient-container {
  padding: 8px 5px 8px 5px !important;
  .duplicate-recipient-avatar {
    height: 28px !important;
    width: 28px !important;
    min-width: 28px !important;
    font-size: 1rem !important;
  }
  
  .duplicate-recipient-label {
    font-size: 0.8rem !important;
  }
}


.duplicate-profiles {
  @include themed() {
    color: t("primary") !important;
  }
  font-size: 0.8rem !important;
  cursor: default;
}