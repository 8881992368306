.dashboard-wrapper {
  padding: 38px 30px;
  @media (max-width: $screen-md) {
    padding-bottom: 80px;
  }
  @media (max-width: $screen-sm) {
    padding: 10px 10px 80px 10px;
  }
  @media (max-width: $screen-xs) {
    padding: 10px 0px 80px 0px;
  }

  .dashboard-title {
    font-size: 20px;
    font-weight: bold;
    margin-left: 30px;
    cursor: pointer;
    .view-profile {
      display: none;
      font-size: 16px;
      font-weight: normal;
      margin-left: 10px;

      @include themed() {
        color: t("iconPurple");
      }
    }
    &:hover {
      .view-profile {
        display: inline;
      }
    }
    @include themed() {
      color: t("fontDarkGray");
    }
    @media (max-width: $screen-md) {
      margin-left: 20px;
    }
  }
}

.dashboard-card-container {
  background: #fff;
  border-radius: 17px;
  height: 100%;
  @include themed() {
    border: 1px solid t("fontNormalGray");
  }

  &.dashboard-card-is-dragging {
    border: 1px solid #6c37c9;
  }

  .dashboard-card-header {
    border-radius: 17px 17px 0px 0px;
    padding: 10px 10px 10px 40px;
    position: relative;
    font-size: 16px;
    font-weight: bold;
    color: #808080;
    min-height: 45px;
    @include themed() {
      border-bottom: 1px solid t("fontNormalGray");
    }
  }

  .dashboard-card-header-white {
    @include themed() {
      background: t("bgRHS");
    }
  }

  .dashboard-card-header-gray {
    @include themed() {
      background: t("bgRHS");
    }
  }

  .dashboard-card-header-purple {
    background: #664f8e;
  }

  .dashboard-drag-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
  }

  .dashboard-card-trash-icon {
    position: absolute;
    right: 10px;
    top: 13px;
  }

  .dashboard-card-content {
    height: 100%;
    @include themed() {
      background: t("bg");
      color: t("fontColor");
    }
    font-size: 16px;
    border-bottom-left-radius: 17px;
    border-bottom-right-radius: 17px;
    @media (max-width: $screen-md) {
      font-size: 14px;
    }
  }
  .large-text {
    font-size: 20px;
    @media (max-width: $screen-md) {
      font-size: 16px;
    }
    .total-credits {
      @include themed() {
        color: t("fontColor");
      }

      &:hover {
        @include themed() {
          color: t("primary");
        }
      }
    }

    .billing-analytic-link {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .dashboard-card-detail-box-container {
    @include themed() {
      border: 1px solid t("fontNormalGray");
    }
    padding: 10px;
    width: auto;
    border-radius: 5px;
    @media (max-width: $screen-md) {
      margin-top: 10px;
    }
  }

  .link-header-content {
    color: #808080;
    &:hover {
      @include themed() {
        color: t("primary");
      }
    }
  }
}

/*  MessagesCallsWidget */

.message-calls-widget-message-dot-notification {
  border: 2px solid #f8f8f8;
  top: 0 !important;
  right: 1px !important;
  width: 10px !important;
  height: 10px !important;
}

.message-calls-widget-calls-to-notification {
  top: 13px !important;
  right: 14px !important;
}

.message-calls-widget-header {
  min-height: 92px !important;
}

/* MessagesCallsWidget */

.messages-calls-widget-container {
  padding: 25px 44px;
  font-size: 14px;
  color: #666;
  height: 100%;
  width: 100%;
  .text-label {
    font-size: 14px;
    color: #808080;
  }
  .text-label-darker {
    font-size: 16px;
    font-weight: 500;
    @include themed() {
      color: t("fontColor");
    }
    @media (max-width: $screen-md) {
      font-size: 14px;
    }
  }
  .arrow-rose {
    border-top-color: #ff4b75;
  }
  .arrow-green {
    border-top-color: #00e38c;
  }
  &__grid {
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 12px;
    column-gap: 24px;
    @media (max-width: $screen-md) {
      column-gap: 8px;
    }
  }
  &__text {
    @include themed() {
      color: t("fontColor");
    }
  }
  &__image {
    width: 280px;
    height: auto;
  }
}

/*  CreditsUsedWidget */

.credits-used-widget-container {
  padding: 15px 17px;
  font-size: 14px;
  color: #a6a6a6;
  .circular-progress-value {
    font-size: 16px;
    color: #202020;
    @media (max-width: $screen-md) {
      font-size: 14px;
    }
  }
}

/* GiftStaticWidget */
.gift-static-widget-container {
  border: 1px solid #d9d9d9;
  border-radius: 17px;
  padding: 17px 30px 21px 30px;
  height: 100%;
  font-size: 16px;
  color: #808080;
  @media (max-width: $screen-md) {
    font-size: 12px;
    padding: 10px;
  }
  .gift-static-widget-container-text {
    max-height: 230px;
    overflow: auto;
  }
}

/* Basics Widget */
.basics-widget-container {
  width: 100%;
  .table {
    .basics-widget-table-header {
      tr th {
        border-bottom: 1px solid #f4f4f4;
        padding-bottom: 5px;
      }
    }
    .basics-widget-table-body {
      tr td {
        font-size: 16px;
        color: #808080;
        border-bottom: 1px solid #fafafa;
        &.label-dark {
          color: #565656;
          font-weight: 500;
        }
        &.label-green {
          color: #35c971;
        }
        @media (max-width: $screen-md) {
          font-size: 14px;
        }
      }
    }
  }
}

/* MyOrganizationSummaryWidget */
.my-organization-summary-container {
  padding: 10px 0;
  font-size: 16px;
  width: 100%;
  @include themed() {
    background: t("bg");
    color: t("fontColor");
  }
  .table {
    @include themed() {
      background: t("bg");
      color: t("fontColor");
    }
    tr td:first-child {
      padding-left: 40px;
    }
    tr td {
      border-top: none;
      border-bottom: 1px solid #f6f6f6;
    }
  }
  &__link {
    @include themed() {
      color: t("fontDarkGray");
    }
  }
  @media (max-width: $screen-md) {
    font-size: 14px;
  }
}

/* SitemapWidget */
.sitemap-widget-table__title {
  font-size: 16px !important;
  font-weight: bold !important;
  @include themed() {
    color: t("primary") !important;
  }
}

.sitemap-widget-table,
.sitemap-widget-table thead tr th {
  @include themed() {
    background: t("bg");
    color: t("text");
  }
  a {
    @include themed() {
      color: t("fontColor");
    }
  }
  padding: 13px;
  @media (max-width: $screen-md) {
    font-size: 14px;
    padding: 5px;
    font-weight: 600;
  }
}

.sitemap-widget-table td {
  padding: 13px;
  a {
    &:hover {
      @include themed() {
        color: t("primary");
      }
    }
  }
  @media (max-width: $screen-md) {
    font-size: 14px;
    padding: 5px;
  }
}

/* AddNewWidget */
.add-new-widget {
  display: flex;
  flex-direction: column;
  gap: 18px;
  border-radius: 17px;
  padding-top: 27px;
  justify-content: center;
  margin: 30px 30px 0px 30px;

  @include themed() {
    border: 1px solid t("fontNormalGray");
  }

  @media (max-width: $screen-md) {
    margin: 20px 20px 0px 20px;
    gap: 4px;
    padding: 5px 0px 5px 0px;
  }

  .add-new-widget-row {
    justify-content: center;
    flex-wrap: wrap;
    @media only screen and (min-width: 1199.98px) {
      display: flex;
      margin: 0px 7rem;
    }
    .add-new-widget-column {
      text-align: center;
      margin-right: 30px;

      @media (max-width: $screen-md) {
        flex: 50%;
        margin-top: 8px;
        margin-right: 0px;
      }
    }
    .add-new-widget-row-button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      .action-button-icon-left {
        @media (max-width: $screen-md) {
          margin-top: 0px;
        }
      }
    }
    .more-actions {
      display: flex;
      margin: 5px 0px 10px 0px;
      justify-content: center;
      .title {
        font-size: 12px;
        letter-spacing: 0px;
        font-weight: normal;
        text-align: left;
        @include themed() {
          color: t("primaryActionButtonBg");
        }
        margin-right: 4px;
      }
    }
  }
}

.dashboard-header {
  padding-left: 40px;
  padding-right: 40px;
  @media (max-width: $screen-md) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.credits-summary-container {
  padding: 0px 24px 0px 24px;
  width: 100%;
  @include themed() {
    background: t("bg");
  }

  .credits-summary-container-title {
    font-size: 14px;
    margin-bottom: 8.5px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }

  .credits-summary-container-title-total-credits {
    @extend .credits-summary-container-title;
    color: #202020;
    @include themed() {
      color: t("fontColor");
    }
  }
  .credits-summary-container-title-others {
    @extend .credits-summary-container-title;
    color: #8f8f8f;
  }
  .credits-summary-container-section {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-left: 21px;
    margin-bottom: 8.5px;
    color: #808080;
  }
  .credits-summary-container-section-sub {
    @extend .credits-summary-container-section;
    margin-left: 44px;
  }
  .credits-summary-container-divider {
    margin-top: 17.5px;
    margin-bottom: 9px;
    @include themed() {
      background: t("fontDarkGray");
    }
  }
}
