.fsp-picker__brand-container {
  display: none !important;
}

.grid {
  .fsp-picker--custom-source-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media (max-width: $screen-md) {
      justify-content: center;
    }

    .grid-thumbnail {
      object-fit: cover;
      width: 100% !important;
    }
  }

  .file-item {
    justify-content: space-between;
  }

  .grid-view-infinite-scroll {
    width: 100%;
    height: 27vh !important;
    @media (max-width: $screen-md) {
      height: 65vh !important;
    }
  }

  .source-list-item {
    width: 210px !important;
    @media (max-width: $screen-xs) {
      width: 80% !important;
    }
  }
}

.video-list-view-infinite-scroll {
  .list-thumbnail {
    object-fit: cover;
    width: 12%;
    height: 90%;
    @media (max-width: $screen-md) {
      width: 25%;
    }
  }

  .source-list-item {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .source-list-inner-container {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
    }

    .video-play-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50%;
      span {
        color: white !important;
      }
    }
  }
}

.delete-selected-button {
  border: 1px solid #ff5800 !important;
  background-color: transparent !important;
  margin-right: 20px;
  span {
    color: #ff5800 !important;
  }
  @media (max-width: $screen-md) {
    padding: 0px 15px !important;
  }
}

.view-edit-btn {
  color: white !important;
}

.list-view-container {
  height: 43vh !important;
  overflow-y: scroll !important;
  @media (max-width: $screen-md) {
    height: 85vh !important;
  }
}

.file-stack-image-footer-container {
  @media (max-width: $screen-md) {
    height: auto !important;
    flex-direction: column;
  }
}

[data-e2e="upload-more"] {
  display: none !important;
}

.video-viewver-container {
  height: 390px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: $screen-sm) {
    height: 75vh !important;
  }
}

.default-video-icon-container {
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-video-view-inner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.video-grid-view-infinite-scroll {
  height: 27vh !important;
  width: 100%;
  @media (max-width: $screen-md) {
    height: 65vh !important;
  }
  .video-play-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    margin-top: 10px;
    span {
      margin: 0px !important;
      color: white !important;
    }
  }
}
