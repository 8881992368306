.standard-modal {
  display: block;

  .modal-content {
    max-height: 90%;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
  }

  .modal-header {
    padding: 28px 31px 18px;
    border-bottom: 0;
  }

  .close-icon {
    margin-top: -14px;
    margin-right: -14px;
    cursor: pointer;
  }

  .content {
    flex-grow: 1;
    max-height: 260px;
    padding: 0;
  }

  .modal-footer {
    padding: 28px 31px 20px;
    border-top: 0;

    .btn {
      min-width: 90px;
    }
  }

  .modal-title {
    font-weight: bold;
    font-size: 20px;
    color: #122b28;
  }

  .header-row {
    border-bottom: none;
  }

  .modal-body {
    padding: 0 31px;
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
}

.modal-table {
  tbody {
    height: 40vh;
    max-height: 40vh;
    display: block;
    overflow: auto;
  }
}

.modal-content.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
