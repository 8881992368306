.confirm-send-message {
  &__text {
    font-size: 16px;
    margin-bottom: 32px;
    @include themed() {
      color: t("fontColor") !important;
    }
  }

  &__message {
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 20px;
    @include themed() {
      background-color: t("filterBackground");
    }
}

&__close {
    border: none !important;
}

&__credit {
    font-size: 16px;
    border-radius: 5px;
    padding: 0 2px;
    @include themed() {
      color: t("bg");
      background-color: t("text");
    }
  }
}
