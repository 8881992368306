.number-settings-table-body {
  & > tr {
    @include themed() {
      border-color: t("tableDivider") !important;
    }

    &.active {
      td {
        @include themed() {
          background: t("bgRHS");
        }
      }
    }

    .child-active {
      position: relative;
      z-index: 10;
      background: white !important;
    }

    .child-active:after {
      content: "";
      position: absolute;
      right: -41px;
      bottom: 0;
      height: 100%;
      width: 41px;
      background: white;
    }

    .child-active:before {
      content: "";
      position: absolute;
      z-index: 20;
      right: -26px;
      top: 0;
      height: 103%;
      width: 2px;
      background: #dee2e6;
    }

    td {
      padding-top: 5px;
      padding-bottom: 5px;
      @include themed() {
        border-color: t("tableDivider") !important;
      }
      white-space: nowrap;
      .arrow-icon {
        font-size: 13px;
        margin-left: 5px;
        cursor: pointer;
      }
      .assigned {
        color: $primary;
        font-weight: bold;
      }
      .normal-size {
        font-size: 16px;
      }
      .original-cost {
        font-size: 12px !important;
        font-weight: normal !important;
        text-align: left;
        text-decoration: line-through;
        margin-right: 0.3rem;
        letter-spacing: 0px;
        color: #a6a6a6;
      }
    }
    td:first-child {
      border: none;
    }
  }

  .smaller-border {
    position: relative;
    z-index: 1;
  }

  .smaller-border:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 1px;
    width: 41px;
    border-bottom: 2px solid white;
  }

  .left-border:after {
    content: "";
    position: absolute;
    left: 24px;
    top: -17px;
    height: 103%;
    width: 2px;
    background: #dee2e6;
  }

  .child-of-other-row {
    margin-left: 25px;
  }

  .short-code-primary-icon {
    position: absolute;
    left: -22px;
    top: 3px;
  }

  .text-to-voice-label {
    @include themed() {
      color: t("iconColor");
    }
  }
}
