.released-numbers-modal {
  .modal-dialog {
    width: 780px;
    max-width: 780px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
}

.released-numbers-container {
  padding: 21px 28px 50px 44px;
  min-height: 380px;
  @extend .container-flex-column-100;
  .released-numbers-title {
    @extend .custom-modal-title;
    margin-bottom: 23px;
  }
  .released-numbers-search {
    max-width: 180px;
    margin-bottom: 24px;
  }
  .released-numbers-info {
    font-size: 12px;
    @include themed() {
      color: t("fontDarkGray");
    }

    margin-bottom: 30px;
  }

  .released-numbers-scroll-table {
    height: 300px;
    table {
      @include themed() {
        background: t("bgRHS");
      }
    }
    .released-numbers-table-header {
      tr {
        th {
          padding-top: 9px;
          padding-bottom: 9px;
          border-top: 1px solid #dee2e6;
          @include themed() {
            background: t("bgRHS");
          }
        }
      }
    }

    .released-numbers-table-body {
      tr {
        td {
          padding-top: 13px;
          padding-bottom: 13px;
          @include themed() {
            color: t("fontColor");
          }

          @media (max-width: $screen-md) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .released-numbers-no-data-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;

    .released-numbers-no-data-info {
      font-size: 14px;
      color: #808080;
    }
  }
}
