.keywords-container {
  height: 100vh;
  .keywords-list {
    width: 300px;
    padding-left: 0;
    padding-right: 0;
    background: #2e2e2e;
    .keyword-item-container {
      padding: 14px 20px 15px 34px;
      cursor: pointer;
      &:hover {
        background: #3e374a;
      }
      &.active {
        background: #3e374a;
        .keyword-text {
          color: #ffffff;
        }
      }
      .keyword-text {
        margin-left: 10px;
        font-size: 16px;
        color: #808080;
      }
    }
  }
  .keywords-wrapper {
    width: 100%;
    .keywords-header {
      background-color: #f8f8f8;
      border: 1px solid #e1e1e1;
      padding: 12px 28px 9px 30px;
      .keyword-header-title {
        font-size: 18px;
        color: #202020;
        margin-left: 12px;
      }
    }
  }
}
