.preview-signature-modal {
  .modal-dialog {
    width: 675px;
    max-width: 675px;
  }
}

.preview-signature-container {
  padding: 13px 31px;
  .preview-signature-title {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 40px;
    color: $font-color-dark;
  }
  .preview-signature-label {
    font-size: 12px;
    color: #707070;
  }

  .preview-signature-left-container {
    max-width: 220px;
    width: 100%;
  }

  .preview-signature-select-container {
    max-width: 220px;
    width: 100%;
  }
  .preview-signature-container-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 81px;
    position: relative;
  }
  .preview-signature-view-default {
    position: absolute;
    bottom: 17px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 16px;
    margin-bottom: 0;
  }
}
