.counter-up-down-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .arrow-container {
    font-size: 0px;
    line-height: 0px;
    border: 1px solid darkgray;
    background: lightgray;

    &.disabled {
      opacity: 0.5;
    }
  }
}
