.notification-banner-container-rhs {
  border: 1px solid #e2e2e3;
  @include themed() {
    background: t("bg");
    color: t("text");
  }

  padding: 8px 19px 5px 7px;
  font-size: 12px;

  position: relative;

  .close-notification-icon {
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 5px;
  }
}
