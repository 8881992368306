/* Redeem Points Step One */
.redeem-points-step-one {
  padding: 24px 32px;
  .title {
    font-size: 24px;
  }
  p {
    font-size: 14px;
    color: #8e8e8e;
    padding-top: 24px;
    margin: 0;
    b {
      color: #565656;
    }
  }
  .reward {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    margin-bottom: 18px;
    font-size: 16px;
    align-items: center;
    .coming-soon-text {
      font-size: 12px;
    }
    &.enabled {
      span {
        color: #2c2c2c;
      }
    }
    &.disabled {
      span {
        color: #565656;
      }
      .btn-outline-primary:disabled {
        color: #565656;
        border-color: #565656;
      }
    }
  }
}

/* Redeem Points Step Two */
#redeemPointsStepTwo {
  .modal-content {
    width: 350px;
  }
}
.redeem-points-step-two {
  padding: 24px 32px;
  .input-label {
    font-size: 12px;
    color: #8e8e8e;
  }
  .successful-text {
    color: #60c288;
    font-weight: 500;
  }
  td {
    font-size: 14px;
    color: #8e8e8e;
    b {
      color: #565656;
    }
  }
  @extend .redeem-points-step-one;
}
