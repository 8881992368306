.action-button {
  border-radius: 5px;
  text-align: center;
  border: none;
  font-weight: 400;
  min-width: max-content;
}
.action-button-primary {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  @include themed() {
    background-color: t("primaryActionButtonBg");
    color: t("primaryActionButtonText");
    svg path:not([fill="none"]) {
      fill: t("primaryActionButtonText") !important;
    }
  }
}
.action-button-primary:disabled {
  cursor: not-allowed;
  pointer-events: none;
  @include themed() {
    background-color: t("primaryActionButtonDisabledBg");
    color: t("primaryActionButtonDisabledText");
    svg path:not([fill="none"]) {
      fill: t("primaryActionButtonDisabledText") !important;
    }
  }
  background-color: #c9b4eb;
}
.action-button-primary--sidebar {
  @extend .action-button-primary;
  @include themed() {
    background-color: t("primaryActionButtonBgSideBar");
    color: t("primaryActionButtonTextSideBar");
    svg path:not([fill="none"]) {
      fill: t("primaryActionButtonTextSideBar") !important;
    }
  }
}
.action-button-primary--sidebar:disabled {
  cursor: not-allowed;
  pointer-events: none;
  @include themed() {
    background-color: t("primaryActionButtonDisabledBgSideBar");
    color: t("primaryActionButtonDisabledTextSideBar");
    svg path:not([fill="none"]) {
      fill: t("primaryActionButtonDisabledTextSideBar") !important;
    }
  }
}
.action-button-default {
  transition: 0s;
  @include themed() {
    background-color: t("defaultActionButtonBg");
    color: t("defaultActionButtonText");
    svg path:not([fill="none"]) {
      fill: t("defaultActionButtonText") !important;
    }
  }
  &.selected {
    @extend .action-button-primary;
  }
}
.action-button-default:hover {
  @extend .action-button-primary;
}
.action-button-default:disabled {
  cursor: not-allowed;
  pointer-events: none;
  @include themed() {
    background-color: t("defaultActionButtonDisabledBg");
    color: t("defaultActionButtonDisabledText");
    svg path:not([fill="none"]) {
      fill: t("defaultActionButtonDisabledText") !important;
    }
  }
}

.action-button-default--sidebar {
  @extend .action-button-default;
  @include themed() {
    background-color: t("defaultActionButtonBgSideBar");
    color: t("defaultActionButtonTextSideBar");
    svg path:not([fill="none"]) {
      fill: t("defaultActionButtonTextSideBar") !important;
    }
  }
}
.action-button-default--sidebar:hover {
  @extend .action-button-primary--sidebar;
}
.action-button-default--sidebar:disabled {
  cursor: not-allowed;
  pointer-events: none;
  @include themed() {
    background-color: t("defaultActionButtonDisabledBgSideBar");
    color: t("defaultActionButtonDisabledTextSideBar");
    svg path:not([fill="none"]) {
      fill: t("defaultActionButtonDisabledTextSideBar") !important;
    }
  }
}

.action-button-ghost {
  @include themed() {
    border: 1px solid t("primaryActionButtonBg");
    color: t("primaryActionButtonBg");
    svg path:not([fill="none"]):not(.exclude-fill) {
      fill: t("primaryActionButtonBg") !important;
    }
    background-color: transparent;
  }
}

.action-button-large {
  font-size: 16px;
  padding: 6px 15px 6px 15px;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    margin-left: 8px;
  }
  &.icon-only {
    padding: 6px 8px 6px 8px;
    svg {
      margin: 0px;
    }
  }
}
.action-button-medium {
  font-size: 14px;
  padding: 4px 8px 4px 8px;
  svg {
    width: 18px;
    height: 18px;
    margin-right: 6px;
    margin-left: 6px;
  }
  &.icon-only {
    padding: 4px 6px 4px 6px;
    svg {
      margin: 0px;
    }
  }
}
.action-button-small {
  font-size: 14px;
  padding: 3px 7px 3px 7px;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 6px;
    margin-left: 6px;
  }
  &.icon-only {
    padding: 0px 3px 0px 3px;
    svg {
      margin: 0px;
    }
  }
}
.action-button-icon {
  line-height: 0;
  vertical-align: middle;
}
.action-button-icon-left {
  @extend .action-button-icon;
  svg {
    margin-left: 0px !important;
  }
  &.icon-only {
    svg {
      margin: 0px !important;
    }
  }
}
.action-button-icon-right {
  @extend .action-button-icon;
  svg {
    margin-right: 0px !important;
  }
  &.icon-only {
    svg {
      margin: 0px !important;
    }
  }
}

.action-button-trailing-more-icon {
  vertical-align: text-top;
  svg {
    width: 9px !important;
    height: 5px !important;
  }
}
