.add-vcard-modal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  .modal-dialog {
    margin: 15px;
    width: 50vw;
    max-width: 50vw;
    min-height: 30vh;

    @media (max-width: $screen-lg) {
      width: 80vw !important;
      max-width: 80vw !important;
    }

    @media (max-width: $screen-md) {
      width: 100vw !important;
      max-width: 100vw !important;
      height: 100vh !important;
      margin: 0px !important;
    }
  }

  .add-vcard-container {
    padding: 13px 30px 24px 30px;
    @media (max-width: $screen-md) {
      overflow-y: auto;
    }
    h1 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 30px;
      @media (max-width: $screen-md) {
        margin-bottom: 15px;
      }
    }

    .vcard-type-container {
      width: 160px !important;
      height: 120px;
      svg path:not([fill="none"]) {
        fill: #808080;
      }
      &:hover {
        @include themed() {
          color: t("primary");
        }
        svg path:not([fill="none"]) {
          @include themed() {
            fill: t("primary");
          }
        }
      }

      &.active {
        @include themed() {
          color: t("primary");
        }

        svg path:not([fill="none"]) {
          @include themed() {
            fill: t("primary");
          }
        }
      }
    }

    .vcard-type-title {
      font-size: 1rem;
      font-weight: bold;
    }
  }

  .vcard-add-label {
    @include themed() {
      color: t("text");
    }
  }

  .label-fields {
    font-size: 10px;
  }

  input {
    font-size: 0.8rem !important;
    @include themed() {
      color: t("text");
    }
  }

  select {
    font-size: 0.8rem !important;
    @include themed() {
      color: t("text");
    }
  }
}

.choose-multiple-contact-modal-container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  .modal-dialog {
    margin: 15px;
    width: 45vw;
    max-width: 45vw;
    min-height: 30vh;
    height: 70vh;

    @media (max-width: $screen-lg) {
      width: 80vw !important;
      max-width: 80vw !important;
    }

    @media (max-width: $screen-md) {
      width: 100vw !important;
      max-width: 100vw !important;
      height: 100vh !important;
      margin: 0px !important;
    }
  }

  .modal-info {
    @include themed() {
      color: t("fontMidGray");
    }
    @media (max-width: $screen-md) {
      padding: 0px 10px 0px 20px;
      font-size: 14px;
    }
  }

  .choose-sender-vcard-number-table {
    height: 400px !important;
  }
}
