.poll-results-campaign-modal {
  .modal-dialog {
    margin: 0;
    width: calc(100vw);
    max-width: calc(100vw);
    height: calc(100vh);
    max-height: calc(100vh);
  }
  .modal-content {
    height: 100%;
    border-radius: 0;
  }
  .poll-results-campaign-close-icon {
    top: 15px;
    right: 10px;
  }
}

.poll-results-campaign-container {
  .poll-results-campaign-container-header {
    height: 60px;
    background: #f8f8f8;
    border-bottom: 1px solid #e1e1e1;
    font-size: 14px;
    color: #808080;
    padding-right: 35px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .poll-results-campaign-chart-wrapper {
    margin: 22px 35px 22px 60px;
    flex: 5;
    color: #808080;
    .poll-results-campaign-chart-container {
      border: 1px solid #d9d9d9;
      border-radius: 17px;
      padding-bottom: 20px;
      .title-text {
        font-size: 14px;
        margin-top: 18px;
        text-align: center;
        padding: 0 20px;
        margin-bottom: 45px;
      }
      .purple-cube {
        width: 14px;
        height: 14px;
        background: #c9b4eb;
        border-radius: 3px;
      }
      .gray-cube {
        width: 14px;
        height: 14px;
        background: #a6a6a6;
        border-radius: 3px;
      }
      .overview-text {
        font-size: 16px;
        color: #2c2c2c;
      }
      .chart-title {
        font-size: 16px;
        color: #777777;
        text-align: center;
      }
    }
  }

  .poll-results-campaign-poll-wrapper {
    background: #f8f8f8;
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .poll-results-campaign-poll-options {
      background: #fff;
      border-radius: 17px;
      padding-top: 16px;
      padding-bottom: 16px;
      margin: 25px;
      max-width: 350px;
      min-width: 300px;
      width: 100%;
      .title-text {
        font-size: 14px;
        color: #a6a6a6;
        text-align: center;
      }
      .poll-link-container {
        border: 1px solid #808080;
        background: #f8f8f8;
        color: #6c37c9;
        border-radius: 10px;
        padding: 4px 15px;
      }
    }
  }
}
