.new-version-available-modal-wrapper {
  .themed-modal {
    background: none !important;
  }
  .modal-content {
    box-shadow: none !important;
  }
  .image {
    width: 100%;
    height: 100%;
    box-shadow: 0px 5px 6px #00000038;
  }
  .close-icon {
    top: 15px;
    right: 15px;
  }
  .download-button {
    position: relative;
    width: 65% !important;
    bottom: 80px;
    margin-left: auto;
    margin-right: auto;
  }
}
