@import "../base/variables";
@import "../base/global-theme";

.merge-fields-wrapper {
  .modal-content {
    padding: 30px;
    max-height: 690px;
    width: 420px;
    @media (max-width: $screen-md) {
      width: 100vw;
    }
  }
  .title {
    font-size: 24px;
  }
  .description {
    font-size: 12px;
    padding: 25px 0px;
    @include themed() {
      color: t("fontDarkGray");
    }
  }
  .merge-field-category {
    @include themed() {
      color: t("fontDarkGray");
    }
    font-size: 12px;
    padding-bottom: 17px;
  }
  .merge-field {
    @include themed() {
      color: t("fontDarkGray");
    }
    font-size: 14px;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    margin-top: 5px;
    padding: 0 2px;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
  .close-modal-button {
    align-self: flex-end;
  }
}
