.number-extension-container {
  .number-extension-label {
    font-size: 12px;
    @include themed() {
      color: t("label");
    }

    margin-bottom: -5px;
  }

  .number-extension-container {
    margin-top: 30px;
    .number-extension-title-label {
      font-size: 12px;
      @include themed() {
        color: t("label");
      }
    }

    .number-extension-label {
      font-size: 16px;
      @include themed() {
        color: t("label");
      }
    }
  }

  .advance-link {
    margin-top: 14px;
    span {
      @include themed() {
        color: t("primary");
      }
      cursor: pointer;
    }
  }
}
