#globalModal,
.global-modal {
  z-index: 999999;

  .modal-header {
    border-bottom: none;
    padding: 0;
    margin-bottom: 30px;
    .close {
      position: absolute;
      right: 15px;
      top: 15px;
    }
    .modal-title {
      @include themed() {
        color: t("fontColor");
      }

      font-size: 1.25rem;
      font-weight: bold;
    }
  }
  .modal-content {
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
    border-radius: 5px;
    padding: 25px;
    border: none;
  }
  .modal-body {
    padding: 0;
    p {
      @include themed() {
        color: t("text");
      }

      font-size: 16px;
      font-weight: 400;
    }
    a {
      @include themed() {
        color: t("primary");
      }

      text-transform: uppercase;
    }
  }
  .modal-footer {
    border-top: none;
    padding: 0;
    margin-top: 40px;

    .btn {
      align-items: center;
      display: inline-flex;
      height: 44px;
      justify-content: center;
      min-width: 90px;
      padding: 0 20px;
    }
    .btn-outline-primary {
      &:hover {
        @include themed() {
          background: rgba(t("primary"), 0.1);
          color: t("primary");
        }
      }
    }
  }
}

.modal-body {
  table tbody td .number {
    .numeric {
      margin-right: 5px;
    }
    .icon {
      margin-right: 5px;
    }
  }
}

.modal-footer {
  &.mt-gap {
    margin-top: 70px;
  }
}

.image-warning-footer-wrapper {
  @media (max-width: $screen-md) {
    flex-direction: column;
    margin-top: 0px !important;
  }
  .text-nowrap {
    @media (max-width: $screen-md) {
      margin-top: 8px;
    }
  }
}
