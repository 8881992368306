.add-new-sms-template-modal {
  & > .modal-dialog {
    width: 560px;
    max-width: 560px;
    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }
  }
}

.add-new-sms-template-container {
  padding: 13px 30px 30px 30px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 34px;
  }
  .char-container {
    font-size: 11px;
    color: #8f8f8f;
  }
}
