.integration-modal {
  .modal-dialog {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    margin: 0;
  }
  .modal-content {
    height: 100%;
    border-radius: 0;
  }
}

.integration-container {
  .integration-header {
    padding-left: 100px;
    padding-right: 30px;
    border-bottom: 0.5px solid #d9d9d9;
    padding-bottom: 10px;
    padding-top: 20px;
    .integration-image {
      width: 68px;
      height: 68px;
    }
    .integration-title {
      font-size: 20px;
      color: #2c2c2c;
      font-weight: bold;
    }
    .menus {
      display: flex;
      margin-left: calc(70px + 1rem);
      .menu {
        color: #808080;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }

  .integration-info-container {
    padding-left: 172px;
    display: flex;
    .first-container {
      padding-top: 25px;
      padding-right: 25px;
      flex: 4;
      p {
        max-width: 650px;
        font-size: 14px;
        color: #707070;
      }
      ul {
        max-width: 650px;
        padding-left: 0;
      }
    }
    .second-container {
      background: #f8f8f8;
      flex: 2;
      padding: 50px 20px 20px 20px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }
}

.settings-tab {
  .settings-subtitle {
    color: #808080;
    font-size: 12px;
  }
  .back-button {
    position: absolute;
    left: -60px;
  }

  .settings-action-title {
    font-size: 18px;
    color: #202020;
    margin-bottom: 56px;
  }
}

.sync-selection-container {
  .sync-selection-title {
    font-size: 18px;
    color: #202020;
    text-align: center;
  }
  .sync-selection-right-container {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    .refresh {
      font-size: 12px;
      margin-top: 3px;
    }
  }
  .sync-selection-selected {
    font-size: 12px;
    color: #808080;
  }
  .sync-selection-infinity-scroll {
    max-height: 400px;
  }
  .sync-selection-view-container {
    position: absolute;
    top: 14px;
    right: 0;
    z-index: 10;
    color: #808080;
    font-size: 12px;
  }
  .sync-selection-table-header {
    tr th:first-child {
      width: 40px;
    }
  }
}

.syncing-in-progress-container {
  color: #707070;
  font-size: 16px;
  .syncing-in-progress-title {
    font-size: 18px;
    color: #202020;
    margin-bottom: 78px;
  }
  .syncing-container {
    margin-bottom: 100px;
  }
  .stop-syncing-btn {
    position: absolute;
    top: -15px;
    right: -130px;
  }
}

.integration-action-card {
  position: relative;
  padding: 14px 0;
  border-radius: 17px;
  background: #fff;
  width: 300px;

  .purchased-text {
    color: #707070;
    font-size: 11px;
    margin-bottom: 33px;
    text-align: center;
  }

  .integration-action-card-banner {
    position: absolute;
    top: 0;
    right: 30px;
  }

  .integration-action-card-title {
    font-size: 14px;
    color: #565656;
    text-align: center;
    margin-bottom: 30px;
  }
  .integration-action-card-price {
    font-size: 18px;
    color: #6c37c9;
    text-align: center;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .integration-action-card-btn {
    max-width: 200px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .integration-action-card-info {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }

  .integration-action-card-reviews {
    padding-top: 35px;
    padding-bottom: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .company-info {
      color: #707070;
      font-size: 11px;
      margin-top: 17px;
      text-align: center;
    }
  }
}

.active-integration-action-card {
  border-radius: 17px;
  background: #fff;
  width: 300px;
  font-size: 14px;
  color: #707070;
  text-align: center;

  .margin-separation {
    margin: 30px 0;
  }

  .deactivate-button {
    max-width: 200px;
  }

  .leave-review {
    font-size: 11px;
    color: #707070;
    margin-top: 1px;
  }
}

.deactivate-integration-modal {
  .modal-dialog {
    width: 680px;
    max-width: 680px;
    height: auto;
    margin: 0 auto;
  }
  .modal-content {
    height: auto;
    border-radius: 5px;
  }
}

.deactivate-integration-container {
  padding: 25px;
  color: #808080;
  font-size: 16px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 17px;
  }
  .deactivate-integration-description {
    margin-bottom: 48px;
    max-width: 600px;
  }
  .confirm-container {
    margin-left: 46px;
    margin-top: 28px;
    margin-bottom: 51px;
  }
}

.integration-outlined-button {
  padding: 14px;
  font-size: 16px;
  color: #565656;
  border: 1px solid #6c37c9;
  border-radius: 5px;
  min-width: 270px;
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  .subtitle {
    color: #a6a6a6;
    font-size: 16px;
    margin-top: -6px;
  }
}

.integration-outlined-button:hover {
  border: 2px solid #6c37c9;
}

.rating-with-count {
  display: flex;
  align-items: center;
  .rating {
    font-size: 18px;
    color: #707070;
    margin-right: 6px;
  }
  .ratting-counts {
    font-size: 10px;
    color: #707070;
    margin-left: 6px;
    margin-top: 1px;
  }
}

.integration-login-modal {
  .modal-dialog {
    width: 380px;
    max-width: 380px;
    height: auto;
    max-height: none;
    margin: 0 auto;
  }
  .modal-content {
    height: auto;
    border-radius: 5px;
  }
}

.integration-login-container {
  padding: 26px 30px;
  .integration-logo {
    width: 63px;
    height: 63px;
  }
  .integration-title {
    font-size: 20px;
    color: #2c2c2c;
  }
  .site-text {
    font-size: 16px;
    color: #565656;
  }
  .preview-svg {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}
