@import "../../../../base/global-theme";

.logs {
  @extend .container-flex-column-100;
  .logs-view-tabs-header {
    border: none;
  }
  @media (max-width: $screen-md) {
    height: 90vh;
  }

  .logs-filter-header {
    @include themed() {
      color: t("tableHeader");
      background: t("bg");
      border-color: t("tableDivider");
    }
    display: flex;
    align-items: center;
    vertical-align: bottom;
    flex-wrap: wrap;
    font-size: 18px;
    font-weight: 500;
    padding-left: 40px;
    padding-right: 3rem;
    margin-bottom: 20px;
    .logs-report-multiselect-wrapper {
      width: 250px;
      margin-right: 20px;
    }
    .logs-filter-wrapper {
      width: 100%;
      max-width: 150px;
      margin-right: 20px;
    }
    .date-label {
      font-size: 14px;
      @include themed() {
        color: t("fontMidGray");
      }
    }

    @media (max-width: $screen-md) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .xs-table-cell {
    width: 2%;
  }

  .small-table-cell {
    width: 5%;
  }
  .medium-table-cell {
    width: 10%;
  }

  .large-table-cell {
    width: 15%;
  }

  .logs-table-header {
    tr th {
      padding: 10px 5px;
      @include themed() {
        border-color: t("tableDivider") !important;
      }

      border-top: 1px solid;
    }
    tr:first-child th {
      width: 30px !important;
    }
  }

  .logs-table-body {
    tr {
      td {
        padding: 5px;
        @media (max-width: $screen-md) {
          padding: 2px;
          margin: 0px;
        }
        @include themed() {
          border-color: t("tableDivider") !important;
        }
        text-transform: capitalize;
      }
    }
    tr:hover {
      @include themed() {
        background: t("bgRHS");
      }
    }

    .active {
      @include themed() {
        background: t("bgRHS");
      }
    }

    .themed-color {
      @include themed() {
        color: t("primary");
      }
    }
  }
}

.credits-header {
  position: sticky;
  top: 40px;
  @include themed() {
    border: 1px solid t("lightToneContrast");
  }
  @include themed() {
    background-color: t("bg");
  }
  th {
    padding: 5px 25px !important;
    font-size: 16px !important;
  }
}

.messages-calls-summary {
  width: 750px;
  &__box {
    @include themed() {
      border: 1px solid t("fontNormalGray");
    }
    padding: 10px;
    width: auto;
    border-radius: 5px;
  }
  @media (max-width: $screen-md) {
    width: 100%;
    &__box {
      margin-top: 10px;
    }
  }
}
