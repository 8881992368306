.apply-tags-modal-container {
  height: 700px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 25px;
  }
  .tags-table {
    height: 500px;
  }
  .change-text {
    font-size: 13px;
    font-weight: 400;
    color: #6c757d;
    vertical-align: middle;
    margin: 0;
  }
}
