.admin-settings-organization-profile-container {
  padding: 40px 67px;
  display: flex;

  @media (max-width: $screen-lg) {
    padding: 5px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }

  .card-info-container {
    min-width: 350px;
    max-width: 380px;
    @media (max-width: $screen-xs) {
      min-width: 300px;
      max-width: 300px;
    }

    @media (max-width: $screen-lg) and (min-width: $screen-sm) {
      min-width: 350px;
      max-width: 350px;
    }
  }

  .organization-info-header-container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .avatar {
      height: 70px;
      width: 70px;
      font-size: 1.8rem;
    }
    .logo-text {
      font-size: 14px;
      color: $primary;
      cursor: pointer;
      &.saving {
        color: #666666;
        font-weight: 600;
      }
    }
  }

  .organization-info-body-container {
    padding: 20px 17px;
    .organization-info-body-custom-select {
      min-width: 100px;
    }
  }

  .description-card {
    font-size: 16px;
  }

  .admin-settings-organization-profile-container-left {
    margin-right: 64px;
    @media (max-width: $screen-lg) {
      margin-right: 10px;
    }
  }
}

.upload-avatar-image {
  position: relative;
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 155px;
    cursor: pointer;
  }
}
