.deleted-users-modal {
  .modal-dialog {
    width: 780px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
}

.deleted-users-container {
  padding: 21px 28px 50px 44px;
  min-height: 380px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 23px;
  }

  .deleted-users-search {
    max-width: 180px;
    margin-bottom: 24px;
  }

  .deleted-users-info {
    font-size: 12px;
    color: #808080;
    margin-bottom: 30px;
  }

  .deleted-users-scroll-table {
    height: 300px;
  }

  .deleted-users-no-data-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;

    .deleted-users-no-data-info {
      font-size: 14px;
      color: #808080;
    }
  }
}
