.search-results-helper-text {
  @include themed() {
    color: t("fontDarkGray") !important;
  }
}

.danger-link-text {
  color: #ff5800;
  text-decoration: underline;
  cursor: pointer;
}

.danger-link-text-with-normal-cursor {
  color: #ff5800;
  text-decoration: underline;
}

.show-hidden-search-results-text {
  @include themed() {
    color: t("primary") !important;
  }
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
}
