.admin-settings-users-active-users,
.admin-settings-users-invite-pending {
  @extend .container-flex-column-100;
  .thread-view-tab-header {
    padding-left: 23px;
    padding-top: 40px;
    padding-bottom: 30px;
    margin-left: 15px;
    @media (max-width: $screen-md) {
      margin-left: 0px;
      padding-left: 10px;
    }
    @include themed() {
      border-color: t("tableDivider") !important;
    }
  }

  .active {
    @include themed() {
      background: t("bgRHS");
    }
  }
  .color-primary {
    @include themed() {
      color: t("primary") !important;
    }
  }
  .admin-settings-users-active-users-table-header,
  .admin-settings-users-invite-pending-table-header {
    @include themed() {
      border-color: t("tableDivider") !important;
    }
    border-bottom: 1px solid;
    tr {
      th {
        padding-bottom: 5px;
      }
      th:first-child {
        width: 1px;
      }
    }
  }
  .admin-settings-scroll-table {
    @media (max-width: $screen-md) {
      height: 70vh;
    }
    .table {
      tr {
        td {
          @include themed() {
            border-color: t("tableDivider") !important;
          }
          @media (max-width: $screen-md) {
            padding: 10px;
          }
          .themed-primary-color {
            @include themed() {
              color: t("primary");
            }
          }
        }
      }
    }
  }
}

.admin-settings-users-rhs {
  padding-top: 45px;
  .close-icon {
    left: 11px;
    top: 15px;
  }

  .menu-icon {
    position: absolute;
    right: 11px;
    top: 15px;
  }

  .title-avatar {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 30px;
  }
}
.delete-user-menu-button {
  padding-left: 6px;
}
