.verification-container {
  position: relative;
  min-height: 100vh;
  padding-top: 40px;

  .logo-container {
    position: absolute;
    top: 16px;
    left: 30px;
    width: 133px;
    height: 27px;
  }

  .welcome {
    padding-top: 80px;
    display: flex;
    justify-content: center;
    text-align: center;
    .title {
      color: #5517c1;
      font-size: 30px;
      margin-top: 25px;
      margin-bottom: 60px;
      font-weight: bold;
    }
    .text {
      font-size: 16px;
      color: #707070;
      margin-bottom: 48px;
    }

    .button-wrapper {
      max-width: 360px;
    }

    .loading-icon {
      margin-top: 80px;
      margin-bottom: 42px;
    }
  }

  .steps-container {
    max-width: 1444px;
    margin: 0 auto;

    .line-steps {
      margin: 0 auto;
      .title {
        margin-top: -5px;
        margin-bottom: 85px;
        font-size: 14px;
        font-weight: bold;
        color: #808080;
        text-align: center;
      }
    }

    .step-one {
      max-width: 700px;
      margin: 0 auto;
      .right-column {
        .label {
          color: #808080;
          font-size: 12px;
          margin-bottom: 0;
        }
        .text {
          color: #202020;
          font-size: 16px;
          margin-bottom: 35px;
        }
      }
    }

    .step-two {
      max-width: 370px;
      margin: 0 auto;
      .invite-code {
        margin-top: 55px;
        color: #808080;
        font-size: 16px;
        .input-box {
          width: 80%;
          margin-left: 10%;
        }
        .invite-code-input {
          text-align: center;
        }
        .text {
          font-weight: bold;
          margin-bottom: 0;
          text-align: center;
        }
      }
    }

    .step-three {
      width: 100%;
      margin: 0 auto;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      .phone-number {
        font-size: 18px;
        margin-top: 45px;
        margin-bottom: 12px;
      }
      .own-landline-text {
        margin-top: 45px;
        font-weight: normal;
        text-align: center;
      }
      .choose-number-button {
        color: $primary;
        margin-top: 12px;
      }
      .choose-type-of-number-button {
        margin-top: 58px;
        color: $primary;
        font-size: 14px;
        font-weight: 500;
        &:focus,
        &:active,
        &:visited {
          border: none;
        }
      }
      .choose-number-option {
        display: block;
        font-size: 14px;
        color: #808080;
        width: 236px !important;
        height: 236px !important;
        text-align: center;
        margin-right: 76px;
        padding-top: 15px;

        .choose-number-option > div {
          display: inline-block;
          width: 100%;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        .text-option {
          margin-top: 15px;
        }

        .option-description {
          display: inline-block;
          width: 100%;
          font-weight: normal;
        }
      }

      .question {
        text-align: center;
        font-weight: normal;
        color: #808080;
        margin-bottom: 50px;
      }

      .plus-icon {
        font-size: 32px;
        color: #393838;
        font-weight: bold;
        padding: 0px 20px;
      }
    }

    .button-footer {
      margin-top: 100px;
      display: flex;
      justify-content: center;
      .button-wrapper {
        width: 360px;
      }
    }
    .step-three-buttons {
      button:nth-child(1) {
        padding: 13px 55px;
      }
      button {
        padding: 13px 21px;
      }
      .skip-button {
        color: $primary;
        &:focus {
          border: none;
        }
      }
    }
  }
  .account-is-in-review-container {
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .background {
      position: absolute;
      transform: scale(3.4);
      margin-bottom: 10%;
    }
    .logo-container {
      position: absolute;
      top: 16px;
      left: 30px;
      width: 133px;
      height: 27px;
    }
    .copyright {
      position: absolute;
      bottom: 0;
      left: auto;
      right: auto;
      color: #ffffff;
      font-size: 12px;
    }

    .white-container {
      max-width: 800px;
      width: 100%;
      background: #ffffff;
      box-shadow: 10px 3px 30px #2d16559f;
      border-radius: 10px;
      padding: 36px 36px 50px 36px;
      position: relative;

      .account-in-review-icon {
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 30px;
      }

      .logo-container {
        position: absolute;
        top: 30px;
        left: 16px;
        width: 133px;
        height: 27px;
      }

      .title {
        color: #808080;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 10px;
      }

      .text-container {
        max-width: 550px;
        margin: 0 auto;
        .text {
          color: #808080;
          font-size: 16px;
          text-align: center;
          margin-bottom: 30px;
        }
        .link-container {
          display: flex;
          justify-content: center;
          font-size: 15px;
          margin: 0 auto;
          margin-top: 40px;
          max-width: 400px;
        }
      }
    }
  }
  .pick-different-number-modal {
    .modal-dialog {
      width: 740px;
      max-width: 740px;
    }
  }

  .pick-different-number-container {
    padding: 30px;

    .number-body-container {
      margin-top: 45px;
      padding: 10px;
      .top-label {
        color: #a6a6a6;
      }

      .search-container {
        margin-top: 32px;
        margin-bottom: 26px;
      }

      .number-chip-container {
        padding: 0 20px 0 20px;
        .number-chip {
          border: 1px solid #c2c2c2;
          border-radius: 5px;
          max-width: 130px;
          padding: 5px;
          text-align: center;
          cursor: pointer;
          height: 54px;

          &.selected {
            border: 1px solid #6c37c9;
            color: #6c37c9;
          }

          .number-chip-text {
            font-size: 16px;
            color: #2a2a2a;
            line-height: 1.35;
            &.selected {
              color: #6c37c9;
            }
          }
          .number-chip-subtitle {
            font-size: 13px;
            color: #888888;
            &.selected {
              color: #6c37c9;
            }
          }
        }
      }

      .refresh-button {
        margin-left: 25px;
        font-size: 16px;
        color: #6c37c9;
        cursor: pointer;
      }
    }
  }
}
