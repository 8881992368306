.audio-recorder {
  @include themed() {
    color: t("iconColor");
  }

  text-align: center;
  font-size: 20px;

  .record-process {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    button {
      padding: 12px;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #122b28;
      margin: 22px 7px 0;
      background-color: #fff;

      transition: none;

      :hover {
        border-width: 1.5px;
      }
      .play-svg {
        @media (max-width: $screen-md) {
          padding-right: 5px;
        }
      }
    }
  }

  .record-icon svg ellipse {
    fill: $secondary;
  }

  &.recording {
    .pause-icon,
    .stop-icon {
      display: flex;
    }
  }

  &.stopped {
    .pause-icon,
    .play-icon,
    .stop-icon {
      display: flex;
    }

    .audioplayer {
      display: block;
    }
  }
  .redo {
    display: inline;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
    @include themed() {
      color: t("primary") !important;
    }
  }
  .audioplayer {
    font-size: 12px;

    .audioplayer-bar {
      height: 4px;
    }

    .audioplayer-time {
      @include themed() {
        color: t("iconColor");
      }
    }

    .audioplayer:not(.audioplayer-playing) .audioplayer-playpause a {
      border-left-color: #122b28;
    }

    .audioplayer-playing .audioplayer-playpause a:after,
    .audioplayer-playing .audioplayer-playpause a:before {
      background-color: #122b28;
    }

    .audioplayer-volume-button {
      background-color: #fff;
    }

    .audioplayer-volume-button a {
      @include themed() {
        background: t("iconColor");
      }

      &::before {
        @include themed() {
          border-right-color: t("iconColor");
        }
      }

      &::after {
        @include themed() {
          border-color: t("iconColor");
        }
      }
    }

    .audioplayer-bar-played {
      background-color: rgba($primary, 0.3);
    }
  }
}
