.admin-settings-numbers {
  @extend .container-flex-column-100;
  .admin-settings-numbers-header-container {
    padding: 10px 28px 10px 28px;
    border-bottom: 2px solid;
    @include themed() {
      border-color: t("tableDivider") !important;
    }
    .short-code {
      margin-top: 25px;
    }

    .admin-settings-numbers-header {
      border-bottom: none;
      padding: 0;
    }
    @media (max-width: $screen-md) {
      padding: 10px 10px;
    }
  }

  .admin-settings-numbers-scroll-table {
    @media (max-width: $screen-md) {
      height: 80vh;
    }
  }

  .admin-settings-numbers-table-header {
    border-bottom: 2px solid;
    @include themed() {
      border-color: t("tableDivider") !important;
    }
    tr {
      th {
        padding-bottom: 5px;
      }
    }
  }
}
