.rhs-settings-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 130005;
  width: 350px;
  box-shadow: 0px 3px 6px #00000029;
  @include themed() {
    background: t("bgRHS");
    border: 1px solid t("lightToneContrast");
  }

  @media (max-width: $screen-md) {
    width: 100%;
  }

  .close-icon {
    position: absolute;
    top: 22px;
    right: 11px;
    cursor: pointer;
    @include themed() {
      path:not([fill="none"]) {
        fill: t("iconColor") !important;
      }
    }
  }

  .avatar-container {
    display: flex;
    justify-content: center;

    .avatar-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .avatar-title {
        font-size: 20px;
        font-weight: 500;
        @include themed() {
          color: t("fontColor");
        }
        margin-top: 5px;
        padding: 0 5px;
        text-align: center;
        .themed-primary-color {
          @include themed() {
            color: t("primary");
          }
        }
      }

      .avatar-subtitle {
        font-size: 14px;
        @include themed() {
          color: t("fontColor");
        }
        line-height: 1.3;
        padding: 0 5px;
      }
    }
  }

  .label {
    font-size: 14px;
    @include themed() {
      color: t("label");
    }

    margin-bottom: 3px;
  }
  .text {
    font-size: 16px;
    @include themed() {
      color: t("fontColor");
    }
  }
}

.white-notifier-container-rhs {
  @include themed() {
    color: t("bg");
  }

  border: 1px solid #a6a6a6;
  padding: 10px 21px;
  margin: 15px 7px;
  .text {
    font-size: 16px;
    color: #202020;
  }
  .modal-anchor {
    font-size: 16px;
    @include themed() {
      color: t("primary");
    }
    cursor: pointer;
  }
}

.text-container-rhs {
  padding: 20px 25px;
  @include themed() {
    color: t("fontColor");
  }
  .themed-color-primary {
    @include themed() {
      color: t("primary");
    }
  }
}

.user-role {
  line-height: 30px;
}
