.series-campaign-setup-modal {
  .modal-dialog {
    width: 500px;
    max-width: 500px;
  }
}

.series-campaign-setup-container {
  padding: 22px 31px;
  max-height: 700px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 20px;
    text-align: center;
  }
}
