.rewards-center-menu-header {
  .total-earnings {
    font-weight: 600;
    font-size: 16px;
    line-height: normal;
  }
  .text {
    color: rgba(0, 0, 0, 0.4);
  }
}
.referral-page {
  .refer-friends-wrapper {
    display: flex;
    justify-content: space-around;
    padding: 60px 0px;
    .left-column {
      width: 40%;
      h3 {
        color: #664f8e;
        font-weight: 600;
        padding-bottom: 30px;
      }
      span {
        color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
        font-weight: 500;
      }
      .redeemable-points {
        color: black;
      }
      .referral-link-wrapper {
        padding-top: 30px;
        span {
          display: inline-block;
          font-weight: 600;
          padding-bottom: 30px;
        }
        .referral-link {
          display: inline-block;
          border: 1px solid rgba(0, 0, 0, 0.4);
          padding: 8px 30px;
          border-radius: 12px;
          background-color: rgba(0, 0, 0, 0.05);
          text-decoration: underline;
          font-weight: normal;
        }
        .share-options {
          .facebook-button {
            background-color: #3b5998;
            color: white;
          }
          .twitter-button {
            background-color: #00acee;
            color: white;
          }
        }
      }
    }
  }
  .referral-card-info {
    width: 80%;
    margin: auto;
  }
  .invited-users-card-info-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .show-more {
    cursor: pointer;
    color: $primary;
    font-weight: normal;
  }
  .ways-to-earn-points-category {
    color: $primary;
  }
  .claim-button {
    color: $primary;
    cursor: pointer;
    font-weight: 500;
  }

  .small-avatar {
    height: 30px;
    width: 30px !important;
    min-width: 0px !important;
    font-size: 0.8rem;
  }
  .rewards-center-gifts-header {
    border-bottom: 1px solid #dee2e6;
    tr {
      th {
        padding-bottom: 5px;
      }
      th:first-child {
        width: 1px;
      }
    }
  }
}
.gifts-page {
  .credits-claimed {
    color: #60c288;
    font-weight: 500;
  }
  span.gifts-dot-notification {
    background-color: #60c288;
    position: absolute;
    top: 4px;
    right: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: block;
  }
}

/* Claim Credits modal */
.claim-credits-modal {
  .claim-credits-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 0px;
  }
  .claim-credits-icon {
    padding-bottom: 30px;
  }
  .claim-credits-text {
    font-size: 16px;
    color: #6c757d;
  }
  .claim-credits-buttons {
    padding: 0px 30px 20px 30px;
  }
}

/* Invited Users */
.number-of-users {
  font-size: 16px;
  font-weight: bold;
  color: #565656;
  padding: 32px;
}
.table-header-filter {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  &.active,
  &:hover {
    color: $primary;
  }
}

/* Invited Users & Template Center Filters Modal */
.invited-users-filters,
.template-center-filters,
.template-preview-modal,
.email-template-preview-modal {
  .modal-content {
    padding: 24px 32px 18px 24px;
  }
  .title {
    font-size: 24px;
  }
  .input-label {
    font-size: 12px;
    color: #8e8e8e;
  }
  .referral-type-select {
    width: 200px;
  }
  .apply-button {
    width: 100px;
    margin: 24px 0px 0px auto;
  }
  .template-dimensions-select {
    width: 150px;
  }
  .template-format-select {
    width: 120px;
  }
}
/* Template Center Filters Modal */
.template-center-filters {
  .modal-content {
    width: auto;
  }
}
/*Template Preview Modal */
.template-preview-modal {
  .modal-content {
    width: 560px;
  }
  .content {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    .description-wrapper {
      display: inline-block;
      width: 60%;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .buttons-container {
      display: inline-flex;
      flex-direction: column;
      width: 30%;
      font-size: 14px;
      color: $primary;
      justify-content: flex-start;

      div {
        margin-bottom: 15px;
      }

      .preview-option {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }
      .share-facebook-button {
        background-color: #3b5998;
        color: white;
      }
    }
    .btn {
      border: none;
    }
  }
}

/* Email Template Preview Modal*/
.email-template-preview-modal {
  .modal-content {
    width: 615px;
  }
  .content {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    height: 480px;
    .subject-label {
      color: #707070;
    }
    .description-wrapper {
      display: inline-block;
      width: 100%;
      padding-right: 16px;
      .subject-label {
        color: #6c757d;
        margin-right: 5px;
      }
      .description {
        margin-top: 16px;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .buttons-container {
      display: inline-flex;
      flex-direction: column;
      width: 150px;
      font-size: 14px;
      color: $primary;
      justify-content: flex-start;
      border-left: 1px solid #6c757d;
      padding-left: 20px;
      margin-left: 10px;

      div {
        margin-bottom: 15px;
      }

      .preview-option {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }
      .share-facebook-button {
        background-color: #3b5998;
        color: white;
      }
    }
    .btn {
      border: none;
    }
  }
}

/* Template Center */
.template-center {
  .table-header {
    height: 70px;
  }
  .template-subpage-title {
    font-size: 16px;
    font-weight: bold;
    color: #565656;
    padding: 32px 0px 0px 40px;
  }
  .selected-filters {
    margin-bottom: 12px;
    .contact-side-chip {
      display: inline-flex;
    }
  }
  .template-container {
    display: grid;
    justify-content: center;
    grid-template-columns: 25% 25% 25%;
    grid-row-gap: 40px;
    grid-column-gap: 40px;
    margin-top: 40px;
  }
  .template-card {
    box-shadow: 0 2px 2px 0 #0000001a;
    .title {
      color: #565656;
      font-size: 16px;
    }
    width: 320px;
    display: inline-flex;
    overflow: hidden;

    .card-info-title-container {
      background-color: white;
      border: none;
    }
    .card-info-content-container {
      padding: 0;
    }
    .template-body {
      display: inline-block;
      color: #8e8e8e;
      width: 100%;
      padding: 10px;
      height: 54px;
      overflow: hidden;
    }
    .template-image-container {
      height: 100px;
      width: 100%;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
.template-card-menu-items {
  font-size: 16px;
  padding: 12px;
  button {
    padding: 6px 0px;
  }
  .menu-option-icon {
    width: 16px;
    height: 16px;
    margin-bottom: 3px;
  }
}

/* Embed Code Modal & Download Template Modal*/
.embed-code-modal,
.download-template-modal {
  .modal-content {
    width: 370px;
    padding: 24px 32px;
    .title {
      font-size: 24px;
    }
  }
}
.embed-code-modal {
  .link-container {
    display: flex;
    justify-content: space-between;
    .link {
      display: inline-block;
      border: 1px solid rgba(0, 0, 0, 0.4);
      padding: 8px;
      border-radius: 12px;
      text-decoration: underline;
      font-weight: normal;
      width: 73%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.download-template-modal {
  .input-label {
    font-size: 12px;
    color: #707070;
  }
  .download-button {
    width: 50%;
    margin: 0 auto;
    margin-top: 24px;
  }
}
