.notifications-numbers-to-follow-modal {
  .modal-dialog {
    width: 450px !important;
    max-width: 450px !important;
    @media (max-width: $screen-xs) {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
  @media (max-width: $screen-md) {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

.notifications-numbers-to-follow-container {
  padding: 22px 26px 25px 26px;
  .notifications-numbers-to-follow-title {
    @extend .custom-modal-title;
    margin-bottom: 21px;
  }

  .notifications-numbers-to-follow-search {
    max-width: 230px;
    margin-bottom: 10px;
  }

  .notifications-numbers-to-follow-scroll-table {
    height: 320px;

    .notifications-numbers-to-follow-table-header {
      tr th {
        padding-left: 0;
      }
    }

    .notifications-numbers-to-follow-table-body {
      tr td {
        padding: 1rem 0.5rem;
      }
      tr td:nth-child(2) {
        min-width: 150px;
      }
    }
  }

  .notifications-numbers-to-follow-footer {
    margin-top: 25px;
    .text {
      font-size: 12px;
      @include themed() {
        color: t("fontDarkGray");
      }
      margin-bottom: 0;
      max-width: 230px;
    }
  }
}
