.access-denied-wrapper {
  .modal-dialog {
    max-width: 800px;
    width: 800px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
  .modal-content {
    width: 800px;
    height: 510px;
    @media (max-width: $screen-md) {
      width: auto;
    }
  }
}
.close-access-denied-modal-icon {
  display: none;
}
.access-denied-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #808080;
  h2 {
    margin-top: 30px;
    font-weight: bold;
  }
  .access-denied-text {
    width: 70%;
    text-align: center;
    margin: 30px 0px;
  }
  .go-back-button {
    padding-left: 30px;
    padding-right: 30px;
  }
}
