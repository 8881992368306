.delete-campaign-modal {
  .modal-dialog {
    width: 680px;
    max-width: 680px;
  }
}

.delete-campaign-container {
  padding: 18px 26px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 18px;
  }
  p {
    font-size: 16px;
    color: #808080;
  }
}
