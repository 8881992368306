.release-number-container-modal {
  .modal-dialog {
    width: 650px;
    max-width: 650px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
  @media (max-width: $screen-lg) {
    z-index: 140000;
  }
}

.release-number-container {
  padding: 21px 26px;
  .release-number-title {
    @extend .custom-modal-title;
    margin-bottom: 17px;
  }
  .release-number-sub-container {
    padding-right: 20px;
    .release-number-scroll-table {
      height: 400px;
    }
    .release-number-scroll-table-no-users {
      height: 100px;
    }

    .release-number-description {
      font-size: 12px;
      color: #afafaf;
    }

    .release-number-table-header {
      border-top: 1px solid #dee2e6;
      border-bottom: 2px solid #dee2e6;
      tr th {
        padding-top: 9px;
        padding-bottom: 9px;
      }
    }

    .release-number-table-body {
      @include themed() {
        color: t("fontDarkGray");
      }
    }
  }

  .release-number-footer {
    margin-top: 80px;
    display: flex;
    justify-content: flex-end;
    .cancel-button {
      width: 105px;
    }
  }
}
.confirm-container {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  @media (max-width: $screen-xs) {
    margin-top: 15px;
    flex-direction: column;
    align-items: center;
  }
  input {
    @media (max-width: $screen-xs) {
      margin-top: 10px;
    }
  }
}
.confirm-text {
  margin-top: 4px;
  @include themed() {
    color: t("iconMidGray");
  }
}

.release-assigned-number-step-one-modal {
  .modal-dialog {
    width: 492px;
    max-width: 492px;
  }
  .step-one-container {
    padding: 0px 16px;
  }
}
.release-assigned-number-step-two-modal {
  .modal-dialog {
    width: 647px;
    max-width: 647px;
  }
  .step-two-container {
    padding: 0px 16px;
  }
  .email-overflow {
    text-overflow: ellipsis;
  }
}

.release-assigned-number-section-title {
  @extend .custom-modal-steps-section-title;
  margin-bottom: 28px;
}
.step-text {
  color: #808080;
  font-size: 12px;
  text-align: left;
}
.release-assigned-number-scroll-table {
  height: 300px;
  margin: 24px 0px;

  .release-assigned-number-table-header {
    tr {
      th {
        padding-top: 9px;
        padding-bottom: 9px;
        border-top: 1px solid #dee2e6;
      }
    }
  }
}
