.merge-single-unresolved-modal {
  .modal-dialog {
    width: 700px;
    max-width: 700px;
  }
}

.merge-single-unresolved-container {
  padding: 40px 35px 25px 35px;
  h1 {
    font-weight: 400;
    font-size: 24px;
    color: #122b28;
    margin-bottom: 0;
    p {
      font-size: 24px;
      font-weight: 600;
      color: #122b28;
    }
    .avatar-conflicted {
      width: 30px;
      height: 30px;
      min-width: 30px;
      font-size: 16px;
    }
  }

  .conflicted-text {
    font-size: 16px;
    color: #707070;
    margin-top: 25px;
    max-width: 500px;
  }
}
