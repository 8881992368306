$colored-page-height: 80vh;

.error-page-wrapper {
  height: $colored-page-height;
  width: 100%;
  position: relative;
  background: #f5f0fd;
  border-bottom-left-radius: 50% 40%;
  border-bottom-right-radius: 50% 40%;

  .row,
  .container {
    height: 100%;
  }

  .logo-icon {
    width: 37px;
    height: 42px;
    //new icon
    background-image: url("../img/icons-new/people-info/pastorsline/pastorsline-purple.svg");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 35px;
    left: 35px;
    z-index: 20;
  }

  .container-error-content {
    max-width: 492px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .center-icon {
      width: 108px;
      height: 114px;
      //new icons
      background-image: url("../img/icons-new/general/message-bubble/message-bubble-filled-purple.svg");
      background-size: contain;
      background-repeat: no-repeat;
      margin-bottom: 25px;
      position: relative;

      span {
        position: absolute;
        top: 21px;
        left: 18px;
        font-size: 40px;
        color: #ffffff;
        font-weight: 400;
      }
    }

    h1 {
      text-align: center;
      font-size: 40px;
      color: #000000;
      font-weight: 500;
      margin-bottom: 20px;
    }
    p {
      text-align: center;
      margin-bottom: 40px;
      font-size: 16px;
      color: #202020;
      font-weight: 400;
      line-height: 1.2;
    }
    .dashboard-button {
      margin: 0 auto;
      border-radius: 0;
      font-weight: 600;
      max-width: 169px;
      width: 100%;
      color: $primary-light;
      border: 1px solid color $primary-light;
    }

    .dashboard-button:hover {
      color: #ffffff;
    }

    .help-text {
      font-weight: 600;
      color: $primary-light;
    }
    .help-text:hover {
      text-decoration: underline;
    }
  }
}
