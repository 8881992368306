.alert-overlay {
  align-items: center;
  background: rgba(0, 0, 0, 0.75);
  bottom: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 60px;
  padding: 30px;
  position: fixed;
  text-align: center;
  top: 0;
  z-index: 9999;
  width: 100%;
  .message {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 40px;
    max-width: 527px;
  }
  .buttons {
    margin-top: 50px;
  }
  .btn {
    background: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #000;
    font-size: 1rem;
    font-weight: 500;
    height: 36px;
    margin-top: 16px;
    width: 260px;
    &.outline {
      background: transparent;
      color: #fff;
      margin-right: 8px;
    }
    &.danger {
      background-color: #ff5800;
      border-color: #ff5800;
      color: #fff;
    }
  }
}
