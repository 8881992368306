.templates-sms-templates-container {
  @extend .container-flex-column-100;

  .sms-templates-table {
    table-layout: fixed;

    @media (max-width: $screen-md) {
      table-layout: auto;
    }

    th:first-child {
      padding-left: 40px;
    }

    .sms-templates-table-body {
      td {
        @media (max-width: $screen-md) {
          padding: 10px;
        }
      }

      td:nth-child(2) {
        @media (max-width: $screen-md) {
          max-width: 20px;
        }
      }

      td:first-child {
        padding-left: 40px;
      }

      .active {
        @include themed() {
          background: t("bgRHS");
        }
      }
    }
  }

  .sms-template-scroll-table {
    @media (max-width: $screen-md) {
      height: 80vh;
    }
  }
}

.templates-sms-templates-rhs {
  .number-container {
    font-size: 11px;

    @include themed() {
      color: t("fontDarkGray");
    }
  }

  .text-container-rhs {
    @include themed() {
      color: t("iconColor");
    }
  }

  .themed-color-primary {
    @include themed() {
      color: t("primary");
    }
  }
}

.delete-sms-template {
  .modal-dialog {
    width: 680px;
    max-width: 680px;

    @media (max-width: $screen-sm) {
      width: auto;
      max-width: auto;
    }
  }
}

.sms-template-rhs-image-container {
  @include themed() {
    background: t("bgRHS") !important;
  }
}
