.standard-form {
  .input-field {
    .label {
      display: block;
      margin-bottom: 0;
      font-size: 12px;
      color: #707070;
    }

    .custom-select {
      color: #122b28;
      font-size: 1rem;
    }

    .form-control {
      color: #122b28;
      font-size: 1rem;
    }
  }

  .form-control:not(textarea) {
    padding: 2px 0;
    height: calc(1.5em + 4px + 2px);
    border-left: 0;
    border-right: 0;
    border-top: 0;
  }

  textarea.form-control {
    border-radius: 10px;
  }

  label + textarea.form-control,
  label + .form-control-wrapper > textarea.form-control {
    margin-top: 5px;
  }

  .invalid-feedback {
    display: block;
    font-size: 80%;
    color: $secondary;
    text-align: right;
  }

  .is-invalid {
    .form-control {
      border-color: $secondary;
    }
  }
}
