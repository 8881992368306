.customize-widget-modal {
  .modal-dialog {
    width: 490px;
    max-width: 490px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
}

.customize-widget-container {
  padding: 19px 25px 35px 25px;
  font-size: 14px;
  @media (max-width: $screen-md) {
    padding: 0px;
  }

  h1 {
    @extend .custom-modal-title;
    margin-bottom: 28px;
    @media (max-width: $screen-md) {
      padding: 20px 25px 0px 25px;
    }
  }

  .customize-widget-description {
    color: #565656;
    @media (max-width: $screen-md) {
      padding: 0px 20px;
    }
  }

  .customize-widget-title-category {
    color: #808080;
  }

  .simple-text-widget-subtitle {
    color: #808080;
    font-size: 14px;
    margin-left: 14px;
    margin-top: 26px;
    @media (max-width: $screen-md) {
      margin-left: 20px;
    }
  }

  .simple-widget-list-container {
    @media (max-width: $screen-md) {
      padding: 0px 20px;
    }
  }
}

.simple-text-widget-container {
  background-color: #fff;
  box-shadow: 0px 3px 3px #00000029;
  border: 0.5px solid #c2c2c2;
  border-radius: 20px;
  height: 61px;
  padding-left: 18px;
  padding-right: 30px;
  width: 435px;
  @media (max-width: $screen-md) {
    width: auto;
  }

  .simple-text-widget-title {
    font-size: 16px;
    color: #202020;
  }

  .grid-item {
    width: calc(100% - 18px - 38px);
  }
}
