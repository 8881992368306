@import "../../base/global-theme";

.onboarding-modal {
  .modal-dialog {
    max-width: 100vw !important;
    margin: 0px !important;
    height: 100vh !important;
    @media (max-width: $screen-md) {
      width: auto !important;
      max-width: auto !important;
    }
  }

  .close-modal-icon {
    z-index: 100;
  }

  .modal-content {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    @include themed() {
      background: t("bg");
    }
    @media (max-width: $screen-md) {
      padding: 10px 20px;
    }
  }

  .sub-switch-container {
    margin-top: 10px;
    padding: 14px 10px 14px 23px;
    border-left: 1px solid #c2c2c2;
  }

  .link-text {
    cursor: pointer;
    @include themed() {
      color: t("primary");
    }
  }

  .helper-text {
    @include themed() {
      color: t("fontDarkGray");
    }
  }

  .steps-label {
    font-size: 16px;
    @include themed() {
      color: t("fontDarkGray");
    }
  }

  .steps-content-container {
    margin-top: 60px;
  }

  .onboarding-signature-step-container {
    padding: 0px !important;
    flex-direction: column !important;

    .card-info-container {
      max-width: none !important;
      width: 100% !important;
      @media (max-width: $screen-md) {
        width: auto !important;
        max-width: auto !important;
      }
    }
  }

  .adv-feature-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media (max-width: $screen-sm) {
      justify-content: center;
    }

    .adv-feature-selector-wrapper {
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 10px;
    }

    .box-selector {
      width: 230px !important;
      font-size: 0.9rem !important;
      height: 45px !important;
    }
  }

  .on-boarding-steps-heading {
    @include themed() {
      color: t("fontColor");
    }
  }

  .steps-icons {
    svg,
    path {
      @include themed() {
        fill: t("fontDarkGray") !important;
      }
    }
  }

  .extra-content-container {
    margin-left: 35px;
  }

  .small-label {
    font-size: 0.75rem;
    @include themed() {
      color: t("fontMidGray");
    }
  }

  .onboarding-step-inner-container {
    overflow-x: hidden !important;
  }

  .onboarding-main-container-desktop {
    height: 90vh !important;
  }

  .on-boarding-voice-input {
    @include themed() {
      background: t("bg") !important;
    }
  }
}
