/*
Author       : Dreamguys
Template Name: Preadmin - Bootstrap Admin Template
Version      : 1.0
*/
/*------------------------------------------------------------------

[Table of contents]

1. Common
2. Table
3. Helper Class
4. Bootstrap Classes
5. Animation
6. Header
7. Sidebar
8. Content
9. Login
10. Dashboard
11. Activity
12. Select2
13. Nav tabs
14. Holidays
15. Edit Profile
16. Chat
17. Focus Label
18. Leave
19. Employee
20. Events
21. Profile
22. Notifications
23. Roles & Permissions
24. Chat Right Sidebar
25. Projects
26. Invoice
27. Task
28. Project View
29. Payslip
30. Attendance
31. Ticket
32. Client Profile
33. Inbox
34. Mail View
35. Pricing
36. Blog
37. Blog View
38. UI Kit
39. Error
40. Lock Screen
41. Countdown
42. Voice call
43. Video Call
44. Incoming call
45. Widgets
46. Contacts
47. Notification settings
48. Add Product
49. Product
50. Product Details
51. Chat Sidebar
52. Gallery
53. Responsive
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[1. Common]
*/
@import "_global-theme.scss";

html {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  color: #333;
  overflow-x: hidden;
  height: 100%;
}
@media (max-width: 1024px) {
  body {
    font-size: 90%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

.h1,
h1 {
  font-size: 2.25rem;
}

.h2,
h2 {
  font-size: 1.875rem;
}

.h3,
h3 {
  font-size: 1.5rem;
}

.h4,
h4 {
  font-size: 1.125rem;
}

.h5,
h5 {
  font-size: 0.875rem;
}

.h6,
h6 {
  font-size: 0.75rem;
}

.form-control {
  border-radius: 0;
  box-shadow: none;
  padding: 0.469rem 0.75rem;
}

.form-control:focus {
  box-shadow: none;
  outline: 0 none;
}

a,
button,
input {
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

button,
input {
  outline: none;
}

input[type="file"] {
  height: auto;
}

input[type="password"],
input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea.form-control {
  height: auto;
  resize: vertical;
}

.navbar-nav > li {
  float: left;
}

.form-group {
  margin-bottom: 20px;
}

.display-block {
  display: block;
}

.btn.rounded {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
/*------------------------------------------------------------------
[2. Table]
*/
.table {
  color: #000;
}

.table.table-white {
  background-color: #fff;
}

.table > tbody > tr > td {
  font-weight: 300;
}

.table-striped > tbody > tr:nth-of-type(2n + 1) {
  background-color: #f6f6f6;
}

table.table td .avatar {
  margin: 0 5px 0 0;
}

table.table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

table.table td h2 a {
  color: #757575;
}

table.table td h2 a:hover {
  color: #00bf6f;
}

table.table td h2 span {
  color: #9e9e9e;
  display: block;
  font-size: 0.75rem;
  margin-top: 3px;
}
/*------------------------------------------------------------------
[3. Helper Class]
*/
.p-0 {
  padding: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.w-md {
  min-width: 110px;
}

.w-40 {
  width: 40px;
}

.block {
  display: block !important;
}

.text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  white-space: break-spaces;
  max-width: 100%;
}
.text-ellipsis-two-lines {
  @extend .text-ellipsis;
  -webkit-line-clamp: 2;
}

.text-muted-light {
  color: #aaa;
}

.card-box {
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 20px;
  position: relative;
}

.card-title {
  color: #333;
  font-size: 1rem;
  margin-bottom: 1.125rem;
}

.page-title {
  color: #565656;
  font-size: 1.3125rem;
  font-weight: normal;
  margin-bottom: 20px;
}

.page-sub-title {
  color: #565656;
  font-size: 1.125rem;
  font-weight: normal;
  margin-bottom: 20px;
}

.cal-icon::after {
  background: transparent url("../img/calander.png") no-repeat scroll 0 0;
  bottom: 0;
  content: "";
  display: block;
  height: 19px;
  margin: auto;
  position: absolute;
  right: 15px;
  top: 0;
  width: 17px;
}

.cal-icon {
  position: relative;
  width: 100%;
}

.badge-success-border {
  border: 1px solid #55ce63;
  color: #55ce63;
  background-color: #fff;
  display: inline-block;
  min-width: 80px;
}

.badge-danger-border {
  border: 1px solid #f62d51;
  color: #f62d51;
  background-color: #fff;
  display: inline-block;
  min-width: 80px;
}

.badge-warning-border {
  border: 1px solid #ffbc34;
  color: #ffbc34;
  background-color: #fff;
  display: inline-block;
  min-width: 80px;
}

.badge-info-border {
  border: 1px solid #009efb;
  color: #009efb;
  background-color: #fff;
  display: inline-block;
  min-width: 80px;
}

.modal-footer.text-left {
  text-align: left;
}

.modal-footer.text-center {
  text-align: center;
}

.font-15 {
  font-size: 15px !important;
}

.font-18 {
  font-size: 1.125rem;
}

.border-right {
  border-right: 1px solid #e7e7e7;
}

.blur {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}
/*------------------------------------------------------------------
[4. Bootstrap Classes]
*/
.btn-white {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
}

.circle,
.rounded {
  border-radius: 500px;
}

.badge-primary,
.bg-primary {
  background-color: #00bf6f !important;
}

.badge-success,
.bg-success {
  background-color: #55ce63 !important;
}

.badge-info,
.bg-info {
  background-color: #009efb !important;
}

.badge-warning,
.bg-warning {
  background-color: #ffbc34 !important;
}

.badge-danger,
.bg-danger {
  background-color: #f62d51 !important;
}

.bg-white {
  background-color: #fff;
}

.bg-blue {
  background-color: #73baef !important;
}

.dropdown-menu > li > a.text-primary,
.text-primary {
  color: #00bf6f;
}

.dropdown-menu > li > a.text-success,
.text-success {
  color: #55ce63;
}

.dropdown-menu > li > a.text-danger,
.text-danger {
  color: #f62d51;
}

.text-danger-smaller {
  color: #f62d51;
  font-size: 0.75rem;
}

.dropdown-menu > li > a.text-info,
.text-info {
  color: #009efb;
}

.dropdown-menu > li > a.text-warning,
.text-warning {
  color: #ffbc34;
}

.dropdown-menu > li > a.text-purple,
.text-purple {
  color: #7460ee;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #00bf6f;
  border-color: #00bf6f;
}

.pagination > li > a,
.pagination > li > span {
  color: #00bf6f;
}

.page-item.active .page-link {
  background-color: #00bf6f;
  border-color: #00bf6f;
}

.dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  transform-origin: left top 0;
  box-shadow: inherit;
  background-color: #fff;
  font-size: 0.8125rem;
}

.navbar-nav .open .dropdown-menu {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.modal-content {
  border-radius: 0;
}

.btn-group-xs > .btn,
.btn-xs {
  min-height: 22px;
  min-width: 22px;
}

.btn-link,
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
  box-shadow: none;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.card {
  margin-bottom: 30px;
  border: 1px solid #e7e7e7;
  box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
}

.dropdown-action .dropdown-toggle::after {
  display: none;
}

.badge.badge-pill {
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
}

.btn.btn-rounded {
  border-radius: 50px;
}

.table .badge {
  font-weight: 700;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background-color: #eee;
  border-color: transparent;
}

.nav-tabs .nav-link {
  border-radius: 0;
}

.table.dataTable {
  border-collapse: collapse !important;
}

.progress-example .progress {
  margin-bottom: 1.5rem;
}

.progress-xs {
  height: 4px;
}

.progress-sm {
  height: 15px;
}

.progress.progress-sm {
  height: 6px;
}

.progress.progress-md {
  height: 8px;
}

.progress.progress-lg {
  height: 18px;
}

.form-control.form-control-sm {
  padding: 0.25rem 0.5rem;
}
/*------------------------------------------------------------------
[5. Animation]
*/
@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(0);
    opacity: 0;
  }

  25% {
    -moz-transform: scale(0);
    opacity: 0.1;
  }

  50% {
    -moz-transform: scale(0.1);
    opacity: 0.3;
  }

  75% {
    -moz-transform: scale(0.5);
    opacity: 0.5;
  }

  100% {
    -moz-transform: scale(1);
    opacity: 0;
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.1);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.5);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@-webkit-keyframes taskHighlighter {
  0% {
    background: #feeb99;
  }

  100% {
    background: #fff;
  }
}
@keyframes taskHighlighter {
  0% {
    background: #feeb99;
  }

  100% {
    background: #fff;
  }
}
/*------------------------------------------------------------------
[8. Content]
*/
.page-wrapper {
  left: 0;
  margin-left: 59px;
  position: relative;
  -webkit-transition: margin 0.4s ease;
  -moz-transition: margin 0.4s ease;
  transition: margin 0.4s ease;
}

.page-wrapper-with-expand-sidebar {
  margin-left: 193px;
}

.top-alert ~ .page-wrapper {
  padding-top: 100px;
}
@media (min-width: 992px) {
  body.drawer-active .page-wrapper {
    margin-left: 170px;
  }

  body.drawer-active .sidebar {
    width: 170px;
  }

  body.drawer-active .sidebar-menu li a {
    // padding: 0 20px;
    text-align: left;
  }

  body.drawer-active .sidebar-menu li a span {
    display: inline-block;
  }

  body.drawer-active .sidebar-menu li a img {
    margin-right: 15px;
  }
}

.page-wrapper > .content {
  padding: 30px;
}
/*------------------------------------------------------------------
[9. Login]
*/
.account-page {
  padding: 40px 0;
}

.account-title {
  font-size: 2rem;
  font-weight: normal;
  margin: 0 0 40px;
  text-align: center;
}

.account-box {
  margin: 0 auto;
  width: 400px;
  border: 1px solid #e7e7e7;
  background-color: #fff;
}

.account-wrapper {
  padding: 30px;
}

.account-logo {
  margin-bottom: 20px;
  text-align: center;
}

.account-box .form-group {
  margin-bottom: 25px;
}

.account-box .account-btn {
  border-radius: 0;
  font-size: 1.375rem;
  padding: 10px 26px;
  border: 0;
}

.account-box .account-btn:focus,
.account-box .account-btn:hover {
  border: 0;
  opacity: 0.8;
}

.account-box a {
  color: #323232;
  font-size: 1rem;
}

.account-box a:hover {
  color: #00bf6f;
}

.account-box .form-control {
  border: 1px solid #e7e7e7;
  height: 50px;
}

.account-box label {
  color: #7f8fa4;
  font-size: 1rem;
  font-weight: normal;
}

.account-logo img {
  width: 100px;
}
/*------------------------------------------------------------------
[10. Dashboard]
*/
.card-table.card-table-top .card-body {
  height: 175px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.card-title > a.btn {
  color: #fff;
}

.custom-table tr {
  background-color: #fff;
}

.table.custom-table > tbody > tr > td,
.table.custom-table > tbody > tr > th,
.table.custom-table > tfoot > tr > td,
.table.custom-table > tfoot > tr > th,
.table.custom-table > thead > tr > td,
.table.custom-table > thead > tr > th {
  padding: 10px 8px;
  vertical-align: middle;
}

.dash-widget-icon {
  border-radius: 100%;
  color: #777;
  display: inline-block;
  float: left;
  font-size: 1.875rem;
  height: 66px;
  line-height: 66px;
  margin-right: 10px;
  text-align: center;
  width: 66px;
}

.dash-widget-info {
  text-align: right;
}

.dash-widget-info > h3 {
  font-size: 1.75rem;
  font-weight: 600;
}

.dash-widget-info > span {
  font-size: 1rem;
}

.activity-panel .activity-box {
  height: 350px;
  overflow-x: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.activity-panel .activity-list > li {
  padding: 0;
  border: 0;
}

.activity-panel .activity-list > li:last-child {
  margin-bottom: 0;
}

.activity-panel .activity-list::before {
  left: 8px;
}

.chat-panel .card-body {
  overflow-y: auto;
  height: 340px;
  -webkit-overflow-scrolling: touch;
}

.chat-panel .chats {
  padding: 30px 0;
}

.chat-panel .chat-date {
  background-color: #fff;
}

.chat-panel .message-area .input-group .form-control:focus {
  height: 44px;
  transition: inherit;
}

.task-panel .card-body {
  height: 286px;
  overflow-x: auto;
}

.task-panel .task-wrapper {
  padding: 0;
}

.member-panel .contact-list {
  border: 1px solid #eaeaea;
  margin-bottom: 15px;
}

.member-panel .contact-list > li:last-child {
  border-bottom: 0;
}

.member-panel .card-body {
  overflow-y: auto;
  height: 260px;
  -webkit-overflow-scrolling: touch;
}
/*------------------------------------------------------------------
[11. Activity]
*/
.activity-box {
  position: relative;
}

.activity-list {
  list-style: none;
  margin: 0 0 0 10px;
  padding: 0;
  position: relative;
}

.activity .activity-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.activity .activity-list > li .activity-user {
  height: 32px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 12px;
  left: 8px;
  width: 32px;
}

.activity .activity-list > li .activity-content {
  background-color: #fff;
  margin: 0 0 0 40px;
  padding: 0;
  position: relative;
}

.activity-list::before {
  background: #ddd;
  bottom: 0;
  content: "";
  left: 23px;
  position: absolute;
  top: 8px;
  width: 2px;
}

.activity .activity-list li::before {
  background: #ddd;
  bottom: 0;
  content: "";
  left: 22px;
  position: absolute;
  top: 12px;
  width: 2px;
}

.activity-list li::before {
  background: #ddd;
  bottom: 0;
  content: "";
  left: 8px;
  position: absolute;
  top: 8px;
  width: 2px;
}

.activity-list > li {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
  border: 1px solid #e7e7e7;
}

.activity-list > li:last-child .activity-content {
  margin-bottom: 0;
}

.activity-user .avatar {
  height: 32px;
  line-height: 32px;
  margin: 0;
  width: 32px;
}

.activity-list > li .activity-user {
  background: #fff;
  height: 32px;
  left: -7px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 3px;
  width: 32px;
}

.activity-list > li .activity-content {
  background-color: #fff;
  margin: 0 0 20px 40px;
  padding: 0;
  position: relative;
}

.activity-list > li .activity-content .timeline-content {
  color: #9e9e9e;
}

.activity-list > li .activity-content .timeline-content a.name {
  color: #000;
}

.activity-list > li .time {
  color: #bdbdbd;
  display: block;
  font-size: 0.75rem;
  line-height: 1.35;
}
/*------------------------------------------------------------------
[12. Select2]
*/
.select2-container .select2-selection--single {
  border: 1px solid #ccc;
  height: 40px;
  border-radius: 0;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 38px;
  right: 7px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #ccc transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #ccc;
  border-width: 0 6px 6px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 15px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #676767;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 38px;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #00bf6f;
}
/*------------------------------------------------------------------
[13. Nav tabs]
*/
.nav-tabs > li > a {
  margin-right: 0;
  color: #888;
  border-radius: 0;
}

.nav-tabs > li > a:focus,
.nav-tabs > li > a:hover {
  border-color: transparent;
  color: #333;
}

.nav-tabs.nav-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}

.nav-tabs.nav-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}

.nav-tabs.nav-tabs-solid > li > a {
  color: #333;
}

.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:focus,
.nav-tabs.nav-tabs-solid > li > a.active:hover {
  background-color: #00bf6f;
  border-color: #00bf6f;
  color: #fff;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
  border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
  border-radius: 50px;
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover {
  border-radius: 50px;
}

.nav-tabs-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}

.nav-tabs-justified > li > a:focus,
.nav-tabs-justified > li > a:hover {
  border-bottom-color: #ddd;
}

.nav-tabs-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}
/*------------------------------------------------------------------
[14. Holidays]
*/
.custom-table tr.holiday-completed {
  color: #aaa;
}

.modal-header.bg-danger .modal-title {
  color: #fff;
}

.dropdown.action-label {
  display: inline-block;
}

.action-label > a {
  display: inline-block;
  min-width: 103px;
}

.action-label .btn-sm {
  padding: 5px;
}
/*------------------------------------------------------------------
[15. Edit Profile]
*/
.profile-img-wrap {
  height: 120px;
  position: absolute;
  width: 120px;
  background: #fff;
  overflow: hidden;
}

.profile-basic {
  margin-left: 140px;
}

.profile-img-wrap img {
  width: 120px;
  height: 120px;
}

.fileupload.btn {
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(33, 33, 33, 0.5);
  border-radius: 0;
  padding: 3px 10px;
  border: none;
}

.fileupload input.upload {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 1.25rem;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: -3px;
  top: -3px;
  padding: 5px;
}

.btn-text {
  color: #fff;
}
/*------------------------------------------------------------------
[16. Chat]
*/
.chat-main-row {
  bottom: 0;
  left: 0;
  padding-bottom: inherit;
  padding-top: inherit;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  @media (max-width: $screen-md) {
    padding-top: 0px;
  }
}

.chat-main-wrapper {
  display: table;
  height: 100%;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
}

.message-view {
  display: table-cell;
  height: 100%;
  float: none;
  padding: 0;
  position: static;
  vertical-align: top;
  width: auto;
}

.chat-window {
  display: table;
  height: 100vh;
  table-layout: fixed;
  width: 100%;
  @include themed() {
    background-color: t("bg");
    border-right: 1px solid t("sideBarDividerColor");
  }

  .notification-banner-empty-chat-window {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    z-index: 1000;
  }
}

.chat-area {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.chat-area-scroll-selector {
  display: flex;
  flex-direction: column;
  flex: 1;
  .chat-contents {
    flex: 1;
    @media (max-width: $screen-md) {
      padding-right: 5px;
    }
  }
}

.people-thread-view-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  @include themed() {
    background-color: t("bg");
  }
  .people-switch-container {
    height: 42px;
  }
  .footer .chat-box {
    min-height: none !important;
  }
  .people-change-log-table {
    .table {
      @media (max-width: $screen-md) {
        margin-left: 0px !important;
      }
    }

    td,
    th {
      @media (max-width: $screen-md) {
        font-size: 12px;
        padding: 10px 5px;
      }
    }
  }

  .card-info-container {
    @media (max-width: $screen-xs) {
      max-width: 320px;
      min-width: 320px !important;
    }
  }
  .info-container {
    @media (max-width: $screen-md) {
      justify-content: center;
    }
  }
}

.fixed-header {
  @include themed() {
    background-color: t("bgRHS");
    border-bottom: 1px solid t("bg");
    color: t("text");
  }

  // border-bottom: 1px solid #eaeaea;
  padding: 10px 15px;
}

.fixed-header .navbar {
  border: 0 none;
  margin: 0;
  min-height: auto;
  padding: 0;
  justify-content: flex-start;
  @media (max-width: $screen-md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .navbar-nav {
    @media (max-width: $screen-md) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .nav-link.active {
      @media (max-width: $screen-md) {
        @include themed() {
          color: t("primary");
        }
        font-weight: 500 !important;
      }
    }
    .nav-link {
      @media (max-width: $screen-md) {
        padding-right: 20px;
        @include themed() {
          color: t("fontColor");
        }
      }
    }
  }
}

.fixed-header .navbar .btn:not(.show-filters-button__show-filters) {
  color: #707070;
  font-size: 0.8125rem;
  align-items: center;
  padding: 0;
  display: inline-flex;
  min-height: 1.5rem;

  .archive-btn {
    z-index: 1;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
  }
}

.fixed-header .navbar .btn.sort-button {
  margin: 0 45px 0 30px;
}
@media (max-width: 768px) {
  .fixed-header .navbar .btn.sort-button {
    margin: 0 25px 0 15px;
  }
}

.fixed-header .navbar .btn.sort-button.active i {
  transform: rotate(180deg);
}

.fixed-header .navbar .btn i {
  margin-left: 5px;
  transition: 0.2s ease-in-out;
}

.fixed-header .navbar .btn:focus {
  box-shadow: none;
}

.fixed-header .navbar .dropdown .dropdown-toggle {
  color: #707070;
  font-size: 0.8125rem;
  background: none;
  border: none;
  margin-left: 30px;
}
@media (max-width: 768px) {
  .fixed-header .navbar .dropdown .dropdown-toggle {
    margin-left: 3px;
  }
}

.fixed-header .navbar .dropdown .dropdown-toggle:focus {
  box-shadow: none;
}

.fixed-header .navbar .custom-control-label {
  font-size: 0.8125rem;
  color: #707070;
}

.fixed-header .navbar .custom-control-inline {
  align-items: center;
}

.custom-radio .custom-control-label::before {
  background-color: #fff;
  border: 1px solid #707070;
  top: 2px;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: transparent;
  top: 2px;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  //old icons - missing file in old icon
  background-image: url("../img/checked.svg");
  top: 2px;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.fixed-header .user-info a {
  color: #76838f;
  text-transform: uppercase;
}

.typing-text {
  color: #00bf6f;
  font-size: 0.75rem;
  text-transform: lowercase;
}

.last-seen {
  color: #a3afb7;
  display: block;
  font-size: 0.75rem;
}

.custom-menu.nav > li > a {
  color: #00bf6f;
  font-size: 1.625rem;
  margin-left: 15px;
  padding: 0;
}

.custom-menu.navbar-nav > li > a:focus,
.custom-menu.navbar-nav > li > a:hover {
  background-color: transparent;
}

.custom-menu .dropdown-menu {
  left: auto;
  right: 0;
}

.search-box .input-group {
  background-color: #e6e6e6;
  border: 1px solid #ddd;
  border-radius: 50px;
}

.search-box .input-group .form-control {
  background: transparent;
  border: none;
}

.search-box .btn {
  background-color: transparent;
}

.chat-contents {
  display: table-row;
  height: 100%;
}

.chat-content-wrap {
  height: 100%;
  position: relative;
  width: 100%;
  @include themed() {
    background: t("bg");
  }
}

.messsages-search-container {
  position: absolute;
  width: 100%;
  z-index: 10;
  @include themed() {
    background-color: t("bg");
  }
  border-bottom: 1px solid #eaeaea;
  height: 50px;
  padding: 12px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .search-count {
    color: #6c757d;
  }

  .messages-search-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .arrow-up {
      font-size: 16px;
      margin-right: 10px;
      cursor: pointer;
    }

    .arrow-chevron-down {
      font-size: 16px;
      cursor: pointer;
      border: none;
    }

    .disabled-arrow {
      color: darkgrey;
    }
  }
}

.chat-wrap-inner {
  bottom: 0;
  left: 0;
  overflow: hidden;
  overflow-y: scroll;
  position: absolute;
  right: 0;
  top: 0;
}

.chats {
  padding: 15px 20px;
}

.chat-body {
  display: block;
  margin: 10px 0 0;
  overflow: visible;
  position: relative;
}

.chat-body:first-child {
  margin-top: 0;
}

.chat-right .chat-content {
  position: relative;
  display: block;
  float: right;
  padding: 8px 15px;
  margin: 0;
  clear: both;
  background-color: #e6e6e6;
  border-radius: 20px 2px 2px 20px;
  max-width: 60%;
}

.chat-right .chat-body {
  padding-left: 48px;
  padding-right: 10px;
}

.plyr--audio .plyr__controls {
  background: none;
  color: #122b28;
  padding: 0;
  margin-left: -5px;
}

.plyr__menu {
  display: none;
}

.plyr--full-ui input[type="range"] {
  color: $primary;
}

.plyr__control svg {
  width: 12px;
  height: 12px;
  color: #707070;
}

.plyr--audio .plyr__control.plyr__tab-focus,
.plyr--audio .plyr__control:hover,
.plyr--audio .plyr__control[aria-expanded="true"] {
  background: none;
  color: #c0392b;
}

.tooltipster-sidetip .tooltipster-box {
  background: #fff;
  border: 1px solid #c0392b;
  border-radius: 7px;
  font-size: 1rem;
  font-weight: 300;
  max-width: 160px;
  text-align: center;
}

.tooltipster-sidetip .tooltipster-box .tooltipster-content {
  color: #122b28;
  padding: 9px 10px;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
  border-top-color: #c0392b;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border {
  border-top-color: #c0392b;
  display: none;
}

.chat-left .chat-body .tip-info {
  position: relative;
}

.chat-footer .date-picker-wrapper .input-group-addon img {
  width: 30px;
}

.chat-footer .blocked-overly {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.chat-footer .blocked-overly p {
  margin: 7px 0 0;
}

.profile-right {
  display: table-cell;
  height: 100%;
  float: none;
  padding: 0;
  position: static;
  vertical-align: top;
}

.display-table {
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1;
}

.close-icon-topbar {
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;
  outline: none;
  // border-left: 1px solid #e1e1e1;
  &.edit-close-topbar {
    justify-content: space-between;
  }
}

.close-btn-topbar,
.edit-btn-topbar,
.edit-btn-topbar:active,
.close-btn-topbar:active {
  background: none;
  border: none;
  outline: none;
}
.edit-btn-topbar {
  color: #5517c1;
}

.table-row {
  display: table-row;
  height: 100%;
}

.table-body {
  position: relative;
  height: 100%;
  width: 100%;
}

.table-content {
  bottom: 0;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.profile-right-inner {
  border-left: 1px solid #eaeaea;
  background-color: #fff;
}

.chat-profile-img {
  padding: 30px;
  position: relative;
  text-align: center;
}

.edit-profile-img {
  height: 120px;
  margin: 0 auto;
  position: relative;
  width: 120px;
  cursor: pointer;
}

.change-img {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: #fff;
  display: none;
  height: 100%;
  left: 0;
  line-height: 120px;
  position: absolute;
  top: 0;
  width: 100%;
}

.edit-profile-img:hover .change-img {
  display: block;
}

.edit-profile-img img {
  height: auto;
  margin: 0;
  width: 120px;
  border-radius: 50%;
}

.user-name {
  color: #333;
}

.edit-btn {
  border-radius: 40px;
  height: 36px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 36px;
}

.vertical-bar {
  @include themed() {
    color: t("primary");
  }
}

.chat-profile-info {
  padding: 15px;
}

.user-det-list {
  list-style: none;
  padding: 0;
}

.user-det-list > li {
  padding: 6px 15px;
}

.user-det-list .text-muted {
  color: #888;
}

.transfer-files .tab-content {
  padding-top: 0;
}

.files-list {
  list-style: none;
  padding-left: 0;
}

.files-list > li {
  border-bottom: 1px solid #eaeaea;
  border-radius: inherit;
  margin: 2px 0 0;
  padding: 10px;
}

.files-cont {
  position: relative;
}

.file-type {
  height: 48px;
  position: absolute;
  width: 48px;
}

.files-icon {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  height: 38px;
  line-height: 38px;
  text-align: center;
  width: 38px;
}

.files-icon i {
  color: #777;
  font-size: 1.25rem;
}

.files-info {
  padding: 0 30px 0 50px;
}

.file-name a {
  color: #333;
}

.files-action {
  display: none;
  height: 30px;
  list-style: none;
  padding-left: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 10px;
  width: 30px;
}

.files-list > li:hover .files-action {
  display: block;
}

.file-date {
  color: #888;
  font-size: 0.75rem;
}

.file-author a {
  color: #00bf6f;
  font-size: 0.75rem;
  text-decoration: underline;
}

.files-action .dropdown-menu {
  left: auto;
  right: 0;
}

.chat-img-attach {
  border-radius: 4px;
  margin-bottom: 12px;
  margin-left: 12px;
  position: relative;
  float: right;
}

.chat-img-attach img {
  border-radius: 4px;
  display: inline-block;
  height: auto;
  max-width: 100%;
}

.chat-placeholder {
  background: rgba(69, 81, 97, 0.6);
  border-radius: 4px;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  padding: 13px;
  position: absolute;
  top: 0;
  width: 100%;
}

.chat-img-name {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 16px;
}

.chat-file-desc {
  font-size: 0.6875rem;
}

.chat-right .chat-content.img-content {
  background-color: transparent;
  color: #76838f;
  padding: 0;
  text-align: right;
}

.chat-right .img-content .chat-time {
  color: #a3afb7;
}

.chat-left .chat-img-attach {
  float: left;
  margin-left: 0;
  margin-right: 12px;
}

.chat-avatar-sm {
  width: 24px;
  margin-right: 10px;
  display: inline-block;
  position: relative;
  float: left;
}

.chat-avatar-sm img {
  width: 24px;
}

.chat-avatar-sm.user-img .status {
  bottom: 4px;
  right: -3px;
}

.files-share-list {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 15px;
}

.chat-user-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.chat-user-list > li {
  border-bottom: 1px solid #eaeaea;
  border-radius: inherit;
  margin: 5px 0 0;
  padding: 10px;
  background-color: #fff;
}

.designation {
  color: #9e9e9e;
  font-size: 0.75rem;
}

.online-date {
  color: #9e9e9e;
  font-size: 0.75rem;
}

.drop-zone {
  width: 100%;
  height: 205px;
  border: 2px dashed #adb7be;
  text-align: center;
  padding: 25px 0 0;
  margin-bottom: 20px;
}

.drop-zone .drop-zone-caption {
  font-weight: 600;
}

.upload-list {
  padding: 0;
  list-style: none;
  margin: 0;
}

.upload-list .file-list {
  background-color: #fff;
  border-top: 1px solid #d8e2e7;
  padding: 10px;
}

.upload-list .file-list:first-child {
  border-top: none;
}

.upload-list .upload-wrap {
  position: relative;
  padding: 0 20px 0 0;
  margin: 0 0 5px;
}

.upload-list .file-name,
.upload-list .file-size {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}

.upload-list .file-name {
  padding-right: 15px;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.upload-list .file-size {
  color: #888;
}

.upload-list .file-close {
  border: none;
  background: none;
  color: #dbe4ea;
  position: absolute;
  right: 0;
  top: 2px;
}

.upload-list .file-close:hover {
  color: #f62d51;
}

.upload-list .upload-process {
  font-size: 0.625rem;
  color: #888;
}

.upload-list .progress {
  margin-bottom: 5px;
  background-color: #f6f8fa;
  height: 5px;
}

.upload-list .file-name i {
  color: #fda75c;
  margin: 0 5px 0 0;
  vertical-align: middle;
}

.upload-drop-zone {
  height: 200px;
  border-width: 2px;
  margin-bottom: 20px;
  color: #ccc;
  border-style: dashed;
  border-color: #adb7be;
  line-height: 200px;
  text-align: center;
  background-color: #fff;
}

.upload-drop-zone.drop {
  color: #222;
  border-color: #222;
}

.upload-text {
  font-size: 1.5rem;
  margin-left: 10px;
}
/*------------------------------------------------------------------
[17. Focus Label]
*/
.form-focus {
  display: flex;
  height: 50px;
  position: relative;
}

.form-focus .focus-label {
  font-size: 1rem;
  font-weight: 400;
  opacity: 0.4;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate3d(0, 22px, 0) scale(1);
  -ms-transform: translate3d(0, 22px, 0) scale(1);
  -o-transform: translate3d(0, 22px, 0) scale(1);
  transform: translate3d(0, 22px, 0) scale(1);
  transform-origin: left top;
  transition: 240ms;
  left: 12px;
  top: -8px;
  z-index: 1;
  color: #888;
}

.form-focus.focused .focus-label {
  opacity: 1;
  font-weight: 300;
  top: -14px;
  font-size: 0.75rem;
  z-index: 1;
}

.form-focus .form-control {
  height: 50px;
  padding: 21px 12px 6px;
}

.form-focus .form-control::-webkit-input-placeholder {
  color: transparent;
  transition: 240ms;
}

.form-focus .form-control:focus::-webkit-input-placeholder {
  transition: none;
}

.form-focus.focused .form-control::-webkit-input-placeholder {
  color: #bbb;
}

.profile-basic .cal-icon {
  width: 100%;
}

.form-focus .select2-container .select2-selection--single {
  border: 1px solid #ccc;
  height: 50px;
  border-radius: 0;
}

.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 48px;
  right: 7px;
}

.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #ccc transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.form-focus
  .select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #ccc;
  border-width: 0 6px 6px;
}

.form-focus
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 12px;
  padding-top: 10px;
}

.form-focus
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #676767;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 38px;
}

.form-focus
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #fc6075;
}
/*------------------------------------------------------------------
[18. Leave]
*/
.filter-row .btn {
  border-radius: 0;
  min-height: 50px;
  padding: 12px;
  text-transform: uppercase;
}

.form-focus.select-focus .focus-label {
  opacity: 1;
  font-weight: 300;
  top: -20px;
  font-size: 0.75rem;
  z-index: 1;
}

.action-label .label {
  display: inline-block;
  min-width: 85px;
  padding: 0.5em 0.6em;
}

.action-label i {
  margin-right: 3px;
}

.action-label .dropdown-menu > li > a {
  padding: 3px 10px;
}
/*------------------------------------------------------------------
[19. Employee]
*/
.action-icon {
  color: #777;
  font-size: 1.125rem;
  padding: 0 10px;
  display: inline-block;
}

.table .dropdown-menu {
  font-size: 0.75rem;
}

.profile-widget {
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.profile-widget .user-name > a {
  color: #333;
}

.dropdown.profile-action {
  position: absolute;
  right: 5px;
  text-align: right;
  top: 10px;
}

.profile-action .dropdown-toggle::after {
  display: none;
}

.profile-img {
  cursor: pointer;
  height: 80px;
  margin: 0 auto;
  position: relative;
  width: 80px;
}

.profile-img .avatar {
  font-size: 1.5rem;
  height: 80px;
  line-height: 80px;
  margin: 0;
  width: 80px;
}

.mobile-no > a {
  color: #777;
  display: inline-block;
}

.staff-mail > a {
  color: #777;
  display: inline-block;
  margin-top: 5px;
}

.staff-action-btns {
  margin-top: 10px;
}

.staff-id {
  margin-top: 5px;
}

.view-icons {
  float: right;
  margin-right: 10px;
}

.view-icons .btn {
  color: #888;
  font-size: 1.125rem;
  margin-right: 5px;
  padding: 4px;
}

.view-icons .btn.active {
  color: #333;
}
/*------------------------------------------------------------------
[20. Events]
*/
.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-view {
  margin-top: 30px;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar h2 {
  font-size: 1.125rem;
  font-weight: 600;
  font-family: "Fira Sans", sans-serif;
  line-height: 30px;
  text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
  font-family: "Fira Sans", sans-serif;
}

.fc-day {
  background: #fff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar .ui-state-hover,
.fc-toolbar button:focus,
.fc-toolbar button:hover {
  z-index: 0;
}

.fc th.fc-widget-header {
  background: #eeeeee;
  font-size: 0.875rem;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
  border-color: #f3f3f3;
}

.fc-button {
  background: #f1f1f1;
  border: none;
  color: #797979;
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin: 0 3px !important;
  padding: 6px 12px !important;
  height: auto !important;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem;
}

.fc-state-hover {
  background: #f3f3f3;
}

.fc-state-highlight {
  background: #f0f0f0;
}

.fc-state-active,
.fc-state-disabled,
.fc-state-down {
  background-color: #00bf6f;
  color: #fff;
  text-shadow: none;
}

.fc-cell-overlay {
  background: #f0f0f0;
}

.fc-unthemed .fc-today {
  background: #fff;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 1px 7px;
  padding: 5px;
  text-align: center;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
  font-weight: 700;
  font-family: "Fira Sans", sans-serif;
}

.fc-basic-view td.fc-day-number {
  padding-right: 8px;
  font-weight: 700;
  font-family: "Fira Sans", sans-serif;
}
/*------------------------------------------------------------------
[21. Profile]
*/
.profile-details {
  text-align: center;
}

.personal-info li .title {
  color: #515365;
  float: left;
  font-weight: 500;
  margin-right: 30px;
  width: 30%;
}

.personal-info li .text {
  color: #888da8;
  display: block;
  overflow: hidden;
}

.personal-info li {
  margin-bottom: 10px;
}

.personal-info {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.profile-view .profile-img-wrap {
  height: 150px;
  width: 150px;
}

.profile-view .profile-img {
  width: 150px;
  height: 150px;
}

.profile-view .profile-img .avatar {
  font-size: 1.5rem;
  height: 150px;
  line-height: 150px;
  margin: 0;
  width: 150px;
}

.profile-view .profile-basic {
  margin-left: 170px;
}

.staff-msg {
  margin-top: 30px;
}

.experience-box {
  position: relative;
}

.experience-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.experience-list::before {
  background: #ddd;
  bottom: 0;
  content: "";
  left: 8px;
  position: absolute;
  top: 8px;
  width: 2px;
}

.experience-list > li {
  position: relative;
}

.experience-list > li:last-child .experience-content {
  margin-bottom: 0;
}

.experience-user .avatar {
  height: 32px;
  line-height: 32px;
  margin: 0;
  width: 32px;
}

.experience-list > li .experience-user {
  background: #fff;
  height: 10px;
  left: 4px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 4px;
  width: 10px;
}

.experience-list > li .experience-content {
  background-color: #fff;
  margin: 0 0 20px 40px;
  padding: 0;
  position: relative;
}

.experience-list > li .experience-content .timeline-content {
  color: #9e9e9e;
}

.experience-list > li .experience-content .timeline-content a.name {
  color: #616161;
  font-weight: bold;
}

.experience-list > li .time {
  color: #bdbdbd;
  display: block;
  font-size: 0.75rem;
  line-height: 1.35;
}

.before-circle {
  background-color: #ddd;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.skills > span {
  border: 1px solid #ccc;
  border-radius: 500px;
  display: block;
  margin-bottom: 10px;
  padding: 6px 12px;
  text-align: center;
}

.profile-info-left {
  border-right: 2px dashed #ccc;
}
/*------------------------------------------------------------------
[23. Roles & Permissions]
*/
.roles-menu {
  margin-top: 20px;
}

.roles-menu > ul {
  background-color: #fff;
  padding: 0;
  margin: 0;
  list-style: none;
}

.roles-menu > ul > li {
  display: block;
  position: relative;
}

.roles-menu > ul > li a {
  border-left: 3px solid transparent;
  color: #333;
  display: block;
  padding: 10px 15px;
  position: relative;
}

.roles-menu > ul > li.active a {
  border-color: #00bf6f;
  color: #00bf6f;
}

.roles-menu > ul > li a:hover {
  border-color: #00bf6f;
  background-color: #eee;
}

.material-switch > input[type="checkbox"] {
  display: none;
}

.material-switch > label {
  cursor: pointer;
  height: 0;
  position: relative;
  width: 40px;
}

.material-switch > label::before {
  background: rgb(0, 0, 0);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: "";
  height: 16px;
  margin-top: -8px;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}

.material-switch > label::after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  content: "";
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}

.material-switch > input[type="checkbox"]:checked + label::before {
  background: inherit;
  opacity: 0.5;
}

.material-switch > input[type="checkbox"]:checked + label::after {
  background: inherit;
  left: 20px;
}

.role-action {
  float: right;
  display: none;
}

.roles-menu > ul > li a:hover .role-action {
  display: block;
}

.slide-nav .sidebar {
  margin-left: 0;
}
/*------------------------------------------------------------------
[24. Chat Right Sidebar]
*/
.fixed-header .custom-menu {
  margin: 1px 0 0;
}

.topnav-dropdown-footer {
  border-top: 1px solid #eee;
  a {
    font-size: 14px;
  }
}

.notification-box {
  bottom: 0;
  margin-right: -300px;
  position: fixed;
  right: 0;
  top: 60px;
  transition: all 0.4s ease 0s;
  width: 300px;
  z-index: 101;
  border-left: 1px solid #ddd;
}

.open-msg-box .notification-box {
  margin-right: 0;
}

.msg-sidebar {
  background-color: #fff;
}

.list-box {
  list-style: none;
  padding-left: 0;
}

.list-item.new-message {
  background-color: rgba(0, 0, 0, 0.2);
}

.list-item {
  border-bottom: 1px solid #eaeaea;
  padding: 10px;
  position: relative;
}

.list-left {
  height: 48px;
  position: absolute;
  width: 48px;
}

.list-body {
  padding: 0 0 0 50px;
}

.message-author {
  color: #333;
  float: left;
  font-weight: bold;
  width: 175px;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.message-time {
  color: #888;
  float: right;
  font-size: 0.6875rem;
}

.message-content {
  color: #333;
  font-size: 0.8125rem;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*------------------------------------------------------------------
[25. Projects]
*/
.team-members {
  display: flex;
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.team-members li {
  float: left;
}

.team-members li:first-child a {
  margin-left: 0;
}

.team-members a {
  border: 2px solid #fff;
  border-radius: 100%;
  display: block;
  height: 38px;
  overflow: hidden;
  width: 38px;
}

.team-members .all-users {
  line-height: 36px;
  opacity: 0.8;
}

.all-users {
  background-color: #ff5e3a;
  color: #fff;
  font-size: 0.625rem;
  font-weight: 800;
  line-height: 34px;
  text-align: center;
}

.team-members img {
  width: 100%;
}

.project-title {
  margin: 0 0 5px;
}

.project-title > h5 {
  font-size: 1rem;
  margin-bottom: 0;
  margin-top: 0;
}

.project-title > a {
  color: #333;
}

.project-box {
  position: relative;
}
/*------------------------------------------------------------------
[26. Invoice]
*/
.invoice-details,
.invoice-payment-details > li span {
  float: right;
  text-align: right;
}

.table .team-members a {
  width: 32px;
  height: 32px;
}

.table .team-members .all-users {
  line-height: 30px;
}

.attach-files > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.attach-files li {
  display: inline-block;
  margin-right: 10px;
  position: relative;
}

.attach-files li img {
  width: 50px;
}

.file-remove {
  color: #f00;
  position: absolute;
  right: -6px;
  top: -7px;
}

.attach-files li:last-child {
  margin-right: 0;
}

.inv-logo {
  height: auto;
  margin-bottom: 20px;
  max-height: 100px;
  width: auto;
}
/*------------------------------------------------------------------
[27. Task]
*/
.task-chat-contents {
  background-color: #fff;
}

.task-chat-contents .chat-left .chat-content {
  max-width: 100%;
}

.task-chat-view {
  border-left: 1px solid #eaeaea;
}

.task-left-sidebar {
  width: 58.3333%;
}

.task-right-sidebar {
  width: 41.6667%;
}

.task-chat-view .chat-left .chat-content {
  border: 0;
  padding: 0;
}

.task-chat-view .chat-left .chat-body {
  margin-left: 50px;
}

.task-chat-view .chat-date {
  background-color: #fff;
}

.task-chat-view .chats {
  padding: 15px 15px 30px;
}

.task-chat-view .avatar {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.followers-add {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  color: #ccc;
  display: inline-block;
  font-size: 1.25rem;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
}

.followers-add:hover {
  border: 1px solid #8c8c8c;
  color: #8c8c8c;
}

.completed-task-msg {
  margin-bottom: 30px;
  margin-left: 50px;
}

.task-chat-contents .chat.chat-left {
  margin-bottom: 20px;
}

.file-attached {
  color: #333;
  font-size: 0.8125rem;
}

.file-attached i {
  font-size: 1rem;
}

.task-attach-img > img {
  border: 1px solid #eaeaea;
  max-width: 100%;
}

.attach-img-download {
  margin-bottom: 5px;
}

.task-chat-view .chat-left .chat-time {
  color: #a3afb7;
  display: inline-block;
  font-size: 0.6875rem;
}

.task-chat-user {
  color: #333;
  font-size: 0.8125rem;
}

.task-chat-view .chat-content > p {
  font-size: 0.8125rem;
  margin-bottom: 0;
}

.task-time {
  color: #a3afb7;
  display: inline-block;
  font-size: 0.6875rem;
}

.task-success {
  color: #55ce63;
  font-size: 0.8125rem;
}

.task-success a {
  color: #55ce63;
}

.task-attach-img {
  margin-bottom: 20px;
}

.task-assign {
  float: left;
}

.assign-title {
  float: left;
  margin-right: 10px;
  margin-top: 5px;
}

.task-assign > a {
  float: left;
}

.followers-add > i {
  line-height: 28px;
}

.task-followers {
  display: flex;
  margin-top: 15px;
}

.followers-title {
  margin: 5px 10px 0 0;
}

.task-line {
  border-color: #eaeaea;
}

.task-information {
  color: #999;
  font-size: 0.6875rem;
  line-height: 17px;
  min-width: 1px;
  margin-left: 50px;
}

.task-info-line {
  overflow-wrap: break-word;
}

.task-user {
  color: #888;
  font-weight: 500;
}

.task-information .task-time {
  display: inline-block;
  padding-left: 10px;
}

.message-area .input-group .form-control:focus {
  height: 150px;
  transition: all 0.5s ease 0s;
}

.task-btn-right {
  display: none !important;
}

.task:hover .task-btn-right {
  display: table-cell !important;
}

.task-action-btn.task-check {
  text-align: left !important;
  width: 40px;
}

.add-task-btn {
  padding: 6px 10px;
  text-transform: uppercase;
}

.action-circle {
  background: transparent;
  border: 1px solid #ccc;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.action-circle .material-icons {
  color: #ccc;
  font-size: 1.125rem;
  vertical-align: -3px;
}

.action-circle.completed {
  background: #35ba67;
  border: 1px solid #2fa65c;
}

.action-circle.completed .material-icons {
  color: #fff;
}

.action-circle.large {
  height: 24px;
  width: 24px;
}

.action-circle.large .material-icons {
  font-size: 1rem;
  vertical-align: -4px;
}

.task-wrapper {
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.task-wrapper .mark-all-tasks {
  text-align: right;
  padding-bottom: 15px;
}

.task-wrapper .mark-all-tasks .mark-all-tasks-container {
  overflow: hidden;
  position: relative;
}

.task-wrapper .mark-all-tasks .mark-all-btn {
  cursor: pointer;
  display: inline-block;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-finished {
  color: #35ba67;
}

.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-incomplete {
  color: #666;
}

.task-wrapper .mark-all-tasks .mark-all-btn#mark-all-incomplete .action-circle {
  border: 1px solid #666;
}

.task-wrapper
  .mark-all-tasks
  .mark-all-btn#mark-all-incomplete
  .action-circle
  .material-icons {
  color: #666;
}

.task-wrapper .mark-all-tasks .mark-all-btn.move-up {
  -webkit-transform: translateY(-30px);
  -moz-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  -o-transform: translateY(-30px);
  transform: translateY(-30px);
  position: absolute;
  right: 0;
}

.task-wrapper .mark-all-tasks .mark-all-btn.move-down {
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
  position: absolute;
  right: 0;
}

.task-wrapper .task-list-footer h3,
.task-wrapper .task-list-header h3 {
  font-size: 1.5rem;
  font-weight: 400;
  color: #666;
}

.task-wrapper .task-list-body {
  max-height: 500px;
  overflow: auto;
}

.task-wrapper #task-list {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #eaeaea;
}

.task-wrapper #task-list li {
  margin: 0;
  padding: 0;
}

.task-wrapper #task-list li .task-container {
  display: table;
  background: #fff;
  padding: 8px 15px;
  width: 100%;
  border: 1px solid #eaeaea;
  border-bottom: none;
  box-sizing: border-box;
  position: relative;
  -webkit-transition: background 200ms ease;
  -moz-transition: background 200ms ease;
  -ms-transition: background 200ms ease;
  -o-transition: background 200ms ease;
  transition: background 200ms ease;
}

.task-wrapper #task-list li .task-container .task-label {
  display: table-cell;
  font-weight: 400;
  vertical-align: middle;
  color: #333;
  word-break: break-all;
}

.task-wrapper #task-list li .task-container .task-action-btn {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
}

.task-wrapper
  #task-list
  li
  .task-container
  .task-action-btn
  .action-circle:hover {
  border: 1px solid #8c8c8c;
  background: #fff;
}

.task-wrapper
  #task-list
  li
  .task-container
  .task-action-btn
  .action-circle:hover
  .material-icons {
  color: #8c8c8c;
}

.task-wrapper #task-list li .task-container:hover {
  background: #f3f3f3;
}

.task-wrapper #task-list li.completed .task-container {
  background: #f3f3f3;
}

.task-wrapper #task-list li.completed .task-container .complete-btn {
  background: #35ba67;
  border: 1px solid #2fa65c;
}

.task-wrapper
  #task-list
  li.completed
  .task-container
  .complete-btn
  .material-icons {
  color: #fff;
}

.task-wrapper #task-list li.completed .task-container .complete-btn:hover {
  background: #35ba67;
  border: 1px solid #2fa65c;
}

.task-wrapper
  #task-list
  li.completed
  .task-container
  .complete-btn:hover
  .material-icons {
  color: #fff;
}

.task-wrapper #task-list li.completed .task-container .task-label {
  color: #ccc;
}

.task-wrapper #task-list li.new .task-container {
  -webkit-animation: taskHighlighter 2000ms linear 1;
  animation: taskHighlighter 2000ms linear 1;
}

.task-wrapper .task-list-footer {
  position: relative;
}

.task-wrapper .task-list-footer .add-task-btn-wrapper {
  text-align: center;
}

.task-wrapper .task-list-footer .add-task-btn {
  cursor: pointer;
  border: 2px solid #9b1bcc;
  display: inline-block;
  height: 35px;
  width: 35px;
  border-radius: 10px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.task-wrapper .task-list-footer .add-task-btn.hide {
  opacity: 1;
  visibility: visible;
  display: block;
}

.task-wrapper .task-list-footer .add-task-btn:hover {
  background: #9b1bcc;
}

.task-wrapper .task-list-footer .add-task-btn:hover .material-icons {
  color: #ebeeef;
}

.task-wrapper .task-list-footer .add-task-btn .material-icons {
  color: #9b1bcc;
  font-size: 2.125rem;
}

.task-wrapper .task-list-footer .new-task-wrapper {
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  max-height: 0;
  overflow: hidden;
}

.task-wrapper .task-list-footer .new-task-wrapper::after {
  content: "";
  display: block;
  clear: both;
}

.task-wrapper .task-list-footer .new-task-wrapper textarea {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #eaeaea;
  border-top: 0;
  height: 45px;
  resize: none;
  padding: 10px 15px;
  margin-bottom: 20px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.task-wrapper .task-list-footer .new-task-wrapper textarea:focus {
  border: 1px solid #bfbfbf;
  outline: none;
}

.task-wrapper .task-list-footer .new-task-wrapper textarea.error {
  border: 1px solid #d93737;
  outline: none;
}

.task-wrapper .task-list-footer .new-task-wrapper .error-message {
  color: #d93737;
  font-style: italic;
}

.task-wrapper .task-list-footer .new-task-wrapper .btn {
  padding: 10px 15px;
  float: right;
  cursor: pointer;
}

.task-wrapper .task-list-footer .new-task-wrapper .add-new-task-btn {
  background: #fff;
  border: 1px solid #ccc;
}

.task-wrapper .task-list-footer .new-task-wrapper.visible {
  max-height: 300px;
  overflow: auto;
}

.notification-popup {
  z-index: 1051;
  position: fixed;
  top: 20px;
  right: 10px;
  width: 300px;
  display: inline-block;
  background: #1f8fef;
  border: 1px solid #1082e4;
  color: #fff;
  padding: 20px;
  opacity: 0.8;
  border-radius: 2px;
  box-sizing: border-box;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.notification-popup.success {
  background: #35ba67;
  border: 1px solid #2fa65c;
}

.notification-popup p {
  margin-top: 0;
  margin-bottom: 0;
}

.notification-popup .task {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
}

.notification-popup .notification-text {
  font-size: 0.875rem;
  display: inline-block;
  overflow: hidden;
}

.notification-popup.hide {
  opacity: 0;
  visibility: hidden;
}

.sidebar-menu.opened {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
  margin-left: 0;
}

html.menu-opened {
  overflow: hidden;
}

html.menu-opened body {
  overflow: hidden;
}

.task-chat-view.opened {
  margin-right: 0;
}

.chat-profile-view.opened {
  margin-right: 0;
}

.menu-opened .message-view.task-view {
  width: 100%;
}
/*------------------------------------------------------------------
[28. Project View]
*/
.project-user .list-box {
  margin: 0;
}

.project-files i {
  color: #76838f;
  font-size: 3.125rem;
}

.project-user .list-item {
  border: 0 none;
  padding: 10px 0;
}

.table-border {
  border: 1px solid #ddd;
}

.file-size {
  color: #888;
}

.project-task .task-wrapper {
  padding: 0;
}

.project-task .tab-content {
  min-height: 200px;
}

.uploaded-box {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: block;
  margin-bottom: 20px;
  padding: 4px;
}

.uploaded-box .uploaded-img-name {
  color: #333;
  padding: 9px;
  text-align: center;
}
/*------------------------------------------------------------------
[29. Payslip]
*/
.payslip-title {
  margin-bottom: 20px;
  text-align: center;
  text-decoration: underline;
  text-transform: uppercase;
}
/*------------------------------------------------------------------
[30. Attendance]
*/
.half-day {
  width: 15px;
}
/*------------------------------------------------------------------
[31. Ticket]
*/
.ticket-header {
  padding: 6px 0;
}

.ticket-priority {
  font-size: 0.875rem;
}

.ticket-chat-view {
  width: 33.3333%;
}

.dataTables_wrapper.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
/*------------------------------------------------------------------
[32. Client Profile]
*/
.tab-box {
  border-bottom: 0 none;
  padding: 0;
}

.team-members a.followers-add {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  color: #ccc;
  display: inline-block;
  font-size: 1.25rem;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
}
/*------------------------------------------------------------------
[33. Inbox]
*/
.table-inbox input[type="radio"],
.table-inbox input[type="checkbox"] {
  cursor: pointer;
}

.mail-list {
  list-style: none;
  padding: 0;
}

.mail-list > li > a {
  color: #333;
  display: block;
  padding: 10px;
}

.mail-list > li.active > a {
  color: #00bf6f;
  font-weight: bold;
}

.unread .mail-date,
.unread .name,
.unread .subject {
  color: #000;
  font-weight: 600;
}

.table-inbox .fa-star {
  color: #ffd200;
}

.table-inbox .starred.fa-star {
  color: #ffd200;
}

.table.table-inbox > tbody > tr > td,
.table.table-inbox > tbody > tr > th,
.table.table-inbox > tfoot > tr > td,
.table.table-inbox > tfoot > tr > th,
.table.table-inbox > thead > tr > td,
.table.table-inbox > thead > tr > th {
  border-bottom: 1px solid #f2f2f2;
  border-top: 0;
}

.table-inbox {
  font-size: 0.875rem;
  margin-bottom: 0;
}

.note-editor.note-frame {
  border: 1px solid #ccc;
  box-shadow: inherit;
}

.note-editor.note-frame .note-statusbar {
  background-color: #fff;
}

.note-editor.note-frame.fullscreen {
  top: 60px;
}

.mail-title {
  font-weight: bold;
  text-transform: uppercase;
}

.form-control.search-message {
  border-radius: 4px;
  margin-left: 5px;
  width: 180px;
  padding: 0.375rem 0.75rem;
}

.table-inbox tr {
  cursor: pointer;
}

table.table-inbox tbody tr.checked {
  background-color: #ffffcc;
}

.mail-label {
  margin-right: 5px;
}
/*------------------------------------------------------------------
[34. Mail View]
*/
.attachments {
  list-style: none;
  margin: 0;
  padding: 0;
}

.attachments li {
  border: 1px solid #eee;
  float: left;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 200px;
}

.attach-file {
  color: #777;
  font-size: 4.38rem;
  padding: 10px;
  text-align: center;
  min-height: 153px;
}

.attach-file > i {
  line-height: 133px;
}

.attach-info {
  background-color: #f4f4f4;
  padding: 10px;
}

.attach-filename {
  color: #777;
  font-weight: bold;
}

.attach-filesize {
  color: #999;
  font-size: 0.75rem;
}

.attach-file img {
  height: auto;
  max-width: 100%;
}

.mailview-header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 15px;
}

.mailview-footer {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 15px;
}

.mailview-footer .btn-white {
  min-width: 102px;
}

.sender-img {
  float: left;
  margin-right: 10px;
  width: 40px;
}

.sender-name {
  display: block;
}

.receiver-name {
  color: #777;
}

.right-action {
  text-align: right;
}

.mail-view-title {
  font-weight: 500;
  font-size: 1.5rem;
  margin: 0;
}

.mail-view-action {
  float: right;
}

.mail-sent-time {
  float: right;
}
/*------------------------------------------------------------------
[35. Pricing]
*/
.pricing-box {
  background-color: #fff;
  border: 1px solid #e7e7e7;
  padding: 30px;
  text-align: center;
  margin-bottom: 30px;
}

.pricing-title {
  margin: 0 0 20px;
  text-transform: uppercase;
  background-color: #f6f6f6;
  padding: 20px;
  font-size: 1.25rem;
}

.pricing-box ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
  text-align: left;
}

.pricing-box ul li {
  line-height: 35px;
}

.pricing-box ul li i {
  color: #00bf6f;
  margin-right: 5px;
}

.table-cell {
  display: table-cell;
  position: relative;
  vertical-align: middle;
}

.add-pricing {
  height: 420px;
  border: 2px dashed #e4e4e4;
}

.btn.add-price-btn {
  width: 80px;
  height: 80px;
  font-size: 1.25rem;
  border-color: #e7e7e7;
  color: #333;
  background-color: #f0f0f0;
  line-height: 68px;
}
/*------------------------------------------------------------------
[36. Blog]
*/
.blog {
  position: relative;
  margin: 0 0 50px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  padding: 20px;
}

.blog-image {
  overflow: hidden;
  margin-bottom: 30px;
}

.blog-image,
.blog-image > a,
.blog-image img {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}

.blog-image img {
  -moz-transform: scaleY(1);
  -webkit-transform: scaleY(1);
  -o-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

.blog-image a:hover img {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.blog-content {
  position: relative;
}

.blog-title {
  color: #000;
  font-weight: normal;
  margin: 0 0 20px;
}

.blog-content p {
  color: #282628;
  font-size: 0.875rem;
  line-height: 25px;
  margin: 0 0 25px;
}

.read-more {
  color: #282628;
  display: inline-block;
  font-size: 0.875rem;
  margin-bottom: 20px;
}

.read-more:hover {
  color: #00bf6f;
  margin-left: 10px;
  -moz-transition: all 0.2s linear 0s;
  -ms-transition: all 0.2s linear 0s;
  -o-transition: all 0.2s linear 0s;
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
}

.read-more i {
  color: #00bf6f;
  font-size: 1.125rem;
  margin-right: 3px;
}

.blog-info {
  border: 1px solid #eaeaea;
  color: #909090;
  font-size: 0.75rem;
  margin-bottom: 0;
  padding: 12px 20px;
}

.blog-info a {
  color: #909090;
  margin-right: 5px;
}

.blog-info a:last-child {
  margin-right: 0;
}

.blog-info a:hover {
  color: #00bf6f;
}

.blog-info i {
  color: #00bf6f;
  font-size: 1.125rem;
  margin-right: 5px;
}

.post-left {
  float: left;
}

.post-right {
  float: right;
}

.post-left ul {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.post-left ul li {
  float: left;
  margin-right: 20px;
}

.post-left ul li:last-child {
  margin-right: 0;
}

.video {
  position: relative;
  padding-bottom: 56%;
  height: 0;
  overflow: hidden;
}

.video embed,
.video iframe,
.video object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.widget {
  margin-bottom: 30px;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #e7e7e7;
}

.widget h5 {
  color: #656565;
  font-size: 1.31rem;
  font-weight: normal;
  margin: 0 0 20px;
}

.search-form .form-control {
  border: 1px solid #eaeaea;
  border-radius: 0;
  border-right: 0;
  box-shadow: inherit;
  height: 40px;
  color: #ababab;
}

.search-form .btn-primary {
  background-color: transparent;
  border-color: #eaeaea;
  border-left: none;
  color: #00bf6f;
  height: 40px;
  border-radius: 0;
}

.latest-posts {
  margin: 0;
  padding: 0;
}

.latest-posts li {
  display: table;
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.latest-posts li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.post-thumb {
  width: 80px;
  float: left;
  overflow: hidden;
}

.post-thumb a img {
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.post-thumb a:hover img {
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.post-info {
  margin-left: 95px;
}

.post-info h4 {
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 18px;
  margin: 0 0 10px;
}

.post-info h4 a {
  color: #333;
}

.post-info p {
  color: #909090;
  font-size: 0.75rem;
  margin: 0;
}

.post-info p i {
  color: #00bf6f;
  font-size: 1rem;
  margin-right: 4px;
}

.categories {
  list-style: none;
  margin: 0;
  padding: 0;
}

.categories li {
  padding: 12px 0;
  border-bottom: 1px solid #eaeaea;
}

.categories li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.categories li a {
  color: #656565;
  font-size: 0.875rem;
}

.categories li a:hover {
  color: #00bf6f;
  margin-left: 10px;
  -moz-transition: all 0.2s linear 0s;
  -ms-transition: all 0.2s linear 0s;
  -o-transition: all 0.2s linear 0s;
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s;
}

.categories > li > a > i {
  color: #00bf6f;
  font-size: 1.125rem;
  margin-right: 10px;
}

.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;

  li {
    float: left;
  }

  .chip {
    align-items: center;
    background: #f8f8f8;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    color: #122b28;
    display: inline-flex;
    font-size: 14px;
    height: 24px;
    margin-top: 15px;
    padding: 0 0 0 8px;

    .close {
      color: #122b28;
      font-size: 12px;
      margin-left: 14px;
      opacity: 1;
      margin-top: 1px;
    }
  }
}

.tag {
  background-color: #eee;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -moz-transition: color 0.2s;
  -ms-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  font-size: 0.75rem;
}

.tag::before {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: "";
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag::after {
  background-color: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: #00bf6f;
  color: white;
}

.tag:hover::after {
  border-left-color: #00bf6f;
}

.comment-by {
  display: block;
  font-size: 0.875rem;
  line-height: 21px;
  margin: 0 0 10px;
}

.comments-list .comment-block p {
  font-size: 0.8125rem;
  line-height: 20px;
  margin: 0;
  text-align: justify;
}

blockquote p {
  font-style: italic;
  font-weight: 400;
}

.grid-blog .blog-title {
  font-size: 1rem;
  margin: 0 0 10px;
}

.grid-blog .blog-title a {
  color: #333;
}

.grid-blog .blog-content p {
  font-size: 0.8125rem;
  margin: 0 0 15px;
  color: #909090;
}

.grid-blog .blog-info {
  border: 0;
  border-top: 1px solid #eaeaea;
  margin-bottom: 0;
  padding: 12px 0;
}

.grid-blog .blog-image {
  margin-bottom: 20px;
}

.grid-blog .read-more {
  font-size: 0.8125rem;
  margin-bottom: 15px;
}
/*------------------------------------------------------------------
[37. Blog View]
*/
.blog-view .blog-title {
  font-size: 2rem;
}

.blog-view .blog-info {
  border: 0 none;
  margin-bottom: 20px;
  padding: 0;
}

.social-share {
  float: left;
  list-style: none;
  margin: 5px 0 0;
  padding: 0;
}

.social-share > li {
  display: inline-block;
  float: left;
  margin-left: 10px;
  text-align: center;
}

.social-share > li:first-child {
  margin-left: 0;
}

.social-share > li > a {
  border: 1px solid #dfdfdf;
  color: #00bf6f;
  display: inline-block;
  font-size: 1.375rem;
  height: 40px;
  line-height: 40px;
  width: 40px;
}

.social-share > li > a:hover {
  background-color: #00bf6f;
  color: #fff;
  border-color: #00bf6f;
}

.widget h3 {
  color: #656565;
  font-size: 1.31rem;
  margin: 0 0 20px;
}

.blog-reply > a {
  color: #00bf6f;
  font-size: 0.75rem;
  font-weight: 500;
}

.blog-date {
  color: #999;
  font-size: 0.75rem;
}

.comments-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.comments-list li {
  clear: both;
  padding: 10px 0 0 88px;
}

.comments-list li .comment {
  margin-bottom: 30px;
}

.comments-list li .comment-author {
  margin-left: -88px;
  position: absolute;
}

.comments-list li img.avatar {
  height: 58px;
  width: 58px;
  border-radius: 58px;
}

.blog-author-name {
  color: #00bf6f;
  font-size: 1.125rem;
}

.text-red {
  color: #f00;
}

.new-comment label {
  font-size: 0.875rem;
  font-weight: 500;
}

.new-comment input.form-control {
  border: 1px solid #e5e5e5;
  border-radius: 0;
  box-shadow: inherit;
  height: 40px;
}

.new-comment textarea.form-control {
  border: 1px solid #e5e5e5;
  border-radius: 0;
  box-shadow: inherit;
}

.new-comment .form-group {
  margin-bottom: 20px;
}

.comment-submit .btn {
  background-color: #00bf6f;
  border-color: #00bf6f;
  border-radius: 0;
  font-size: 1.125rem;
  padding: 8px 26px;
  color: #fff;
}

.about-author-img {
  background-color: #fff;
  height: 120px;
  overflow: hidden;
  position: absolute;
  width: 120px;
}

.author-details {
  margin-left: 145px;
}

.about-author {
  min-height: 120px;
}

.author-details .blog-author-name {
  display: inline-block;
  margin-bottom: 10px;
}

.blog-navigation {
  text-align: right;
}
/*------------------------------------------------------------------
[38. UI Kit]
*/
.pagination-box .pagination {
  margin-top: 0;
}
// .tab-content {
//   padding-top: 20px;
// }
/*------------------------------------------------------------------
[39. Error]
*/
.error-box {
  background-color: #fff;
  border-radius: 5px;
  line-height: 1;
  margin: 0 auto;
  max-width: 475px;
  padding: 50px 30px 55px;
  text-align: center;
  width: 100%;
}

.error-heading {
  font-size: 3.5em;
  font-weight: bold;
}

.error-title {
  color: #2c2c2c;
  font-size: 1.375rem;
  font-weight: normal;
  margin: 0 0 1.5rem;
}

.error-wrapper {
  background-color: #fff;
  margin: 0;
  color: #4f5155;
  -moz-box-align: center;
  -moz-box-pack: center;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.error-box h1 {
  font-size: 9.38rem;
}

.error-box p {
  margin-bottom: 30px;
}

.error-box .btn {
  text-transform: uppercase;
}
/*------------------------------------------------------------------
[40. Lock Screen]
*/
.lock-user {
  margin-bottom: 20px;
}

.lock-user img {
  margin-bottom: 15px;
  width: 100px;
}
/*------------------------------------------------------------------
[41. Countdown]
*/
.countdown {
  margin-bottom: 80px;
}

.countdown ul {
  display: block;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 600px;
}

.countdown ul li {
  display: inline-block;
  float: left;
}

.countdown ul li.timer {
  background-color: #f5f5f5;
  border-radius: 6px;
  color: #313131;
  padding: 20px 15px;
  text-align: center;
  width: 19.75%;
}

.countdown ul li span {
  font-size: 3.125rem;
}

.countdown ul li p {
  color: #313131;
  font-size: 0.75rem;
  margin: 0;
  text-transform: uppercase;
}

.countdown ul li.seperator {
  font-size: 3rem;
  line-height: 100px;
  padding: 0 8px;
  text-align: center;
  vertical-align: top;
  width: 41px;
}

.newsletter {
  text-align: center;
}

.newsletter h3 {
  font-size: 1.125rem;
  font-weight: normal;
  margin-bottom: 11px;
  text-align: center;
  text-transform: uppercase;
}

.newsletter form {
  margin: 0 auto;
  width: 500px;
}

.newsletter input[type="text"] {
  border: 1px solid #d8d8d8;
  border-radius: 5px 0 0 5px;
  font-size: 0.75rem;
  height: 46px;
  padding: 0 20px;
  width: 350px;
  float: left;
}

.submit-btn {
  background-color: #cdcdcd;
  border: 1px solid #cdcdcd;
  border-radius: 0 5px 5px 0;
  color: #fff;
  padding: 0 20px;
  text-transform: uppercase;
  width: 150px;
  cursor: pointer;
  float: right;
  height: 46px;
}

.submit-btn:hover {
  background-color: #00bf6f;
  border: 1px solid #00bf6f;
}

.title-section {
  margin-bottom: 80px;
  text-align: center;
}

.coming-title {
  font-size: 2.5rem;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 0;
}

.sub-title {
  color: #bdbdbd;
  font-size: 0.875rem;
  margin: 0;
}

.coming-wrapper {
  display: table;
  height: 100vh;
  width: 100%;
  padding: 30px 0;
  background-color: #fff;
}
/*------------------------------------------------------------------
[42. Voice call]
*/
.voice-call-avatar {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
}

.voice-call-avatar .call-avatar {
  margin: 15px;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 3px;
  background-color: #fff;
}

.call-duration {
  display: inline-block;
  font-size: 1.88rem;
  margin-top: 4px;
  position: absolute;
  left: 0;
}

.voice-call-avatar .call-timing-count {
  padding: 5px;
}

.voice-call-avatar .username {
  font-size: 1.125rem;
  text-transform: uppercase;
}

.call-icons {
  text-align: center;
  position: relative;
}

.call-icons .call-items {
  border-radius: 5px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}

.call-icons .call-items .call-item {
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}

.call-icons .call-items .call-item:last-child {
  margin-right: 0;
}

.call-icons .call-items .call-item a {
  color: #777;
  border: 1px solid #ddd;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50px;
  display: inline-block;
  font-size: 1.25rem;
}

.call-icons .call-items .call-item a i {
  width: 18px;
  height: 18px;
}

.user-video {
  bottom: 0;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

.user-video img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}

.user-video video {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}

.my-video {
  position: absolute;
  z-index: 99;
  bottom: 20px;
  right: 20px;
}

.my-video ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.my-video ul li {
  float: left;
  width: 120px;
  margin-right: 10px;
}

.my-video ul li img {
  border: 3px solid #fff;
  border-radius: 6px;
}

.end-call {
  position: absolute;
  top: 7px;
  right: 0;
}

.end-call a {
  color: #fff;
  border-radius: 50px;
  display: inline-block;
  background-color: #f06060;
  padding: 8px 25px;
  text-transform: uppercase;
}

.call-users {
  position: absolute;
  z-index: 99;
  bottom: 20px;
  right: 20px;
}

.call-users ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.call-users ul li {
  float: left;
  width: 80px;
  margin-left: 10px;
}

.call-users ul li img {
  border-radius: 6px;
  padding: 2px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.call-mute {
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: none;
  text-align: center;
  line-height: 80px;
  border-radius: 6px;
  font-size: 1.88rem;
  color: #fff;
  top: 0;
  border: 3px solid transparent;
}

.call-users ul li a:hover .call-mute {
  display: block;
}

.call-details {
  margin: 10px 0 0;
  display: flex;
}

.call-info {
  margin-left: 10px;
  width: 100%;
}

.call-timing,
.call-user-details {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.call-description {
  white-space: nowrap;
  vertical-align: bottom;
}

.call-timing {
  color: #a0a0a2;
  display: flex;
  font-size: 0.875rem;
  margin-top: 1px;
  overflow: hidden;
  white-space: pre;
}
/*------------------------------------------------------------------
[43. Video Call]
*/
.content-full {
  height: 100%;
  position: relative;
  width: 100%;

  .archive-side-bar-bottom {
    position: absolute;
    bottom: 0;
    background: #f8f8f8;
    width: calc(100% - 14px);
  }

  .message-tab {
    @include themed() {
      background: t("leftSideMenu") !important;
    }
  }
}

.video-window .fixed-header {
  padding: 0;
  border: 0;
}

.video-window .fixed-header .nav > li > a {
  padding: 19px 15px;
}
/*------------------------------------------------------------------
[44. Incoming Call]
*/
.incoming-btns {
  margin-top: 20px;
}
/*------------------------------------------------------------------
[45. Widgets]
*/
.dash-widget {
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 20px;
  position: relative;
}

.dash-widget2 {
  display: block;
  min-height: 90px;
  background: #fff;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 30px;
  border: 1px solid #e7e7e7;
}

.dash-widget2 .dash-widget-icon {
  border-radius: 4px 0 0 4px;
  display: block;
  float: left;
  height: 88px;
  width: 90px;
  text-align: center;
  font-size: 2.81rem;
  line-height: 90px;
  color: #fff;
}

.dash-widget2 .dash-widget-info {
  padding: 11px 20px;
}

.dash-progress {
  margin-top: 15px;
}

.dash-progress h5 {
  margin-bottom: 0;
}

.dash-widget3 .dash-widget-icon {
  color: #fff;
  height: 60px;
  line-height: 60px;
  width: 60px;
}

.dash-widget4 .dash-widget-icon {
  color: #fff;
}

.dash-widget5 {
  display: block;
  min-height: 90px;
  background: #fff;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 30px;
  border: 1px solid #e7e7e7;
}

.dash-widget5 .dash-widget-icon {
  border-radius: 0;
  display: block;
  float: left;
  height: 88px;
  width: 90px;
  text-align: center;
  font-size: 2.81;
  line-height: 90px;
  color: #fff;
}

.dash-widget5 .dash-widget-info {
  padding: 11px 0;
}

.analytics-desc {
  display: block;
  margin: 10px 0;
  text-align: center;
}

.analytics-desc > .analytics-count {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 1rem;
}

.analytics-desc > .analytics-title {
  text-transform: uppercase;
  font-size: 0.75rem;
}

.profile-widget.profile-widget4 {
  padding: 0;
}

.profile-bg {
  background: url("../img/user-02.jpg");
  background-size: cover;
  height: 141px;
  color: #fff;
  padding: 20px;
}

.profile-widget .profile-bg .user-name > a {
  color: #fff;
}

.profile-bg h5 {
  color: #fff;
}

.profile-avatar {
  position: relative;
  top: -50px;
  margin-bottom: -50px;
}

.profile-avatar img {
  width: 80px;
  height: 80px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.profile-widget .user-info {
  padding: 10px 15px;
}

.profile-widget .user-info .title {
  margin-bottom: 4px;
  font-size: 1.5rem;
  line-height: 1;
  color: #262626;
  vertical-align: middle;
}

.profile-widget .user-info .desc {
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 20px;
  color: #737373;
  text-overflow: ellipsis;
}

.profile-widget.profile-widget3 {
  padding: 0;
  overflow: hidden;
}

.profile-widget3 {
  background-color: #fff;
  border: 1px solid #e1e8ed;
  border-radius: 6px;
  margin-bottom: 30px;
}

.avatar-link {
  border-radius: 6px;
  display: inline-block;
  float: left;
  margin: -30px 5px 0 8px;
  max-width: 100%;
  vertical-align: bottom;
  position: relative;
}

.avatar-link img {
  border: 2px solid #fff;
  border-radius: 7px;
  box-sizing: border-box;
  color: #fff;
  height: 72px;
  width: 72px;
}

.avatar-link img.rounded-circle {
  border-radius: 50%;
}

.profile-widget3 .user-info {
  margin: 5px 0 0;
  text-align: left;
  padding: 0;
}

.profile-widget3 .user-info .username {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 21px;
}

.profile-widget3 .user-info .username a {
  color: #333;
}

.user-analytics {
  padding: 10px 0;
}
/*------------------------------------------------------------------
[46. Contacts]
*/
.contacts-header {
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  padding: 10px 15px;
}

.contacts-header .navbar {
  border: 0 none;
  margin: 0;
  min-height: auto;
}

.contacts-header .user-info a {
  color: #76838f;
  text-transform: uppercase;
}

.contact-left {
  display: table-cell;
  height: 100%;
  float: none;
  padding: 0;
  position: static;
  vertical-align: top;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  width: 100%;
}

.contacts-list {
  position: relative;
  padding: 0 70px 0 20px;
}

.contact-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.contact-list > li {
  border-bottom: 1px solid #eaeaea;
  border-radius: inherit;
  padding: 10px;
  background-color: #fff;
}

.contact-cont {
  position: relative;
}

.contact-type {
  height: 48px;
  position: absolute;
  width: 48px;
}

.contact-icon {
  background-color: #f3f7f9;
  border: 1px solid #e4eaec;
  border-radius: 4px;
  display: inline-block;
  height: 38px;
  line-height: 38px;
  text-align: center;
  width: 38px;
}

.contact-icon i {
  color: #76838f;
  font-size: 1.25rem;
}

.contact-info {
  padding: 0 30px 0 50px;
}

.contact-action {
  height: 30px;
  list-style: none;
  padding-left: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 10px;
  width: 30px;
}

.contact-date {
  color: #888;
  font-size: 0.75rem;
}

.contact-author a {
  color: #00bf6f;
  font-size: 0.75rem;
  text-decoration: underline;
}

.contact-action .dropdown-menu {
  left: auto;
  right: 0;
}

.contact-alphapets {
  background-color: #fff;
  border-left: 1px solid #e7e7e7;
  bottom: 0;
  height: 100%;
  overflow: hidden;
  padding-bottom: 121px;
  position: fixed;
  right: 0;
  top: 121px;
  width: 50px;
}

.contact-box {
  display: inline-table;
  height: 100%;
  padding: 30px 15px;
  position: relative;
  width: 100%;
}

.alphapets-inner {
  height: 100%;
  overflow: auto;
}

.alphapets-inner a {
  display: block;
  text-align: center;
  padding: 2px;
  color: #333;
}
/*------------------------------------------------------------------
[47. Notification settings]
*/
.notification-list .list-group-item {
  padding: 15px;
}
/*------------------------------------------------------------------
[48. Add Product]
*/
.product-thumbnail {
  position: relative;
  margin-bottom: 0;
  margin-top: 10px;
}

.product-remove {
  position: absolute;
  top: -10px;
  right: -6px;
  color: #f06060;
  cursor: pointer;
}

.bootstrap-tagsinput {
  width: 100%;
  height: 40px;
  box-shadow: inherit;
  border-radius: 0;
  line-height: 30px;
}

.bootstrap-tagsinput .badge {
  background-color: #00bf6f;
  border-radius: 3px 0 0 3px;
  color: #fff;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -moz-transition: color 0.2s;
  -ms-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  font-size: 0.75rem;
  border: 0;
  float: left;
}

.bootstrap-tagsinput .badge [data-role="remove"]:hover {
  box-shadow: inherit;
  color: #f00;
}

.bootstrap-tagsinput .badge::before {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: "";
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.bootstrap-tagsinput .badge::after {
  background-color: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #00bf6f;
  border-top: 13px solid transparent;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}

.bootstrap-tagsinput .badge:hover {
  background-color: #00bf6f;
  color: white;
}

.bootstrap-tagsinput .badge:hover::after {
  border-left-color: #00bf6f;
}
/*------------------------------------------------------------------
[49. Product]
*/
.product {
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: 5px;
  background-color: #fff;
  margin-bottom: 30px;
}

.product-inner {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.product-inner img {
  width: 100%;
  height: auto;
}

.product-inner .cart-btns {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 191, 111, 0.1);
  opacity: 0;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.cart-btns .btn {
  width: 120px;
  display: block;
  margin: 30px auto 0;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.product a.addcart-btn {
  margin: -100px auto 10px;
}

.product a.proedit-btn {
  margin: 0 auto;
}

.product .pro-desc {
  padding: 15px 10px;
}

.pro-desc .price {
  font-size: 1.125rem;
  line-height: 20px;
  color: #333;
  font-weight: bold;
}

.product:hover .cart-btns {
  opacity: 1;
}

.product:hover a.addcart-btn {
  margin: 55px auto 10px;
}

.pro-desc h5 a {
  color: #333;
}

.product-det {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  min-height: 40px;
}

.product-desc {
  padding: 0 0 0 70px;
}

.product-det > img {
  top: 0;
  width: 60px;
  position: absolute;
  left: 0;
}

.product-desc a,
.product-desc span {
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
}

.price-sup {
  font-weight: bold;
  margin-bottom: 0;
}
/*------------------------------------------------------------------
[50. Product Details]
*/
.product-content {
  position: relative;
}

.product-content p {
  color: #282628;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 25px;
  margin: 0 0 25px;
}

.proimage-thumb {
  float: left;
  list-style: none;
  padding: 0;
}

.proimage-thumb li {
  float: left;
  height: 60px;
  width: 80px;
  text-align: center;
  margin: 13px 12px 0 0;
}

.proimage-thumb li img {
  display: block;
  height: 62px;
  width: 81px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  position: relative;
}

.rated {
  color: #fc0;
}

.product_price {
  font-size: 1.88rem;
  font-weight: bold;
}

.review-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.review-list li {
  clear: both;
  padding: 10px 0 0 88px;
}

.review-list li .review {
  margin-bottom: 30px;
}

.review-list li .review-author {
  margin-left: -88px;
  position: absolute;
}

.review-list li img.avatar {
  height: 58px;
  width: 58px;
  border-radius: 58px;
}

.review-by {
  display: block;
  font-size: 0.875rem;
  line-height: 21px;
  margin: 0 0 10px;
}

.review-list .review-block p {
  font-size: 0.8125rem;
  line-height: 20px;
  margin: 0;
  text-align: justify;
}

.new-review label {
  font-size: 0.875rem;
  font-weight: 500;
}

.new-review input.form-control {
  border: 1px solid #e5e5e5;
  border-radius: 0;
  box-shadow: inherit;
  height: 40px;
}

.new-review textarea.form-control {
  border: 1px solid #e5e5e5;
  border-radius: 0;
  box-shadow: inherit;
}

.new-review .form-group {
  margin-bottom: 20px;
}

.review-submit .btn {
  background-color: #00bf6f;
  border-color: #00bf6f;
  border-radius: 0;
  font-size: 1.125rem;
  padding: 8px 26px;
  color: #fff;
}

.review-date {
  color: #999;
  font-size: 0.75rem;
}

.review-author-name {
  font-size: 1.125rem;
}

.product-reviews {
  margin-bottom: 30px;
}
/*------------------------------------------------------------------
[51. Chat Sidebar]
*/
.chat-sidebar {
  background-color: #fff;
  width: 350px;
  transition: width 0.4s ease;
  @media only screen and (max-width: 1400px) and (min-width: $screen-md) {
    width: 250px;
  }

  &.chat-sidebar-hidden {
    width: 0 !important;
  }

  &.minimized-right-panel {
    width: 44px;
  }
}

.chat-sidebar .chat-contents {
  @include themed() {
    background-color: t("bgRHS");
  }
}

.chat-sidebar .chat-left .chat-content {
  border: 0;
  padding: 0;
  border-radius: 0 !important;
  background-color: transparent;
  max-width: 100%;
}

.chat-sidebar .chat-left .chat-body {
  margin-left: 50px;
  padding-right: 0;
}

.chat-sidebar .chat-date {
  background-color: #fff;
}

.chat-sidebar .chats {
  padding: 15px 15px 30px;
}
// .chat-sidebar .avatar {
//   width: 30px;
//   height: 30px;
//   line-height: 30px;
// }

.chat-sidebar .chat.chat-left {
  margin-bottom: 20px;
}

.chat-sidebar .chat.chat-left:last-child {
  margin-bottom: 0;
}

.chat-sidebar .chat-left .chat-time {
  color: #888;
  display: inline-block;
  font-size: 0.6875rem;
}

.chat-sidebar .chat-content > p {
  font-size: 0.8125rem;
  margin-bottom: 0;
}

.chat-sidebar.opened {
  margin-right: 0;
}

/*------------------------------------------------------------------
[52. Gallery]
*/
.lg-backdrop {
  z-index: 1041;
}

#lightgallery .img-thumbnail {
  box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
}
/*------------------------------------------------------------------
[53. Responsive]
*/
/* TIMELINE MODAL */
.modal-backdrop.show {
  z-index: 99;
  opacity: 0;
}

.timeline-modal {
  z-index: 99999;
}

.timeline-modal .modal-header {
  border-bottom: none;
  padding: 0;
}

.timeline-modal .modal-content {
  border: none;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
  border-radius: 5px;
  position: relative;
}

.timeline-modal .modal-header .close {
  font-size: 2.5rem;
  color: #c0392b;
  opacity: 1;
  width: auto;
  height: auto;
  position: absolute;
  padding: 0;
  right: 25px;
  top: 20px;
  z-index: 9;
}

.timeline-modal .modal-header .close:focus {
  outline: 0;
}
/* AUTOMATED MESSAGE INFO MODAL  */
.automated-message-info.timeline-modal .modal-body {
  padding: 60px 70px;
}

.automated-message-info.timeline-modal .modal-dialog {
  max-width: 456px;
}

.automated-message-info.timeline-modal .modal-body table {
  margin: 0;
}

.automated-message-info.timeline-modal .modal-body table tr {
  height: 30px;
}

.automated-message-info.timeline-modal .modal-body table tr td {
  font-size: 1rem;
  color: #122b28;
  font-weight: 300;
}

.automated-message-info.timeline-modal .modal-body table tr td strong {
  font-weight: 500;
}

.automated-message-info.timeline-modal .modal-body .contact-single {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.automated-message-info.timeline-modal .modal-body .label {
  font-size: 0.75rem;
  font-weight: 400;
  color: #122b28;
  margin-top: 25px;
  display: block;
  margin-right: 5px;
}
/* FORWAD SUCCESS INFO MODAL */
.forward-success.timeline-modal .modal-body {
  padding: 40px 90px;
  background: $primary;
}

.forward-success.timeline-modal .modal-content {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}

.forward-success.timeline-modal .modal-body p {
  margin: 0;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  color: #fff;
}

.forward-success.timeline-modal .modal-dialog {
  max-width: 500px;
}
/* DELETE MESSAGE INFO MODAL */
.delete-message.timeline-modal .modal-body {
  padding: 60px 24px 25px 25px;
}

.delete-message.timeline-modal .modal-content {
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
  border-radius: 5px;
}

.delete-message.timeline-modal .modal-body h2 {
  margin: 0;
  color: #122b28;
  font-size: 1.25rem;
  font-weight: bold;
}

.delete-message.timeline-modal .modal-body p {
  margin: 20px 0 0;
  color: #122b28;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 46px;
}

.delete-message.timeline-modal .modal-dialog {
  max-width: 480px;
}

.delete-message.timeline-modal .modal-body .modal-footer {
  border-top: none;
  padding: 0;
}

.delete-message.timeline-modal .modal-body .modal-footer .btn.btn-secondary {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: $primary;
  border-color: $primary;
  padding: 12px 20px;
}

.delete-message.timeline-modal .modal-body .modal-footer .btn.btn-primary {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #c0392b;
  border-color: #c0392b;
  padding: 12px 20px;
}
/* DELETE MESSAGE SUCCESS INFO MODAL */
.delete-message-success.timeline-modal .modal-body {
  padding: 51px 40px 43px;
  background: $primary;
}

.delete-message-success.timeline-modal .modal-content {
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
}

.delete-message-success.timeline-modal .modal-body p {
  margin: 0;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  color: #fff;
}

.delete-message-success.timeline-modal .modal-dialog {
  max-width: 380px;
}
/*CALL TO INFO MODAL  */
.call-modal.timeline-modal {
  text-align: center;
}

.call-modal.timeline-modal .make-call {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.call-modal.timeline-modal .make-call .chat-info {
  font-size: 1.25rem;
  font-weight: 500;
  color: #122b28;
}

.call-modal.timeline-modal .make-call span {
  font-size: 1rem;
  font-weight: 300;
  color: #122b28;
  margin-top: 18px;
}

.call-modal.timeline-modal .make-call .avatar {
  margin: 0 0 6px;
}

.call-modal.timeline-modal .make-call .avatar.big {
  width: 105px;
  height: 105px;
  line-height: 105px;
  font-size: 3.125rem;
  font-weight: 400;
  color: #fff;
}

.call-modal.timeline-modal .modal-content {
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.26);
  border-radius: 5px;
}

.call-modal.timeline-modal .modal-body {
  padding: 48px 10px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.call-modal.timeline-modal .modal-body h3 {
  margin: 0;
  color: #122b28;
  font-size: 1.25rem;
  font-weight: bold;
}

.call-modal.timeline-modal .modal-dialog {
  max-width: 333px;
}

.call-modal.timeline-modal .modal-body .call-from a {
  font-size: 1rem;
  color: #122b28;
  font-weight: 500;
}

.call-modal.timeline-modal .modal-body .call-from span {
  color: #122b28;
  font-size: 1rem;
  font-weight: 300;
  margin-right: 15px;
}

.call-modal.timeline-modal .modal-body .credits {
  margin: 0 0 15px;
  display: flex;
  justify-content: center;
}

.call-modal.timeline-modal .modal-body .credits span {
  color: #122b28;
  font-size: 0.75rem;
  font-weight: 300;
}

.call-modal.timeline-modal .modal-body .credits span:first-child {
  margin-right: 18px;
}

.call-modal.timeline-modal .modal-body .modal-footer {
  border-top: none;
  padding: 0;
  flex-direction: column;
}

.call-modal.timeline-modal .modal-body .modal-footer .btn-secondary {
  background-color: $primary;
  border-radius: 5px;
  font-size: 1rem;
  color: #fff;
  font-weight: 500;
  padding: 12px 0;
  border: 1px solid $primary;
  min-width: 95px;
  margin-right: 16px;
}

.call-modal.timeline-modal .modal-body .modal-footer .btn-primary {
  background-color: #c0392b;
  border-radius: 5px;
  font-size: 1rem;
  color: #fff;
  font-weight: 500;
  padding: 12px 0;
  border: 1px solid #c0392b;
  min-width: 95px;
}

.call-modal.timeline-modal .modal-body .modal-footer .full-button {
  width: 206px;
}

.call-modal.timeline-modal .modal-body .modal-footer .btn-circle {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  background: $primary;
  border: none;
  outline: 0;
  display: inline-block;
  margin-left: 7px;
  margin-right: 7px;
  padding: 0;
}

.call-modal.timeline-modal .modal-body .modal-footer .btn-circle:focus {
  box-shadow: none;
}

.call-modal.timeline-modal .modal-body .modal-footer .toggle-mute .muted {
  display: none;
}

.call-modal.timeline-modal .modal-body .modal-footer .toggle-mute.active .mute {
  display: none;
}

.call-modal.timeline-modal
  .modal-body
  .modal-footer
  .toggle-mute.active
  .muted {
  display: inline-block;
  width: 30px;
  height: 30px;
}

.call-modal.timeline-modal
  .modal-body
  .modal-footer
  .btn-dial-pad[aria-expanded="true"] {
  background: #122b28;
}

.call-modal.timeline-modal .modal-body .modal-footer .btn-circle.call {
  background: #c0392b;
}

.call-modal.timeline-modal .modal-body .modal-footer .btn-circle img {
  width: 22px;
}

.call-modal.timeline-modal .modal-body .modal-footer .btn-circle .mike {
  width: 22px;
}

.call-modal.timeline-modal .modal-body .modal-footer .btn-circle .dial-pad {
  width: 28px;
}

.call-modal.timeline-modal .modal-body .modal-footer .btn-circle .settings {
  width: 33px;
}

.call-modal.timeline-modal .modal-body .modal-footer .btn-circle .call {
  width: 35px;
}
/* Dialpad */
.call-modal.timeline-modal .dail-pad {
  width: 100%;
  max-width: 250px;
  margin: 20px auto 0;
}

.call-modal.timeline-modal .dail-pad .dial-pad-btn {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  outline: 0;
  border: 1px solid #122b28;
  background: transparent;
  margin-top: 13px;
  font-size: 1.25rem;
  color: #122b28;
  font-weight: 300;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  margin-left: auto;
  margin-right: auto;
}

.call-modal.timeline-modal .collapse-content {
  width: 100%;
  margin-top: 36px;
}

.call-modal.timeline-modal .collapse-content table {
  margin: 0 auto;
}

.call-modal.timeline-modal .collapse-content table.settings td {
  font-size: 1rem;
  font-weight: 300;
  color: #122b28;
  text-align: left;
  height: 48px;
  padding: 0 10px;
}

.call-modal.timeline-modal .collapse-content .custom-select {
  width: 154px;
  font-size: 0.875rem;
  font-weight: 500;
  color: #707070;
  //new icons
  background: url("../img/icons-new/general/expand/expand-gray.svg") no-repeat
    right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 12px 10px;
  padding: 0 8px;
  border-radius: none;
  height: 27px;
}

.call-modal.timeline-modal .dail-pad .dial-pad-btn span {
  font-size: 0.4375rem;
  font-weight: 500;
}

.call-modal.timeline-modal .dail-pad .dial-pad-btn:focus {
  background: rgba(0, 0, 0, 0.1);
}
/* MODAL MESSAGE FORWARD */

.forward-modal.timeline-modal .modal-dialog {
  max-width: 690px;
}

.resizing_select {
  width: 50px;
}

.width_tmp_select {
  display: none;
}

svg.icon-green {
  path {
    fill: #00c186;
  }
}

.modal-body-group-list .modal-body-group-item {
  cursor: pointer;
}

.modal-body-group-list .modal-body-group-item:hover {
  background: #f2f2f2;
}

.color-primary {
  color: $primary !important;
}

.scroll-padding {
  scroll-padding-bottom: 300px !important;
}

.text-success {
  color: #22dd8e;
}

.mobile-header-name {
  @include themed() {
    color: t("fontColor");
  }
  font-size: 16px;
}
