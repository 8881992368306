@import "../base/global-theme";

.audioplayer {
  @include themed() {
    color: t("iconColor");
  }
  z-index: 1;
  background: none;
  display: flex;
  align-items: center;
}

.audioplayer-mini {
  width: 2.5em; /* 40 */
  margin: 0 auto;
}

.audioplayer-playpause {
  width: 16px;
  text-align: left;
  text-indent: -9999px;
  cursor: pointer;
  position: relative;
  margin-top: -2px;
}

.audioplayer-playpause.small {
  border-width: 0.39em;
  margin: -0.25em 0 0 -0.08em;
}

.audioplayer-mini .audioplayer-playpause {
  width: 100%;
}
.audioplayer-playpause a {
  display: block;
}
.audioplayer:not(.audioplayer-playing) .audioplayer-playpause a {
  width: 0;
  height: 0;
  border: 0.5em solid transparent; /* 8 */
  border-right: none;
  @include themed() {
    border-left-color: t("iconColor");
  }

  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.5em 0 0 -0.25em; /* 8 4 */
}
.audioplayer-playing .audioplayer-playpause a {
  width: 0.75em; /* 12 */
  height: 0.75em; /* 12 */
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.375em 0 0 -0.375em; /* 6 */
}
.audioplayer-playing .audioplayer-playpause a:before,
.audioplayer-playing .audioplayer-playpause a:after {
  width: 40%;
  height: 100%;
  background-color: #2c2c2c;
  content: "";
  position: absolute;
  top: 0;
}

.audioplayer-playing .audioplayer-playpause.purple a:before,
.audioplayer-playing .audioplayer-playpause.purple a:after {
  background-color: #6c37c9;
}

.audioplayer-playing .audioplayer-playpause a:before {
  left: 0;
}
.audioplayer-playing .audioplayer-playpause a:after {
  right: 0;
}
.audioplayer-time {
  text-align: center;
  @include themed() {
    color: t("iconColor");
  }
}

.audioplayer-time.purple {
  @include themed() {
    border-left-color: t("primary");
  }
}

.audioplayer-time.small {
  font-size: 12px;
}

.audioplayer-bar {
  min-width: 40px;
  height: 0.875em;
  @include themed() {
    background: t("iconColor");
  }
  cursor: pointer;
  width: 100%;
  position: relative;
}

.audioplayer-bar.small {
  height: 0.33em;
}

.audioplayer-novolume .audioplayer-bar {
  right: 4.375em; /* 70 */
}
.audioplayer-bar div,
.audioplayer-bar input {
  width: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
.audioplayer-bar-loaded {
  background-color: #ddd;
  z-index: 1;
}
.audioplayer-bar-played {
  background-color: rgba(85, 23, 193, 0.3);
  z-index: 9;
}
.audioplayer-volume {
  width: 16px;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 12px;
  margin-top: 3px;
}

.audioplayer-volume-button {
  width: 100%;
  height: 100%;
}
.audioplayer-volume-button a {
  width: 0.313em; /* 5 */
  height: 0.375em; /* 6 */
  background-color: #727272;
  display: block;
  position: relative;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.audioplayer-volume-button a:before,
.audioplayer-volume-button a:after {
  content: "";
  position: absolute;
}
.audioplayer-volume-button a:before {
  width: 0;
  height: 0;
  border: 0.5em solid transparent; /* 8 */
  border-left: none;
  @include themed() {
    border-right-color: t("iconColor");
  }

  z-index: 2;
  top: 50%;
  right: -0.25em;
  margin-top: -0.5em; /* 8 */
}
.audioplayer:not(.audioplayer-mute) .audioplayer-volume-button a:after {
  /* "volume" icon by Nicolas Gallagher, http://nicolasgallagher.com/pure-css-gui-icons */
  width: 0.313em; /* 5 */
  height: 0.313em; /* 5 */
  @include themed() {
    border-color: t("iconColor");
  }

  border: 0.25em double; /* 4 */
  border-width: 0.25em 0.25em 0 0; /* 4 */
  left: 0.563em; /* 9 */
  top: -0em; /* 1 */
  -webkit-border-radius: 0 0.938em 0 0; /* 15 */
  -moz-border-radius: 0 0.938em 0 0; /* 15 */
  border-radius: 0 0.938em 0 0; /* 15 */
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.audioplayer-volume-adjust {
  height: 6.25em; /* 100 */
  cursor: default;
  position: absolute;
  left: 0;
  right: -1px;
  top: -9999px;
  background: #222;
  -webkit-box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.15),
    2px -2px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.15),
    2px -2px 2px rgba(0, 0, 0, 0.15);
  box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.15),
    2px -2px 2px rgba(0, 0, 0, 0.15);
}
.audioplayer-volume:not(:hover) .audioplayer-volume-adjust {
  opacity: 0;
  display: none;
}
.audioplayer-volume:hover .audioplayer-volume-adjust {
  top: auto;
  bottom: 100%;
  display: none;
}
.audioplayer-volume-adjust > div {
  width: 40%;
  height: 80%;
  background-color: #222;
  cursor: pointer;
  position: relative;
  z-index: 1;
  margin: 30% auto 0;
}
.audioplayer-volume-adjust div div {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #007fd1;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#007fd1),
    to(#c600ff)
  );
  background: -webkit-linear-gradient(bottom, #007fd1, #c600ff);
  background: -moz-linear-gradient(bottom, #007fd1, #c600ff);
  background: -ms-radial-gradient(bottom, #007fd1, #c600ff);
  background: -o-linear-gradient(bottom, #007fd1, #c600ff);
  background: linear-gradient(to top, #007fd1, #c600ff);
}
.audioplayer-novolume .audioplayer-volume {
  display: none;
}

.audioplayer-bar,
.audioplayer-bar div,
.audioplayer-volume-adjust div {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.audioplayer-volume-adjust div div,
.audioplayer-bar-played {
  -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.5);
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.5);
}

.audioplayer *,
.audioplayer *:before,
.audioplayer *:after {
  -webkit-transition: color 0.25s ease, background-color 0.25s ease,
    opacity 0.5s ease;
  -moz-transition: color 0.25s ease, background-color 0.25s ease,
    opacity 0.5s ease;
  -ms-transition: color 0.25s ease, background-color 0.25s ease,
    opacity 0.5s ease;
  -o-transition: color 0.25s ease, background-color 0.25s ease,
    opacity 0.5s ease;
  transition: color 0.25s ease, background-color 0.25s ease, opacity 0.5s ease;
}

.audioplayer .tooltip {
  margin-bottom: 15px;
}
