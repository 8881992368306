.choose-theme-modal {
  .MuiDivider-root {
    @include themed() {
      background-color: t("iconColor") !important;
    }
  }
  .themed-title {
    font-size: 24px;
    font-weight: normal;
  }
  svg.theme-icon {
    @include themed() {
      path:not([fill="none"]) {
        fill: t("iconColor") !important;
      }
    }
  }
  .save-button {
    margin-left: auto;
    width: 20%;
  }
}
