@import "../../base/global-theme";

.voice-dialer-paper {
  width: 350px;
  overflow: hidden !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137) !important;
  transition: width 0.4s ease !important;

  @include themed() {
    background: t("bgRHS");
  }

  @media (max-width: $screen-md) {
    width: 100vw !important;
    z-index: 10000 !important;
  }
}

.voice-dialer-main-container {
  overflow: hidden !important;

  @media (max-width: $screen-md) {
    width: 100vw !important;
    z-index: 10000 !important;
    height: 100vh !important;
    overflow: auto !important;
  }
}

.voice-dialer-content-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;

    svg {
      @include themed() {
        path:not([fill="none"]) {
          fill: t("iconColor") !important;
        }
      }
    }
  }

  .drawer-heading {
    font-size: 1.5rem;
    @include themed() {
      color: t("fontColor");
    }
    @media (max-width: $screen-md) {
      font-size: 1rem;
    }
  }

  .bottom-section {
    padding: 20px 10px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    @media (max-width: $screen-md) {
      padding: 10px;
    }

    @include themed() {
      color: t("fontColor");
    }

    svg,
    path {
      @include themed() {
        fill: t("fontColor");
      }
    }
  }

  .voice-dialer-input-box {
    border: none !important;
    font-size: 1rem;
    @include themed() {
      background: t("bgRHS") !important;
    }
  }

  .helper-label {
    @include themed() {
      color: t("fontMidGray");
    }
  }

  .helper-dark-label {
    @include themed() {
      color: t("fontDarkGray");
    }
  }

  .star-digit {
    font-size: 42px !important;
    margin-top: 13px !important;

    @media (max-width: $screen-md) {
      font-size: 30px !important;
    }
  }

  .nothing-found-text {
    @include themed() {
      color: t("fontColor");
    }
  }

  .dialer-box-container-on-mobile {
    position: fixed;
    bottom: 0;
    @include themed() {
      background: t("bgRHS") !important;
    }
  }
}

.call-is-in-progress-modal {
  .modal-dialog {
    width: 500px;
    max-width: 500px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
}
