@import "../base/global-theme";

.sidebar-item-inbox {
  a {
    .icon-white,
    .icon-black {
      width: 25px !important;
      max-width: 25px !important;
      max-height: 22px !important;
      margin-right: 0px !important;
      position: static !important;
      vertical-align: middle !important;
      margin-top: 19px !important;
    }
    .icon-white {
      display: none;
    }
    .icon-black {
      display: inline-block;
    }

    &:hover,
    &:focus,
    &:active {
      .icon-white {
        display: inline-block;
      }
      .icon-black {
        display: none;
      }
    }
  }
}

.sidebar-popover-accordian-container {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 10px;
  padding-right: 10px;
  background: $light-tone;
  cursor: pointer;

  .accordianBtn {
    display: flex;
    justify-content: space-between;

    i {
      position: relative;
      top: 2px;
    }
  }
}

.thread-checkbox {
  width: 36px;
  min-width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  overflow: hidden;
  margin: 0 4px 0 10px;
  //border-radius: 2px;
  //margin-right: 10px;
  font-size: 1.06rem;
  //border: 1px solid #808080;
  display: flex;
  justify-content: center;
  align-items: center;

  &.thread-checkbox-active {
    width: 14px;
    min-width: 14px;
    height: 14px;
    line-height: 14px;
  }

  &.thread-item-white-checkbox {
    @include themed() {
      border-color: t("threadCheckbox");
    }
  }

  .thread-checkbox-uncheck {
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 1px solid #808080;
    &.thread-item-white-checkbox {
      @include themed() {
        border-color: t("threadCheckbox");
      }
    }
    &:hover {
      @include themed() {
        border-color: t("threadItemsHoverColor") !important;
      }
    }
  }

  .thread-checkbox-check {
    width: 14px;
    height: 14px;
    border: 1px solid #808080;
    border-radius: 2px;
    background: $sidebar-icon-default;
    color: #fff;
    position: relative;
    svg {
      position: absolute;
      left: -2px;
      top: -2px;
      path:not([fill="none"]) {
        fill: white;
      }
    }
  }
}

.collapsable-menu-list {
  margin-bottom: 1px;

  .collapsable-menu-wrapper {
    width: 95%;
    display: flex;
    margin: auto;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;

    .horizontal-icon-and-text {
      display: flex;
      flex-direction: row;
    }

    .empty {
      width: 10px;
      height: 1px;
    }

    .icon {
      font-size: 16px;
    }
  }
}

.sidebar-collapsed-noti-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #ffff57;
  color: #565656;
}
