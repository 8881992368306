.admin-settings-billing {
  font-size: 16px;
  .current-plan {
    padding: 40px 69px;
    .first-column {
      margin-right: 65px;
    }

    .current-plan-card-container {
      .hr-divider {
        height: 2px;
        margin-left: -18px;
        margin-right: -18px;
      }
      .label-info {
        font-size: 14px;
        color: #707070;
      }
    }

    .notification-banner-wrapper {
      position: absolute;
      top: 0;
      left: 31px;
      right: 31px;
    }
  }

  .transaction-history {
    padding-top: 25px;
    .transaction-history-table-header {
      border-bottom: 1px solid #dee2e6;
      tr {
        th {
          padding-bottom: 5px;
        }
      }
    }
  }

  .filters-container {
    padding: 12px 28px 12px 50px;
  }
}

.billing-add-card-rhs {
  width: 410px;
  padding-top: 38px;
  padding-bottom: 38px;

  .close-icon {
    left: 15px;
    top: 15px;
    cursor: pointer;
  }

  .add-card-title {
    font-size: 16px;
    @include themed() {
      color: t("text");
    }

    margin-bottom: 22px;
  }

  .step-container {
    padding: 30px 38px 38px 30px;
    .step-title {
      font-size: 14px;
      @include themed() {
        color: t("text");
      }
      margin-right: 83px;
    }

    .arrow-title {
      font-size: 12px;
    }
  }

  .switch-container {
    padding: 30px 38px 38px 30px;
  }

  .card-btn:disabled {
    background: #808080;
    border-color: #808080;
  }
  .MuiInput-formControl {
    input {
      @include themed() {
        color: t("text");
      }
    }
  }
  .MuiInput-underline::after {
    @include themed() {
      border-bottom-color: t("primary");
    }
  }
}
