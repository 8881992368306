.poll-setup-modal {
  .modal-dialog {
    width: 500px;
    max-width: 500px;
  }
}

.poll-setup-container {
  padding: 22px 31px;
  max-height: 700px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 20px;
    text-align: center;
  }
  .step-one {
    font-size: 14px;
    color: #202020;
    .chart-title {
      font-size: 12px;
      color: #777777;
      text-align: center;
    }
  }
}
