.input-field-with-edit {
  .form-control {
    display: none;
  }

  &.editing {
    .form-control {
      display: block;
      padding-left: 0;
      border: 0;
      border-bottom: 1px solid #935ef0;
      font-size: 22px;
      color: #122b28;
      font-weight: 600;
      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
    .label {
      font-size: 10px;
      color: #8f8f8f;
    }

    .value {
      display: none;
    }

    .edit {
      display: none;
      vertical-align: baseline;
    }
  }

  .for-password {
    position: relative;

    .edit {
      position: absolute;
      top: 4px;
    }
  }
}
