// User Summary

.user-summary {
  height: 72px;
  line-height: 55px;

  .nav-item {
    height: 100%;
    background-color: #202020;
    position: relative;

    .user-img .text-avatar {
      height: 38px;
      width: 38px;
      line-height: 38px;
    }

    .filter-number {
      color: #808080;
      font-size: 14px;
      font-weight: 500;
    }

    .number {
      display: inline-block;
      color: #202020;
      font-size: 12px;
      font-weight: 500;
      height: 22px;
      width: 22px;
      background-color: #808080;
      text-align: center;
      line-height: 22px;
      border-radius: 50%;
      margin-left: 10px;
      margin-right: 8px;
    }

    .dropdown-toggle::after {
      display: none;
    }

    .company {
      font-size: 12px;
      color: #565656;
    }

    .user-link i {
      color: #808080;
      font-size: 9px;
    }

    .number-row {
      position: absolute;
      left: 64px;
      top: 2px;
    }

    .company-row {
      position: absolute;
      left: 64px;
      top: 22px;

      i {
        color: #565656;
        font-size: 9px;
        margin-left: 6px;
      }
    }
  }
}
// Hubs tab

.hubs-tab {
  background: $primary-light;
  height: 46px;
  position: absolute;
  width: 300px;
  z-index: 9999;

  @media (max-width: 991px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    transition: ease-in-out 0.2s;

    .nav-tabs {
      border: 0;
      border-radius: 0;
      padding: 0;
    }
  }

  .nav-tabs {
    background: $primary-light;
    border-bottom: none;
    min-height: 47px;

    .nav-link {
      align-items: center;
      background: none;
      border: none;
      color: #fff;
      display: inline-flex;
      height: 46px;
      padding: 0 15px;

      &.active {
        background: none;
        border: none;

        svg path {
          fill: rgba(255, 255, 255, 1);
        }
      }

      svg path {
        fill: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.message-view {
  &.left {
    @media (max-width: 991px) {
      left: 0;
      margin-right: 0;
      width: 100% !important;
    }
  }
}

// Panel tabs
.left-panel {
  .fixed-header {
    height: 47px;
    padding: 0;

    .nav-tabs {
      min-height: 47px;
      background: $primary-light;

      .nav-link {
        height: 46px;
        padding: 0 15px;
        color: #fff;
        display: inline-flex;
        align-items: center;
        background: none;
        border: none;

        &.active {
          background: none;
          border: none;

          svg path {
            fill: rgba(255, 255, 255, 1);
          }
        }

        svg path {
          fill: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}

.sidebar-notification-drop-container {
  .dropdown-menu {
    left: 12px !important;
    &::before {
      width: 0;
      height: 0;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-right: 9px solid #ffffff;
      background-color: transparent;

      position: absolute;
      bottom: 10px;
      left: -9px;
      content: "";
      z-index: 1;
    }

    .topnav-dropdown-header {
      background-color: #f8f8f8;
      font-size: 16px !important;
      font-weight: bold;
      height: 48px !important;
      span {
        line-height: 48px !important;
      }
    }
  }

  .show button {
    svg path {
      fill: #ffffff !important;
    }
    span.notification-badge {
      background-color: #ffffff;
    }
  }

  .sidebar-notification-drop {
    background: transparent;
    border: transparent;
    position: relative;
    outline: none;
    box-shadow: none !important;
    margin-left: 7px;

    &:hover,
    &:active {
      background: transparent;
      border: transparent;

      svg path {
        fill: #ffffff !important;
      }

      span.notification-badge {
        background-color: #ffffff;
      }
    }

    &:active,
    &:focus {
      outline: none !important;
    }

    svg path {
      fill: #6c37c9;
    }

    span.notification-badge,
    span.notification-badge-static {
      background-color: #e24e00;
      position: absolute;
      top: 10px;
      right: 14px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
}
