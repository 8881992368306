.unicode-characters-modal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  .modal-dialog {
    margin: 15px;
    width: 50vw;
    max-width: 50vw;
    min-height: 30vh;

    @media (max-width: $screen-lg) {
      width: 80vw !important;
      max-width: 80vw !important;
    }

    @media (max-width: $screen-md) {
      width: 100vw !important;
      max-width: 100vw !important;
      height: 100vh !important;
      margin: 0px !important;
    }
  }

  .unicode-characters-modal-container {
    padding: 13px 30px 24px 30px;
    @media (max-width: $screen-md) {
      overflow-y: auto;
    }
    h1 {
      font-size: 24px;
      font-weight: bold;
    }

    .unicode-characters-modal-title {
      font-size: 1rem;
      font-weight: bold;
    }

    .unicode-characters-modal-message-text {
      @include themed() {
        color: t("fontDarkGray");
      }
    }

    .unicode-characters-modal-label {
      font-weight: normal !important;
      @include themed() {
        color: t("fontDarkGray");
      }
    }

    .unicode-characters-modal-signature-text {
      font-size: 0.9rem;
    }

    .unicode-characters-modal-characters-count {
      @include themed() {
        color: t("fontDarkGray");
      }
    }

    .unicode-character-container {
      padding: 10px;
      background-color: #eb6033;
      border-radius: 5px;
      color: white;
      font-size: 1.2rem;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 10px 5px 5px;
    }
  }
}
