@import "../base/global-theme";

.plus-minus-checkbox {
  .container {
    width: 100%;
    height: 100%;

    input {
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  }

  .custom-checkbox {
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    input {
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }

    &:last-child {
      border-bottom: none;
    }

    .custom-control-label {
      font-size: 16px;
      font-weight: 500;
      width: 100%;
      height: 100%;

      &:after {
        border-radius: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &:before {
        border-radius: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .number {
        color: #b4b4b4;
        font-weight: 400;
        margin-left: 10px;
      }
    }

    &.disabled {
      .custom-control-input:checked ~ .custom-control-label::before {
        background: #96a1ab;
        border: 1px solid #96a1ab;
      }
    }

    &.disabled-archived {
      .custom-control-input:checked ~ .custom-control-label::before {
        @include themed() {
          background: t("primary");
          border-color: t("primary");
        }
        border: 1px solid;
        opacity: 0.5;
      }
    }

    .disabled-border {
      &.custom-control-label::before {
        border: 1px solid #d9d9d9;
      }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      @include themed() {
        background: t("primary");
      }
    }
  }
}

.plus-minus-checkbox-container {
  flex-shrink: 0;
  &.default-size {
    width: 18px;
    height: 18px;
  }

  &.small-size {
    width: 14px;
    height: 14px;
  }

  &.danger-color {
    .custom-control {
      .custom-control-label {
        &::before {
          border: 1px solid #ff5800;
        }
      }
      .custom-control-input:checked ~ .custom-control-label::before {
        background: #ff5800;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      background: rgba(255, 88, 0, 0.4);
    }

    .custom-control-input:not(:disabled):active
      ~ .custom-control-label::before {
      background: rgba(255, 88, 0, 0.4);
    }
  }

  &.primary-color {
    .custom-control {
      .custom-control-label {
        &::before {
          border: 1px solid $primary;
        }
      }
      .custom-control-input:checked ~ .custom-control-label::before {
        background: $primary;
      }
    }
  }

  @extend .plus-minus-checkbox;
}
