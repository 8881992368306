.send-contest-modal {
  .modal-dialog {
    width: 480px;
    max-width: 480px;
  }
}

.send-contest-container {
  padding: 30px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 45px;
  }
}
