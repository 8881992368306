.add-new-user-modal {
  .modal-dialog {
    width: 360px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
}

.add-new-user-container {
  padding: 25px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 25px;
  }

  .add-new-user-section-title {
    @extend .custom-modal-steps-section-title;
    margin-bottom: 28px;
  }

  .description-text {
    font-size: 14px;
    // color: #808080;
    span {
      color: #3e374a;
    }
  }

  .autocompleted-fields {
    opacity: 0;
    transition: opacity 0.3s;
    &.visible {
      opacity: 1;
    }
  }
}

.select-groups-modal {
  .modal-dialog {
    width: 450px;
    @media (max-width: $screen-md) {
      width: auto;
      max-width: auto;
    }
  }
}

.select-groups-container {
  padding: 25px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 25px;
  }
  .search-container {
    max-width: 230px;
  }
  .select-groups-table-header {
    tr th:first-child {
      width: 18px;
    }
  }
  .select-groups-list {
    height: 250px;
  }
}
.admin-info-text {
  background-color: #64c3e0;
  width: 100%;
  text-align: center;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 16px;
  font-size: 14px;
}
