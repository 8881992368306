.delete-payment-method-modal {
  .modal-dialog {
    width: 690px;
    max-width: 690px;
  }
}

.delete-payment-method-container {
  padding: 18px 26px;
  h1 {
    @extend .custom-modal-title;
    margin-bottom: 17px;
  }

  .description-text {
    font-size: 16px;
    color: #808080;
    margin-bottom: 33px;
  }
}
