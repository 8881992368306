.hub-thread-group-table-view_header {
  .hub-thread-group-table-view_header--title {
    margin: 0 0 0 10px;

    font-size: 18px;
    font-weight: 500;
    @include themed() {
      color: t("fontColor");
    }
    vertical-align: middle;
  }

  .hub-thread-group-table-view_header--icon-container {
    width: 38px;
    height: 38px;
    @include themed() {
      background-color: t("iconMidGray");
    }
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 26px;
      height: 26px;
      path:not([fill="none"]) {
        @include themed() {
          fill: t("bg");
        }
      }
    }
  }

  .hub-thread-group-table-view_header--actions {
    margin-left: auto;
    display: flex;

    .hub-thread-group-table-view_header--action-button {
      width: 24px;
      height: 24px;
      margin-right: 12px;

      svg {
        width: 24px;
        height: 24px;

        path:not([fill="none"]) {
          @include themed() {
            fill: t("fontDarkGray");
          }
        }
      }
    }
  }
}

.hub-thread-group-table-view {
  &__show-external {
    @include themed() {
      color: t("searchClear");
    }
  }
}

.hub-thread-view-all-container {
  padding: 15px 15px 15px 25px;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @include themed() {
    border-bottom: 1px solid t("fontDarkGray");
  }

  &:hover,
  &.hover {
    @include themed() {
      background-color: t("filterBackgroundHover");
    }
  }

  .hub-thread-view-all-container--icon-container {
    width: 38px;
    height: 38px;

    @include themed() {
      background-color: t("filterBackgroundHover");
    }
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 22px;
      height: 22px;
      path:not([fill="none"]) {
        @include themed() {
          fill: t("leftMenuText");
        }
      }
    }
  }

  h3 {
    padding: 0;
    margin: 0 10px;
    @include themed() {
      color: t("leftMenuText");
    }
    font-size: 16px;
    font-weight: 400;
  }

  p {
    margin-left: auto;
    margin-bottom: 0;
    @include themed() {
      color: t("fontDarkGray");
    }
  }
}
