.user-settings-greetings {
  @extend .container-flex-column-100;
  .user-settings-greetings-view-tabs-header {
    border: none;
  }

  .user-settings-greetings-table-header {
    tr th {
      @include themed() {
        border-color: t("tableDivider") !important;
      }

      border-top: 1px solid;
    }
    tr:first-child th {
      width: 30px !important;
    }
  }

  .user-settings-greetings-table-body {
    tr {
      cursor: pointer;
      td {
        @media (max-width: $screen-md) {
          padding: 2px;
          margin: 0px;
        }
        @include themed() {
          border-color: t("tableDivider") !important;
        }
      }
    }
    tr:hover {
      @include themed() {
        background: t("bgRHS");
      }
    }

    .active {
      @include themed() {
        background: t("bgRHS");
      }
    }

    .table-audioplayer-wrapper {
      width: 45%;
      @supports (-webkit-touch-callout: none) {
        @media (max-width: $screen-md) {
          width: 100%;
        }
      }
    }
    .themed-color {
      @include themed() {
        color: t("primary");
      }
    }
  }

  .greetings-rhs {
    padding-top: 45px;
    .greetings-avatar-container {
      margin-bottom: 35px;
    }
    .audio-container {
      padding: 30px 25px 20px 25px;
      .text {
        font-size: 14px;
        @include themed() {
          color: t("primary");
        }

        margin-bottom: 0;
        margin-top: 8px;
        margin-left: 5px;
        cursor: pointer;
      }
      @supports (-webkit-touch-callout: none) {
        @media (max-width: $screen-md) {
          padding: 0px;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
        }
        .text-to-voice-player-container {
          @media (max-width: $screen-md) {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 10px;
          }
        }
      }
      .text-container {
        @include themed() {
          color: t("fontColor");
        }
      }
    }
    .status-container {
      padding: 30px 25px 25px 25px;
    }
    .numbers-assigned-container {
      padding: 20px 25px 0 25px;
    }

    .delete-button {
      cursor: pointer;
      margin-top: 45px;
      padding-left: 30px;
    }
  }
  .user-settings-scroll-table {
    @media (max-width: $screen-md) {
      height: calc(100vh - 28vh);
    }
  }
}
