.custom-switch {
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 13px;
  padding-top: 13px;

  &:last-child {
    border-bottom: 0;
  }
  .content {
    padding: 0 !important;
  }

  .custom-control-label::before {
    background: rgba(180, 180, 180, 0.4);
    border: 0;
    height: 16px;
    top: 50%;
    transform: translate(0, -50%);
    width: 31px;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background: rgba(85, 23, 193, 0.4);
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    @include themed() {
      background: t("primary");
    }
    transform: translateX(0.85rem) translateY(-50%);
  }
  .custom-control-label::after {
    background: #fff;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.16));
    height: 13px;
    // top: calc(0.25rem + 1.5px);
    width: 13px;
    top: 50%;
    transform: translate(0, -50%);
  }
}
