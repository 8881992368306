.resolve-conflicts-modal {
  .modal-dialog {
    margin: 15px;
    width: calc(100vw - 90px);
    max-width: calc(100vw - 90px);
    height: calc(100vh - 90px);
    max-height: calc(100vh - 90px);
    margin-left: auto;
  }
  .modal-content {
    height: 100%;
    border-radius: 0;
  }
}

.resolve-conflicts-container {
  height: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  h1 {
    display: flex;
    align-items: center;
    @extend .custom-modal-title;
  }
  .description-text {
    font-size: 14px;
    color: #808080;
    margin-bottom: 36px;
  }

  .selection-action {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    @include themed() {
      background: t("bg");
    }
    .left-side {
      color: #808080;
      font-size: 12px;
    }
  }
}
